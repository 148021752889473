import { createSlice } from '@reduxjs/toolkit';
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {
        user: {
            firstName: ""
        }
      },
      languages: [],
      innerContent: {
        login:false
      },
      currentLanguage: "",
      selectedPackage: null,
      selectedShipping: null,
      shipping_list_count: 0,
      shipping_list: [],
      isShippingLoading:false,
      packages:[]
  },
  reducers: {	
      setShippingCoPoints: (state, { payload }) => {
          state.shippingCoPoints = payload;
      },	
      setSelectedShipping: (state, { payload }) => {
          state.selectedShipping = payload;
      },	
      setIsShippingLoading: (state, { payload }) => {
          state.isShippingLoading = payload;
      },	
      setShipping_list: (state, { payload }) => {
          state.shipping_list = payload;
      },
      setShipping_list_count: (state, { payload }) => {
          state.shipping_list_count = payload;
      },	
      setInnerContent: (state, { payload }) => {
          state.innerContent = payload;
      },	
    getSession: (state, { payload }) => {
          state.userInfo = payload;
      },	
    clearSessionData: (state, { payload }) => {
          state.userInfo = payload;
      },
      setLanguages: (state, { payload }) => {
          state.languages = payload;
      },

      setPackages: (state, { payload }) => {
          state.packages = payload;
      },

      selectPackage: (state, { payload }) => {
          state.selectedPackage = payload;
      },
      onLanguageChange: (state, { payload }) => {
          state.currentLanguage = payload;
      },
  },
});
// Action creators are generated for each case reducer function
export const { clearSessionData, getSession, setShippingCoPoints, setSelectedShipping, setShipping_list, setIsShippingLoading, setShipping_list_count, setInnerContent, setLanguages, onLanguageChange, setPackages, selectPackage } =
  userSlice.actions;

export default userSlice.reducer;
