import { configureStore } from '@reduxjs/toolkit';

import snackbarSlice from '../reducers/snackbarSlicer';
import userSlice from '../reducers/user-reducers/UserSlicer';
export default configureStore({
  reducer: {
    snackbar: snackbarSlice,
    user:userSlice
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});
