/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, Grid, Checkbox, FormControlLabel, Box } from '@mui/material';
import { SelectField, TextField, MultilineField } from '../../style-guide';
import './Forgot.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/user-reducers/UserSlicer';
import { changePassword, forgot_password_request, ping } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import logo from '../../images/logo.png'
import background from '../../images/title/contact-title.jpg'
import loginImage from '../../images/title/login.jpg'
import CloseIcon from '@mui/icons-material/Close';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import ActionButton from '../Common/ActionButton';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from '../../style-guide/Loader';
export const Forgot = (props) => {
    const { isForgot, setIsLogin, setIsRegister, setIsForgot, tokenForCh, isChangePass, setIsChangePass } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));


    const [userName, setUserName] = new useState("");
    const [email, setEmail] = new useState("");
    const [password, setPassword] = new useState("");
    const [confpassword, setConfpassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const goTo = (e) => {
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {

        }
    }
    const forgotPassWord = () => {
        setIsLoading(true)
        forgot_password_request(email, email, function (response) {
            if (response.misc.CODE === 1) {
                setIsLoading(false)
                dispatch(snackbarToggle({ type: 'success', message: "Check email for next action." }));
                setIsForgot(false);
                //  ping();
            } else {
            }
        });

    }
    const changePassword_req = () => {
        if (password?.length < 8) {
            dispatch(snackbarToggle({ type: 'error', message: 'נא להזין סיסמה עם לפחות 8 אותיות.' }));
            return;
        }
        setIsLoading(true)
        changePassword(tokenForCh, password, function (response) {
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: "Password updated." }));
                setIsLoading(false)
                setCookie("SESSION_ID", response.session, 30);
                ping(response.session, function (response) {
                    if (response.misc.CODE === 1) {
                        setCookie("SESSION_ID", response.session, 30);
                        dispatch(getSession(response));
                        navigate("/accounts")
                        setIsChangePass(false);
                        setIsForgot(false);
                        //  ping();
                    } else {
                    }
                })
            } else {
            }
        });

    }
    useEffect(() => {
    }, [userInfo]);
    const [isEye, setIsEye] = new useState(true);
    const [isConfirmEye, setIsConfirmEye] = new useState(true);
    const [isLoading, setIsLoading] = new useState(false);
    return (
        <DialogWithSideBar
            open={isForgot}
            onClose={setIsForgot}
            isOutClose={true}
        >
            <Fragment>
                {isLoading && <Loader isFullDisplay />}
                <Box container className="reg_popup_data">
                    <Box className="popup_tittle">
                        שכחת את הסיסמא
                    </Box>
                    <Box>
                        {!isChangePass && <TextField
                            className='text-field'
                            required
                            dir="rtl"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            label='Email'
                        />
                        }
                        {isChangePass && <Grid container className='position-reletive'>
                            <Grid onClick={() => { setIsEye(!isEye) }} className="prefix-field">
                                {isEye
                                    ? <FaEye style={{ fill: "#625252", width: "100%", height: "30%" }} />
                                    : <FaEyeSlash style={{ fill: "#625252", width: "100%", height: "30%" }} />}
                            </Grid>
                            <TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                label='סיסמה'
                                type={`${isEye ? 'password' : 'text'}`}
                                onKeyDown={e => keyPressed(e)}
                                parentClassName={'prefix-password'}
                            />
                        </Grid>
                        }
                        {isChangePass && 
                            <Grid container className='position-reletive'>
                                <Grid onClick={() => { setIsConfirmEye(!isConfirmEye) }} className="prefix-field">
                                    {isConfirmEye
                                    ? <FaEye style={{ fill: "#625252", width: "100%", height: "30%" }} />
                                    : <FaEyeSlash style={{ fill: "#625252", width: "100%", height: "30%" }} />}
                            </Grid>
                            <TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={confpassword}
                                onChange={event => setConfpassword(event.target.value)}
                                    label='Confirm'
                                    type={`${isConfirmEye ? 'password' : 'text'}`}
                                onKeyDown={e => keyPressed(e)}
                                parentClassName={'prefix-password'}
                            />
                        </Grid>
                        }
                    </Box>
                    <Box className='form-action'>
                        {!isChangePass && <ActionButton className={'submit-action mt-24'} textLabel={'המשך'} onClick={() => forgotPassWord()} />}
                        {isChangePass && <ActionButton disabled={confpassword !== password} className={'submit-action mt-24'} textLabel={'המשך'} onClick={() => changePassword_req()} />}
                    </Box>
                    {/*<Box container item lg={12} justifyContent="center" alignContent="center" className="screen-footer scarlet" onClick={() => { setIsForgot(false); setIsRegister(false); setIsLogin(true);navigate("/home")}} sx={{ mt: 3, cursor: 'pointer' }}>
                        הירשם
                    </Box>
                    <Box container item lg={12} justifyContent="center" alignContent="center" className="screen-footer scarlet" onClick={() => { setIsRegister(true); setIsForgot(false); setIsLogin(false); navigate("/home") }} sx={{ mt: 6, cursor: 'pointer' }}>
                        רישום

                    </Box>*/}
                </Box>
            </Fragment>
        </DialogWithSideBar>
    );
}

export default Forgot;