/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';

import { Box, TextField } from '@mui/material';

const MultilineFieldWrapper = ({ label, value, onChange, required, rows = 2, dir='ltr', className, xs, sm, md, lg }) => {
  return (
    <Box className='form-field-wrapper'>
      <TextField
        fullWidth
        multiline
        rows={rows}
        label={label}
        value={value || ''}
        onChange={onChange}
        required={required}
        dir={dir}
        className={className}
      />
    </Box>
  );
};

MultilineFieldWrapper.defaultProps = {
  label: '',
  value: '',
  required: null,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 2,
};

export default memo(MultilineFieldWrapper);
