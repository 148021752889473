/* eslint-disable react/prop-types */
import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';

const PaperComponent = props => {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const DialogWrapper = ({ open, onHandleCancel, title, children, maxWidth,direction }) => {
  return (
    <Dialog
      open={open}
      onClose={onHandleCancel}
      maxWidth={maxWidth}
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'>
          {title && <DialogTitle
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1, direction: direction }}
        id='draggable-dialog-title'>
        {title}
               <IconButton onClick={onHandleCancel} color='secondary'>
                  <CloseIcon />
              </IconButton>
      </DialogTitle>}
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

DialogWrapper.defaultProps = {
  maxWidth: 'md',
};

export default DialogWrapper;
