/* eslint-disable no-unused-vars */

import { BrowserRouter, Routes, Route, Link, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'
export const Wrapper = ({ children }) => {
    const location = useLocation();
    document.documentElement.scrollTo(0, 0);
    /*useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);*/
    return children
}
export default Wrapper;