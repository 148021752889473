export const STATUS = {
  1: 'IN_PROGRESS',
  2: 'SUCCESS',
  3: 'FAILED',
  4: 'NOT_STARTED',
};
export const URL = {
    'USER_HANDLER': '/user/userHandler',
    'ADMIN_HANDLER': '/admin/adminHandler',
    'SHIPPING_HANDLER': '/shipping/shippingHandler'
}
export const LANGUAGES = ["ENGLISH", "HEBREW"]
export const autoCloseSnackbar = 4000;
//export const chWtMultiplier = 167 / 1000000;
export const chWtMultiplier = 1 / 5000;
export const homeWareHouses = [''];
export const settingsRedbox1 = [
    {
        name: "הלבשה ואופנה",
        subCategory: {
            category1: ["הלבשה", "הנעלה", "שעונים", "תיקים", "תכשיט מלאכותי", "תחפושות"],
            category2: ["6206900000/8", "6405900000/6", "9102190000/5", "4202290000/7", "7117909000/7", "6204230000/4"],
            category3: ["0", "0", "0", "0", "8", "0"],
            category4: ["0", "0", "0", "0", "0", "0"],
        }
    },
    {
        name: "טיפוח ובריאות",
        subCategory: {
            category1: ["מברשת שיניים חשמלית", "משקפיים", "וויטמינים", "קוסמטיקה וטואלט", "בשמים","איפור"],
            category2: ["8509803000/1", "9004900000/4", "2936290000/2", "3304300000/0", "3303000000/5", "3303000000/6"],
            category3: ["0", "0", "0", "0", "0", "0"],
            category4: ["0", "0", "0", "0", "0", "0"],
        }
    },
    {
        name: "מחשבים ואביזרים נילווים",
        subCategory: {
            category1: ["יומן אלקטרוני", "מדפסת למחשב", "ציוד מיחשוב", "לפטופ", "מיקרופונים", "טאבלט", "מכונת צילום", "סורק", "צורב דיסקים", "תקליטורים לקול בלבד", "כללי"],
            category2: ["8471700000/8", "8473300000/8", "8471410000/9", "8471300000/2", "8518109000/3", "8471300000/2", "8443390000/2", "8443320000/7", "8471700000/8", "8523110000/6", "8523110000/6"],
            category3: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        }
    },
    {
        name: "חשמל ואלקטרוניקה",
        subCategory: {
            category1: ["מגבר קול", "מגהץ", "מחשבון", "מטול שקופיות", "מייבש כביסה", "מייבש שיער", "מכונת גילוח", "מכונת כביסה", "מכונת סריגה", "מכונת תפירה", "מכשיר אדים", "מכשיר עיסוי", "ממרט רצפה", "סדין חשמלי", "שואב אבק", "שמיכה חשמלית"
                , "שמיכה חשמלית", "תנור חימום בגז", "תנור חימום בנפט", "תנור חימום חשמלי", "שואב רובוטי", "כללי"],
            category2: ["8518400000/9", "8516400000/3", "8470100000/6", "8528690000/7", "8451210000/3", "8516310000/2", "8510100000/9", "8450110000/6", "8447909000/7", "8452100000/4", "9019200000/9", "9019101900/0", "8479896600/2", "9404901100/3", "8508110000/1", "9404901100/3", "7321810000/3", "7321820000/1", "8508110000/1", "8508110000/1"],
            category3: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "12", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
            
        }
    },
    {
        name: "טלפונים ותקשורת",
        subCategory: {
            category1: ["טלפון אלחוטי", "טלפון סלולרי", "משדר נייד", "משיבון", "פקסימיליה","טלפון קווי"],
            category2: ["8517110000/2", "8517120000/0", "8517629000/6", "8519500000/6", "8443310000/9","8517180000/7"],
            category3: ["0", "0", "0", "0", "0","0"],
            category4: ["0", "0", "0", "0", "0","0"],
            
        }
    },
    {
        name: "כלי עבודה",
        subCategory: {
            category1: ["מקדחה חשמלית", "משור בנזין", "משור חשמלי","סכינים"],
            category2: ["8467219000/0", "8202100000/3", "8467220000/7","8208909000/3"],
            category3: ["10", "10", "10","10"],
            category4: ["0", "0", "0","0"],
            
        }
    },
    {
        name: "רכב ואביזרים",
        subCategory: {
            category1: ["חלקי חילוף לרכב", "מגבר קול לרכב", "מגלה רדאר לרכב", "מושב בטיחותי לתינוק", "קסדות מגן", "רדיו/טייפ נייד", "CD- רדיו לרכב", "רמקולים לרכב", "צמיגים לרכב","רכיבי תאורה לרכב"],
            category2: ["8708999000/3", "8518400000/9", "8526100000/5", "9401711000/1", "6506100000/9", "8527139000/8", "8527290000/3", "8518290000/2", "4011100000/2","8512209000/5"],
            category3: ["60", "0", "0", "0", "0","0","0","0", "0","0"],
            category4: ["12", "0", "0", "0", "0","0","0","0", "0","0"],
            category5: ["75", "0", "0", "0", "0","0","0","0", "0","0"],
        }
    },
    {
        name: "צעצועים ומשחקי וידיאו",
        subCategory: {
            category1: ["קונסולת משחקים","צעצועים"],
            category2: ["9504900000/3", "9503000000/4"],
            category3: ["0", "0"],
            category4: ["0", "0"],
        }
    },
    {
        name: "לבית ולגן",
        subCategory: {
            category1: ["וילונות", "חומרי ניקוי ", "כיורים מנירוסטה", "כיורים קרמיים", "כלי מיטה", "מאזני אדם", "מגבות", "מכסחת דשא", "מפות שולחן", "סכום מכסף", "סכיני גילוח", "ספרים", "עגלת תינוק", "עטים","רהיטים מעץ או ממתכת "],
            category2: ["6303990000/4", "3402909900/8", "7324100000/6", "6910902000/3, 69101090004", "9404901900/6", "8423109000/6", "6302600000/8", "8433190000/5", "6304190000/0", "7114111000/8", "8212200000/1", "4901190000/5", "8715000000/5", "9608100000/0","9403209900/7"],
            category3: ["0", "0", "0", "0", "8", "12", "0", "0", "0", "12", "0", "0", "0", "0","12"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0","0"],
        }
    },
    {
        name: "ספורט, תחביבים ופנאי",
        subCategory: {
            category1: ["נרתיקים לאקדח", "אוהלים", "אוכף", "אופני כושר", "אופניים", "טלסקופ", "כדורי טניס", "משקפת", "ציוד דייג", "ציוד ספורט","ציוד סקי"],
            category2: ["6306290000/5","6306290000/4", "4201100000/0", "9506990000/9", "8712000000/2", "9005801000/1", "9506401000/2", "9005100000/9", "9507900000/6", "9506990000/9","9506190000/7"],
            category3: ["0","0", "10", "0","0", "0", "0","12", "0","0","0"],
            category4: ["0","0", "0", "0","0", "0", "0","0", "0","0","0"],
        }
    },
    {
        name: "מוצרי חשמל למטבח",
        subCategory: {
            category1: ["גריל גז ברביקיו", "גריל חשמלי", "טוחן אשפה", "כיריים חשמליים", "מדיח כלים", "מטחנת קפה", "מיקרוגל", "מכונה להכנת קפה", "מערבל מזון", "מצנם-טוסטר", "סיר בישול חשמלי", "פלטת שבת", "פרקולאטור", "קולט אדים", "קומקום חשמלי","תנור בישול"],
            category2: ["7321110000/0", "8516609000/1", "8509400000/8", "8516600000/1", "8422110000/5", "8509400000/8", "8516500000/2", "8516710000/8", "8509400000/8", "8516720000/6", "8509809000/5", "8516600000/1", "8516710000/8", "8509809000/5", "8516799000/2","8516600000/1"],
            category3: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "12","0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0","0", "0", "0", "0", "0","0"],
            
        }
    },
    {
        name: "נגנים ומערכות צפיה ושמע",
        subCategory: {
            category1: ["אוזניות", "DVD", "וידיאו", "טלויזיה עד 50 אינצ'", "טלויזיה מעל 50 אינצ'", "מיני דיסק מנגן קבצי MP3", "מערכת משולבת", "מקרן וידיאו", "קומפקט דיסק", "קלטות אודיו ריקות", "רדיו", "רמקולים", "רשמקול","שלט רחוק"],
            category2: ["8518301000/9", "8521909000/9", "8521909000/9", "8528729000/2", "8528721000/0", "8519899000/5", "8527139000/8", "8528690000/7", "8527910000/3", "8523291100/9", "8527290000/3", "8518290000/2", "8519819000/2","8543709000/5"],
            category3: ["0","0","0", "0","0", "0", "0", "0", "0", "0", "0","0", "0","0"],
            category4: ["0","0","0", "0","0", "0", "0", "0", "0", "0", "0","0", "0","0"],
            
        }
    },
    {
        name: "צילום",
        subCategory: {
            category1: ["מצלמה דיגיטלית", "מצלמה פוטוגראפית", "מצלמת וידיאו", "סרט צילום", "עדשה למצלמה","מצלמת אבטחה"],
            category2: ["8525800000/0", "9006300000/5", "8525800000/0", "3701990000/2", "9002110000/4","8521901000/7"],
            category3: ["0","0","0", "0", "0","0"],
            category4: ["0","0","0", "0", "0","0"],
            
        }
    },
    {
        name: "כלי נגינה",
        subCategory: {
            category1: ["אורגן חשמלי", "אקורדיונים", "גיטרה ומדולינה", "גיטרה חשמלית", "כלי מיתר עם קשת", "כלי נשיפה", "מפוחית פה", "סקסופון", "פסנתרים","תופים"],
            category2: ["9201900000/6", "9205900000/7", "9202900000/4", "9207900000/3", "9202100000/2", "9205100000/5", "9205100000/5", "9205100000/5", "9201100000/4","9206000000/4"],
            category3: ["0", "0","0", "0", "0", "0", "0","0","0","0"],
            category4: ["0", "0","0", "0", "0", "0", "0","0","0","0"],
            
        }
    },
    /*{
        name: "כלבו",
        subCategory: {
            category1: ["כלבו"],
            category2: ["9201900000/6"],
            category3: ["0"],
            category4: ["0"],
            
        }
    },*/
];
export const settingsRedbox = [
    {
        name: "הלבשה ואופנה",
        subCategory: {
            category1: ["הלבשה", "הנעלה", "שעונים", "תיקים", "תכשיט מלאכותי", "תחפושות","כללי"],
            category2: ["6206900000/8", "6405900000/6", "9102190000/5", "4202290000/7", "7117909000/7", "6204230000/4", "6204230001/4"],
            category3: ["0", "0", "0", "0", "8", "0", "0"],
            category4: ["0", "0", "0", "0", "0", "0", "0"],
        }
    },
    {
        name: "טיפוח ובריאות",
        subCategory: {
            category1: ["מברשת שיניים חשמלית", "משקפיים", "וויטמינים", "קוסמטיקה וטואלט", "בשמים", "איפור", "כללי"],
            category2: ["8509803000/1", "9004900000/4", "2936290000/2", "3304300000/0", "3303000000/5", "3303000000/6", "3303000000/6"],
            category3: ["0", "0", "0", "0", "0", "0", "0"],
            category4: ["0", "0", "0", "0", "0", "0", "0"],
        }
    },
    {
        name: "מחשבים ואביזרים נילווים",
        subCategory: {
            category1: ["יומן אלקטרוני", "מדפסת למחשב", "ציוד מיחשוב", "לפטופ", "מיקרופונים", "טאבלט", "מכונת צילום", "סורק", "צורב דיסקים", "תקליטורים לקול בלבד", "כללי"],
            category2: ["8471700000/8", "8473300000/8", "8471410000/9", "8471300000/2", "8518109000/3", "8471300000/2", "8443390000/2", "8443320000/7", "8471700000/8","8523110000/6","8523110000/6"],
            category3: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        }
    },
    {
        name: "חשמל ואלקטרוניקה",
        subCategory: {
            category1: ["מגבר קול", "מגהץ", "מחשבון", "מטול שקופיות", "מייבש כביסה", "מייבש שיער", "מכונת גילוח", "מכונת כביסה", "מכונת סריגה", "מכונת תפירה", "מכשיר אדים", "מכשיר עיסוי", "ממרט רצפה", "סדין חשמלי", "שואב אבק", "שמיכה חשמלית"
                , "שמיכה חשמלית", "תנור חימום בגז", "תנור חימום בנפט", "תנור חימום חשמלי", "שואב רובוטי", "כללי"],
            category2: ["8518400000/9", "8516400000/3", "8470100000/6", "8528690000/7", "8451210000/3", "8516310000/2", "8510100000/9", "8450110000/6", "8447909000/7", "8452100000/4", "9019200000/9", "9019101900/0", "8479896600/2", "9404901100/3", "8508110000/1", "9404901100/3", "7321810000/3", "7321820000/1","8508110000/1","8508110000/1"],
            category3: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "12", "0", "0", "0", "0", "0", "0", "0","0","0","0","0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0","0","0","0","0"],
            
        }
    },
    {
        name: "טלפונים ותקשורת",
        subCategory: {
            category1: ["טלפון אלחוטי", "טלפון סלולרי", "משדר נייד", "משיבון", "פקסימיליה", "טלפון קווי", "כללי"],
            category2: ["8517110000/2", "8517120000/0", "8517629000/6", "8519500000/6", "8443310000/9","8517180000/7","8517180000/7"],
            category3: ["0", "0", "0", "0", "0","0","0"],
            category4: ["0", "0", "0", "0", "0","0","0"],
            
        }
    },
    {
        name: "כלי עבודה",
        subCategory: {
            category1: ["מקדחה חשמלית", "משור בנזין", "משור חשמלי", "סכינים", "כללי"],
            category2: ["8467219000/0", "8202100000/3", "8467220000/7","8208909000/3","8208909000/3"],
            category3: ["10", "10", "10","10","0"],
            category4: ["0", "0", "0","0","0"],
            
        }
    },
    
    {
        name: "צעצועים ומשחקי וידיאו",
        subCategory: {
            category1: ["קונסולת משחקים", "צעצועים", "כללי"],
            category2: ["9504900000/3", "9503000000/4", "9503000000/4"],
            category3: ["0", "0", "0"],
            category4: ["0", "0", "0"],
        }
    },
    {
        name: "לבית ולגן",
        subCategory: {
            category1: ["וילונות", "חומרי ניקוי ", "כיורים מנירוסטה", "כיורים קרמיים", "כלי מיטה", "מאזני אדם", "מגבות", "מכסחת דשא", "מפות שולחן", "סכום מכסף", "סכיני גילוח", "ספרים", "עגלת תינוק", "עטים", "רהיטים מעץ או ממתכת ", "כללי"],
            category2: ["6303990000/4", "3402909900/8", "7324100000/6", "6910902000/3, 69101090004", "9404901900/6", "8423109000/6", "6302600000/8", "8433190000/5", "6304190000/0", "7114111000/8", "8212200000/1", "4901190000/5", "8715000000/5", "9608100000/0","9403209900/7","9403209900/7"],
            category3: ["0", "0", "0", "0", "8", "12", "0", "0", "0", "12", "0", "0", "0", "0","12","0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0","0","0"],
        }
    },
    {
        name: "ספורט, תחביבים ופנאי",
        subCategory: {
            category1: ["נרתיקים לאקדח", "אוהלים", "אוכף", "אופני כושר", "אופניים", "טלסקופ", "כדורי טניס", "משקפת", "ציוד דייג", "ציוד ספורט", "ציוד סקי", "כללי"],
            category2: ["6306290000/5","6306290000/4", "4201100000/0", "9506990000/9", "8712000000/2", "9005801000/1", "9506401000/2", "9005100000/9", "9507900000/6", "9506990000/9","9506190000/7","9506190000/7"],
            category3: ["0", "0", "10", "0","0", "0", "0","12", "0","0","0","0"],
            category4: ["0", "0", "0", "0","0", "0", "0","0", "0","0","0","0"],
        }
    },
    {
        name: "מוצרי חשמל למטבח",
        subCategory: {
            category1: ["גריל גז ברביקיו", "גריל חשמלי", "טוחן אשפה", "כיריים חשמליים", "מדיח כלים", "מטחנת קפה", "מיקרוגל", "מכונה להכנת קפה", "מערבל מזון", "מצנם-טוסטר", "סיר בישול חשמלי", "פלטת שבת", "פרקולאטור", "קולט אדים", "קומקום חשמלי", "תנור בישול", "כללי"],
            category2: ["7321110000/0", "8516609000/1", "8509400000/8", "8516600000/1", "8422110000/5", "8509400000/8", "8516500000/2", "8516710000/8", "8509400000/8", "8516720000/6", "8509809000/5", "8516600000/1", "8516710000/8", "8509809000/5", "8516799000/2","8516600000/1","8516600000/1"],
            category3: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "12","0","0"],
            category4: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0","0", "0", "0", "0", "0","0","0"],
            
        }
    },
    {
        name: "נגנים ומערכות צפיה ושמע",
        subCategory: {
            category1: ["אוזניות", "DVD", "וידיאו", "טלויזיה עד 50 אינצ'", "טלויזיה מעל 50 אינצ'", "מיני דיסק מנגן קבצי MP3", "מערכת משולבת", "מקרן וידיאו", "קומפקט דיסק", "קלטות אודיו ריקות", "רדיו", "רמקולים", "רשמקול", "שלט רחוק", "כללי"],
            category2: ["8518301000/9", "8521909000/9", "8521909000/9", "8528729000/2", "8528721000/0", "8519899000/5", "8527139000/8", "8528690000/7", "8527910000/3", "8523291100/9", "8527290000/3", "8518290000/2", "8519819000/2","8543709000/5","8543709000/5"],
            category3: ["0","0","0", "0","0", "0", "0", "0", "0", "0", "0","0", "0","0","0"],
            category4: ["0","0","0", "0","0", "0", "0", "0", "0", "0", "0","0", "0","0","0"],
            
        }
    },
    {
        name: "צילום",
        subCategory: {
            category1: ["מצלמה דיגיטלית", "מצלמה פוטוגראפית", "מצלמת וידיאו", "סרט צילום", "עדשה למצלמה", "מצלמת אבטחה", "כללי"],
            category2: ["8525800000/0", "9006300000/5", "8525800000/0", "3701990000/2", "9002110000/4","8521901000/7","8521901000/7"],
            category3: ["0","0","0", "0", "0","0","0"],
            category4: ["0","0","0", "0", "0","0","0"],
            
        }
    },
    {
        name: "כלי נגינה",
        subCategory: {
            category1: ["אורגן חשמלי", "אקורדיונים", "גיטרה ומדולינה", "גיטרה חשמלית", "כלי מיתר עם קשת", "כלי נשיפה", "מפוחית פה", "סקסופון", "פסנתרים", "תופים", "כללי"],
            category2: ["9201900000/6", "9205900000/7", "9202900000/4", "9207900000/3", "9202100000/2", "9205100000/5", "9205100000/5", "9205100000/5", "9201100000/4","9206000000/4","9206000000/4"],
            category3: ["0", "0","0", "0", "0", "0", "0","0","0","0","0"],
            category4: ["0", "0","0", "0", "0", "0", "0","0","0","0","0"],
            
        }
    },
    /*{
        name: "רכב אביזרים",
        subCategory: {
            category1: ["דיבורית לרכב", "התקני ריסון לילדים", "וילונות לרכב", "יחידת בקרת שיוט לרכב", "כיסויי מושבים", "מגבה מטלטל לרכב", "מגברים לרכב", "מוצרי פלסטיק לרכב", "מוצרים לציוד בלימה", "מסכים לרכב", "מערכות ניווט", "מערכת מולטימדיה לרכב", "מקררים לרכב", "משולש אזהרה", "נוזל בלמים", "סרטים זוהרים", "רמקולים לרכב", "שטיחים לרכב מגומי"],
            category2: ["8518-5040/4", "9401-7100/2", "6303-9200/9", "9032-8991/6", "6304-9300/5", "8425-4200/3", "8518-4000/7", "3926-9089/7", "6813", "8528-5990/9", "8526-9110/4", "8528-5990/9", "8418-6940/7", "3926-9090/5", "3819-0090/1", "3919-1090/8", "8518-2900/2", "4016-9100/1"],
            // category2: ["8708999000/3", "8518400000/9", "8526100000/5", "9401711000/1", "6506100000/9", "8527139000/8", "8527290000/3", "8518290000/2", "8518-2900/2","4016-9100/1"],
            category3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            category4: [0, 0, 0, 19.5, 0, 0, 0, 19.5, 0, 0, 0, 0, 0, 19.5, 0, 19.5, 0, 0],
            //category5: ["0", "40", "0", "40", "0", "40", "0", "0", "0", "0"],
            category5: [{ value: 0, isCar: false }, { value: 40, isCar: false }, { value: 0, isCar: false }, { value: 40, isCar: true }, { value: 0, isCar: false }, { value: 40, isCar: false }, { value: 0, isCar: false }, { value: 0, isCar: true }, { value: 40, isCar: false }, { value: 0, isCar: false }, { value: 0, isCar: false }, { value: 0, isCar: false }, { value: 0, isCar: false }, { value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 0, isCar: false }, { value: 0, isCar: false }],
        }
    },*/
    {
        //name: "רכב חלקי חילוף",
        name: "רכב חלקי חילוף  ואביזרים",//susheel
        /*subCategory: {
            category1: ["חלקי חילוף לרכב","ABS מערכת", "תופי בלם", "דיסקיות", "רפידות", "שמשות לרכב", "ערכת בלמים", "רכיבי תאורה לרכב", "צמיגים לרכב", "רמקולים לרכב", "קסדות מגן", "מכסה מנוע", "מושב בטיחותי לתינוק","צמיגים לאופנוע"],
            category2: ["4013-1000/8", "8708-8090/3", "8481-8019.5/0", "8708-2990/0", "8526-1000/5", "8708-9490/4", "8708-7090/4", "8708-9950/7", "8708-3099/9", "8708-9500/0", "8708-1090/0", "850-4000/2", "8708-9919.5/6", "8414-8011/4", "9401-2000/9", "8407-3490/9", "8421-2300/2", "7009-1000/3", "8708-2290/5", "8413-3010/3", "8539-509/1", "8708-9290/8", "850-2090/5", "4009-4210/8", "4011-1000/2", "8708-9190/0", "8708-3019/7"],
            category3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            category4: [19.5,0,0,0,0,19.5,19.5,0,0,0,0,19.5,0,0],
            //category5: ["0", "40", "0", "40", "0", "40", "0", "0", "0", "0"],
            category5: [
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 0, isCar: false },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
            ],
        }*/
        subCategory: {
            category1: ["מנוע", "תיבות הילוכים/ גלי הינע", "אגזוזים (רכבי אספנות בלבד)", "פנסים", "מראות",
                "חלקי ברקס (רכבי אספנות בלבד)", "חלקי היגוי (רכבי אספנות בלבד)", "גלגלים/צמיגים (רכבי אספנות בלבד)",
                "חלקי פח כולל זכוכיות", "קישוטים", "מושבים לרכב", "חלקי פנים וקישוטים", "מולטימדיה",
                "אביזרי רכב", "מערכת קירור"],
            category2: ["4013-1000/8", "4013-1000/7", "4013-1000/6", "4013-1000/5", "4013-1000/4", "4013-1000/3",
                "4013-1000/2", "4013-1000/1", "4013-1000/0", "4013-1001/0", "4013-1001/1", "4013-1001/2",
                "4013-1001/3","4013-1001/4","4013-1001/5"],
            category3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            category4: [19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 0, 19.5],
            //category5: ["0", "40", "0", "40", "0", "40", "0", "0", "0", "0"],
            category5: [
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 40, isCar: true },
                { value: 0, isCar: false },
                { value: 40, isCar: true }
            ],
        }
        /*subCategory: {
            category1: ["אבובים לרכב", "בולמי זעזועים", "ברזים ושסתומים לרכב", "גגונים ונשיאת ציוד", "גלאי רדאר", "גלגלי הגה", "גלגלי כביש", "ווי גרירה", "חלקי מערכות בלימה", "כריות בטיחות מתנפחות", "לוחות זיהוי לרכב", "מגבים לרכב", "מגיני בוץ לרכב", "מדחס לרכב", "מושבים לרכב", "מנועי רכב", "מסנני דלק/שמן לרכב", "מראות לרכב", "מרכב רכב פגושים", "משאבות בלימה וקרור לרכב", "נורות לרכב", "עמעמי פליטה לרכב", "ציוד התראה ותאורה לרכב", "צינורות למרכות בילום אוויר", "צמיגים לרכב", "רדיאטור לרכב", "רפידות בלימה"],
            category2: ["4013-1000/8", "8708-8090/3", "8481-8019.5/0", "8708-2990/0", "8526-1000/5", "8708-9490/4", "8708-7090/4", "8708-9950/7", "8708-3099/9", "8708-9500/0", "8708-1090/0", "850-4000/2", "8708-9919.5/6", "8414-8011/4", "9401-2000/9", "8407-3490/9", "8421-2300/2", "7009-1000/3", "8708-2290/5", "8413-3010/3", "8539-509/1", "8708-9290/8", "850-2090/5", "4009-4210/8", "4011-1000/2", "8708-9190/0", "8708-3019/7"],
            category3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            category4: [0, 19.5, 19.5, 19.5, 19.5, 0, 19.5, 19.5, 19.5, 19.5, 0, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 19.5, 0],
            //category5: ["0", "40", "0", "40", "0", "40", "0", "0", "0", "0"],
            category5: [{ value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 0, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: false }, { value: 0, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 0, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: false }],
        }*/
    },
    /*{
        name: "אופנועים אביזרים",
        subCategory: {
            category1: ["קסדות מגן"],
            category2: ["6506-1000/9"],
            category3: [0],
            category4: [0],
            //category5: ["0", "40", "0", "40", "0", "40", "0", "0", "0", "0"],
            category5: [{ value: 40, isCar: false }],
        }
    },*/
   /* {
        name: "אופנועים חלקי חילוף",
        subCategory: {
            category1: ["חגורת בטיחות", "כבלי מעצור", "מושבים", "מזלגות", "מיכל דלק", "מערכת ABS", "נעלי רפידות,דיסקאות ותופי בלימה", "עממים", "שלדה"],
            category2: ["8714-1090/8", "8714-1090/8", "8714-1010/6", "8714-1090/8", "8714-1090/8", "8714-1020/5", "8714-1020/5", "8714-1090/8", "8714-1090/8"],
            category3: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            category4: [19.5, 19.5, 19.5, 19.5, 19.5, 0, 0, 19.5, 19.5],
            //category5: ["0", "40", "0", "40", "0", "40", "0", "0", "0", "0"],
            category5: [{ value: 40, isCar: true }, { value: 40, isCar: true }, { value: 0, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: true }, { value: 40, isCar: false }, { value: 40, isCar: false }, { value: 40, isCar: true }, { value: 40, isCar: true }],
        }
    },*/
    {
        name: "מוצרי מזון",
        subCategory: {
            category1: ["מוצרי מזון (לא כולל תבלינים)", "כללי"],
            category2: ["8714-1090/8","8714-1090/8"],
            category3: [0,0],
            category4: [0,0],
        }
    },
    /*{
        name: "כלבו",
        subCategory: {
            category1: ["כלבו"],
            category2: ["9201900000/6"],
            category3: ["0"],
            category4: ["0"],
            
        }
    },*/
];
export const weightAndVal =
[{ wt: 1, val: 12 },
{ wt: 1.1, val: 12.4 },
{ wt: 1.2, val: 12.8 },
{ wt: 1.3, val: 13.2 },
{ wt: 1.4, val: 13.6 },
{ wt: 1.5, val: 14 },
{ wt: 1.6, val: 14.4 },
{ wt: 1.7, val: 14.8 },
{ wt: 1.8, val: 15.2 },
{ wt: 1.9, val: 15.6 },
{ wt: 2, val: 16 },
{ wt: 2.1, val: 16.4 },
{ wt: 2.2, val: 16.8 },
{ wt: 2.3, val: 17.2 },
{ wt: 2.4, val: 17.6 },
{ wt: 2.5, val: 18 },
{ wt: 2.6, val: 18.4 },
{ wt: 2.7, val: 18.8 },
{ wt: 2.8, val: 19.2 },
{ wt: 2.9, val: 19.6 },
{ wt: 3, val: 20 },
{ wt: 3.1, val: 20.4 },
{ wt: 3.2, val: 20.8 },
{ wt: 3.3, val: 21.2 },
{ wt: 3.4, val: 21.6 },
{ wt: 3.5, val: 22 },
{ wt: 3.6, val: 22.4 },
{ wt: 3.7, val: 22.8 },
{ wt: 3.8, val: 23.2 },
{ wt: 3.9, val: 23.6 },
{ wt: 4, val: 24 },
{ wt: 4.1, val: 24.4 },
{ wt: 4.2, val: 24.8 },
{ wt: 4.3, val: 25.2 },
{ wt: 4.4, val: 25.6 },
{ wt: 4.5, val: 26 },
{ wt: 4.6, val: 26.4 },
{ wt: 4.7, val: 26.8 },
{ wt: 4.8, val: 27.2 },
{ wt: 4.9, val: 27.6 },
{ wt: 5, val: 28 },
{ wt: 5.1, val: 28.4 },
{ wt: 5.2, val: 28.8 },
{ wt: 5.3, val: 29.2 },
{ wt: 5.4, val: 29.6 },
{ wt: 5.5, val: 30 },
{ wt: 5.6, val: 30.4 },
{ wt: 5.7, val: 30.8 },
{ wt: 5.8, val: 31.2 },
{ wt: 5.9, val: 31.6 },
{ wt: 6, val: 32 },
{ wt: 6.1, val: 32.4 },
{ wt: 6.2, val: 32.8 },
{ wt: 6.3, val: 33.2 },
{ wt: 6.4, val: 33.6 },
{ wt: 6.5, val: 34 },
{ wt: 6.6, val: 34.4 },
{ wt: 6.7, val: 34.8 },
{ wt: 6.8, val: 35.2 },
{ wt: 6.9, val: 35.6 },
{ wt: 7, val: 36 },
{ wt: 7.1, val: 36.4 },
{ wt: 7.2, val: 36.8 },
{ wt: 7.3, val: 37.2 },
{ wt: 7.4, val: 37.6 },
{ wt: 7.5, val: 38 },
{ wt: 7.6, val: 38.4 },
{ wt: 7.7, val: 38.8 },
{ wt: 7.8, val: 39.2 },
{ wt: 7.9, val: 39.6 },
{ wt: 8, val: 40 },
{ wt: 8.1, val: 40.4 },
{ wt: 8.2, val: 40.8 },
{ wt: 8.3, val: 41.2 },
{ wt: 8.4, val: 41.6 },
{ wt: 8.5, val: 42 },
{ wt: 8.6, val: 42.4 },
{ wt: 8.7, val: 42.8 },
{ wt: 8.8, val: 43.2 },
{ wt: 8.9, val: 43.6 },
{ wt: 9, val: 44 },
{ wt: 9.1, val: 44.4 },
{ wt: 9.2, val: 44.8 },
{ wt: 9.3, val: 45.2 },
{ wt: 9.4, val: 45.6 },
{ wt: 9.5, val: 46 },
{ wt: 9.6, val: 46.4 },
{ wt: 9.7, val: 46.8 },
{ wt: 9.8, val: 47.2 },
{ wt: 9.9, val: 47.6 },
{ wt: 10, val: 48 },
{ wt: 10.1, val: 48.4 },
{ wt: 10.2, val: 48.8 },
{ wt: 10.3, val: 49.2 },
{ wt: 10.4, val: 49.6 },
{ wt: 10.5, val: 50 },
{ wt: 10.6, val: 50.4 },
{ wt: 10.7, val: 50.8 },
{ wt: 10.8, val: 51.2 },
{ wt: 10.9, val: 51.6 },
{ wt: 11, val: 52 },
{ wt: 11.1, val: 52.4 },
{ wt: 11.2, val: 52.8 },
{ wt: 11.3, val: 53.2 },
{ wt: 11.4, val: 53.6 },
{ wt: 11.5, val: 54 },
{ wt: 11.6, val: 54.4 },
{ wt: 11.7, val: 54.8 },
{ wt: 11.8, val: 55.2 },
{ wt: 11.9, val: 55.6 },
{ wt: 12, val: 56 },
{ wt: 12.1, val: 56.4 },
{ wt: 12.2, val: 56.8 },
{ wt: 12.3, val: 57.2 },
{ wt: 12.4, val: 57.6 },
{ wt: 12.5, val: 58 },
{ wt: 12.6, val: 58.4 },
{ wt: 12.7, val: 58.8 },
{ wt: 12.8, val: 59.2 },
{ wt: 12.9, val: 59.6 },
{ wt: 13, val: 60 },
{ wt: 13.1, val: 60.4 },
{ wt: 13.2, val: 60.8 },
{ wt: 13.3, val: 61.2 },
{ wt: 13.4, val: 61.6 },
{ wt: 13.5, val: 62 },
{ wt: 13.6, val: 62.4 },
{ wt: 13.7, val: 62.8 },
{ wt: 13.8, val: 63.2 },
{ wt: 13.9, val: 63.6 },
{ wt: 14, val: 64 },
{ wt: 14.1, val: 64.4 },
{ wt: 14.2, val: 64.8 },
{ wt: 14.3, val: 65.2 },
{ wt: 14.4, val: 65.6 },
{ wt: 14.5, val: 66 },
{ wt: 14.6, val: 66.4 },
{ wt: 14.7, val: 66.8 },
{ wt: 14.8, val: 67.2 },
{ wt: 14.9, val: 67.6 },
{ wt: 15, val: 68 },
{ wt: 15.1, val: 68.4 },
{ wt: 15.2, val: 68.8 },
{ wt: 15.3, val: 69.2 },
{ wt: 15.4, val: 69.6 },
{ wt: 15.5, val: 70 },
{ wt: 15.6, val: 70.4 },
{ wt: 15.7, val: 70.8 },
{ wt: 15.8, val: 71.2 },
{ wt: 15.9, val: 71.6 },
{ wt: 16, val: 72 },
{ wt: 16.1, val: 72.4 },
{ wt: 16.2, val: 72.8 },
{ wt: 16.3, val: 73.2 },
{ wt: 16.4, val: 73.6 },
{ wt: 16.5, val: 74 },
{ wt: 16.6, val: 74.4 },
{ wt: 16.7, val: 74.8 },
{ wt: 16.8, val: 75.2 },
{ wt: 16.9, val: 75.6 },
{ wt: 17, val: 76 },
{ wt: 17.1, val: 76.4 },
{ wt: 17.2, val: 76.8 },
{ wt: 17.3, val: 77.2 },
{ wt: 17.4, val: 77.6 },
{ wt: 17.5, val: 78 },
{ wt: 17.6, val: 78.4 },
{ wt: 17.7, val: 78.8 },
{ wt: 17.8, val: 79.2 },
{ wt: 17.9, val: 79.6 },
{ wt: 18, val: 80 },
{ wt: 18.1, val: 80.4 },
{ wt: 18.2, val: 80.8 },
{ wt: 18.3, val: 81.2 },
{ wt: 18.4, val: 81.6 },
{ wt: 18.5, val: 82 },
{ wt: 18.6, val: 82.4 },
{ wt: 18.7, val: 82.8 },
{ wt: 18.8, val: 83.2 },
{ wt: 18.9, val: 83.6 },
{ wt: 19, val: 84 },
{ wt: 19.1, val: 84.4 },
{ wt: 19.2, val: 84.8 },
{ wt: 19.3, val: 85.2 },
{ wt: 19.4, val: 85.6 },
{ wt: 19.5, val: 86 },
{ wt: 19.6, val: 86.4 },
{ wt: 19.7, val: 86.8 },
{ wt: 19.8, val: 87.2 },
{ wt: 19.9, val: 87.6 },
{ wt: 20, val: 88 },
{ wt: 20.1, val: 88.4 },
{ wt: 20.2, val: 88.8 },
{ wt: 20.3, val: 89.2 },
{ wt: 20.4, val: 89.6 },
{ wt: 20.5, val: 90 },
{ wt: 20.6, val: 90.4 },
{ wt: 20.7, val: 90.8 },
{ wt: 20.8, val: 91.2 },
{ wt: 20.9, val: 91.6 },
{ wt: 21, val: 92 },
{ wt: 21.1, val: 92.4 },
{ wt: 21.2, val: 92.8 },
{ wt: 21.3, val: 93.2 },
{ wt: 21.4, val: 93.6 },
{ wt: 21.5, val: 94 },
{ wt: 21.6, val: 94.4 },
{ wt: 21.7, val: 94.8 },
{ wt: 21.8, val: 95.2 },
{ wt: 21.9, val: 95.6 },
{ wt: 22, val: 96 },
{ wt: 22.1, val: 96.4 },
{ wt: 22.2, val: 96.8 },
{ wt: 22.3, val: 97.2 },
{ wt: 22.4, val: 97.6 },
{ wt: 22.5, val: 98 },
{ wt: 22.6, val: 98.4 },
{ wt: 22.7, val: 98.8 },
{ wt: 22.8, val: 99.2 },
{ wt: 22.9, val: 99.6 },
{ wt: 23, val: 100 },
{ wt: 23.1, val: 100.4 },
{ wt: 23.2, val: 100.8 },
{ wt: 23.3, val: 101.2 },
{ wt: 23.4, val: 101.6 },
{ wt: 23.5, val: 102 },
{ wt: 23.6, val: 102.4 },
{ wt: 23.7, val: 102.8 },
{ wt: 23.8, val: 103.2 },
{ wt: 23.9, val: 103.6 },
{ wt: 24, val: 104 },
{ wt: 24.1, val: 104.4 },
{ wt: 24.2, val: 104.8 },
{ wt: 24.3, val: 105.2 },
{ wt: 24.4, val: 105.6 },
{ wt: 24.5, val: 106 },
{ wt: 24.6, val: 106.4 },
{ wt: 24.7, val: 106.8 },
{ wt: 24.8, val: 107.2 },
{ wt: 24.9, val: 107.6 },
{ wt: 25, val: 108 },
{ wt: 25.1, val: 108.4 },
{ wt: 25.2, val: 108.8 },
{ wt: 25.3, val: 109.2 },
{ wt: 25.4, val: 109.6 },
{ wt: 25.5, val: 110 },
{ wt: 25.6, val: 110.4 },
{ wt: 25.7, val: 110.8 },
{ wt: 25.8, val: 111.2 },
{ wt: 25.9, val: 111.6 },
{ wt: 26, val: 112 },
{ wt: 26.1, val: 112.4 },
{ wt: 26.2, val: 112.8 },
{ wt: 26.3, val: 113.2 },
{ wt: 26.4, val: 113.6 },
{ wt: 26.5, val: 114 },
{ wt: 26.6, val: 114.4 },
{ wt: 26.7, val: 114.8 },
{ wt: 26.8, val: 115.2 },
{ wt: 26.9, val: 115.6 }]


export const cc_years = [{
    id: 1, name: 22
}, {
    id: 2, name: 23
}, {
    id: 3, name: 24
}, {
    id: 4, name: 25
}, {
    id: 5, name: 26
}, {
    id: 6, name: 27
}, {
    id: 7, name: 28
}, {
    id: 8, name: 29
}, {
    id: 9, name: 30
}, {
    id: 10, name: 31
}, {
    id: 11, name: 32
}, {
    id: 12, name: 33
}, {
    id: 13, name: 34
}]
export const cc_months = [{
    id: 1, name: "01"
}, {
    id: 2, name: "02"
}, {
    id: 3, name: "03"
}, {
    id: 4, name: "04"
}, {
    id: 5, name: "05"
}, {
    id: 6, name: "06"
}, {
    id: 7, name: "07"
}, {
    id: 8, name: "08"
}, {
    id: 9, name: "09"
}, {
    id: 10, name: "10"
}, {
    id: 11, name: "11"
}, {
    id: 12, name: "12"
}]

export const shippingCoPoints = [
    {
        "n_code": "3613",
        "city": "אבו גוש",
        "line": "",
        "latitude": "31.80686",
        "distribution_area": "",
        "city_code": "472",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "108",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הפול",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר מרקט סולטן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 11:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.10769"
    },
    {
        "n_code": "1994",
        "city": "אבו סנאן",
        "line": "",
        "latitude": "32.959702",
        "distribution_area": "",
        "city_code": "473",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אבו סנאן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מוראד פארם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 16-22 ו' 14-22",
        "mesirot_yn": "y",
        "longitude": "35.171272"
    },
    {
        "n_code": "1440",
        "city": "אבו סנאן",
        "line": "",
        "latitude": "32.95992715",
        "distribution_area": "",
        "city_code": "473",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אבו סנאן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תצוגת אחים גמעה דרך סולטן אל אטרש",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים ראשון עד שישי 9:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.16751036"
    },
    {
        "n_code": "3820",
        "city": "אבטין",
        "line": "",
        "latitude": "32.76104",
        "distribution_area": "",
        "city_code": "652",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אבטין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "S.M Electra",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:30-21:00 ו'10:30-16:30",
        "mesirot_yn": "y",
        "longitude": "35.11172"
    },
    {
        "n_code": "4099",
        "city": "אבטליון",
        "line": "",
        "latitude": "32.838690",
        "distribution_area": "",
        "city_code": "1275",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אבטליון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בית הבד אבטליון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "בית הבד ימים א-ה 9:00-16:00 ו 9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.350201"
    },
    {
        "n_code": "3218",
        "city": "אביבים",
        "line": "",
        "latitude": "33.088822",
        "distribution_area": "",
        "city_code": "1115",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9o8y",
        "house": "1",
        "distribution_area_desc": "",
        "street": "חיטה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר אביבים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-13:00/16:00-20:00 ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.471781"
    },
    {
        "n_code": "4847",
        "city": "אביעזר",
        "line": "",
        "latitude": "31.6824328287616",
        "distribution_area": "",
        "city_code": "1070",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "אביעזר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט יוסי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-19:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.019370738"
    },
    {
        "n_code": "2207",
        "city": "אבן יהודה",
        "line": "",
        "latitude": "32.279313",
        "distribution_area": "",
        "city_code": "182",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "114",
        "house": "3",
        "distribution_area_desc": "",
        "street": "העצמאות",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "א-קוויפ e-quip",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-19  ו'9-14",
        "mesirot_yn": "y",
        "longitude": "34.889882"
    },
    {
        "n_code": "3232",
        "city": "אבן יהודה",
        "line": "",
        "latitude": "32.269821",
        "distribution_area": "",
        "city_code": "182",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "132",
        "house": "68",
        "distribution_area_desc": "",
        "street": "המיסדים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פינת ההפתעות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-18:30 ו' 8:30-14:30",
        "mesirot_yn": "y",
        "longitude": "34.887366"
    },
    {
        "n_code": "1243",
        "city": "אבן יהודה",
        "line": "",
        "latitude": "32.27973672",
        "distribution_area": "",
        "city_code": "182",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "118",
        "house": "8",
        "distribution_area_desc": "",
        "street": "המחתרת",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "צרכנית האופק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ב-ד-ה :00 8:30-14:0017:00-20 ג' ו' 8:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.88460803"
    },
    {
        "n_code": "3462",
        "city": "אבני איתן",
        "line": "",
        "latitude": "32.821361",
        "distribution_area": "",
        "city_code": "4011",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "3",
        "distribution_area_desc": "",
        "street": "אבני איתן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "משק רם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-19:00  ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.766249"
    },
    {
        "n_code": "3249",
        "city": "אבני חפץ",
        "line": "",
        "latitude": "32.286672",
        "distribution_area": "",
        "city_code": "3793",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "107",
        "house": "2",
        "distribution_area_desc": "",
        "street": "גלעד",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פרוג",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-17:30",
        "mesirot_yn": "y",
        "longitude": "35.072039"
    },
    {
        "n_code": "2230",
        "city": "אום אל-פחם",
        "line": "",
        "latitude": "32.522202",
        "distribution_area": "",
        "city_code": "2710",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6506",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שכ ג'דוע",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "דוקטור פיטנס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 13-21 ש' 12-21",
        "mesirot_yn": "y",
        "longitude": "35.159483"
    },
    {
        "n_code": "3471",
        "city": "אום אל-פחם",
        "line": "",
        "latitude": "32.536604",
        "distribution_area": "",
        "city_code": "2710",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "157",
        "house": "50",
        "distribution_area_desc": "",
        "street": "עין אבראהים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט דחבור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 6:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.144734"
    },
    {
        "n_code": "2693",
        "city": "אומן",
        "line": "",
        "latitude": "32.564376",
        "distribution_area": "",
        "city_code": "1108",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אומן",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט אומן בע''מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-21  ו' 7-15",
        "mesirot_yn": "y",
        "longitude": "35.242759"
    },
    {
        "n_code": "4366",
        "city": "אופקים",
        "line": "",
        "latitude": "31.319213",
        "distribution_area": "",
        "city_code": "31",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "109",
        "house": "25",
        "distribution_area_desc": "",
        "street": "קבוץ גלויות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מרקט S",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:30-20:00 ו 7:30-15:00",
        "mesirot_yn": "y",
        "longitude": "34.614371"
    },
    {
        "n_code": "2122",
        "city": "אופקים",
        "line": "",
        "latitude": "31.302930",
        "distribution_area": "",
        "city_code": "31",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "308",
        "house": "20",
        "distribution_area_desc": "",
        "street": "ישעיהו",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סופר סמדר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-21 ו' 6-15",
        "mesirot_yn": "y",
        "longitude": "34.623824"
    },
    {
        "n_code": "4070",
        "city": "אופקים",
        "line": "",
        "latitude": "31.318420",
        "distribution_area": "",
        "city_code": "31",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "137",
        "house": "6",
        "distribution_area_desc": "",
        "street": "ז'בוטינסקי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן אופקים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.621381"
    },
    {
        "n_code": "2072",
        "city": "אור הנר",
        "line": "",
        "latitude": "31.557212",
        "distribution_area": "",
        "city_code": "67",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אור הנר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כלבו אור הנר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-19  ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "34.602534"
    },
    {
        "n_code": "3912",
        "city": "אור יהודה",
        "line": "",
        "latitude": "32.022675",
        "distribution_area": "",
        "city_code": "2400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "221",
        "house": "11",
        "distribution_area_desc": "",
        "street": "המלאכה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "הסטוק-אור יהודה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה10-20 ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "34.861664"
    },
    {
        "n_code": "4862",
        "city": "אור יהודה",
        "line": "",
        "latitude": "32.032004",
        "distribution_area": "",
        "city_code": "2400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "303",
        "house": "2",
        "distribution_area_desc": "",
        "street": "הראשונים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "הקולה בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-18:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.847107"
    },
    {
        "n_code": "3699",
        "city": "אור יהודה",
        "line": "",
        "latitude": "32.02419",
        "distribution_area": "",
        "city_code": "2400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "220",
        "house": "16",
        "distribution_area_desc": "",
        "street": "היצירה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "חנות החיות ודגי נוי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה  9:00-19:00 שישי  9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.86270"
    },
    {
        "n_code": "4911",
        "city": "אור עקיבא",
        "line": "",
        "latitude": "32.501264",
        "distribution_area": "",
        "city_code": "1020",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "22",
        "distribution_area_desc": "",
        "street": "שד הנשיא וייצמן",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "לה פרפיום",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 שישי 10:00-14:00  (ליד שווארמה שאולי)   בוויז -לה פרפיום-",
        "mesirot_yn": "y",
        "longitude": "34.926699"
    },
    {
        "n_code": "4946",
        "city": "אור עקיבא",
        "line": "",
        "latitude": "32.50567807714405",
        "distribution_area": "",
        "city_code": "1020",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "123",
        "house": "10",
        "distribution_area_desc": "",
        "street": "שפירא אברהם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "רמי שיווק מזון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:30-21:30 שישי 6:30 עד כניסת שבת",
        "mesirot_yn": "y",
        "longitude": "34.92579956"
    },
    {
        "n_code": "4599",
        "city": "אורטל",
        "line": "",
        "latitude": "33.087472",
        "distribution_area": "",
        "city_code": "4013",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אורטל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מזכירות קיבוץ אורטל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-16:30",
        "mesirot_yn": "y",
        "longitude": "35.759398"
    },
    {
        "n_code": "4364",
        "city": "אורים",
        "line": "",
        "latitude": "31.304377",
        "distribution_area": "",
        "city_code": "403",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אורים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אור המדבר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-16:30 ו 9-12",
        "mesirot_yn": "y",
        "longitude": "34.518458"
    },
    {
        "n_code": "4018",
        "city": "אורנית",
        "line": "",
        "latitude": "32.132957",
        "distribution_area": "",
        "city_code": "3760",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "7",
        "distribution_area_desc": "",
        "street": "השיטה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "רונן מחשבים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-15 ו' 9-12 בתיאום מראש לשלוח וואטצפ054-4604422",
        "mesirot_yn": "y",
        "longitude": "34.993704"
    },
    {
        "n_code": "3982",
        "city": "אושה",
        "line": "",
        "latitude": "32.795156",
        "distribution_area": "",
        "city_code": "278",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אושה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכולית קיבוץ אושה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-18 ו' 7-13:30  048458541",
        "mesirot_yn": "y",
        "longitude": "35.114678"
    },
    {
        "n_code": "1248",
        "city": "אזור",
        "line": "",
        "latitude": "32.02368909",
        "distribution_area": "",
        "city_code": "565",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "2",
        "distribution_area_desc": "",
        "street": "שפינוזה",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "סופר סול",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-17:00 ו'07:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.80373174"
    },
    {
        "n_code": "4886",
        "city": "אחיהוד",
        "line": "",
        "latitude": "32.907794",
        "distribution_area": "",
        "city_code": "785",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אחיהוד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "יהב רהיטים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 15:00-19:00, שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.173832"
    },
    {
        "n_code": "3814",
        "city": "אילת",
        "line": "",
        "latitude": "29.543481",
        "distribution_area": "",
        "city_code": "2600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "627",
        "house": "4",
        "distribution_area_desc": "",
        "street": "עין יהב",
        "line_desc": "",
        "sorting_area_code": "  6",
        "name": "דיגיטל פוסט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-18:00 ו' 9:00-12:00 מרכז גיא-לי שחמון",
        "mesirot_yn": "y",
        "longitude": "34.935653"
    },
    {
        "n_code": "4140",
        "city": "איתמר",
        "line": "",
        "latitude": "32.174652",
        "distribution_area": "",
        "city_code": "3762",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "איתמר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "חנות הצעצועים של ארזי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 16:00-20:00 ו 8-13",
        "mesirot_yn": "y",
        "longitude": "35.308050"
    },
    {
        "n_code": "3330",
        "city": "אכסאל",
        "line": "",
        "latitude": "32.681687",
        "distribution_area": "",
        "city_code": "478",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "33",
        "distribution_area_desc": "",
        "street": "אכסאל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ג'מבו הום אכסאל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 9-21",
        "mesirot_yn": "y",
        "longitude": "35.323073"
    },
    {
        "n_code": "3134",
        "city": "אלון הגליל",
        "line": "",
        "latitude": "32.756972",
        "distribution_area": "",
        "city_code": "1182",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אלון הגליל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכולית אלון הגליל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-19:00 ו' 7:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.218911"
    },
    {
        "n_code": "3482",
        "city": "אלון מורה",
        "line": "",
        "latitude": "32.235018",
        "distribution_area": "",
        "city_code": "3579",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "3",
        "distribution_area_desc": "",
        "street": "אלון מורה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אגודה שיתופית דואר אלון מורה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-12:00 ו' 8:00-10:00 (הנקודה סמוכה לדואר היישוב, שעות הפתיחה בזמני הדואר בלבד.)",
        "mesirot_yn": "y",
        "longitude": "35.331449"
    },
    {
        "n_code": "3809",
        "city": "אלוני הבשן",
        "line": "",
        "latitude": "33.04359",
        "distribution_area": "",
        "city_code": "4017",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אלוני הבשן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות אלוני הבשן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00 ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.83888"
    },
    {
        "n_code": "2161",
        "city": "אליכין",
        "line": "",
        "latitude": "32.405674",
        "distribution_area": "",
        "city_code": "41",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "104",
        "house": "39",
        "distribution_area_desc": "",
        "street": "שבזי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סופר סטוק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-20   ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "34.925720"
    },
    {
        "n_code": "2063",
        "city": "אליפלט",
        "line": "",
        "latitude": "32.948132",
        "distribution_area": "",
        "city_code": "730",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אליפלט",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אופני הקריון גליל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-18  ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.548081"
    },
    {
        "n_code": "2227",
        "city": "אלמגור",
        "line": "",
        "latitude": "32.912188",
        "distribution_area": "",
        "city_code": "1125",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אלמגור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "החנות של יפתח- חומרי בנייה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-17  ו' 7-12",
        "mesirot_yn": "y",
        "longitude": "35.602138"
    },
    {
        "n_code": "4554",
        "city": "אלעד",
        "line": "",
        "latitude": "32.047530",
        "distribution_area": "",
        "city_code": "1309",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "111",
        "house": "84",
        "distribution_area_desc": "",
        "street": "רבן יוחנן בן זכאי",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "TALI",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה  16:30-20:00 שישי 9:00-13:00 כניסה נפרדת WAZE https://www.waze.com/he/live-map/directions?to=ll.31.82303",
        "mesirot_yn": "y",
        "longitude": "34.964067"
    },
    {
        "n_code": "3514",
        "city": "אלעזר",
        "line": "",
        "latitude": "31.659457",
        "distribution_area": "",
        "city_code": "3618",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "106",
        "house": "111",
        "distribution_area_desc": "",
        "street": "מתתיהו",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר תורגמן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00 ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.141826"
    },
    {
        "n_code": "4442",
        "city": "אלפי מנשה",
        "line": "",
        "latitude": "32.165652",
        "distribution_area": "",
        "city_code": "3750",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "131",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כנרת",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אלמה חנות נוחות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-19:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.018847"
    },
    {
        "n_code": "3053",
        "city": "אלקנה",
        "line": "",
        "latitude": "32.110885",
        "distribution_area": "",
        "city_code": "3560",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "120",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אדני פז",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אל אף בשרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 11:30-22:30",
        "mesirot_yn": "y",
        "longitude": "35.035488"
    },
    {
        "n_code": "1447",
        "city": "אמירים",
        "line": "",
        "latitude": "32.93806888",
        "distribution_area": "",
        "city_code": "1064",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אמירים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "רביד מרקט אמירים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-19 ו' 7-15",
        "mesirot_yn": "y",
        "longitude": "35.45105346"
    },
    {
        "n_code": "3536",
        "city": "אניעם",
        "line": "",
        "latitude": "32.95707951338479",
        "distribution_area": "",
        "city_code": "4012",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אניעם",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט אניעם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-19:00 ו'6:30-15:30",
        "mesirot_yn": "y",
        "longitude": "35.74187751"
    },
    {
        "n_code": "3416",
        "city": "אעבלין",
        "line": "",
        "latitude": "32.829191",
        "distribution_area": "",
        "city_code": "529",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "6733",
        "house": "5",
        "distribution_area_desc": "",
        "street": "אם אלשתויה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת אבו שריף",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:00-13:00 15:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.179901"
    },
    {
        "n_code": "2996",
        "city": "אעבלין",
        "line": "",
        "latitude": "32.823685",
        "distribution_area": "",
        "city_code": "529",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "5",
        "distribution_area_desc": "",
        "street": "אעבלין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סוהייב קומפיוטר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 11-21",
        "mesirot_yn": "y",
        "longitude": "35.193610"
    },
    {
        "n_code": "3158",
        "city": "אפיקים",
        "line": "",
        "latitude": "32.681651",
        "distribution_area": "",
        "city_code": "176",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אפיקים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכז מזון אפיקים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-20 ו' 7-16 ש' 10-16",
        "mesirot_yn": "y",
        "longitude": "35.578312"
    },
    {
        "n_code": "4829",
        "city": "אפרת",
        "line": "",
        "latitude": "31.6688021661286",
        "distribution_area": "",
        "city_code": "3650",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "149",
        "house": "2",
        "distribution_area_desc": "",
        "street": "זית שמן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "גולדז נינג'ה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-22:00 שישי 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.163696102"
    },
    {
        "n_code": "1448",
        "city": "אפרת",
        "line": "",
        "latitude": "31.652184",
        "distribution_area": "",
        "city_code": "3650",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "118",
        "house": "1",
        "distribution_area_desc": "",
        "street": "התאנה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "כלבו אפרת (טמבור) - מרכז מסחרי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-13 ב ד ה 16-18 ו' 8:30-12",
        "mesirot_yn": "y",
        "longitude": "35.149768"
    },
    {
        "n_code": "3042",
        "city": "אפרת",
        "line": "",
        "latitude": "31.645697",
        "distribution_area": "",
        "city_code": "3650",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "150",
        "house": "2",
        "distribution_area_desc": "",
        "street": "רחל אמנו",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינצר ספרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "אה-ה 9:00-19:00 ו' 8:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.131725"
    },
    {
        "n_code": "2645",
        "city": "אפרת",
        "line": "",
        "latitude": "31.663973",
        "distribution_area": "",
        "city_code": "3650",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "123",
        "house": "6",
        "distribution_area_desc": "",
        "street": "הדקל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "ענפי הדקל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה  9:00-19:00 ו' 8:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.156338"
    },
    {
        "n_code": "4738",
        "city": "אריאל",
        "line": "",
        "latitude": "32.101590",
        "distribution_area": "",
        "city_code": "3570",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "315",
        "house": "4",
        "distribution_area_desc": "",
        "street": "העמל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מאפיית שיפון פלוס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'-ה' 10:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.169179"
    },
    {
        "n_code": "3889",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.809534",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "118",
        "house": "11",
        "distribution_area_desc": "",
        "street": "הראשונים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אגודת למען העיוור",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-18. ב19.05 שעות פתיחה 08:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.647380"
    },
    {
        "n_code": "4645",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.805532535824305",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "128",
        "house": "15",
        "distribution_area_desc": "",
        "street": "רוגוזין",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אינטרו מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 06:30-23:30 יום שישי 15:30- 07:00",
        "mesirot_yn": "y",
        "longitude": "34.6435573"
    },
    {
        "n_code": "3927",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.811050",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "175",
        "house": "1",
        "distribution_area_desc": "",
        "street": "קדושי בלזן",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "בא-לי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "24/7 פתוח",
        "mesirot_yn": "y",
        "longitude": "34.641788"
    },
    {
        "n_code": "3953",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.800855",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "257",
        "house": "20",
        "distribution_area_desc": "",
        "street": "שפירא משה חיים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הכול כלול",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12-18:30 ו' 9-10",
        "mesirot_yn": "y",
        "longitude": "34.641527"
    },
    {
        "n_code": "3962",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.810459",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "501",
        "house": "9",
        "distribution_area_desc": "",
        "street": "האורגים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "זול סטוק אשדוד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12-20 ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "34.661918"
    },
    {
        "n_code": "4841",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.800784",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "240",
        "house": "4",
        "distribution_area_desc": "",
        "street": "אבן עזרא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "טוטו רסקו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-12:00  16:00-24:00 שישי 6:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.650266"
    },
    {
        "n_code": "3895",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.804344",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "142",
        "house": "10",
        "distribution_area_desc": "",
        "street": "קדש",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מינימרקט הגל המתוק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה8-23 ו'8-19",
        "mesirot_yn": "y",
        "longitude": "34.647551"
    },
    {
        "n_code": "4572",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.804945",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "140",
        "house": "20",
        "distribution_area_desc": "",
        "street": "ההגנה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מפגש החברים ההגנה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:00-20:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.646307"
    },
    {
        "n_code": "4283",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.811704",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "274",
        "house": "46",
        "distribution_area_desc": "",
        "street": "העבודה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מרכז הרכב אשדוד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "דלק פז א-ה 8-18 ו 8:30-13",
        "mesirot_yn": "y",
        "longitude": "34.666697"
    },
    {
        "n_code": "4602",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.800172566652126",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "218",
        "house": "17",
        "distribution_area_desc": "",
        "street": "שמואל הנגיד",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סיטונאות אחד העם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "חנות מספר 6 א-ה 8:00-20:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.6478875"
    },
    {
        "n_code": "4769",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.815046518933688",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "504",
        "house": "11",
        "distribution_area_desc": "",
        "street": "בעלי מלאכה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "עולם הפירזול",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-18:00",
        "mesirot_yn": "y",
        "longitude": "34.6623765"
    },
    {
        "n_code": "4756",
        "city": "אשדוד",
        "line": "",
        "latitude": "31.781466",
        "distribution_area": "",
        "city_code": "70",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "365",
        "house": "4",
        "distribution_area_desc": "",
        "street": "אדר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פופ טויס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-16:00  18:00-20:00. חנות תהיה סגורה מ08.05 עד 12.05",
        "mesirot_yn": "y",
        "longitude": "34.638053"
    },
    {
        "n_code": "1779",
        "city": "אשדות יעקב מאוחד",
        "line": "",
        "latitude": "32.664855",
        "distribution_area": "",
        "city_code": "188",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אשדות יעקב  (מאוחד)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מתנות פור אול",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-17 ו' 8:30-14",
        "mesirot_yn": "y",
        "longitude": "35.582594"
    },
    {
        "n_code": "4914",
        "city": "אשכולות",
        "line": "",
        "latitude": "31.391727",
        "distribution_area": "",
        "city_code": "3722",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אשכולות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות אשכולות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-13:45",
        "mesirot_yn": "y",
        "longitude": "34.905492"
    },
    {
        "n_code": "2297",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.672770",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "435",
        "house": "68",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "ADM פתרונות תקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ב-ד-ה 11:00 עד 14:00 ובערב מ 16:00 עד 18:00. יום ג-ו מ 11:00 עד 14:00",
        "mesirot_yn": "y",
        "longitude": "34.587380"
    },
    {
        "n_code": "3983",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.655848869287382",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "507",
        "house": "34",
        "distribution_area_desc": "",
        "street": "התחיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "גלידרית יפית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 09:00-13:00 ערב 16:00-19:00 ו'08:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.5845984"
    },
    {
        "n_code": "4455",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.662189",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "437",
        "house": "99",
        "distribution_area_desc": "",
        "street": "צה\\\"ל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "החמניה עונג שבת אשקלון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-22:00 ו 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.595120"
    },
    {
        "n_code": "3954",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.669022",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "451",
        "house": "46",
        "distribution_area_desc": "",
        "street": "רמז דוד",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הכול כלול",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12-18:30 ו' 9-13. בוואיז הכל כלול רחוב דוד רמז 24",
        "mesirot_yn": "y",
        "longitude": "34.584775"
    },
    {
        "n_code": "4637",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.663365",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "812",
        "house": "5",
        "distribution_area_desc": "",
        "street": "האופן",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כל החי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.597602"
    },
    {
        "n_code": "4039",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.674901",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4456",
        "house": "999",
        "distribution_area_desc": "",
        "street": "צומת סילבר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן - אשקלון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.605731"
    },
    {
        "n_code": "4058",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.661307",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "474",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נח\\\"ל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן קניון חוצות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 19:00 11:00 הפסקה 15:25 16:00 יום ו' 13:00 09:00",
        "mesirot_yn": "y",
        "longitude": "34.589941"
    },
    {
        "n_code": "3449",
        "city": "אשקלון",
        "line": "",
        "latitude": "31.667596",
        "distribution_area": "",
        "city_code": "7100",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "451",
        "house": "1",
        "distribution_area_desc": "",
        "street": "רמז דוד",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "קופי PLAY טוטו לוטו במתחם רמז",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 14:30-20:00 שישי 9:00-13:30",
        "mesirot_yn": "y",
        "longitude": "34.583839"
    },
    {
        "n_code": "1449",
        "city": "אשתאול",
        "line": "",
        "latitude": "31.77821427",
        "distribution_area": "",
        "city_code": "740",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "38",
        "distribution_area_desc": "",
        "street": "אשתאול",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "צרכניית אשתאול",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 6-19 ג 7-15 ו' 6-15",
        "mesirot_yn": "y",
        "longitude": "35.01173903"
    },
    {
        "n_code": "3718",
        "city": "באר גנים",
        "line": "",
        "latitude": "31.70062",
        "distribution_area": "",
        "city_code": "108",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "7474",
        "house": "1",
        "distribution_area_desc": "",
        "street": "באר גנים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "טוב הארץ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "יום א'-ה: 16:00-20:00, יום ב' וה': 9:00-12:00, יום ו': 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.61082"
    },
    {
        "n_code": "3262",
        "city": "באר טוביה",
        "line": "",
        "latitude": "31.727925",
        "distribution_area": "",
        "city_code": "155",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4sdf",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ניר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "זו סנטר- אזור התעשיה באר טוביה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-20:00 ו'8:30-14:30 088609618",
        "mesirot_yn": "y",
        "longitude": "34.751376"
    },
    {
        "n_code": "2164",
        "city": "באר טוביה",
        "line": "",
        "latitude": "31.726515",
        "distribution_area": "",
        "city_code": "155",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "bgb",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הבאר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "שיא טופ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 9:00-17:00",
        "mesirot_yn": "y",
        "longitude": "34.750345"
    },
    {
        "n_code": "4560",
        "city": "באר יעקב",
        "line": "",
        "latitude": "31.944067",
        "distribution_area": "",
        "city_code": "2530",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "102",
        "house": "6",
        "distribution_area_desc": "",
        "street": "הרב עוזיאל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "הכפריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.836125"
    },
    {
        "n_code": "4811",
        "city": "באר יעקב",
        "line": "",
        "latitude": "31.945065",
        "distribution_area": "",
        "city_code": "2530",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "166",
        "house": "10",
        "distribution_area_desc": "",
        "street": "קרן היסוד",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "קליית התאומים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00",
        "mesirot_yn": "y",
        "longitude": "34.836429"
    },
    {
        "n_code": "4748",
        "city": "באר יעקב",
        "line": "",
        "latitude": "31.94183731022289",
        "distribution_area": "",
        "city_code": "2530",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "223",
        "house": "9",
        "distribution_area_desc": "",
        "street": "קישון אפרים",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "שמחה ושפע",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-14:00 16:30-19:00 שישי 9:30-13:30",
        "mesirot_yn": "y",
        "longitude": "34.84341248"
    },
    {
        "n_code": "3448",
        "city": "באר מילכא",
        "line": "",
        "latitude": "30.932720",
        "distribution_area": "",
        "city_code": "9959",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "1",
        "house": "1",
        "distribution_area_desc": "",
        "street": "באר מילכא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות באר מילכה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.407633"
    },
    {
        "n_code": "4833",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.227545",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "671",
        "house": "7",
        "distribution_area_desc": "",
        "street": "נחל פרת",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אמה מרקט בפארק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-23:00 שישי 8:00-18:00",
        "mesirot_yn": "y",
        "longitude": "34.821570"
    },
    {
        "n_code": "4171",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.251964",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "847",
        "house": "14",
        "distribution_area_desc": "",
        "street": "יצחק נפחא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מ.ד התקנות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-16:30 ו 8:30-12",
        "mesirot_yn": "y",
        "longitude": "34.812322"
    },
    {
        "n_code": "3200",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.269276",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "377",
        "house": "44",
        "distribution_area_desc": "",
        "street": "מקלף מרדכי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מינימרקט הפרלמנט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-23:30 ו' 8:00- חצי שעה לפני כניסת השבת",
        "mesirot_yn": "y",
        "longitude": "34.777415"
    },
    {
        "n_code": "4744",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.247858000423264",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "607",
        "house": "2",
        "distribution_area_desc": "",
        "street": "ז'בוטינסקי זאב",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מינימרקט ז'בוטינסקי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 07:30-11:00 + 17:00-23:30 שישי 8-12",
        "mesirot_yn": "y",
        "longitude": "34.8070750"
    },
    {
        "n_code": "1397",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.27496484",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1267",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שד האנדרטה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סופר טל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 07:00-19:00 ו' 07:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.82139662"
    },
    {
        "n_code": "4054",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.254642",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "531",
        "house": "3",
        "distribution_area_desc": "",
        "street": "האורגים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן האורגים ב\\\"ש",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.815782"
    },
    {
        "n_code": "4896",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.2827586168037",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1359",
        "house": "33",
        "distribution_area_desc": "",
        "street": "שריג נחום",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פודלס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.805798389"
    },
    {
        "n_code": "4681",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.225448",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "857",
        "house": "20",
        "distribution_area_desc": "",
        "street": "הפלדה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "קאר ווש",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "עמק שרה א-ה 7:00-17:00 שישי 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.811792"
    },
    {
        "n_code": "4601",
        "city": "באר שבע",
        "line": "",
        "latitude": "31.282406",
        "distribution_area": "",
        "city_code": "9000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1359",
        "house": "25",
        "distribution_area_desc": "",
        "street": "שריג נחום",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "תומס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-23:00 שישי 7\\\"00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.805662"
    },
    {
        "n_code": "2410",
        "city": "בארותיים",
        "line": "",
        "latitude": "32.321364",
        "distribution_area": "",
        "city_code": "697",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "999",
        "distribution_area_desc": "",
        "street": "הנחלים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "צרכניית בארותיים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-19:30  ו' 6:30-15",
        "mesirot_yn": "y",
        "longitude": "34.983206"
    },
    {
        "n_code": "2155",
        "city": "בועיינה-נוג'ידאת",
        "line": "",
        "latitude": "32.806700",
        "distribution_area": "",
        "city_code": "482",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בועיינה-נוג'ידאת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכבסת נטופה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-17  שבת8-17 במרכז בניין הדואר לשעבר  החנות תהיה סגורה מ10.07 עד 13.07",
        "mesirot_yn": "y",
        "longitude": "35.366576"
    },
    {
        "n_code": "4230",
        "city": "בועיינה-נוג'ידאת",
        "line": "",
        "latitude": "32.812547",
        "distribution_area": "",
        "city_code": "482",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בועיינה-נוג'ידאת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת הכפר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-23 ו 9:30-12",
        "mesirot_yn": "y",
        "longitude": "35.376854"
    },
    {
        "n_code": "3976",
        "city": "בוקעאתא",
        "line": "",
        "latitude": "33.20333866069745",
        "distribution_area": "",
        "city_code": "4001",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בוקעאתא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "Oromo Coffee",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 9-19",
        "mesirot_yn": "y",
        "longitude": "35.77677233"
    },
    {
        "n_code": "3315",
        "city": "בוקעאתא",
        "line": "",
        "latitude": "33.200394",
        "distribution_area": "",
        "city_code": "4001",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "15",
        "distribution_area_desc": "",
        "street": "בוקעאתא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "טוטו ניזאר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ש 9:00-24:00",
        "mesirot_yn": "y",
        "longitude": "35.774369"
    },
    {
        "n_code": "4150",
        "city": "בית אורן",
        "line": "",
        "latitude": "32.731485",
        "distribution_area": "",
        "city_code": "317",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בית אורן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכולית בית אורן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-18 ו 7-15",
        "mesirot_yn": "y",
        "longitude": "35.006393"
    },
    {
        "n_code": "1379",
        "city": "בית אל",
        "line": "",
        "latitude": "31.938213",
        "distribution_area": "",
        "city_code": "3574",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "146",
        "house": "1",
        "distribution_area_desc": "",
        "street": "המפעל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "לבנדר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א, ב, ג, ה 10:30-14:00 16:15-18:30 שישי 10:30-13:00 ד' סגור 050-5524710 אהרן https://goo.gl/maps/KkC1db4YYTbGhcEU6",
        "mesirot_yn": "y",
        "longitude": "35.221902"
    },
    {
        "n_code": "1903",
        "city": "בית אל",
        "line": "",
        "latitude": "31.945861",
        "distribution_area": "",
        "city_code": "3574",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "sdfb",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אזור תעשיה ב",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "שריגים-בת חן שריג",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-14 17-22 ו' 10-14",
        "mesirot_yn": "y",
        "longitude": "35.223189"
    },
    {
        "n_code": "4484",
        "city": "בית אריה",
        "line": "",
        "latitude": "32.035916",
        "distribution_area": "",
        "city_code": "3652",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "117",
        "house": "11",
        "distribution_area_desc": "",
        "street": "הגפן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "שירת הבריאה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים ג,ד,ה 15:30-17:00 ימים א,ג 8:00-9:30 שישי וערבי חג בתאום מראש 0504574166",
        "mesirot_yn": "y",
        "longitude": "35.051924"
    },
    {
        "n_code": "4503",
        "city": "בית ג'ן",
        "line": "",
        "latitude": "32.965690",
        "distribution_area": "",
        "city_code": "480",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6713",
        "house": "999",
        "distribution_area_desc": "",
        "street": "משפחת קבלאן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "נאיה פאשן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-20:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.381311"
    },
    {
        "n_code": "2617",
        "city": "בית ג'ן",
        "line": "",
        "latitude": "32.957956",
        "distribution_area": "",
        "city_code": "480",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בית ג'ן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פוטו לין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-22  ו' 9-16 בכניסה לבית ג'ן",
        "mesirot_yn": "y",
        "longitude": "35.377627"
    },
    {
        "n_code": "3036",
        "city": "בית השיטה",
        "line": "",
        "latitude": "32.549310",
        "distribution_area": "",
        "city_code": "242",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "בית השיטה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סופר מרקט בית השיטה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ו 07-14 א-ה 16-20",
        "mesirot_yn": "y",
        "longitude": "35.438607"
    },
    {
        "n_code": "2638",
        "city": "בית חגי",
        "line": "",
        "latitude": "31.492616",
        "distribution_area": "",
        "city_code": "8701",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בית חגי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "לביא מחשבים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-17  ו' 8:30-13",
        "mesirot_yn": "y",
        "longitude": "35.079574"
    },
    {
        "n_code": "4622",
        "city": "בית יצחק-שער חפר",
        "line": "",
        "latitude": "32.342765",
        "distribution_area": "",
        "city_code": "326",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "119",
        "house": "117",
        "distribution_area_desc": "",
        "street": "דרך השרון",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מד-דוג",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.892873"
    },
    {
        "n_code": "2315",
        "city": "בית נחמיה",
        "line": "",
        "latitude": "31.976832",
        "distribution_area": "",
        "city_code": "784",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "81",
        "distribution_area_desc": "",
        "street": "דרך הכלנית",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "המעיין לגן - נא לרשום בWAZE את שם העס'",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-17  ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "34.953374"
    },
    {
        "n_code": "3405",
        "city": "בית עריף",
        "line": "",
        "latitude": "31.995420",
        "distribution_area": "",
        "city_code": "604",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "בית עריף",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "צרכניית בית עריף",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 06:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.935099"
    },
    {
        "n_code": "2350",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.511979",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "189",
        "house": "7",
        "distribution_area_desc": "",
        "street": "העמל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אוטופון- צים סנטר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-21:30  ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.502045"
    },
    {
        "n_code": "2134",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.494408",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "128",
        "house": "139",
        "distribution_area_desc": "",
        "street": "אהבת ישראל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אלעד רוגל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ב,ד,ה 9:30-14:00 16:00-19:45 ו' 9:30-14:00 (0526646861(",
        "mesirot_yn": "y",
        "longitude": "35.496871"
    },
    {
        "n_code": "3732",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.49896",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "106",
        "house": "45",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "בית הנייד",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-20:00 ו'9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.49796"
    },
    {
        "n_code": "2163",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.501240",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "105",
        "house": "85",
        "distribution_area_desc": "",
        "street": "שאול המלך",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "חד וחלק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-13 16-20 ו' 9-12:30",
        "mesirot_yn": "y",
        "longitude": "35.494561"
    },
    {
        "n_code": "4239",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.505849",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "146",
        "house": "22",
        "distribution_area_desc": "",
        "street": "מיזוג גלויות",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט אחים פרץ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6-21 ו 6-13",
        "mesirot_yn": "y",
        "longitude": "35.491984"
    },
    {
        "n_code": "4223",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.502404",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "147",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מכלוף יעקב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט ימית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-19:30 ו 7-14",
        "mesirot_yn": "y",
        "longitude": "35.491659"
    },
    {
        "n_code": "4071",
        "city": "בית שאן",
        "line": "",
        "latitude": "32.495524",
        "distribution_area": "",
        "city_code": "9200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9100",
        "house": "1",
        "distribution_area_desc": "",
        "street": "השחם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סלקשן ישפרו סנטר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.497256"
    },
    {
        "n_code": "4006",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.705800",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "340",
        "house": "27",
        "distribution_area_desc": "",
        "street": "אביי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "Tool Box",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-20:45 ו' 9-12",
        "mesirot_yn": "y",
        "longitude": "34.974635"
    },
    {
        "n_code": "3919",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.748702",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "143",
        "house": "1",
        "distribution_area_desc": "",
        "street": "סמ ויצ\\\"ו",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "הסטוק בית שמש",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10-20 ו'9:30-14",
        "mesirot_yn": "y",
        "longitude": "34.985458"
    },
    {
        "n_code": "4523",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.715007",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "415",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נחל לכיש",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "לארג' פעמי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-22:00",
        "mesirot_yn": "y",
        "longitude": "34.993587"
    },
    {
        "n_code": "4816",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.748949675405523",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "00b4",
        "house": "19",
        "distribution_area_desc": "",
        "street": "ככר נוימן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מרכז הנדוניה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-13:00  16:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.9861097"
    },
    {
        "n_code": "4072",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.756690",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "148",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אלון יגאל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סלקשן ביג בית שמש",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.990193"
    },
    {
        "n_code": "4815",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.706640679328782",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "333",
        "house": "22",
        "distribution_area_desc": "",
        "street": "שד' האמוראים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פרימיום",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ב-ה 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.9706342"
    },
    {
        "n_code": "3855",
        "city": "בית שמש",
        "line": "",
        "latitude": "31.755005",
        "distribution_area": "",
        "city_code": "2610",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "148",
        "house": "20",
        "distribution_area_desc": "",
        "street": "אלון יגאל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "רשת  אנרגיה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 6:00-00:00",
        "mesirot_yn": "y",
        "longitude": "34.992280"
    },
    {
        "n_code": "4907",
        "city": "ביתן אהרן",
        "line": "",
        "latitude": "32.36154319123593",
        "distribution_area": "",
        "city_code": "252",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "ביתן אהרן",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "יין בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ראשון עד שבת 10:00-18:00",
        "mesirot_yn": "y",
        "longitude": "34.86923483"
    },
    {
        "n_code": "1763",
        "city": "ביתר עילית",
        "line": "",
        "latitude": "31.702447",
        "distribution_area": "",
        "city_code": "3780",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "204",
        "house": "62",
        "distribution_area_desc": "",
        "street": "המגיד ממעזריטש",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "המכולת של חיימקה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "-ה 12:00-20:00 ו 8:00-12:00 ש'22-3:00",
        "mesirot_yn": "y",
        "longitude": "35.123313"
    },
    {
        "n_code": "4355",
        "city": "בן נון",
        "line": "",
        "latitude": "31.862441",
        "distribution_area": "",
        "city_code": "9404",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "vv f",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הגפן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט בן נון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-18 ו 8-14",
        "mesirot_yn": "y",
        "longitude": "34.947541"
    },
    {
        "n_code": "4315",
        "city": "בן שמן (מושב)",
        "line": "",
        "latitude": "31.950866",
        "distribution_area": "",
        "city_code": "2013",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "50",
        "distribution_area_desc": "",
        "street": "דרך הרימון",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "צרכניית מושב בן שמן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6-19 ו 6-15",
        "mesirot_yn": "y",
        "longitude": "34.920984"
    },
    {
        "n_code": "1971",
        "city": "בני ברק",
        "line": "",
        "latitude": "32.096752",
        "distribution_area": "",
        "city_code": "6100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "201",
        "house": "113",
        "distribution_area_desc": "",
        "street": "אברבנאל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סטריאו טיפ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-17 ו' 8:30-12",
        "mesirot_yn": "y",
        "longitude": "34.834192"
    },
    {
        "n_code": "9002",
        "city": "בני ברק",
        "line": "",
        "latitude": "32.086605",
        "distribution_area": "",
        "city_code": "6100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0004",
        "house": "20",
        "distribution_area_desc": "",
        "street": "*הרב קוק*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס בני ברק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.826111"
    },
    {
        "n_code": "1827",
        "city": "בני דקלים",
        "line": "",
        "latitude": "31.516886",
        "distribution_area": "",
        "city_code": "1368",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "19",
        "distribution_area_desc": "",
        "street": "בני דקלים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "החנות של שלוה - רח' כיסופים 19",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-14 16-20 ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "34.921799"
    },
    {
        "n_code": "4727",
        "city": "בני דרור",
        "line": "",
        "latitude": "32.259659000003154",
        "distribution_area": "",
        "city_code": "386",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "7001",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מתחם קניון דרורים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אי הממתקים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-20:00 שישי 9:00-14:00 מוצ\\\"ש 20:00-21:30",
        "mesirot_yn": "y",
        "longitude": "34.8939533"
    },
    {
        "n_code": "2429",
        "city": "בני נצרים",
        "line": "",
        "latitude": "31.144037",
        "distribution_area": "",
        "city_code": "1363",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "בני נצרים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות חלוצית אחד",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-13",
        "mesirot_yn": "y",
        "longitude": "34.316923"
    },
    {
        "n_code": "1861",
        "city": "בני עי\\\"ש",
        "line": "",
        "latitude": "31.789741",
        "distribution_area": "",
        "city_code": "1066",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "101",
        "house": "11",
        "distribution_area_desc": "",
        "street": "בגין מנחם",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פון סנטר בני עייש",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א',ב',ד',ה' ,10:30-19:30  ג' 10:30-14:30,  ו' 10:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.757359"
    },
    {
        "n_code": "4582",
        "city": "בנימינה-גבעת עדה",
        "line": "",
        "latitude": "32.514157",
        "distribution_area": "",
        "city_code": "9800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "115",
        "house": "3",
        "distribution_area_desc": "",
        "street": "האמן",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "360 Berries",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 8:00-18:00 שישי 8:00-16:00",
        "mesirot_yn": "y",
        "longitude": "34.947885"
    },
    {
        "n_code": "2321",
        "city": "בנימינה-גבעת עדה",
        "line": "",
        "latitude": "32.512566",
        "distribution_area": "",
        "city_code": "9800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "6",
        "distribution_area_desc": "",
        "street": "בנימינה-גבעת עדה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "גרין קווין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ב,ד,ה 10-18 ג' 10-15ו 9-13",
        "mesirot_yn": "y",
        "longitude": "34.948616"
    },
    {
        "n_code": "4635",
        "city": "בנימינה-גבעת עדה",
        "line": "",
        "latitude": "32.52170973764791",
        "distribution_area": "",
        "city_code": "9800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "151",
        "house": "21",
        "distribution_area_desc": "",
        "street": "דרך המסילה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "כלב מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-14:00  16:00-19:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.94685951"
    },
    {
        "n_code": "3804",
        "city": "בנימינה-גבעת עדה",
        "line": "",
        "latitude": "32.51644",
        "distribution_area": "",
        "city_code": "9800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "213",
        "house": "44",
        "distribution_area_desc": "",
        "street": "האסיף",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "תיבת החיות בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-19:30 ו' 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.94656"
    },
    {
        "n_code": "4298",
        "city": "בסמת טבעון",
        "line": "",
        "latitude": "32.746525",
        "distribution_area": "",
        "city_code": "944",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אלזהור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאפיית המרכז האחים סעדי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "24/7",
        "mesirot_yn": "y",
        "longitude": "35.152472"
    },
    {
        "n_code": "4419",
        "city": "בקוע",
        "line": "",
        "latitude": "31.828300",
        "distribution_area": "",
        "city_code": "864",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בקוע",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "תמוז מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-21:00 ו 6:00-14:00 ז 20:00-23:00",
        "mesirot_yn": "y",
        "longitude": "34.926277"
    },
    {
        "n_code": "2827",
        "city": "בר יוחאי",
        "line": "",
        "latitude": "32.999181",
        "distribution_area": "",
        "city_code": "1191",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בר יוחאי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "משפחת שמעוני",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 9:00-14:00 16:00-19:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.448362"
    },
    {
        "n_code": "3793",
        "city": "ברכה",
        "line": "",
        "latitude": "32.19355",
        "distribution_area": "",
        "city_code": "3710",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ברכה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מיכל עיצובים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-12:00-16:00-19:00 ו'  9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.26489"
    },
    {
        "n_code": "1626",
        "city": "ברכיה",
        "line": "",
        "latitude": "31.66909556",
        "distribution_area": "",
        "city_code": "746",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ברכיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "שווקית ברכיה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 6:30-20:30 ו' 06:30-15",
        "mesirot_yn": "y",
        "longitude": "34.62604568"
    },
    {
        "n_code": "4116",
        "city": "בת הדר",
        "line": "",
        "latitude": "31.645694",
        "distribution_area": "",
        "city_code": "1323",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בת הדר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הקולה בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מתחם פאוור סנטר א-ה 9-22 ו 7:30-14:30",
        "mesirot_yn": "y",
        "longitude": "34.594372"
    },
    {
        "n_code": "4607",
        "city": "בת חפר",
        "line": "",
        "latitude": "32.332447",
        "distribution_area": "",
        "city_code": "1319",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "123",
        "house": "38",
        "distribution_area_desc": "",
        "street": "חיטה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פיסי 2",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 8:00-17:00 שישי 8:00-14:0",
        "mesirot_yn": "y",
        "longitude": "35.013051"
    },
    {
        "n_code": "9011",
        "city": "בת ים",
        "line": "",
        "latitude": "32.018216",
        "distribution_area": "",
        "city_code": "6200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0003",
        "house": "17",
        "distribution_area_desc": "",
        "street": "*השר פנקס*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס בת ים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 10 בבוקר ועד 7 בערב, שישי וערבי חג 10-14 חגים - אין פעילות",
        "mesirot_yn": "y",
        "longitude": "34.749504"
    },
    {
        "n_code": "4049",
        "city": "ג'דיידה-מכר",
        "line": "",
        "latitude": "32.930124",
        "distribution_area": "",
        "city_code": "1292",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "213",
        "distribution_area_desc": "",
        "street": "ג'דיידה-מכר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרקט זאהר אסדי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים ראשון עד שישי 12:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.151366"
    },
    {
        "n_code": "2064",
        "city": "ג'ולס",
        "line": "",
        "latitude": "32.942145",
        "distribution_area": "",
        "city_code": "485",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ג'ולס",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סיזארס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש  8-19",
        "mesirot_yn": "y",
        "longitude": "35.183586"
    },
    {
        "n_code": "1715",
        "city": "ג'לג'וליה",
        "line": "",
        "latitude": "32.152935",
        "distribution_area": "",
        "city_code": "627",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ג'לג'וליה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "איה בייבי - אחרי בנק לאומי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים ראשון עד שבת 11:00-21:00 לפני בנק לאומי 0585110011",
        "mesirot_yn": "y",
        "longitude": "34.954700"
    },
    {
        "n_code": "4859",
        "city": "ג'סר א-זרקא",
        "line": "",
        "latitude": "32.536103",
        "distribution_area": "",
        "city_code": "541",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "102",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עומר בן אלכטאב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מרקט ואטליז אלמיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 7:00-23:00",
        "mesirot_yn": "y",
        "longitude": "34.915166"
    },
    {
        "n_code": "3484",
        "city": "ג'ש (גוש חלב)",
        "line": "",
        "latitude": "33.02072",
        "distribution_area": "",
        "city_code": "487",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ג'ש (גוש חלב)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קיוסק רמי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.44854"
    },
    {
        "n_code": "2927",
        "city": "גבולות",
        "line": "",
        "latitude": "31.210020",
        "distribution_area": "",
        "city_code": "352",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבולות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מרכולית גבולות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-19 ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "34.465324"
    },
    {
        "n_code": "3431",
        "city": "גבע כרמל",
        "line": "",
        "latitude": "32.664896",
        "distribution_area": "",
        "city_code": "683",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "107",
        "house": "56",
        "distribution_area_desc": "",
        "street": "דרך הערבה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סים מעבדה לתיקון סלולרים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00 - 15:00  ו' סגור זמנית",
        "mesirot_yn": "y",
        "longitude": "34.952281"
    },
    {
        "n_code": "4466",
        "city": "גבעון החדשה",
        "line": "",
        "latitude": "31.851403",
        "distribution_area": "",
        "city_code": "3644",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבעון החדשה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "שטיפה 2000",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 9:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.167048"
    },
    {
        "n_code": "3050",
        "city": "גבעת אבני",
        "line": "",
        "latitude": "32.775710",
        "distribution_area": "",
        "city_code": "1293",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "10",
        "distribution_area_desc": "",
        "street": "הסביונים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכולית בגבעה (מתחם)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-14:00 16:00-18:00 ו' 10:00-13:00 מרכז מסחרי גבעת אבני",
        "mesirot_yn": "y",
        "longitude": "35.437412"
    },
    {
        "n_code": "4943",
        "city": "גבעת אולגה",
        "line": "",
        "latitude": "32.4363650251477",
        "distribution_area": "",
        "city_code": "9101",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "גבעת אולגה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "ק.ש.ר סלולאר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-14:00  16:00-19:00 שישי 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.887424755"
    },
    {
        "n_code": "2518",
        "city": "גבעת אלה",
        "line": "",
        "latitude": "32.722519",
        "distribution_area": "",
        "city_code": "1288",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבעת אלה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר גבעת האלה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-19:30  ו' 6:30-14:30",
        "mesirot_yn": "y",
        "longitude": "35.245798"
    },
    {
        "n_code": "4183",
        "city": "גבעת זאב",
        "line": "",
        "latitude": "31.864102",
        "distribution_area": "",
        "city_code": "3730",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "256",
        "house": "3",
        "distribution_area_desc": "",
        "street": "נחליאלי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אבו חלף אספקה טכנית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-17 ו 8-12 ( לרשום בוויז אבו חלף אספקה טכנית גבעת זאב ) ליד משתלת הורד האדום",
        "mesirot_yn": "y",
        "longitude": "35.175753"
    },
    {
        "n_code": "3505",
        "city": "גבעת זאב",
        "line": "",
        "latitude": "31.862922",
        "distribution_area": "",
        "city_code": "3730",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "255",
        "house": "10",
        "distribution_area_desc": "",
        "street": "קדרון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "גבעת החיות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 ו' 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.174955"
    },
    {
        "n_code": "3800",
        "city": "גבעת יואב",
        "line": "",
        "latitude": "32.800215",
        "distribution_area": "",
        "city_code": "4021",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבעת יואב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "השוקה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-19:00 ו'7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.681439"
    },
    {
        "n_code": "3906",
        "city": "גבעת עדה",
        "line": "",
        "latitude": "32.518192",
        "distribution_area": "",
        "city_code": "50",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "xo34",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הדקל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "המושבה הקטנה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-22:30 ו'7-13",
        "mesirot_yn": "y",
        "longitude": "35.002025"
    },
    {
        "n_code": "3668",
        "city": "גבעת עוז",
        "line": "",
        "latitude": "32.55658",
        "distribution_area": "",
        "city_code": "703",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבעת עוז",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אלונית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "24/7",
        "mesirot_yn": "y",
        "longitude": "35.19956"
    },
    {
        "n_code": "2783",
        "city": "גבעת עוז",
        "line": "",
        "latitude": "32.555751",
        "distribution_area": "",
        "city_code": "703",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבעת עוז",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "כל בו גבעת עוז",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-13-14-18:30  ו' 7-13",
        "mesirot_yn": "y",
        "longitude": "35.197826"
    },
    {
        "n_code": "4759",
        "city": "גבעת שמואל",
        "line": "",
        "latitude": "32.06805392216794",
        "distribution_area": "",
        "city_code": "681",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "153",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ארזים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "בלו פון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 09:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.84586405"
    },
    {
        "n_code": "4436",
        "city": "גבעת שמואל",
        "line": "",
        "latitude": "32.081026",
        "distribution_area": "",
        "city_code": "681",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "170",
        "house": "17",
        "distribution_area_desc": "",
        "street": "הנשיא",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "המסמר שלי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 ו 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.851256"
    },
    {
        "n_code": "4808",
        "city": "גבעת שמואל",
        "line": "",
        "latitude": "32.07541144502666",
        "distribution_area": "",
        "city_code": "681",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "114",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "חיות בגבעה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 9:00-14:30",
        "mesirot_yn": "y",
        "longitude": "34.84652669"
    },
    {
        "n_code": "4514",
        "city": "גבעת שמואל",
        "line": "",
        "latitude": "32.075348",
        "distribution_area": "",
        "city_code": "681",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "114",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מכבסת נועם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-19:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.846695"
    },
    {
        "n_code": "2501",
        "city": "גבעת שמואל",
        "line": "",
        "latitude": "32.085520",
        "distribution_area": "",
        "city_code": "681",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "133",
        "house": "96",
        "distribution_area_desc": "",
        "street": "הזיתים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "קליית יבגי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-18",
        "mesirot_yn": "y",
        "longitude": "34.852492"
    },
    {
        "n_code": "3706",
        "city": "גבעת שמואל",
        "line": "",
        "latitude": "32.07884",
        "distribution_area": "",
        "city_code": "681",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "162",
        "house": "11",
        "distribution_area_desc": "",
        "street": "בן גוריון",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "ריציס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'ב'ד'ה' 9:00-14:00-16:00-19:00  ג'9:00-14:00 ו'9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.84697"
    },
    {
        "n_code": "1408",
        "city": "גבעתי",
        "line": "",
        "latitude": "31.73576758",
        "distribution_area": "",
        "city_code": "793",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבעתי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "תקוה מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 6-14 א-ה 16-21",
        "mesirot_yn": "y",
        "longitude": "34.68009157"
    },
    {
        "n_code": "9010",
        "city": "גבעתיים",
        "line": "",
        "latitude": "32.074495778606405",
        "distribution_area": "",
        "city_code": "6300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0001",
        "house": "18",
        "distribution_area_desc": "",
        "street": "*שינקין*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס גבעתיים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.8111448"
    },
    {
        "n_code": "4515",
        "city": "גבת",
        "line": "",
        "latitude": "32.677059",
        "distribution_area": "",
        "city_code": "133",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גבת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "יזרעאלה - הבית לגינה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.215026"
    },
    {
        "n_code": "4953",
        "city": "גדרה",
        "line": "",
        "latitude": "31.815237",
        "distribution_area": "",
        "city_code": "2550",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "134",
        "house": "18",
        "distribution_area_desc": "",
        "street": "הבילוים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "בית טבע ארז",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-20:00 שישי 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.772910"
    },
    {
        "n_code": "4365",
        "city": "גדרה",
        "line": "",
        "latitude": "31.809858",
        "distribution_area": "",
        "city_code": "2550",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "212",
        "house": "38",
        "distribution_area_desc": "",
        "street": "דרך הפרחים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הבזאר הגדול",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-13:30  16:15-18:30 יש הפסקה בין השעות",
        "mesirot_yn": "y",
        "longitude": "34.784464"
    },
    {
        "n_code": "4509",
        "city": "גדרה",
        "line": "",
        "latitude": "31.810868",
        "distribution_area": "",
        "city_code": "2550",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "217",
        "house": "2",
        "distribution_area_desc": "",
        "street": "אירוס",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "ממתקי רפאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:00-14:00 16:15-19:00 שישי 9:00-14:30",
        "mesirot_yn": "y",
        "longitude": "34.784640"
    },
    {
        "n_code": "3599",
        "city": "גונן",
        "line": "",
        "latitude": "33.12513",
        "distribution_area": "",
        "city_code": "852",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גונן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מוסך גונן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה8:00-16:00 ו'8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.64716"
    },
    {
        "n_code": "2734",
        "city": "גורן",
        "line": "",
        "latitude": "33.057127",
        "distribution_area": "",
        "city_code": "755",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גורן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כלי בר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-17 ו'8-13:00",
        "mesirot_yn": "y",
        "longitude": "35.236906"
    },
    {
        "n_code": "1468",
        "city": "גינתון",
        "line": "",
        "latitude": "31.96401861",
        "distribution_area": "",
        "city_code": "863",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "106",
        "house": "37",
        "distribution_area_desc": "",
        "street": "הזית",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "צרכניית גינתון (מכולת)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א ב ד ה 6-19 ימים ג+ו' 6-14",
        "mesirot_yn": "y",
        "longitude": "34.9133197"
    },
    {
        "n_code": "4871",
        "city": "גלגל",
        "line": "",
        "latitude": "32.000433732533544",
        "distribution_area": "",
        "city_code": "3606",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "גלגל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מזכירות גלגל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.4446430"
    },
    {
        "n_code": "2668",
        "city": "גן הדרום",
        "line": "",
        "latitude": "31.804220",
        "distribution_area": "",
        "city_code": "1072",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "102",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הגפן",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכניית גן הדרום",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א',ב',ד',ה'  06:30- 14:00  16:00- 19:00, ג' 06:30- 19:00, ו' 06:30- 16:00",
        "mesirot_yn": "y",
        "longitude": "34.700406"
    },
    {
        "n_code": "4822",
        "city": "גן יבנה",
        "line": "",
        "latitude": "31.789608669339753",
        "distribution_area": "",
        "city_code": "166",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "116",
        "house": "5",
        "distribution_area_desc": "",
        "street": "העצמאות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "טסטאפון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-14:00  16:00-20:00, ו' וערבי חג  9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.7139207"
    },
    {
        "n_code": "3275",
        "city": "גן יבנה",
        "line": "",
        "latitude": "31.795336",
        "distribution_area": "",
        "city_code": "166",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "123",
        "house": "56",
        "distribution_area_desc": "",
        "street": "המגינים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "עולם המצעים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 ו' 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.704494"
    },
    {
        "n_code": "3690",
        "city": "גן שלמה",
        "line": "",
        "latitude": "31.87850",
        "distribution_area": "",
        "city_code": "144",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "גן שלמה",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "כולבו קבוצת שילר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-19:00 ו'7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.79838"
    },
    {
        "n_code": "3803",
        "city": "דאלית אל-כרמל",
        "line": "",
        "latitude": "32.69729",
        "distribution_area": "",
        "city_code": "494",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "156",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ואדי אלפש",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "Mcoffe",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 9:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.05455"
    },
    {
        "n_code": "3616",
        "city": "דאלית אל-כרמל",
        "line": "",
        "latitude": "32.691378",
        "distribution_area": "",
        "city_code": "494",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0001",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כביש ראשי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בון פט (חנות חיות )",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.050735"
    },
    {
        "n_code": "2557",
        "city": "דאלית אל-כרמל",
        "line": "",
        "latitude": "32.687307",
        "distribution_area": "",
        "city_code": "494",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "134",
        "house": "999",
        "distribution_area_desc": "",
        "street": "דליה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חלבי סמיח מתנות ותיירות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 10-18 (טלפון לבירורים- 048395146 -WAZE סמיח חלבי חנות מתנות פופים שטיחים )",
        "mesirot_yn": "y",
        "longitude": "35.060220"
    },
    {
        "n_code": "4291",
        "city": "דאלית אל-כרמל",
        "line": "",
        "latitude": "32.697290",
        "distribution_area": "",
        "city_code": "494",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "156",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ואדי אלפש",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "רונן שופ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ז 5:00-24:00",
        "mesirot_yn": "y",
        "longitude": "35.060190"
    },
    {
        "n_code": "4906",
        "city": "דבוריה",
        "line": "",
        "latitude": "32.682836214708175",
        "distribution_area": "",
        "city_code": "489",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "128",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אלשראר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שוק בכפר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-22:00 שישי 8:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.3763530"
    },
    {
        "n_code": "3936",
        "city": "דבוריה",
        "line": "",
        "latitude": "32.693903",
        "distribution_area": "",
        "city_code": "489",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "דבוריה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תקשורת יסמין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 9-22, כניסה לכפר, כיכר שניה מצד שמאל",
        "mesirot_yn": "y",
        "longitude": "35.368792"
    },
    {
        "n_code": "3480",
        "city": "דגניה א'",
        "line": "",
        "latitude": "32.706970",
        "distribution_area": "",
        "city_code": "62",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "3",
        "distribution_area_desc": "",
        "street": "דגניה א'",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תוצרת דגניה א",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-16:00 ו' 9:00-13:00 ש- 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.575035"
    },
    {
        "n_code": "3119",
        "city": "דגניה ב'",
        "line": "",
        "latitude": "32.699229",
        "distribution_area": "",
        "city_code": "79",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "דגניה ב'",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כלבו דגניה ב'",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א.ה 7:00 19:00 ו' 7:00 - 14:00",
        "mesirot_yn": "y",
        "longitude": "35.575553"
    },
    {
        "n_code": "3500",
        "city": "דייר אל-אסד",
        "line": "",
        "latitude": "32.937109",
        "distribution_area": "",
        "city_code": "490",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "דייר אל-אסד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "דוקטור פון  מרכז הכפר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים שני עד שבת 11:00-18:30",
        "mesirot_yn": "y",
        "longitude": "35.269080"
    },
    {
        "n_code": "4383",
        "city": "דייר אל-אסד",
        "line": "",
        "latitude": "32.934731",
        "distribution_area": "",
        "city_code": "490",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "דייר אל-אסד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מארינה נרגילות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "כביש ראשי א-ז 12:00-00:00  0543259126",
        "mesirot_yn": "y",
        "longitude": "35.267192"
    },
    {
        "n_code": "3453",
        "city": "דייר חנא",
        "line": "",
        "latitude": "32.864773",
        "distribution_area": "",
        "city_code": "492",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "5",
        "distribution_area_desc": "",
        "street": "דייר חנא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספרית אלעודה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 10:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.366452"
    },
    {
        "n_code": "2930",
        "city": "דימונה",
        "line": "",
        "latitude": "31.066354",
        "distribution_area": "",
        "city_code": "2200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "tgwt",
        "house": "67",
        "distribution_area_desc": "",
        "street": "מרכז מסחרי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אדל מצעים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-14 , 16-19 יום ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "35.030644"
    },
    {
        "n_code": "1412",
        "city": "דימונה",
        "line": "",
        "latitude": "31.07444881",
        "distribution_area": "",
        "city_code": "2200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "287",
        "house": "6",
        "distribution_area_desc": "",
        "street": "שמעון בר יוחאי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "המכולת שלי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-14 16-20 ו' 8-15",
        "mesirot_yn": "y",
        "longitude": "35.04137691"
    },
    {
        "n_code": "3144",
        "city": "דימונה",
        "line": "",
        "latitude": "31.069355",
        "distribution_area": "",
        "city_code": "2200",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "0223",
        "house": "293",
        "distribution_area_desc": "",
        "street": "טיילת הספורט",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "טיים אאוט.פסק זמן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 14-23 ו' 10-15:00 ש' 30 דק' מצאת השבת עד 23:00",
        "mesirot_yn": "y",
        "longitude": "35.033554"
    },
    {
        "n_code": "4061",
        "city": "דימונה",
        "line": "",
        "latitude": "31.061660",
        "distribution_area": "",
        "city_code": "2200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "163",
        "house": "3",
        "distribution_area_desc": "",
        "street": "המלאכה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן דימונה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.023938"
    },
    {
        "n_code": "3748",
        "city": "דימונה",
        "line": "",
        "latitude": "31.06107",
        "distribution_area": "",
        "city_code": "2200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "163",
        "house": "25",
        "distribution_area_desc": "",
        "street": "המלאכה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פיסטוק חנות לחיות מחמד",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'-ה' 08-12 + 16:00-19:30   שישי 14:00- 09:00",
        "mesirot_yn": "y",
        "longitude": "35.02529"
    },
    {
        "n_code": "2794",
        "city": "דישון",
        "line": "",
        "latitude": "33.083368",
        "distribution_area": "",
        "city_code": "2063",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "דישון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט דישון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-19:00 ו' 8-16:00",
        "mesirot_yn": "y",
        "longitude": "35.518668"
    },
    {
        "n_code": "4272",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.155568",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "111",
        "house": "9",
        "distribution_area_desc": "",
        "street": "הבנים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אירית לחם תושיה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-19 ו 8-19",
        "mesirot_yn": "y",
        "longitude": "34.892664"
    },
    {
        "n_code": "4786",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.155589",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0f45",
        "house": "9",
        "distribution_area_desc": "",
        "street": "הבנים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מיי קידס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א,ב,ד,ה 10:00-18:00 שלישי 10:00-13:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.892799"
    },
    {
        "n_code": "3562",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.15103213231041",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "230",
        "house": "8",
        "distribution_area_desc": "",
        "street": "המוסדות",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מינימרקט לב השכונה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-13:00 17:00-18:30 ו' 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.88279218"
    },
    {
        "n_code": "3516",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.159560",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "341",
        "house": "19",
        "distribution_area_desc": "",
        "street": "כנרת",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מכולת פנינת כנרת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 09:00-19:00 ו' 7:30-13:30",
        "mesirot_yn": "y",
        "longitude": "34.914145"
    },
    {
        "n_code": "3273",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.139057",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "161",
        "house": "17",
        "distribution_area_desc": "",
        "street": "אלכסנדר הגדול",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סופר שכונתי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א ב ד ה 8-14  16-20  ג' 8-14  ו' 8-15",
        "mesirot_yn": "y",
        "longitude": "34.900091"
    },
    {
        "n_code": "4696",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.15048529195479",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0f0b",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אסף הרופא",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "שופופו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 09:30-18:30  שישי 09:30-14:30  097888417",
        "mesirot_yn": "y",
        "longitude": "34.89498932"
    },
    {
        "n_code": "3694",
        "city": "הוד השרון",
        "line": "",
        "latitude": "32.15978",
        "distribution_area": "",
        "city_code": "9700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "315",
        "house": "6",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "שוק השרון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 16:00-19:00 שישי סגור",
        "mesirot_yn": "y",
        "longitude": "34.90358"
    },
    {
        "n_code": "1679",
        "city": "הושעיה",
        "line": "",
        "latitude": "32.758626",
        "distribution_area": "",
        "city_code": "1186",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "הושעיה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "צרכניית אלדר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-13:30 16-19 ו' 7-13",
        "mesirot_yn": "y",
        "longitude": "35.294412"
    },
    {
        "n_code": "3595",
        "city": "הזורעים",
        "line": "",
        "latitude": "32.74504",
        "distribution_area": "",
        "city_code": "307",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "הזורעים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מזכירות הזורעים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'ג'ה' :18:00-19:00",
        "mesirot_yn": "y",
        "longitude": "35.50243"
    },
    {
        "n_code": "2514",
        "city": "הסוללים",
        "line": "",
        "latitude": "32.751261",
        "distribution_area": "",
        "city_code": "677",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "הסוללים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכול",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-20  ו' 6:30-16",
        "mesirot_yn": "y",
        "longitude": "35.236143"
    },
    {
        "n_code": "4118",
        "city": "העוגן",
        "line": "",
        "latitude": "32.361356",
        "distribution_area": "",
        "city_code": "423",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "העוגן",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "חי טוב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-19 ו 9-14",
        "mesirot_yn": "y",
        "longitude": "34.924548"
    },
    {
        "n_code": "4553",
        "city": "הר עמשא",
        "line": "",
        "latitude": "31.342937",
        "distribution_area": "",
        "city_code": "1261",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "הר עמשא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אישטח",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.102710"
    },
    {
        "n_code": "2832",
        "city": "הרצליה",
        "line": "",
        "latitude": "32.171510",
        "distribution_area": "",
        "city_code": "6400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "618",
        "house": "60",
        "distribution_area_desc": "",
        "street": "שד' אלי לנדאו",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "ג'וניור מרקט  (מלון דניאל)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-23  ו' 9 עד שעה לפנית כניסת שבת/ חג",
        "mesirot_yn": "y",
        "longitude": "34.800525"
    },
    {
        "n_code": "4584",
        "city": "הרצליה",
        "line": "",
        "latitude": "32.163247",
        "distribution_area": "",
        "city_code": "6400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "קניון ארנה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "בואו נגיד ש...",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "כניסה A ימים ראשון עד שבת 9:00-19:00 שישי עד 15:00 חנות ספרים קניון ארנה",
        "mesirot_yn": "y",
        "longitude": "34.797296"
    },
    {
        "n_code": "1211",
        "city": "הרצליה",
        "line": "",
        "latitude": "32.16546955",
        "distribution_area": "",
        "city_code": "6400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "106",
        "house": "55",
        "distribution_area_desc": "",
        "street": "כצנלסון",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט השחר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 06:00-23:00 יום ו' 05:00-16:00",
        "mesirot_yn": "y",
        "longitude": "34.85923622"
    },
    {
        "n_code": "4147",
        "city": "הרצליה",
        "line": "",
        "latitude": "32.189181",
        "distribution_area": "",
        "city_code": "6400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "794",
        "house": "7",
        "distribution_area_desc": "",
        "street": "לוטם",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מעדניית מלכה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מרכז מסחרי נוף ים א-ה 6-21 ו 6-14",
        "mesirot_yn": "y",
        "longitude": "34.810716"
    },
    {
        "n_code": "9005",
        "city": "הרצליה",
        "line": "",
        "latitude": "32.167164",
        "distribution_area": "",
        "city_code": "6400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0011",
        "house": "74",
        "distribution_area_desc": "",
        "street": "*סוקולוב*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס הרצליה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.848377"
    },
    {
        "n_code": "4577",
        "city": "זוהר",
        "line": "",
        "latitude": "31.595634",
        "distribution_area": "",
        "city_code": "44",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רימון",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכניית מושב זוהר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:30-13:00 16:00-19:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.692365"
    },
    {
        "n_code": "2856",
        "city": "זכרון יעקב",
        "line": "",
        "latitude": "32.565688",
        "distribution_area": "",
        "city_code": "9300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "256",
        "house": "2",
        "distribution_area_desc": "",
        "street": "השמורה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "דיקלה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-19:30 ו'8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.943332"
    },
    {
        "n_code": "3812",
        "city": "זכרון יעקב",
        "line": "",
        "latitude": "32.56028",
        "distribution_area": "",
        "city_code": "9300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "167",
        "house": "23",
        "distribution_area_desc": "",
        "street": "כנפי נשרים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מרכולית ענת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'ב'ד' ה'8:30-12:30-16:30-19:30 ג'8:30-15:00ו'8:30-13:30",
        "mesirot_yn": "y",
        "longitude": "34.96438"
    },
    {
        "n_code": "4100",
        "city": "זכרון יעקב",
        "line": "",
        "latitude": "32.574093",
        "distribution_area": "",
        "city_code": "9300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "125",
        "house": "18",
        "distribution_area_desc": "",
        "street": "הנדיב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מרכולית ענת המושבה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א ב ד ה 14-21 ג 16-20 ו 9-14",
        "mesirot_yn": "y",
        "longitude": "34.954107"
    },
    {
        "n_code": "4873",
        "city": "זכרון יעקב",
        "line": "",
        "latitude": "32.57342398255195",
        "distribution_area": "",
        "city_code": "9300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "121",
        "house": "52",
        "distribution_area_desc": "",
        "street": "המיסדים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סלולאר לי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-18:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.95448829"
    },
    {
        "n_code": "4945",
        "city": "זכריה",
        "line": "",
        "latitude": "31.708823213235878",
        "distribution_area": "",
        "city_code": "799",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "dfgd",
        "house": "10",
        "distribution_area_desc": "",
        "street": "הכלנית",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מרקט בכפר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-17:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.9424243"
    },
    {
        "n_code": "3757",
        "city": "זמר",
        "line": "",
        "latitude": "32.35840",
        "distribution_area": "",
        "city_code": "1290",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0001",
        "house": "999",
        "distribution_area_desc": "",
        "street": "איזור תעשיה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "Super Large",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'-ה' 08:00-19:00,  ו' 08:00-16:00,  שבת 08:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.02663"
    },
    {
        "n_code": "2881",
        "city": "זמרת",
        "line": "",
        "latitude": "31.447573",
        "distribution_area": "",
        "city_code": "1065",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "זמרת",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מכולת זמרת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-14:00 16:00-20:30 ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "34.554792"
    },
    {
        "n_code": "3600",
        "city": "חד-נס",
        "line": "",
        "latitude": "32.93665",
        "distribution_area": "",
        "city_code": "4026",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "חד-נס",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מזכירות חד נס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.63935"
    },
    {
        "n_code": "1479",
        "city": "חדיד",
        "line": "",
        "latitude": "31.9688442",
        "distribution_area": "",
        "city_code": "618",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "8",
        "distribution_area_desc": "",
        "street": "האירוס",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "צרכניית חדיד",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 6-19 ג+ו' 6-14",
        "mesirot_yn": "y",
        "longitude": "34.93406046"
    },
    {
        "n_code": "4948",
        "city": "חדרה",
        "line": "",
        "latitude": "",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0002",
        "house": "34",
        "distribution_area_desc": "",
        "street": "הנשיא",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "D.N.A MOBILE",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-18:00 שישי וערבי חג 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": ""
    },
    {
        "n_code": "4853",
        "city": "חדרה",
        "line": "",
        "latitude": "32.435681",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "602",
        "house": "91",
        "distribution_area_desc": "",
        "street": "הרברט סמואל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "להיט בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.923085"
    },
    {
        "n_code": "3199",
        "city": "חדרה",
        "line": "",
        "latitude": "32.434128",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1010",
        "house": "1",
        "distribution_area_desc": "",
        "street": "יהודי פקיעין",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מזל טוב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.909332"
    },
    {
        "n_code": "3622",
        "city": "חדרה",
        "line": "",
        "latitude": "32.45349",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "313",
        "house": "32",
        "distribution_area_desc": "",
        "street": "מנא\\\"י",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פיצוציית המזל שלי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-20:00 ו'7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.90808"
    },
    {
        "n_code": "4360",
        "city": "חדרה",
        "line": "",
        "latitude": "32.415868",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "740",
        "house": "7",
        "distribution_area_desc": "",
        "street": "אלוף יקותיאל אדם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פלאפוניה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-19 ו 9:30-14",
        "mesirot_yn": "y",
        "longitude": "34.951793"
    },
    {
        "n_code": "4115",
        "city": "חדרה",
        "line": "",
        "latitude": "32.437414",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "611",
        "house": "17",
        "distribution_area_desc": "",
        "street": "שד רוטשילד",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פרו מובייל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-18:00, שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.921269"
    },
    {
        "n_code": "2248",
        "city": "חדרה",
        "line": "",
        "latitude": "32.438812",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "609",
        "house": "81",
        "distribution_area_desc": "",
        "street": "הגבורים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קאבר שופ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-18:30  ו' 10-13:30 חנות הסלולר שלי",
        "mesirot_yn": "y",
        "longitude": "34.923260"
    },
    {
        "n_code": "3808",
        "city": "חדרה",
        "line": "",
        "latitude": "32.42044",
        "distribution_area": "",
        "city_code": "6500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "809",
        "house": "16",
        "distribution_area_desc": "",
        "street": "השושנים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קנדי שופ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-20:00 ו'9:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.95229"
    },
    {
        "n_code": "4478",
        "city": "חולדה",
        "line": "",
        "latitude": "31.831618",
        "distribution_area": "",
        "city_code": "160",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "חולדה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר שלומי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-18:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.882380"
    },
    {
        "n_code": "3913",
        "city": "חולון",
        "line": "",
        "latitude": "32.007496",
        "distribution_area": "",
        "city_code": "6600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "127",
        "house": "26",
        "distribution_area_desc": "",
        "street": "הרוקמים",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "הסטוק חולון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 14:00-21:00 ו'9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.801137"
    },
    {
        "n_code": "3879",
        "city": "חולון",
        "line": "",
        "latitude": "32.024791",
        "distribution_area": "",
        "city_code": "6600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "706",
        "house": "29",
        "distribution_area_desc": "",
        "street": "שנקר",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "על הדרך",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11-19 ו'10-14",
        "mesirot_yn": "y",
        "longitude": "34.776809"
    },
    {
        "n_code": "9004",
        "city": "חולון",
        "line": "",
        "latitude": "32.022202",
        "distribution_area": "",
        "city_code": "6600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0003",
        "house": "6",
        "distribution_area_desc": "",
        "street": "*כיכר וויצמן*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס חולון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.774707"
    },
    {
        "n_code": "4810",
        "city": "חולון",
        "line": "",
        "latitude": "32.023566182972374",
        "distribution_area": "",
        "city_code": "6600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "117",
        "house": "7",
        "distribution_area_desc": "",
        "street": "הפלד",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "קיוסק אקספרס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מתחם רמי לוי ליד מחסני תאורה א-ה 9:00-12:00 18:00-20:00שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.7976714"
    },
    {
        "n_code": "3414",
        "city": "חוסן",
        "line": "",
        "latitude": "32.998839",
        "distribution_area": "",
        "city_code": "662",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "108",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שביל קק\\\"ל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרקט חוסן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6-21 ו' 6-16",
        "mesirot_yn": "y",
        "longitude": "35.296587"
    },
    {
        "n_code": "4932",
        "city": "חורה",
        "line": "",
        "latitude": "31.297901",
        "distribution_area": "",
        "city_code": "1303",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6702",
        "house": "471",
        "distribution_area_desc": "",
        "street": "שכ 1",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "שוג תקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ראשון עד שבת 9:00-22:00",
        "mesirot_yn": "y",
        "longitude": "34.945441"
    },
    {
        "n_code": "2129",
        "city": "חורפיש",
        "line": "",
        "latitude": "33.018233",
        "distribution_area": "",
        "city_code": "496",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "חורפיש",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "טוטו לוטו דלק חורפיש",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 9-23 ליד תחנת דלק",
        "mesirot_yn": "y",
        "longitude": "35.347304"
    },
    {
        "n_code": "2005",
        "city": "חורפיש",
        "line": "",
        "latitude": "33.015720",
        "distribution_area": "",
        "city_code": "496",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "חורפיש",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ראיף גדבאן- צבע וכלי עבודה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 8:30-18",
        "mesirot_yn": "y",
        "longitude": "35.351569"
    },
    {
        "n_code": "3133",
        "city": "חזון",
        "line": "",
        "latitude": "32.899493",
        "distribution_area": "",
        "city_code": "1047",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "חזון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חזון שיווק והפצה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב,ג,ד,ה 10:00-19:00 שישי שבת 10:00-19:00 א- סגור.(צומת מושב חזון. בwaze חזון שיווק והפצה)",
        "mesirot_yn": "y",
        "longitude": "35.415237"
    },
    {
        "n_code": "2037",
        "city": "חיפה",
        "line": "",
        "latitude": "32.807778",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "698",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אילנות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בדק בית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:30-15:00 16:30-18:30 ו'9:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.975819"
    },
    {
        "n_code": "4688",
        "city": "חיפה",
        "line": "",
        "latitude": "32.80390114395351",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "690",
        "house": "3",
        "distribution_area_desc": "",
        "street": "דרך הים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בורקס אליה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-02:00 בלילה יום שישי עד שעה לפני כניסת שבת מוצ\\\"ש שעה לאחר יציאת שבת",
        "mesirot_yn": "y",
        "longitude": "34.98660681"
    },
    {
        "n_code": "4623",
        "city": "חיפה",
        "line": "",
        "latitude": "32.804783",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3328",
        "house": "4",
        "distribution_area_desc": "",
        "street": "הפועל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "דנאל שיווק והפצה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-16:00 שישי 8:00-11:00",
        "mesirot_yn": "y",
        "longitude": "34.998450"
    },
    {
        "n_code": "3524",
        "city": "חיפה",
        "line": "",
        "latitude": "32.809207419933436",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "3351",
        "house": "53",
        "distribution_area_desc": "",
        "street": "החלוץ",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "דרגסטור חמודי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "פתוח כל השבוע 6:00-18:00- יום ראשון סגור!!!! לא להתקשר בימי ראשון!! לא יהיה מענה",
        "mesirot_yn": "y",
        "longitude": "35.0004418"
    },
    {
        "n_code": "3381",
        "city": "חיפה",
        "line": "",
        "latitude": "32.818349",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "413",
        "house": "41",
        "distribution_area_desc": "",
        "street": "הגפן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הגפן 41",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 07:00-23:00",
        "mesirot_yn": "y",
        "longitude": "34.985935"
    },
    {
        "n_code": "1498",
        "city": "חיפה",
        "line": "",
        "latitude": "32.788854",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "633",
        "house": "2",
        "distribution_area_desc": "",
        "street": "הצלפים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "היפר דואק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-19:00 ו' 8:00-14:30",
        "mesirot_yn": "y",
        "longitude": "34.993938"
    },
    {
        "n_code": "4869",
        "city": "חיפה",
        "line": "",
        "latitude": "32.81552535188017",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "377",
        "house": "4",
        "distribution_area_desc": "",
        "street": "איתין יהודה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חד פעמי לאירוע מושלם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.05090517"
    },
    {
        "n_code": "2463",
        "city": "חיפה",
        "line": "",
        "latitude": "32.802695",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "919",
        "house": "25",
        "distribution_area_desc": "",
        "street": "גאולה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "טי אם מור מחשבים בע''מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:30-18  ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "35.000186"
    },
    {
        "n_code": "3148",
        "city": "חיפה",
        "line": "",
        "latitude": "32.807458",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "823",
        "house": "73",
        "distribution_area_desc": "",
        "street": "ארלוזורוב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ליקר Premier",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-23:00 ו' 9:00-23:00",
        "mesirot_yn": "y",
        "longitude": "34.994465"
    },
    {
        "n_code": "3077",
        "city": "חיפה",
        "line": "",
        "latitude": "32.800548",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "914",
        "house": "43",
        "distribution_area_desc": "",
        "street": "סנש חנה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מיני מרקט יוני",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים 9:00-16:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.999439"
    },
    {
        "n_code": "3609",
        "city": "חיפה",
        "line": "",
        "latitude": "32.81211",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "718",
        "house": "63",
        "distribution_area_desc": "",
        "street": "התשבי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט רמת התישבי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-18:00 ו'9:000-13:00",
        "mesirot_yn": "y",
        "longitude": "34.98134"
    },
    {
        "n_code": "2859",
        "city": "חיפה",
        "line": "",
        "latitude": "32.819380",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "354",
        "house": "31",
        "distribution_area_desc": "",
        "street": "דרך יפו",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מיקס סטור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 8:00-21:00",
        "mesirot_yn": "y",
        "longitude": "34.997681"
    },
    {
        "n_code": "4392",
        "city": "חיפה",
        "line": "",
        "latitude": "32.815269",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "394",
        "house": "7",
        "distribution_area_desc": "",
        "street": "פרץ י. ל.",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מיראל פארטי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א סגור ב-ה 10:00-18 ו 9:00-18:30 ז 9:00-17:00",
        "mesirot_yn": "y",
        "longitude": "34.996196"
    },
    {
        "n_code": "1490",
        "city": "חיפה",
        "line": "",
        "latitude": "32.807455",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "563",
        "house": "17",
        "distribution_area_desc": "",
        "street": "יהושפט המלך",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ממתקי רחל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 8-14 16-18:00 יום ו 8 -14",
        "mesirot_yn": "y",
        "longitude": "34.961258"
    },
    {
        "n_code": "2168",
        "city": "חיפה",
        "line": "",
        "latitude": "32.806186",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3354",
        "house": "64",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מקור החשמל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א ב ד ה 9:30-20:30 ג' 9:30-17  ו' 9:30-12",
        "mesirot_yn": "y",
        "longitude": "35.002198"
    },
    {
        "n_code": "4361",
        "city": "חיפה",
        "line": "",
        "latitude": "32.792790",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1022",
        "house": "27",
        "distribution_area_desc": "",
        "street": "חניתה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "נימ רץ משלוחים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א עד ה 10:00-19:00  שישי סגור",
        "mesirot_yn": "y",
        "longitude": "35.012242"
    },
    {
        "n_code": "4367",
        "city": "חיפה",
        "line": "",
        "latitude": "32.807977",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "698",
        "house": "2",
        "distribution_area_desc": "",
        "street": "אילנות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סלע בייק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-19:00 ו 9:00-15:00 ליד הכניסה לשופרסל אקספרס",
        "mesirot_yn": "y",
        "longitude": "34.976332"
    },
    {
        "n_code": "1871",
        "city": "חיפה",
        "line": "",
        "latitude": "32.820721",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "348",
        "house": "55",
        "distribution_area_desc": "",
        "street": "דרך העצמאות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספורט מיכלין",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-17 ו' 8-13:30",
        "mesirot_yn": "y",
        "longitude": "34.997679"
    },
    {
        "n_code": "3360",
        "city": "חיפה",
        "line": "",
        "latitude": "32.807550",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "333c",
        "house": "9",
        "distribution_area_desc": "",
        "street": "סוקולוב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פאנסי ליין-חיפה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-18 ו' 10-14",
        "mesirot_yn": "y",
        "longitude": "35.002025"
    },
    {
        "n_code": "4292",
        "city": "חיפה",
        "line": "",
        "latitude": "32.804669",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "635",
        "house": "121",
        "distribution_area_desc": "",
        "street": "שד הנשיא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פיינאפל אקספרס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ז 7:00-24:00",
        "mesirot_yn": "y",
        "longitude": "34.987781"
    },
    {
        "n_code": "3496",
        "city": "חיפה",
        "line": "",
        "latitude": "32.823399",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "399",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שד בן גוריון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קורנר סטור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-20:30 ו' 8:30-15:30",
        "mesirot_yn": "y",
        "longitude": "34.992641"
    },
    {
        "n_code": "4803",
        "city": "חיפה",
        "line": "",
        "latitude": "32.803694",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "635",
        "house": "128",
        "distribution_area_desc": "",
        "street": "שד הנשיא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "רז פלוס ערוגות הבושם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00",
        "mesirot_yn": "y",
        "longitude": "34.987230"
    },
    {
        "n_code": "2551",
        "city": "חיפה",
        "line": "",
        "latitude": "32.817927",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "741",
        "house": "28",
        "distribution_area_desc": "",
        "street": "בית לחם",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שבירו'ס מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-20 ו' 6:30-15",
        "mesirot_yn": "y",
        "longitude": "34.973185"
    },
    {
        "n_code": "2820",
        "city": "חיפה",
        "line": "",
        "latitude": "32.820002",
        "distribution_area": "",
        "city_code": "4000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "362",
        "house": "21",
        "distribution_area_desc": "",
        "street": "מאיר (רוטברג)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תרנים פרפיום  בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-19:00 ו' 9-17:00",
        "mesirot_yn": "y",
        "longitude": "34.991685"
    },
    {
        "n_code": "3410",
        "city": "חלץ",
        "line": "",
        "latitude": "31.578915",
        "distribution_area": "",
        "city_code": "820",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "חלץ",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הצרכניה של אלי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-13 16-19 ו' 8-13:45",
        "mesirot_yn": "y",
        "longitude": "34.658853"
    },
    {
        "n_code": "4352",
        "city": "חצור הגלילית",
        "line": "",
        "latitude": "32.976746",
        "distribution_area": "",
        "city_code": "2034",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "116",
        "house": "25",
        "distribution_area_desc": "",
        "street": "יצחק שמיר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כוכב התקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-19 ו 9-14",
        "mesirot_yn": "y",
        "longitude": "35.542630"
    },
    {
        "n_code": "4612",
        "city": "חצור הגלילית",
        "line": "",
        "latitude": "32.981465",
        "distribution_area": "",
        "city_code": "2034",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "115",
        "house": "30",
        "distribution_area_desc": "",
        "street": "בן גוריון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תבליני דניאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-19:00 שישי 7:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.544361"
    },
    {
        "n_code": "2882",
        "city": "חריש",
        "line": "",
        "latitude": "32.462552",
        "distribution_area": "",
        "city_code": "1247",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "307",
        "house": "3",
        "distribution_area_desc": "",
        "street": "טורקיז",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "די סטור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'-ה' 10:00-19:00, שישי 09:00-13:30",
        "mesirot_yn": "y",
        "longitude": "35.047145"
    },
    {
        "n_code": "3584",
        "city": "חריש",
        "line": "",
        "latitude": "32.46855926412536",
        "distribution_area": "",
        "city_code": "1247",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "303",
        "house": "43",
        "distribution_area_desc": "",
        "street": "דרך ארץ",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "לייק פון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-18:00 ו' 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.03898670"
    },
    {
        "n_code": "3329",
        "city": "חריש",
        "line": "",
        "latitude": "32.463128",
        "distribution_area": "",
        "city_code": "1247",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "303",
        "house": "45",
        "distribution_area_desc": "",
        "street": "דרך ארץ",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מור וקינמון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-19 ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "35.043321"
    },
    {
        "n_code": "4674",
        "city": "חריש",
        "line": "",
        "latitude": "32.46092009409171",
        "distribution_area": "",
        "city_code": "1247",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "12lk",
        "house": "62",
        "distribution_area_desc": "",
        "street": "דרך ארץ",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מתנות קטנות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-18:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.04743486"
    },
    {
        "n_code": "4611",
        "city": "טבריה",
        "line": "",
        "latitude": "32.781099",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "2276",
        "house": "56",
        "distribution_area_desc": "",
        "street": "האבות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "גרעיני עפולה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.508567"
    },
    {
        "n_code": "3367",
        "city": "טבריה",
        "line": "",
        "latitude": "32.788115",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "214",
        "house": "1",
        "distribution_area_desc": "",
        "street": "העמקים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "דלק בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 24/7 עד שעה לפני כניסת השבת.",
        "mesirot_yn": "y",
        "longitude": "35.537721"
    },
    {
        "n_code": "4209",
        "city": "טבריה",
        "line": "",
        "latitude": "32.790992",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "202",
        "house": "27",
        "distribution_area_desc": "",
        "street": "אלחדיף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "האנגר 13",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-2 בלילה ו' 8-16",
        "mesirot_yn": "y",
        "longitude": "35.539299"
    },
    {
        "n_code": "4440",
        "city": "טבריה",
        "line": "",
        "latitude": "32.785194",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "493",
        "house": "1",
        "distribution_area_desc": "",
        "street": "המברג",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הסטוק טבריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מתחם ביג א-ה 16:00-21:00, שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.497580"
    },
    {
        "n_code": "4208",
        "city": "טבריה",
        "line": "",
        "latitude": "32.795006",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "318",
        "house": "1",
        "distribution_area_desc": "",
        "street": "החשמונאים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הפיצוציה של רונלד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 24/7 ו עד 16:00",
        "mesirot_yn": "y",
        "longitude": "35.524833"
    },
    {
        "n_code": "2602",
        "city": "טבריה",
        "line": "",
        "latitude": "32.786982",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "508",
        "house": "801",
        "distribution_area_desc": "",
        "street": "שקד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאפיית ומינימרקט ארזים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-22 ו' 6-שעה לפני כניסת שבת",
        "mesirot_yn": "y",
        "longitude": "35.521206"
    },
    {
        "n_code": "1387",
        "city": "טבריה",
        "line": "",
        "latitude": "32.78622845",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "328",
        "house": "129",
        "distribution_area_desc": "",
        "street": "דרך הגבורה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכולית שובל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-21 ו' 6:30-14",
        "mesirot_yn": "y",
        "longitude": "35.5290832"
    },
    {
        "n_code": "2459",
        "city": "טבריה",
        "line": "",
        "latitude": "32.790493",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "235",
        "house": "5",
        "distribution_area_desc": "",
        "street": "בן זכאי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "משו טבריה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-19  ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "35.535827"
    },
    {
        "n_code": "4130",
        "city": "טבריה",
        "line": "",
        "latitude": "32.789063",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "234",
        "house": "24",
        "distribution_area_desc": "",
        "street": "יהודה הלוי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סלקשן טבריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.533886"
    },
    {
        "n_code": "4126",
        "city": "טבריה",
        "line": "",
        "latitude": "32.797940",
        "distribution_area": "",
        "city_code": "6700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "110",
        "house": "6",
        "distribution_area_desc": "",
        "street": "ביאליק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שילת מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-22 ו 7-13",
        "mesirot_yn": "y",
        "longitude": "35.530261"
    },
    {
        "n_code": "4952",
        "city": "טובא-זנגריה",
        "line": "",
        "latitude": "32.966260",
        "distribution_area": "",
        "city_code": "962",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "טובא-זנגריה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כל בו הגליל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'-ו' 6-24:00",
        "mesirot_yn": "y",
        "longitude": "35.592333"
    },
    {
        "n_code": "4303",
        "city": "טובא-זנגריה",
        "line": "",
        "latitude": "32.971994",
        "distribution_area": "",
        "city_code": "962",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "טובא-זנגריה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פיצוציית והיב זנגריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 9:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.593742"
    },
    {
        "n_code": "3472",
        "city": "טורעאן",
        "line": "",
        "latitude": "32.777548",
        "distribution_area": "",
        "city_code": "498",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "טורעאן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "גוגו מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב,ג,ד,ה,ו,ש, 10:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.374624"
    },
    {
        "n_code": "3685",
        "city": "טייבה (בעמק)",
        "line": "",
        "latitude": "32.605418",
        "distribution_area": "",
        "city_code": "497",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "טייבה (בעמק)",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מאפיית חסן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 6:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.451146"
    },
    {
        "n_code": "4644",
        "city": "טירה",
        "line": "",
        "latitude": "32.23046437357776",
        "distribution_area": "",
        "city_code": "2720",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "110",
        "house": "70",
        "distribution_area_desc": "",
        "street": "טארק עבד אל-חי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "Dr.digital",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "שבת-חמישי 11:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.95306601"
    },
    {
        "n_code": "2604",
        "city": "טירה",
        "line": "",
        "latitude": "32.22926",
        "distribution_area": "",
        "city_code": "2720",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "#0002",
        "house": "2",
        "distribution_area_desc": "",
        "street": "א -לוז",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קינג זו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-21 ו' 9-15 ש' 9-21",
        "mesirot_yn": "y",
        "longitude": "34.94970"
    },
    {
        "n_code": "4708",
        "city": "טירת כרמל",
        "line": "",
        "latitude": "32.767191",
        "distribution_area": "",
        "city_code": "2100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "211",
        "house": "2",
        "distribution_area_desc": "",
        "street": "הפטיש",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "גיימובייל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 שישי 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.967147"
    },
    {
        "n_code": "4349",
        "city": "טירת כרמל",
        "line": "",
        "latitude": "32.767342",
        "distribution_area": "",
        "city_code": "2100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "436",
        "house": "28",
        "distribution_area_desc": "",
        "street": "רמב\\\"ם",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סטופ שופ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:30-22:00 ו 6:30-16:00",
        "mesirot_yn": "y",
        "longitude": "34.974003"
    },
    {
        "n_code": "4516",
        "city": "טירת כרמל",
        "line": "",
        "latitude": "32.761539",
        "distribution_area": "",
        "city_code": "2100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "425",
        "house": "2",
        "distribution_area_desc": "",
        "street": "העצמאות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספורטוטו אורי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-20:30 שישי 8:30-16:30",
        "mesirot_yn": "y",
        "longitude": "34.973130"
    },
    {
        "n_code": "4579",
        "city": "טירת כרמל",
        "line": "",
        "latitude": "32.761350",
        "distribution_area": "",
        "city_code": "2100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "425",
        "house": "4",
        "distribution_area_desc": "",
        "street": "העצמאות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קו האופק טיסנים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 13:00-19:00 שישי 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.973214"
    },
    {
        "n_code": "4728",
        "city": "טירת צבי",
        "line": "",
        "latitude": "32.422230",
        "distribution_area": "",
        "city_code": "268",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "טירת צבי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מזכירות טירת צבי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-16:00 שישי 7:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.526249"
    },
    {
        "n_code": "1641",
        "city": "טללים",
        "line": "",
        "latitude": "30.99206171",
        "distribution_area": "",
        "city_code": "1177",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "טללים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כלבו טללים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 12-16-19:00 8-ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "34.77102353"
    },
    {
        "n_code": "2383",
        "city": "טלמון",
        "line": "",
        "latitude": "31.938881",
        "distribution_area": "",
        "city_code": "3788",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "טלמון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט צדוק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-14  16-20  ו' 8-13",
        "mesirot_yn": "y",
        "longitude": "35.133024"
    },
    {
        "n_code": "4925",
        "city": "טמרה",
        "line": "",
        "latitude": "32.854732",
        "distribution_area": "",
        "city_code": "8900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "טמרה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "8 פול קפה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 8:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.194481"
    },
    {
        "n_code": "4138",
        "city": "טמרה",
        "line": "",
        "latitude": "32.851868",
        "distribution_area": "",
        "city_code": "8900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "טמרה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "Black & yellow",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א' סגור, ב'-ש' 9:00-18:00  בוויז: מותג הצמיג- black & yellow  טמרה",
        "mesirot_yn": "y",
        "longitude": "35.201426"
    },
    {
        "n_code": "4930",
        "city": "טמרה",
        "line": "",
        "latitude": "32.847288",
        "distribution_area": "",
        "city_code": "8900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "xoo3",
        "house": "1",
        "distribution_area_desc": "",
        "street": "טמרה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "S3adte",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ראשון עד שבת 10:00-21:00  רחוב אל-שריף  https://waze.com/ul/hsvc4bvqsv",
        "mesirot_yn": "y",
        "longitude": "35.197407"
    },
    {
        "n_code": "4913",
        "city": "טנא",
        "line": "",
        "latitude": "31.375529",
        "distribution_area": "",
        "city_code": "3743",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "טנא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות טנא עומרים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א,ה 9:00-11:00 ב,ד 9:00-11:00 ג 17:00-19:00 שישי 9:00-11:00",
        "mesirot_yn": "y",
        "longitude": "34.956882"
    },
    {
        "n_code": "2057",
        "city": "יאנוח-ג'ת",
        "line": "",
        "latitude": "32.975071",
        "distribution_area": "",
        "city_code": "1295",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "יאנוח-ג'ת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ביג פארם-כביש ראשי ליד בנק הדואר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 10-14 16-21",
        "mesirot_yn": "y",
        "longitude": "35.229523"
    },
    {
        "n_code": "3157",
        "city": "יבנאל",
        "line": "",
        "latitude": "32.706218",
        "distribution_area": "",
        "city_code": "46",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "140",
        "house": "1",
        "distribution_area_desc": "",
        "street": "דרך ההר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ברסלב סנטר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:45-16:00 ו' 8:45-12:00.",
        "mesirot_yn": "y",
        "longitude": "35.504775"
    },
    {
        "n_code": "4799",
        "city": "יבנאל",
        "line": "",
        "latitude": "32.703879",
        "distribution_area": "",
        "city_code": "46",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "116",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הגורן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאסטר ווב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.511422"
    },
    {
        "n_code": "3914",
        "city": "יבנה",
        "line": "",
        "latitude": "31.868775",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "158",
        "house": "50",
        "distribution_area_desc": "",
        "street": "שד דואני",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "א.י.נחום אסתר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:30-13:30 16:30-19:30 שישי 8-14:30",
        "mesirot_yn": "y",
        "longitude": "34.744480"
    },
    {
        "n_code": "4001",
        "city": "יבנה",
        "line": "",
        "latitude": "31.886109",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "191",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הקישון",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אחים ללזרי אספקה 2013 בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11-19 ו' 10-13",
        "mesirot_yn": "y",
        "longitude": "34.733926"
    },
    {
        "n_code": "3282",
        "city": "יבנה",
        "line": "",
        "latitude": "31.872438",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "162",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הנשיאים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "בית הנרגילה-יבנה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-22:00 ו' 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.749489"
    },
    {
        "n_code": "4745",
        "city": "יבנה",
        "line": "",
        "latitude": "31.873198",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "212",
        "house": "4",
        "distribution_area_desc": "",
        "street": "הרב אבוחצירא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הכפריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00",
        "mesirot_yn": "y",
        "longitude": "34.747037"
    },
    {
        "n_code": "4931",
        "city": "יבנה",
        "line": "",
        "latitude": "31.875231",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0357",
        "house": "25",
        "distribution_area_desc": "",
        "street": "הדרור",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כלבו יונה ורד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 9:00-14:00  החנות סגורה מ10.07 עד 14.07",
        "mesirot_yn": "y",
        "longitude": "34.743924"
    },
    {
        "n_code": "3179",
        "city": "יבנה",
        "line": "",
        "latitude": "31.877007",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "035f",
        "house": "5",
        "distribution_area_desc": "",
        "street": "האורן",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מינימרקט הערבה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-22:00 ו' 7:00- 14:30",
        "mesirot_yn": "y",
        "longitude": "34.739297"
    },
    {
        "n_code": "4771",
        "city": "יבנה",
        "line": "",
        "latitude": "31.88492005246996",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "200",
        "house": "3",
        "distribution_area_desc": "",
        "street": "שד הסנהדרין",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פיס אוף קייק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00 שישי 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.73886784"
    },
    {
        "n_code": "4534",
        "city": "יבנה",
        "line": "",
        "latitude": "31.872685",
        "distribution_area": "",
        "city_code": "2660",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "172",
        "house": "9",
        "distribution_area_desc": "",
        "street": "העצמאות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פרי-לי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.749163"
    },
    {
        "n_code": "4083",
        "city": "יגור",
        "line": "",
        "latitude": "32.749677",
        "distribution_area": "",
        "city_code": "96",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "יגור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "נישו בית קפה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'-ו' 7:30-16:00",
        "mesirot_yn": "y",
        "longitude": "35.069616"
    },
    {
        "n_code": "1417",
        "city": "יד בנימין",
        "line": "",
        "latitude": "31.79807499",
        "distribution_area": "",
        "city_code": "577",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "159",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עובדיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פי.סי.טק חנות פלאפונים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 9-19 ו' 9-13(מרכז מסחרי חדש) קומה ב'",
        "mesirot_yn": "y",
        "longitude": "34.82170041"
    },
    {
        "n_code": "2225",
        "city": "יהוד-מונוסון",
        "line": "",
        "latitude": "32.030902",
        "distribution_area": "",
        "city_code": "9400",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "135",
        "house": "31",
        "distribution_area_desc": "",
        "street": "חתוכה סעדיה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "הלו ארטיק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א,ב,ד,ה 10:00-14:00 16:00-19:00 ג,ו- 10:00-14:00  הלו ארטיק מאסטר מובייל לשעבר",
        "mesirot_yn": "y",
        "longitude": "34.888627"
    },
    {
        "n_code": "4695",
        "city": "יהוד-מונוסון",
        "line": "",
        "latitude": "32.03045711061673",
        "distribution_area": "",
        "city_code": "9400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "קניון סביונים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "הסטוק יהוד מונוסון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-20:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.87818872"
    },
    {
        "n_code": "4858",
        "city": "יהוד-מונוסון",
        "line": "",
        "latitude": "32.02801488791291",
        "distribution_area": "",
        "city_code": "9400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0f81",
        "house": "40",
        "distribution_area_desc": "",
        "street": "דרך העצמאות",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "ניופון פיקס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.88951814"
    },
    {
        "n_code": "3614",
        "city": "יהוד-מונוסון",
        "line": "",
        "latitude": "32.02925",
        "distribution_area": "",
        "city_code": "9400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "125",
        "house": "17",
        "distribution_area_desc": "",
        "street": "אשכנזי",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "נירו פון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 11:00-14:00 16:00-19:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.89058"
    },
    {
        "n_code": "3805",
        "city": "ינוב",
        "line": "",
        "latitude": "32.308678",
        "distribution_area": "",
        "city_code": "753",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "27",
        "distribution_area_desc": "",
        "street": "נתיב הזית",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סיטבון מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.952506"
    },
    {
        "n_code": "4861",
        "city": "יסוד המעלה",
        "line": "",
        "latitude": "33.045705",
        "distribution_area": "",
        "city_code": "29",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "יסוד המעלה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מפגש המושבה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-23:00 שישי 8:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.573348"
    },
    {
        "n_code": "4184",
        "city": "יפיע",
        "line": "",
        "latitude": "32.686793",
        "distribution_area": "",
        "city_code": "499",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "יפיע",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאפיית אסנאבל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "כביש ראשי חיפה נצרתא-ו 6:00-24:00",
        "mesirot_yn": "y",
        "longitude": "35.271659"
    },
    {
        "n_code": "4371",
        "city": "יפיע",
        "line": "",
        "latitude": "32.692113",
        "distribution_area": "",
        "city_code": "499",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "107",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רח 701",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרקט אלואדי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ז 7:00-23:00 - שכונת אשמאלי- מיקום בגוגל מפות: https://goo.gl/maps/ZsFojvhCwswCEyZh9",
        "mesirot_yn": "y",
        "longitude": "35.276353"
    },
    {
        "n_code": "4742",
        "city": "יפעת",
        "line": "",
        "latitude": "32.675256",
        "distribution_area": "",
        "city_code": "134",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "יפעת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שטיפעת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00 שישי 8:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.221303"
    },
    {
        "n_code": "4023",
        "city": "יצהר",
        "line": "",
        "latitude": "32.169197",
        "distribution_area": "",
        "city_code": "3749",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "יצהר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אגודה שיתופית יצהר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ב+ד 7:30-21-00 ו' 8:30-9:30 טל בחנות : 0543984399",
        "mesirot_yn": "y",
        "longitude": "35.235999"
    },
    {
        "n_code": "4521",
        "city": "יציץ",
        "line": "",
        "latitude": "31.862680",
        "distribution_area": "",
        "city_code": "759",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הרימון",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "אמילי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.863074"
    },
    {
        "n_code": "4586",
        "city": "יקיר",
        "line": "",
        "latitude": "32.147340",
        "distribution_area": "",
        "city_code": "3647",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "115",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שד ראשונים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "דואט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'-ה' - 10:00-19:00 ו' - 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.116437"
    },
    {
        "n_code": "3849",
        "city": "יקנעם עילית",
        "line": "",
        "latitude": "32.664936",
        "distribution_area": "",
        "city_code": "240",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "191",
        "house": "27",
        "distribution_area_desc": "",
        "street": "הקידמה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אבי בן גד שיווק בע\\\\\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ד 8-17  ה' 8-16",
        "mesirot_yn": "y",
        "longitude": "35.105527"
    },
    {
        "n_code": "1038",
        "city": "יקנעם עילית",
        "line": "",
        "latitude": "32.63675625",
        "distribution_area": "",
        "city_code": "240",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "224",
        "house": "20",
        "distribution_area_desc": "",
        "street": "יער אודם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "ביג מרקט חיון בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-19:30  יום שישי 8:30-13:30",
        "mesirot_yn": "y",
        "longitude": "35.08857191"
    },
    {
        "n_code": "4568",
        "city": "יקנעם עילית",
        "line": "",
        "latitude": "32.642689",
        "distribution_area": "",
        "city_code": "240",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "210",
        "house": "9",
        "distribution_area_desc": "",
        "street": "שד יצחק רבין",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "החמניה יקנעם עילית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-20:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.092425"
    },
    {
        "n_code": "2718",
        "city": "יקנעם עילית",
        "line": "",
        "latitude": "32.655717",
        "distribution_area": "",
        "city_code": "240",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "160",
        "house": "4",
        "distribution_area_desc": "",
        "street": "התמר",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "זיגי מכשירי כתיבה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:45-13:30 15:30  18:30 שישי 9:45-15:30 חלוקת חבילות בשעות פעילות בלבד!",
        "mesirot_yn": "y",
        "longitude": "35.105079"
    },
    {
        "n_code": "4043",
        "city": "יקנעם עילית",
        "line": "",
        "latitude": "32.659962",
        "distribution_area": "",
        "city_code": "240",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "160",
        "house": "2",
        "distribution_area_desc": "",
        "street": "התמר",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סלקשן - יקנעם קניון G",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.105290"
    },
    {
        "n_code": "2583",
        "city": "ירוחם",
        "line": "",
        "latitude": "30.988344",
        "distribution_area": "",
        "city_code": "831",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "138",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בורנשטיין צבי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פארם ירוחם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-13:00-14:00-19  ו' 8:30-12:30",
        "mesirot_yn": "y",
        "longitude": "34.928382"
    },
    {
        "n_code": "4215",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.819981",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4485",
        "house": "4",
        "distribution_area_desc": "",
        "street": "ולנשטיין",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "CHANGE RAMOT",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ב, ד-ה 9:00-18:00 ימי ג 9:00-14:00 ימי שישי וערב חג 9:00 עד 13:00 כל שישי אחרון של החודש סגור",
        "mesirot_yn": "y",
        "longitude": "35.195398"
    },
    {
        "n_code": "4204",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.752626",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1797",
        "house": "4",
        "distribution_area_desc": "",
        "street": "הרכבים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "GET PACKING",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-18 ו 9-12 0585444321",
        "mesirot_yn": "y",
        "longitude": "35.212758"
    },
    {
        "n_code": "3986",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.773218741916196",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1518",
        "house": "2",
        "distribution_area_desc": "",
        "street": "סוקולוב",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "Mia Market",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-22:00 ו' 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.2196897"
    },
    {
        "n_code": "3960",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.826824",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4686",
        "house": "6",
        "distribution_area_desc": "",
        "street": "אל אימאם מוסלים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "Package@",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-16. החנות תהיה סגורה מ02.05 עד 08.05",
        "mesirot_yn": "y",
        "longitude": "35.224881"
    },
    {
        "n_code": "3469",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.853983",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "3727",
        "house": "14",
        "distribution_area_desc": "",
        "street": "איתן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "SMART DELIVERY atarot",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 10:30-16:00",
        "mesirot_yn": "y",
        "longitude": "35.214766"
    },
    {
        "n_code": "4697",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.750957",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1839",
        "house": "14",
        "distribution_area_desc": "",
        "street": "האומן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "ZadaTech מחשבים וסלולר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-18:00 שישי 10:00-11:30",
        "mesirot_yn": "y",
        "longitude": "35.209620"
    },
    {
        "n_code": "4153",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.830008",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4753",
        "house": "67",
        "distribution_area_desc": "",
        "street": "שד דיין משה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אלו מציאות- סטוקים ומציאות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12-20 ו 10-12",
        "mesirot_yn": "y",
        "longitude": "35.236914"
    },
    {
        "n_code": "4574",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.748075",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "5951",
        "house": "4",
        "distribution_area_desc": "",
        "street": "חי אל-מדארס",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אלראזי גבל מוכבר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.244519"
    },
    {
        "n_code": "4546",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.778036",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "5584",
        "house": "113",
        "distribution_area_desc": "",
        "street": "ראבעה אל עדוויה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אם אר סלולר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ליד כנסיית פאטר נוסטר  א-ה 11:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.245204"
    },
    {
        "n_code": "4698",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.755573",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1302",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הנוטרים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אצ ממתקים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-20:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.197501"
    },
    {
        "n_code": "3040",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.731518",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "4565",
        "house": "5",
        "distribution_area_desc": "",
        "street": "צביה ויצחק",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "בייק שופ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-20:00 ו' 9:00-14 (קניון אפטאוון קומה 2)",
        "mesirot_yn": "y",
        "longitude": "35.187587"
    },
    {
        "n_code": "4585",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.772565",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4146",
        "house": "102",
        "distribution_area_desc": "",
        "street": "דרך יריחו",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "בית ביות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 שבת 10:00-20:00 נמצא ב ראס אל עמוד",
        "mesirot_yn": "y",
        "longitude": "35.243879"
    },
    {
        "n_code": "3336",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.764527",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1627",
        "house": "12",
        "distribution_area_desc": "",
        "street": "הצפירה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "גולד מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-19 ו' 7:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.218277"
    },
    {
        "n_code": "1067",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.77273202",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1903",
        "house": "4",
        "distribution_area_desc": "",
        "street": "חקלאי זאב",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "המינימרקט שלי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 9:30-20:30  ו' 8:30-14",
        "mesirot_yn": "y",
        "longitude": "35.18438386"
    },
    {
        "n_code": "4242",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.731416",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4565",
        "house": "8",
        "distribution_area_desc": "",
        "street": "צביה ויצחק",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "המתוקים של ספיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-19:00",
        "mesirot_yn": "y",
        "longitude": "35.188577"
    },
    {
        "n_code": "4812",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.729620",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4539",
        "house": "218",
        "distribution_area_desc": "",
        "street": "הגננת",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "הפיצוציה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-22:00 שישי 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.191490"
    },
    {
        "n_code": "4787",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.729816",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4539",
        "house": "216",
        "distribution_area_desc": "",
        "street": "הגננת",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "השדה מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 שישי 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.189814"
    },
    {
        "n_code": "4739",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.762384134523593",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1636",
        "house": "43",
        "distribution_area_desc": "",
        "street": "עמק רפאים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "זר פון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.2183264"
    },
    {
        "n_code": "4949",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.765359",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1714",
        "house": "41",
        "distribution_area_desc": "",
        "street": "דרך חברון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "טמבור בדרך חברון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-17:00 שישי 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.225580"
    },
    {
        "n_code": "2979",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.769112",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "1058",
        "house": "43",
        "distribution_area_desc": "",
        "street": "בית וגן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "יהודה מינימרקט בית וגן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ב,ד,ה 7-15 16-20 ג' 7-14 ו'7-13",
        "mesirot_yn": "y",
        "longitude": "35.184822"
    },
    {
        "n_code": "4874",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.786553",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4274",
        "house": "4",
        "distribution_area_desc": "",
        "street": "אל-חרירי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מ.ס אקאדמי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "קומה 3  א-ה 10:00-19:00 שבת 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.234198"
    },
    {
        "n_code": "4567",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.756449",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1266",
        "house": "8",
        "distribution_area_desc": "",
        "street": "דהומיי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מוגלי ילד הג'ונגל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.164972"
    },
    {
        "n_code": "3207",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.785944",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "895",
        "house": "22",
        "distribution_area_desc": "",
        "street": "בית הדפוס",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מוסך הסלולר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-18:00 ו' 10:30-12:30",
        "mesirot_yn": "y",
        "longitude": "35.191165"
    },
    {
        "n_code": "3115",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.758462",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1753",
        "house": "72",
        "distribution_area_desc": "",
        "street": "דרך בית לחם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מיני מרקט אקספרס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:00-20:30 ו' 6:00-16:00 (ניתן לחייג ל- 026732305)",
        "mesirot_yn": "y",
        "longitude": "35.221467"
    },
    {
        "n_code": "3156",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.733498",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9077",
        "house": "4",
        "distribution_area_desc": "",
        "street": "מבוא הקינמון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט אליהו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-19:00 ו' 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.200269"
    },
    {
        "n_code": "4243",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.737695",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4182",
        "house": "63",
        "distribution_area_desc": "",
        "street": "הנגבי שבתאי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט המשפחה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10-20",
        "mesirot_yn": "y",
        "longitude": "35.199686"
    },
    {
        "n_code": "4722",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.780820",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1036",
        "house": "30",
        "distribution_area_desc": "",
        "street": "בית הכרם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מכבסת הכיכר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ב,ד,ה 8:00-18:00 ג',ו' 8:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.188734"
    },
    {
        "n_code": "4910",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.80548154427896",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3058",
        "house": "2",
        "distribution_area_desc": "",
        "street": "אבא ברדיצב",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "מכבסת פרפקט (מיאלון)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-17:00 שישי 8:00-12:30",
        "mesirot_yn": "y",
        "longitude": "35.24159166"
    },
    {
        "n_code": "3236",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.769132",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "828",
        "house": "10",
        "distribution_area_desc": "",
        "street": "הנשיא",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מעדניית הנשיא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-20:00 ו' 6:00- שעה לפני כניסת השבת",
        "mesirot_yn": "y",
        "longitude": "35.212903"
    },
    {
        "n_code": "4846",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.73443509395574",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4565",
        "house": "30",
        "distribution_area_desc": "",
        "street": "צביה ויצחק",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מעון לב גילה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 15:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.18172435"
    },
    {
        "n_code": "4677",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.84070535819159",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4128",
        "house": "3",
        "distribution_area_desc": "",
        "street": "דרך ראמאללה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "נרגילות אל וואפה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-23:00 שישי 14:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.22995462"
    },
    {
        "n_code": "4908",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.793846777278812",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4615",
        "house": "14",
        "distribution_area_desc": "",
        "street": "אל-זהרא'",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סאקרה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "שני עד שבת 9:30-18:00",
        "mesirot_yn": "y",
        "longitude": "35.2296984"
    },
    {
        "n_code": "1328",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.78281194",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1070",
        "house": "48",
        "distribution_area_desc": "",
        "street": "הרב טייב חי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר בני תורה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.17183889"
    },
    {
        "n_code": "4831",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.750669",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1753",
        "house": "142",
        "distribution_area_desc": "",
        "street": "דרך בית לחם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר דני קרסגי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.217651"
    },
    {
        "n_code": "1880",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.820526",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0d66",
        "house": "134",
        "distribution_area_desc": "",
        "street": "רחמילביץ משה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר הקשתות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-21 ו' 5-16",
        "mesirot_yn": "y",
        "longitude": "35.255458"
    },
    {
        "n_code": "1331",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.74547675",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3767",
        "house": "12",
        "distribution_area_desc": "",
        "street": "קשאני אליעזר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר קשאני",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:30-21 ו' 6:30-15",
        "mesirot_yn": "y",
        "longitude": "35.23402353"
    },
    {
        "n_code": "4443",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.786164",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "983",
        "house": "58",
        "distribution_area_desc": "",
        "street": "הרב שאולזון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עטרוניקה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 ו 9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.173812"
    },
    {
        "n_code": "4198",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.755147",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1753",
        "house": "71",
        "distribution_area_desc": "",
        "street": "דרך בית לחם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עילאי פירות וירקות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "אתם מוזמנים לאסוף את המשלוח במהלך שעות הפעילות הבאות  ימים א-ה 11:00-20:00 יום שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.220399"
    },
    {
        "n_code": "3740",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.75316",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3809",
        "house": "2",
        "distribution_area_desc": "",
        "street": "פועלי צדק",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עימגה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.21567"
    },
    {
        "n_code": "3320",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.728454",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9033",
        "house": "5",
        "distribution_area_desc": "",
        "street": "קניון אפ טאון גיל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פון טאון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-19:00 9:00-14:00 קומה שניה מעל המספרה ''היופי שבשיער''",
        "mesirot_yn": "y",
        "longitude": "35.185137"
    },
    {
        "n_code": "2090",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.753160",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "4325",
        "house": "4",
        "distribution_area_desc": "",
        "street": "עולי הגרדום",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פינוקיו ספרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-13:30  16-19 ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.235593"
    },
    {
        "n_code": "4700",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.81769061872804",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1820",
        "house": "7",
        "distribution_area_desc": "",
        "street": "שטרן יורי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פיצוחי אזולאי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:00-21:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.25423611"
    },
    {
        "n_code": "4378",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.802188",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4316",
        "house": "21",
        "distribution_area_desc": "",
        "street": "ההגנה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פרטנר הגבעה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-17:00 קומה 2, גבעה הצרפתית,",
        "mesirot_yn": "y",
        "longitude": "35.238564"
    },
    {
        "n_code": "4684",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.826720",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "4741",
        "house": "3",
        "distribution_area_desc": "",
        "street": "שג'רת אל דור",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "קלאסיק טוסט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 22:00-10:00 https://waze.com/ul/hsv9j19g7q",
        "mesirot_yn": "y",
        "longitude": "35.227650"
    },
    {
        "n_code": "1333",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.78617067",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1004",
        "house": "15",
        "distribution_area_desc": "",
        "street": "קרית משה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "רונית כלבו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 07:30-19:00 יום ו' 07:30-14:00",
        "mesirot_yn": "y",
        "longitude": "35.19417887"
    },
    {
        "n_code": "3655",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.78989",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "930",
        "house": "31",
        "distribution_area_desc": "",
        "street": "נג'ארה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "שיפון גבעת שאול",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.19141"
    },
    {
        "n_code": "4918",
        "city": "ירושלים",
        "line": "",
        "latitude": "31.757533",
        "distribution_area": "",
        "city_code": "3000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1314",
        "house": "13",
        "distribution_area_desc": "",
        "street": "יוסי בן יועזר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "תבליני השחר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00",
        "mesirot_yn": "y",
        "longitude": "35.210029"
    },
    {
        "n_code": "3813",
        "city": "ירכא",
        "line": "",
        "latitude": "32.95286",
        "distribution_area": "",
        "city_code": "502",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ירכא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "H&R SYSTEMS",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א, ב, ד, ה 10:00-13:00  15:00-18:00,  ג, ו 10:00-13:00  שעות עבודת הדואר 08:00-14:00 בין 29.06-08.07",
        "mesirot_yn": "y",
        "longitude": "35.21361"
    },
    {
        "n_code": "4576",
        "city": "ירכא",
        "line": "",
        "latitude": "32.954662",
        "distribution_area": "",
        "city_code": "502",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ירכא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ביג דיגיטל  0722220208",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ליד מועצה מקומית ימים א-ו 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.215134"
    },
    {
        "n_code": "2011",
        "city": "ירכא",
        "line": "",
        "latitude": "32.956876",
        "distribution_area": "",
        "city_code": "502",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6730",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אזור התעשייה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ג'ימי קור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 8:30-18 מול מתחם ביג",
        "mesirot_yn": "y",
        "longitude": "35.210519"
    },
    {
        "n_code": "3304",
        "city": "יתיר",
        "line": "",
        "latitude": "31.361320",
        "distribution_area": "",
        "city_code": "1329",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "7001",
        "house": "2",
        "distribution_area_desc": "",
        "street": "חוות יתיר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "יקב לה פורה בלאנש",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-14:00 (בתאום מראש ) נייד 0586619899 או 0586619898",
        "mesirot_yn": "y",
        "longitude": "35.111178"
    },
    {
        "n_code": "3945",
        "city": "כאבול",
        "line": "",
        "latitude": "32.870580",
        "distribution_area": "",
        "city_code": "504",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כאבול",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ניופוניקס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'-ש' 11:30 - 21:00, ו' סגור. בכביש הראשי כיכר רביעי צמוד לשוק אסלאם אפשר הגעה דרך הויז 046601744",
        "mesirot_yn": "y",
        "longitude": "35.204681"
    },
    {
        "n_code": "3493",
        "city": "כאוכב אבו אל-היג'א",
        "line": "",
        "latitude": "32.833371",
        "distribution_area": "",
        "city_code": "505",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "כאוכב אבו אל-היג'א",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "צמיגי ח.מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ו' 8:00-17:30",
        "mesirot_yn": "y",
        "longitude": "35.247473"
    },
    {
        "n_code": "4524",
        "city": "כדורי",
        "line": "",
        "latitude": "32.702934",
        "distribution_area": "",
        "city_code": "371",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כדורי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תחנת טוטו/לוטו אורן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-22:30 שישי 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.406675"
    },
    {
        "n_code": "1628",
        "city": "כוכב השחר",
        "line": "",
        "latitude": "31.959354",
        "distribution_area": "",
        "city_code": "3564",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כוכב השחר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מרכולית כוכב השחר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 7:30:19:30 ו' 7:30-13",
        "mesirot_yn": "y",
        "longitude": "35.351696"
    },
    {
        "n_code": "3888",
        "city": "כוכב יעקב",
        "line": "",
        "latitude": "31.881409",
        "distribution_area": "",
        "city_code": "3779",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "209",
        "house": "1",
        "distribution_area_desc": "",
        "street": "פרי מגדים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אחי יעקב סופר חברתי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:30-20 ו'7:30-13",
        "mesirot_yn": "y",
        "longitude": "35.245452"
    },
    {
        "n_code": "2460",
        "city": "כוכב יעקב",
        "line": "",
        "latitude": "31.880137",
        "distribution_area": "",
        "city_code": "3779",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כוכב יעקב",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "קיוסק כוכב יעקב",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 12-23  ו' 11 עד שעה לפני כניסת שבת, במרכז המסחרי",
        "mesirot_yn": "y",
        "longitude": "35.244823"
    },
    {
        "n_code": "3649",
        "city": "כורזים",
        "line": "",
        "latitude": "32.91427",
        "distribution_area": "",
        "city_code": "1252",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כורזים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קרניגן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א עד ה 8:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.56320"
    },
    {
        "n_code": "3164",
        "city": "כיסופים",
        "line": "",
        "latitude": "31.374748",
        "distribution_area": "",
        "city_code": "840",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כיסופים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "קיבוץ כיסופים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-16:30 ו' 8:30-11 (להסבר דרכי הגעה ניתן לחייג ל 0547917917 אורה)",
        "mesirot_yn": "y",
        "longitude": "34.398942"
    },
    {
        "n_code": "4282",
        "city": "כנות",
        "line": "",
        "latitude": "31.797896",
        "distribution_area": "",
        "city_code": "2006",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "c047",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ירוק",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "קאר פוינט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "סדש ביצרון א-ה 8:30-16 ו  8:30-13צומת כנות תחנת דלק סדש",
        "mesirot_yn": "y",
        "longitude": "34.760275"
    },
    {
        "n_code": "2754",
        "city": "כנף",
        "line": "",
        "latitude": "32.870474",
        "distribution_area": "",
        "city_code": "4028",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כנף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-20  ו'7-17",
        "mesirot_yn": "y",
        "longitude": "35.698808"
    },
    {
        "n_code": "2916",
        "city": "כנרת (מושבה)",
        "line": "",
        "latitude": "32.725209",
        "distribution_area": "",
        "city_code": "63",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כנרת (מושבה)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מקום טוב באמצע כביש 90",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "כל השבוע 6:00-00:00",
        "mesirot_yn": "y",
        "longitude": "35.567591"
    },
    {
        "n_code": "4447",
        "city": "כנרת (מושבה)",
        "line": "",
        "latitude": "32.721429",
        "distribution_area": "",
        "city_code": "63",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "108",
        "house": "999",
        "distribution_area_desc": "",
        "street": "חוף הירדן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תמר בכפר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-18:00 ו 9:00-17:00 ע.חג 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.567040"
    },
    {
        "n_code": "3299",
        "city": "כנרת (קבוצה)",
        "line": "",
        "latitude": "32.715088",
        "distribution_area": "",
        "city_code": "57",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "4",
        "distribution_area_desc": "",
        "street": "כנרת (קבוצה)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות-ק.כנרת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-21:00 ו' 6:00- חצי שעה לפני כניסת השבת.",
        "mesirot_yn": "y",
        "longitude": "35.563275"
    },
    {
        "n_code": "3375",
        "city": "כסרא-סמיע",
        "line": "",
        "latitude": "32.965506",
        "distribution_area": "",
        "city_code": "1296",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כסרא-סמיע",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מיני מארקט אלאמאנה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 08:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.301839"
    },
    {
        "n_code": "4919",
        "city": "כסרא-סמיע",
        "line": "",
        "latitude": "32.965095",
        "distribution_area": "",
        "city_code": "1296",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כסרא-סמיע",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שטיפת המבריק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ליד בית העם ימים ראשון עד שבת 9:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.302322"
    },
    {
        "n_code": "4927",
        "city": "כעביה-טבאש-חג'אג'רה",
        "line": "",
        "latitude": "32.74810942002044",
        "distribution_area": "",
        "city_code": "978",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "121",
        "house": "40",
        "distribution_area_desc": "",
        "street": "אלזייתון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר השלום",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 10:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.18314336"
    },
    {
        "n_code": "4454",
        "city": "כפר אדומים",
        "line": "",
        "latitude": "31.823128",
        "distribution_area": "",
        "city_code": "3638",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "106",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נחל ערוגות",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מזכירות נופי פרת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-10:00 , א 19:00-21:00, ד 15:00-18:00 קישור לוויז: https://waze.com/ul/hsv9j59pqt",
        "mesirot_yn": "y",
        "longitude": "35.319894"
    },
    {
        "n_code": "2081",
        "city": "כפר אוריה",
        "line": "",
        "latitude": "31.792459",
        "distribution_area": "",
        "city_code": "364",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר אוריה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט אוריה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-13:0016-19 ו 7:-16:00",
        "mesirot_yn": "y",
        "longitude": "34.948554"
    },
    {
        "n_code": "3569",
        "city": "כפר בלום",
        "line": "",
        "latitude": "33.171628",
        "distribution_area": "",
        "city_code": "357",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר בלום",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כלבו כפר בלום",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-19:00 ו' 6:30-16:00 ש' 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.609116"
    },
    {
        "n_code": "4754",
        "city": "כפר דניאל",
        "line": "",
        "latitude": "31.933827",
        "distribution_area": "",
        "city_code": "707",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כפר דניאל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "צרכנית כפר דניאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:45-22:00 שישי 6:45-15:00",
        "mesirot_yn": "y",
        "longitude": "34.933441"
    },
    {
        "n_code": "4302",
        "city": "כפר הנשיא",
        "line": "",
        "latitude": "32.974717",
        "distribution_area": "",
        "city_code": "443",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר הנשיא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מזכירות כפר הנשיא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-16",
        "mesirot_yn": "y",
        "longitude": "35.603894"
    },
    {
        "n_code": "1512",
        "city": "כפר ויתקין",
        "line": "",
        "latitude": "32.38153901",
        "distribution_area": "",
        "city_code": "190",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "124",
        "house": "999",
        "distribution_area_desc": "",
        "street": "דרך הכפר",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מחסן טכני",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 08:00-16:00 ו' 08:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.87876558"
    },
    {
        "n_code": "4205",
        "city": "כפר ויתקין",
        "line": "",
        "latitude": "32.382747",
        "distribution_area": "",
        "city_code": "190",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר ויתקין",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קומסס עולם של ניקיון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "אזור התעשיה ימים א-ה 9:00-16:30 שישי 8:30-13",
        "mesirot_yn": "y",
        "longitude": "34.890575"
    },
    {
        "n_code": "4878",
        "city": "כפר חב\\\"ד",
        "line": "",
        "latitude": "31.992415",
        "distribution_area": "",
        "city_code": "696",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "114",
        "house": "2",
        "distribution_area_desc": "",
        "street": "הבעל שם טוב",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "היפר ליפסקר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-14:00 ב-ה 7:00-20:00 שישי 7:00-13:30",
        "mesirot_yn": "y",
        "longitude": "34.849267"
    },
    {
        "n_code": "3248",
        "city": "כפר יאסיף",
        "line": "",
        "latitude": "32.955406",
        "distribution_area": "",
        "city_code": "507",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כפר יאסיף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "Fit step pro kfar lady comfort",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ש 10:15-13:45 16:15-19:30 (מרכז הכפר ליד דואר ישראל)048873131",
        "mesirot_yn": "y",
        "longitude": "35.162572"
    },
    {
        "n_code": "3601",
        "city": "כפר יאסיף",
        "line": "",
        "latitude": "32.95259",
        "distribution_area": "",
        "city_code": "507",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר יאסיף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אלענאן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ד-ה 9-12 16-20 ג' 9-12  ש' 9-12 קישור למסלול ההגעה https://goo.gl/maps/jyaFF5rAJUYvSgZLA",
        "mesirot_yn": "y",
        "longitude": "35.16101"
    },
    {
        "n_code": "3727",
        "city": "כפר ידידיה",
        "line": "",
        "latitude": "32.34439",
        "distribution_area": "",
        "city_code": "233",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר ידידיה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סוכנות דואר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-10:00-12:30:ו'8:00-10:00",
        "mesirot_yn": "y",
        "longitude": "34.89913"
    },
    {
        "n_code": "3457",
        "city": "כפר יונה",
        "line": "",
        "latitude": "32.321248",
        "distribution_area": "",
        "city_code": "168",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "204",
        "house": "17",
        "distribution_area_desc": "",
        "street": "העצמאות",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "הדקה ה- 90",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-22:30 ו' 9:30-15:00",
        "mesirot_yn": "y",
        "longitude": "34.926941"
    },
    {
        "n_code": "3593",
        "city": "כפר יונה",
        "line": "",
        "latitude": "32.31730",
        "distribution_area": "",
        "city_code": "168",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "201",
        "house": "11",
        "distribution_area_desc": "",
        "street": "אח\\\"י אילת",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מישמיש",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:30-20:00 ו'8:30-15:30",
        "mesirot_yn": "y",
        "longitude": "34.93783"
    },
    {
        "n_code": "3425",
        "city": "כפר יונה",
        "line": "",
        "latitude": "32.311721",
        "distribution_area": "",
        "city_code": "168",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "276",
        "house": "46",
        "distribution_area_desc": "",
        "street": "גרניט",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "נאפ קופי סטור (סופר יד)",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-23 ו' :00-15 ש'20:00-22:00",
        "mesirot_yn": "y",
        "longitude": "34.940848"
    },
    {
        "n_code": "2893",
        "city": "כפר יונה",
        "line": "",
        "latitude": "32.318440",
        "distribution_area": "",
        "city_code": "168",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "113",
        "house": "3",
        "distribution_area_desc": "",
        "street": "רמב\\\"ם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "שולחני הערוך",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-13:30-15:30-20 ו'9-14",
        "mesirot_yn": "y",
        "longitude": "34.941609"
    },
    {
        "n_code": "3049",
        "city": "כפר כמא",
        "line": "",
        "latitude": "32.723068",
        "distribution_area": "",
        "city_code": "508",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "100",
        "house": "5",
        "distribution_area_desc": "",
        "street": "אדיגה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שמסי אלקטרוניקה (בוויז)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב'-ה' 09:00-14:00  15:00-19:00, שישי 09:00-11:15  14:00-16:00, שבת 10:00-14:00  15:00-19:00. א' סגור",
        "mesirot_yn": "y",
        "longitude": "35.445913"
    },
    {
        "n_code": "3570",
        "city": "כפר כנא",
        "line": "",
        "latitude": "32.75122",
        "distribution_area": "",
        "city_code": "509",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6715",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אזור התעשייה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מפגש יחד תחנת דלק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 6:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.34210"
    },
    {
        "n_code": "3386",
        "city": "כפר כנא",
        "line": "",
        "latitude": "32.742765",
        "distribution_area": "",
        "city_code": "509",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "6754",
        "house": "1",
        "distribution_area_desc": "",
        "street": "פוק אל עין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספורט סטאר    048409905",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א 15:00-20:0 ב-ש 10:30-20:30. חנות תהיה סגורה עד06.05",
        "mesirot_yn": "y",
        "longitude": "35.337682"
    },
    {
        "n_code": "3530",
        "city": "כפר כנא",
        "line": "",
        "latitude": "32.74430501225773",
        "distribution_area": "",
        "city_code": "509",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר כנא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פלאפון כפר כנא",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": ".ג.ד.ה ו10:00-14:00 16:00-19:00",
        "mesirot_yn": "y",
        "longitude": "35.33921770"
    },
    {
        "n_code": "3486",
        "city": "כפר מונש",
        "line": "",
        "latitude": "32.348401",
        "distribution_area": "",
        "city_code": "387",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כפר מונש",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "בלוניר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-17:00 ו' 10:00-13:00 (ממומלץ לתאם מראש-0503335326)",
        "mesirot_yn": "y",
        "longitude": "34.915365"
    },
    {
        "n_code": "3753",
        "city": "כפר מימון",
        "line": "",
        "latitude": "31.43515",
        "distribution_area": "",
        "city_code": "1095",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר מימון",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "שוק בכפר בכפר מימון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00 ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.53405"
    },
    {
        "n_code": "2269",
        "city": "כפר מל\\\"ל",
        "line": "",
        "latitude": "32.165057",
        "distribution_area": "",
        "city_code": "98",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "111",
        "house": "8",
        "distribution_area_desc": "",
        "street": "עין חי",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "באני בי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-19  ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "34.894246"
    },
    {
        "n_code": "3400",
        "city": "כפר מנדא",
        "line": "",
        "latitude": "32.806379",
        "distribution_area": "",
        "city_code": "510",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "כפר מנדא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "Vanilla בית קפה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-23:00 ו' 14:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.256422"
    },
    {
        "n_code": "2238",
        "city": "כפר מנדא",
        "line": "",
        "latitude": "32.814129",
        "distribution_area": "",
        "city_code": "510",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר מנדא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אליסמין סלולר ומחשבים 050-2499280",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 12-21 ו' 14:30-21:30  ש' 12:30-21:30",
        "mesirot_yn": "y",
        "longitude": "35.263763"
    },
    {
        "n_code": "3625",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.17768",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "340",
        "house": "14",
        "distribution_area_desc": "",
        "street": "כצנלסון ברל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אוטנטי כפר סבא",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-21:00 ו'9:00-15:00.",
        "mesirot_yn": "y",
        "longitude": "34.90322"
    },
    {
        "n_code": "4267",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.175480",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "205",
        "house": "18",
        "distribution_area_desc": "",
        "street": "התע\\\"ש",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אנימל שופ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-1800 שישי  10:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.926965"
    },
    {
        "n_code": "1944",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.182406",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "420",
        "house": "28",
        "distribution_area_desc": "",
        "street": "טשרניחובסקי",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "גרינבוים צבעים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ב,ד,ה 8:15 -19:00ג ,ו 8:15 - 14:30",
        "mesirot_yn": "y",
        "longitude": "34.900450"
    },
    {
        "n_code": "4021",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.185333",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "134",
        "house": "15",
        "distribution_area_desc": "",
        "street": "שאול המלך",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "הכל לבית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א,ב,ד,ה 09:00-13:00  16:00-19:00,  ג,ו 09:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.941704"
    },
    {
        "n_code": "3063",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.192228",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "667",
        "house": "3",
        "distribution_area_desc": "",
        "street": "רפפורט",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "זו ארץ זו ק.הירוקה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-21:00 ו' 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.891878"
    },
    {
        "n_code": "2445",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.176056",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "420",
        "house": "16",
        "distribution_area_desc": "",
        "street": "טשרניחובסקי",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "יואב פתרונות הדפסה ותקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-19:00  ו' 10-14 (dmi dyo)",
        "mesirot_yn": "y",
        "longitude": "34.892212"
    },
    {
        "n_code": "3760",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.17563",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "205",
        "house": "10",
        "distribution_area_desc": "",
        "street": "התע\\\"ש",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "לימונענע",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-18:00 ו' 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.92816"
    },
    {
        "n_code": "1273",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.19038638",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "442",
        "house": "18",
        "distribution_area_desc": "",
        "street": "רמז דוד",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מינימרקט העליה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00 - 14:00 בין 16:00 20:00 ( אין חלוקת חבילות בין השעות 14:00 16:00 )  יום ו' 9:00  14:00",
        "mesirot_yn": "y",
        "longitude": "34.90166622"
    },
    {
        "n_code": "4094",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.182605",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "242",
        "house": "25",
        "distribution_area_desc": "",
        "street": "בקעת בית נטופה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מכבסת הדרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-13 16-19 ו 8:30-13",
        "mesirot_yn": "y",
        "longitude": "34.928921"
    },
    {
        "n_code": "2293",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.175953",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "523",
        "house": "85",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מתנות רקמה ממוחשבת מגוון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00-19:00 ו' 11:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.905535"
    },
    {
        "n_code": "3188",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.173348",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "2176",
        "house": "32",
        "distribution_area_desc": "",
        "street": "גלר זאב",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סופר בן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 ו' 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.896194"
    },
    {
        "n_code": "3721",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.17991",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "238",
        "house": "5",
        "distribution_area_desc": "",
        "street": "עמק החולה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סופר הדרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-10:00 16:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.93477"
    },
    {
        "n_code": "1952",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.173819",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "521",
        "house": "22",
        "distribution_area_desc": "",
        "street": "התחיה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סופר זול מוטי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00  ו'' 5:30-14",
        "mesirot_yn": "y",
        "longitude": "34.903680"
    },
    {
        "n_code": "3725",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.17522",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "523",
        "house": "110",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סיטונאות א.מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-19:00 ו' 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.90877"
    },
    {
        "n_code": "4256",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.173730",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1310",
        "house": "10",
        "distribution_area_desc": "",
        "street": "המנופים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "עידן 2000",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-20:30 ו 9:00-14",
        "mesirot_yn": "y",
        "longitude": "34.928972"
    },
    {
        "n_code": "3426",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.177218",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "523",
        "house": "43",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "פינת המזל-חנות נוחות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-22:00",
        "mesirot_yn": "y",
        "longitude": "34.901056"
    },
    {
        "n_code": "4880",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.17296150043706",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1310",
        "house": "2",
        "distribution_area_desc": "",
        "street": "המנופים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "קליית חממה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-18:00 שישי 8:00-11:00",
        "mesirot_yn": "y",
        "longitude": "34.92862903"
    },
    {
        "n_code": "4884",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.174438",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "523",
        "house": "137",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "קליית חממה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-18:00 שישי 8:00-11:00",
        "mesirot_yn": "y",
        "longitude": "34.912811"
    },
    {
        "n_code": "4247",
        "city": "כפר סבא",
        "line": "",
        "latitude": "32.176862",
        "distribution_area": "",
        "city_code": "6900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "533",
        "house": "42",
        "distribution_area_desc": "",
        "street": "ששת הימים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "שוק בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-18",
        "mesirot_yn": "y",
        "longitude": "34.893592"
    },
    {
        "n_code": "3815",
        "city": "כפר עציון",
        "line": "",
        "latitude": "31.65158",
        "distribution_area": "",
        "city_code": "3488",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר עציון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "תכלת מרדכי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-19:00 ו' 9:30-13:30",
        "mesirot_yn": "y",
        "longitude": "35.11452"
    },
    {
        "n_code": "4876",
        "city": "כפר קאסם",
        "line": "",
        "latitude": "32.112792872426596",
        "distribution_area": "",
        "city_code": "634",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "231",
        "house": "9",
        "distribution_area_desc": "",
        "street": "חמזה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "זול סטוק כפר קאסם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-22:00 שבת 10:00-22:00",
        "mesirot_yn": "y",
        "longitude": "34.9603968"
    },
    {
        "n_code": "3057",
        "city": "כפר קאסם",
        "line": "",
        "latitude": "32.116976",
        "distribution_area": "",
        "city_code": "634",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "103",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עומר בן אל חטאב",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "ספריית אלנג'אח",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:30-23:30 ו' 14:00-23:30",
        "mesirot_yn": "y",
        "longitude": "34.980771"
    },
    {
        "n_code": "2509",
        "city": "כפר קרע",
        "line": "",
        "latitude": "32.506274",
        "distribution_area": "",
        "city_code": "654",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "b123",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ח'אלאת אל שיליח",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "זול סטוק כפר קרע",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 9-22 ליד המועצה המקומית",
        "mesirot_yn": "y",
        "longitude": "35.041821"
    },
    {
        "n_code": "1142",
        "city": "כפר רופין",
        "line": "",
        "latitude": "32.45940959",
        "distribution_area": "",
        "city_code": "295",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כפר רופין",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מזכירות כפר רופין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 8-15",
        "mesirot_yn": "y",
        "longitude": "35.55657231"
    },
    {
        "n_code": "3420",
        "city": "כפר שמאי",
        "line": "",
        "latitude": "32.956975",
        "distribution_area": "",
        "city_code": "605",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "כפר שמאי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת כפר שמאי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-19 ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "35.457206"
    },
    {
        "n_code": "4467",
        "city": "כפר תבור",
        "line": "",
        "latitude": "32.684624",
        "distribution_area": "",
        "city_code": "47",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "172",
        "house": "3",
        "distribution_area_desc": "",
        "street": "לח\\\"י",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סטור פלוס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.419576"
    },
    {
        "n_code": "3940",
        "city": "כרמיאל",
        "line": "",
        "latitude": "32.921630",
        "distribution_area": "",
        "city_code": "1139",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "106",
        "house": "1",
        "distribution_area_desc": "",
        "street": "העמק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "זול סטוק כרמיאל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-16 ו' 10:30-13",
        "mesirot_yn": "y",
        "longitude": "35.303673"
    },
    {
        "n_code": "3424",
        "city": "כרמיאל",
        "line": "",
        "latitude": "32.921750",
        "distribution_area": "",
        "city_code": "1139",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "117",
        "house": "17",
        "distribution_area_desc": "",
        "street": "החרושת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מ.ג הצפון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-19 ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "35.308924"
    },
    {
        "n_code": "3276",
        "city": "כרמיאל",
        "line": "",
        "latitude": "32.923059",
        "distribution_area": "",
        "city_code": "1139",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "159",
        "house": "4",
        "distribution_area_desc": "",
        "street": "החרמש",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קפה ספר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-17:00 ו' 8:30-14:00",
        "mesirot_yn": "y",
        "longitude": "35.310747"
    },
    {
        "n_code": "4951",
        "city": "כרמיאל",
        "line": "",
        "latitude": "32.904154",
        "distribution_area": "",
        "city_code": "1139",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "161",
        "house": "52",
        "distribution_area_desc": "",
        "street": "הפרחים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שוט פוינט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 12:00-20:00 שישי 10:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.298817"
    },
    {
        "n_code": "2239",
        "city": "כרמיה",
        "line": "",
        "latitude": "31.603114",
        "distribution_area": "",
        "city_code": "768",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כרמיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "217 חנות אופניים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-19  ו' 8:30-14",
        "mesirot_yn": "y",
        "longitude": "34.543873"
    },
    {
        "n_code": "1765",
        "city": "כרמל (מושב בדרום)",
        "line": "",
        "latitude": "31.431688",
        "distribution_area": "",
        "city_code": "3656",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כרמל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "עשבי קדם בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 09-17",
        "mesirot_yn": "y",
        "longitude": "35.183740"
    },
    {
        "n_code": "2900",
        "city": "לביא",
        "line": "",
        "latitude": "32.787618",
        "distribution_area": "",
        "city_code": "585",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "לביא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "לביאה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-19 ו 9-12",
        "mesirot_yn": "y",
        "longitude": "35.442121"
    },
    {
        "n_code": "2742",
        "city": "להבות הבשן",
        "line": "",
        "latitude": "33.143280",
        "distribution_area": "",
        "city_code": "380",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "להבות הבשן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ש.י.א סטור בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-20 ו' 6:30 - 16",
        "mesirot_yn": "y",
        "longitude": "35.646522"
    },
    {
        "n_code": "4082",
        "city": "להבים",
        "line": "",
        "latitude": "31.363095",
        "distribution_area": "",
        "city_code": "1271",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "180",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שדרות שעורה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הום פעמי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10-19 ו 10-13",
        "mesirot_yn": "y",
        "longitude": "34.816757"
    },
    {
        "n_code": "4845",
        "city": "לוד",
        "line": "",
        "latitude": "31.950851",
        "distribution_area": "",
        "city_code": "7000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "231",
        "house": "12",
        "distribution_area_desc": "",
        "street": "כהן אלי",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "אגם המזל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.888357"
    },
    {
        "n_code": "4507",
        "city": "לוד",
        "line": "",
        "latitude": "31.958939",
        "distribution_area": "",
        "city_code": "7000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "707",
        "house": "16",
        "distribution_area_desc": "",
        "street": "ארבע העונות",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "לחם ברכה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 9:00-19:00",
        "mesirot_yn": "y",
        "longitude": "34.879949"
    },
    {
        "n_code": "3070",
        "city": "לוד",
        "line": "",
        "latitude": "31.953607",
        "distribution_area": "",
        "city_code": "7000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "305",
        "house": "28",
        "distribution_area_desc": "",
        "street": "שד צה\\\"ל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "מכולת חינאווי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-19 ו' 8-14 החנות תהיה סגורה מ29.05 עד 4.06",
        "mesirot_yn": "y",
        "longitude": "34.897610"
    },
    {
        "n_code": "3483",
        "city": "לוד",
        "line": "",
        "latitude": "31.956587",
        "distribution_area": "",
        "city_code": "7000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "101",
        "house": "58",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "רדיו אורון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א'ב'ד'ה' 9:30-13:00  16:00-18:00 שלישי ו שישי 9:30-13:00",
        "mesirot_yn": "y",
        "longitude": "34.896787"
    },
    {
        "n_code": "1650",
        "city": "לוזית",
        "line": "",
        "latitude": "31.6875451",
        "distribution_area": "",
        "city_code": "52",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "לוזית",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מכולת לוזית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 8:00-18:30 שישי סגור",
        "mesirot_yn": "y",
        "longitude": "34.8911275"
    },
    {
        "n_code": "4941",
        "city": "לוחמי הגיטאות",
        "line": "",
        "latitude": "32.961512",
        "distribution_area": "",
        "city_code": "595",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "לוחמי הגיטאות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "נוקי Noki",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-13:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.095173"
    },
    {
        "n_code": "4736",
        "city": "ליבנה",
        "line": "",
        "latitude": "31.357088",
        "distribution_area": "",
        "city_code": "8702",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ליבנה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות שני ליבנה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 08:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.068821"
    },
    {
        "n_code": "4307",
        "city": "לקיה",
        "line": "",
        "latitude": "31.326396",
        "distribution_area": "",
        "city_code": "1060",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6701",
        "house": "6",
        "distribution_area_desc": "",
        "street": "שכ 1",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סופר מרקט גזל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "שכונה 3 א-ו 6:00-1:00 בלילה",
        "mesirot_yn": "y",
        "longitude": "34.865679"
    },
    {
        "n_code": "3624",
        "city": "לשם",
        "line": "",
        "latitude": "32.06588",
        "distribution_area": "",
        "city_code": "9927",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "לשם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "לחם לשם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 07:00-19:00 ו 07:00-14:00  במתחם מסחרי בכניסה ללשם",
        "mesirot_yn": "y",
        "longitude": "35.0492788"
    },
    {
        "n_code": "2980",
        "city": "מבוא חורון",
        "line": "",
        "latitude": "31.846720",
        "distribution_area": "",
        "city_code": "3709",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "156",
        "house": "1",
        "distribution_area_desc": "",
        "street": "התעשיה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "נועם אלימלך חומר בנייה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-16:00 ו' 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.034856"
    },
    {
        "n_code": "4398",
        "city": "מבואות יריחו",
        "line": "",
        "latitude": "31.907547",
        "distribution_area": "",
        "city_code": "3825",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מבואות יריחו",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מיכל שמעוני",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-18:00 ו 9:00-13:00 ליצירת קשר חייג 0527906643",
        "mesirot_yn": "y",
        "longitude": "35.415546"
    },
    {
        "n_code": "4439",
        "city": "מבשרת ציון",
        "line": "",
        "latitude": "31.800126",
        "distribution_area": "",
        "city_code": "1015",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6521",
        "house": "999",
        "distribution_area_desc": "",
        "street": "קניון הראל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "הסטוק מבשרת ציון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א' - ה' 10:00-20:00, ו' 10:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.148910"
    },
    {
        "n_code": "1383",
        "city": "מבשרת ציון",
        "line": "",
        "latitude": "31.79163937",
        "distribution_area": "",
        "city_code": "1015",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "189",
        "house": "4",
        "distribution_area_desc": "",
        "street": "היצירה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "ממתקי אבי לוי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-19 ו' 10-15",
        "mesirot_yn": "y",
        "longitude": "35.14346412"
    },
    {
        "n_code": "3190",
        "city": "מבשרת ציון",
        "line": "",
        "latitude": "31.794814",
        "distribution_area": "",
        "city_code": "1015",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "125",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מבצע נחשון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "תיבת נח",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 ו' 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.141659"
    },
    {
        "n_code": "3108",
        "city": "מג'ד אל-כרום",
        "line": "",
        "latitude": "32.918625",
        "distribution_area": "",
        "city_code": "516",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "6772",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מערבית-מג'ד אלכרום",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "extra market",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "כל השבוע: 6:00-00:00",
        "mesirot_yn": "y",
        "longitude": "35.245156"
    },
    {
        "n_code": "4916",
        "city": "מג'ד אל-כרום",
        "line": "",
        "latitude": "32.918758",
        "distribution_area": "",
        "city_code": "516",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "462",
        "house": "1",
        "distribution_area_desc": "",
        "street": "סלאח אלדין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תחנת דלק תפוח",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 שישי 9:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.247772"
    },
    {
        "n_code": "1720",
        "city": "מג'דל שמס",
        "line": "",
        "latitude": "33.270315",
        "distribution_area": "",
        "city_code": "4201",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מג'דל שמס",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חרמון מחשבים ( כניסה דרומית)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ש-ה 10-20",
        "mesirot_yn": "y",
        "longitude": "35.772186"
    },
    {
        "n_code": "3436",
        "city": "מג'דל שמס",
        "line": "",
        "latitude": "33.254959",
        "distribution_area": "",
        "city_code": "4201",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "מג'דל שמס",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "טראק תחנת דלק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "24/7",
        "mesirot_yn": "y",
        "longitude": "35.765119"
    },
    {
        "n_code": "4370",
        "city": "מגאר",
        "line": "",
        "latitude": "32.887513",
        "distribution_area": "",
        "city_code": "481",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מגאר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ביג נור",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ז 7:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.410691"
    },
    {
        "n_code": "3660",
        "city": "מגאר",
        "line": "",
        "latitude": "32.89121",
        "distribution_area": "",
        "city_code": "481",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מגאר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "גרעיני עפולה החמניה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-22:00 ו' 8:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.40410"
    },
    {
        "n_code": "3866",
        "city": "מגאר",
        "line": "",
        "latitude": "32.890707",
        "distribution_area": "",
        "city_code": "481",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מגאר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קרלו קופי שופ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 7:00-24:00",
        "mesirot_yn": "y",
        "longitude": "35.407644"
    },
    {
        "n_code": "3285",
        "city": "מגדל",
        "line": "",
        "latitude": "32.839215",
        "distribution_area": "",
        "city_code": "65",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "135",
        "house": "2",
        "distribution_area_desc": "",
        "street": "נוף הארבל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הצומת של חיליק",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-24:00 ו' 6:30-17:30",
        "mesirot_yn": "y",
        "longitude": "35.504188"
    },
    {
        "n_code": "4066",
        "city": "מגדל העמק",
        "line": "",
        "latitude": "32.668722",
        "distribution_area": "",
        "city_code": "874",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "215",
        "house": "1",
        "distribution_area_desc": "",
        "street": "האיצטדיון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סלקשן מגדל העמק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.240075"
    },
    {
        "n_code": "3463",
        "city": "מגדלים",
        "line": "",
        "latitude": "32.090047",
        "distribution_area": "",
        "city_code": "3751",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "9",
        "distribution_area_desc": "",
        "street": "מגדלים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "הכפרייה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה- 6:45-13:00 15:30-20:00 ו' 6:45-13:00",
        "mesirot_yn": "y",
        "longitude": "35.342616"
    },
    {
        "n_code": "4935",
        "city": "מגל",
        "line": "",
        "latitude": "32.387786",
        "distribution_area": "",
        "city_code": "375",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מגל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מסיק קיבוץ מגל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.033506"
    },
    {
        "n_code": "3716",
        "city": "מדרך עוז",
        "line": "",
        "latitude": "32.59790",
        "distribution_area": "",
        "city_code": "2029",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מדרך עוז",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סופר עוז",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ג,ד,ה  08:00-14:00  16:30-19:00 ב,ו  08:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.15915"
    },
    {
        "n_code": "4229",
        "city": "מודיעין עילית",
        "line": "",
        "latitude": "31.924315",
        "distribution_area": "",
        "city_code": "3797",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "250",
        "house": "13",
        "distribution_area_desc": "",
        "street": "שד הרב מפוניבז'",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "השוק השכונתי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 12-22",
        "mesirot_yn": "y",
        "longitude": "35.047497"
    },
    {
        "n_code": "4936",
        "city": "מודיעין עילית",
        "line": "",
        "latitude": "31.92824753976004",
        "distribution_area": "",
        "city_code": "3797",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "214",
        "house": "5",
        "distribution_area_desc": "",
        "street": "קצות החושן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "על הסיבוב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:30-14:00  16:00-22:00 שישי 7:30 עד שעתיים לפני שבת",
        "mesirot_yn": "y",
        "longitude": "35.04259386"
    },
    {
        "n_code": "4356",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.904285",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "703",
        "house": "23",
        "distribution_area_desc": "",
        "street": "אודם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אקספרס Seven",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 09:00-20:00,  שישי 09:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.995152"
    },
    {
        "n_code": "3281",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.899076",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "100",
        "house": "23",
        "distribution_area_desc": "",
        "street": "עמק דותן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "בית הנרגילה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-22:00 ו' 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.020928"
    },
    {
        "n_code": "4407",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.890413",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1503",
        "house": "1",
        "distribution_area_desc": "",
        "street": "החרט",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "הסטוק מודיעין",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מתחם רמי לוי מרכז עינב, א-ה 9:00-21:00 ו 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.961976"
    },
    {
        "n_code": "1133",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.88653301",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3196",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שדרת עומרים מ\\\"ר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "לב מתוק רעות במרכז מסחרי  לב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "‏א-ה 8:30-21:00 יום ו' 7-15",
        "mesirot_yn": "y",
        "longitude": "35.01764332"
    },
    {
        "n_code": "4777",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.902434",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "138",
        "house": "6",
        "distribution_area_desc": "",
        "street": "פיקוס",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מאנצ' חנות נוחות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-23:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.009557"
    },
    {
        "n_code": "3521",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.89967496134551",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "330",
        "house": "2",
        "distribution_area_desc": "",
        "street": "לב העיר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עולם הממתקים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-22:00 ו' 9:00-14:00 דוחן הממתקים ליד מגנוליה קומת כניסה",
        "mesirot_yn": "y",
        "longitude": "35.00800385"
    },
    {
        "n_code": "1218",
        "city": "מודיעין-מכבים-רעו",
        "line": "",
        "latitude": "31.905916",
        "distribution_area": "",
        "city_code": "1200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "182",
        "house": "21",
        "distribution_area_desc": "",
        "street": "צאלון",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פנינת המשחקים מודיעין סנטר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10.00-18.30 יום ו' 9.30-14.00",
        "mesirot_yn": "y",
        "longitude": "35.017291"
    },
    {
        "n_code": "1614",
        "city": "מולדת",
        "line": "",
        "latitude": "32.58708675",
        "distribution_area": "",
        "city_code": "269",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מולדת",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "צרכנית פנסטר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 7-19 ו' 7-13",
        "mesirot_yn": "y",
        "longitude": "35.43988709"
    },
    {
        "n_code": "4879",
        "city": "מוסמוס",
        "line": "",
        "latitude": "32.545698",
        "distribution_area": "",
        "city_code": "9971",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מוסמוס",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אלוואדי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ראשון עד שבת 24/7",
        "mesirot_yn": "y",
        "longitude": "35.155975"
    },
    {
        "n_code": "3303",
        "city": "מורן",
        "line": "",
        "latitude": "32.921026",
        "distribution_area": "",
        "city_code": "1163",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מורן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות-מורן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:00-21:00 ו' 6:00- חצי שעה לפני כניסת השבת.",
        "mesirot_yn": "y",
        "longitude": "35.394731"
    },
    {
        "n_code": "2455",
        "city": "מורשת",
        "line": "",
        "latitude": "32.826397",
        "distribution_area": "",
        "city_code": "1178",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מורשת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "גרעין תקווה מורשת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-11  ב' ד' 16-19",
        "mesirot_yn": "y",
        "longitude": "35.234105"
    },
    {
        "n_code": "4618",
        "city": "מזכרת בתיה",
        "line": "",
        "latitude": "31.852175",
        "distribution_area": "",
        "city_code": "28",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "158",
        "house": "1",
        "distribution_area_desc": "",
        "street": "דולב",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר נאות רבין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-19:00",
        "mesirot_yn": "y",
        "longitude": "34.849207"
    },
    {
        "n_code": "4487",
        "city": "מזרעה",
        "line": "",
        "latitude": "32.982663",
        "distribution_area": "",
        "city_code": "517",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "107",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אבו בקר אלסדיק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "דוקן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'-ה' 07:00-24:00 ליצירת קשר חייג 0547487890",
        "mesirot_yn": "y",
        "longitude": "35.095514"
    },
    {
        "n_code": "2717",
        "city": "מירב",
        "line": "",
        "latitude": "32.452754",
        "distribution_area": "",
        "city_code": "1282",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מירב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מרכולית קיבוץ מירב",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ג 7:30-19 ד-ה 7:30-21 ו' 8-13",
        "mesirot_yn": "y",
        "longitude": "35.424961"
    },
    {
        "n_code": "2792",
        "city": "מירון",
        "line": "",
        "latitude": "32.984992",
        "distribution_area": "",
        "city_code": "607",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מירון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר מירון בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-22:00  ו'7-17:00",
        "mesirot_yn": "y",
        "longitude": "35.440873"
    },
    {
        "n_code": "4882",
        "city": "מישור אדומים",
        "line": "",
        "latitude": "31.781256",
        "distribution_area": "",
        "city_code": "9997",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "11",
        "house": "5",
        "distribution_area_desc": "",
        "street": "דיקלה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מאפיית שיפון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 08:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.297304"
    },
    {
        "n_code": "4648",
        "city": "מישור אדומים",
        "line": "",
        "latitude": "31.789985",
        "distribution_area": "",
        "city_code": "9997",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1",
        "house": "12",
        "distribution_area_desc": "",
        "street": "חרובית",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סטוק סיטי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 8:30-15:00",
        "mesirot_yn": "y",
        "longitude": "35.331054"
    },
    {
        "n_code": "4031",
        "city": "מיתר",
        "line": "",
        "latitude": "31.327878",
        "distribution_area": "",
        "city_code": "1268",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "214",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שדרות המייסדים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "Go Market",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-23 ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "34.938912"
    },
    {
        "n_code": "3202",
        "city": "מנוף",
        "line": "",
        "latitude": "32.853263",
        "distribution_area": "",
        "city_code": "1174",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מנוף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "עמית לספורט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.240245"
    },
    {
        "n_code": "3274",
        "city": "מנרה",
        "line": "",
        "latitude": "33.195464",
        "distribution_area": "",
        "city_code": "347",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מנרה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כלבו מנרה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-19:00  ש- 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.543699"
    },
    {
        "n_code": "4428",
        "city": "מנשית זבדה",
        "line": "",
        "latitude": "32.706894",
        "distribution_area": "",
        "city_code": "994",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "102",
        "house": "999",
        "distribution_area_desc": "",
        "street": "האלון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קאר פרטס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מול תחנת הדלק  ב-ו 9:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.193056"
    },
    {
        "n_code": "4746",
        "city": "מסעדה",
        "line": "",
        "latitude": "33.232725",
        "distribution_area": "",
        "city_code": "4203",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מסעדה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרקט ריחאן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 8:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.756114"
    },
    {
        "n_code": "4015",
        "city": "מסעדה",
        "line": "",
        "latitude": "33.232689",
        "distribution_area": "",
        "city_code": "4203",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מסעדה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספורט ולי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'-ו' 11-19",
        "mesirot_yn": "y",
        "longitude": "35.755599"
    },
    {
        "n_code": "4901",
        "city": "מעיליא",
        "line": "",
        "latitude": "33.026649",
        "distribution_area": "",
        "city_code": "518",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מעיליא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט קסיס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ב-ו 9:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.258952"
    },
    {
        "n_code": "2419",
        "city": "מעלה אדומים",
        "line": "",
        "latitude": "31.772150",
        "distribution_area": "",
        "city_code": "3616",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "141",
        "house": "5",
        "distribution_area_desc": "",
        "street": "דרך קדם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "איי גאדג'ט- קניון עופר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-19 ו' 10-13:00",
        "mesirot_yn": "y",
        "longitude": "35.298543"
    },
    {
        "n_code": "1154",
        "city": "מעלה אדומים",
        "line": "",
        "latitude": "31.7670723",
        "distribution_area": "",
        "city_code": "3616",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "160",
        "house": "22",
        "distribution_area_desc": "",
        "street": "צמח השדה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט 06",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 7:30-19:30 ו' 7:30-14:00",
        "mesirot_yn": "y",
        "longitude": "35.29818987"
    },
    {
        "n_code": "4033",
        "city": "מעלה גמלא",
        "line": "",
        "latitude": "32.890172",
        "distribution_area": "",
        "city_code": "4008",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מעלה גמלא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר מעלה גמלא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-20 ו' 8-15",
        "mesirot_yn": "y",
        "longitude": "35.682173"
    },
    {
        "n_code": "3090",
        "city": "מעלה שומרון",
        "line": "",
        "latitude": "32.168311",
        "distribution_area": "",
        "city_code": "3637",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מעלה שומרון",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "א.חצרוני בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:30-18:00 ו' 7:30-14:00",
        "mesirot_yn": "y",
        "longitude": "35.072025"
    },
    {
        "n_code": "4581",
        "city": "מעלות-תרשיחא",
        "line": "",
        "latitude": "33.013599",
        "distribution_area": "",
        "city_code": "1063",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "302",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מעלה המסגד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "live music tarshiha",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "שני עד שבת 10:00-19:00",
        "mesirot_yn": "y",
        "longitude": "35.267284"
    },
    {
        "n_code": "4693",
        "city": "מעלות-תרשיחא",
        "line": "",
        "latitude": "33.019189",
        "distribution_area": "",
        "city_code": "1063",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "207",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נרקיס עוזי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "טל תבלינים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "אזור התעשיה  א-ה 8:30-13:00  14:00-19:30 שישי 8:30-14:00",
        "mesirot_yn": "y",
        "longitude": "35.282468"
    },
    {
        "n_code": "1939",
        "city": "מעלות-תרשיחא",
        "line": "",
        "latitude": "33.015149",
        "distribution_area": "",
        "city_code": "1063",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "107",
        "house": "22",
        "distribution_area_desc": "",
        "street": "הרב קוק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כלבו מרק - מרכז מסחרי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-13:30  16:00-19:00  ו' 9:00-13:30",
        "mesirot_yn": "y",
        "longitude": "35.281429"
    },
    {
        "n_code": "2575",
        "city": "מפגש הבקעה",
        "line": "",
        "latitude": "32.054914",
        "distribution_area": "",
        "city_code": "4",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0002",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מפגש בקעה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פון פיקס PHONEFIX",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-19  ו' 9-15  (מול זול בגדול)",
        "mesirot_yn": "y",
        "longitude": "35.469595"
    },
    {
        "n_code": "3851",
        "city": "מצובה",
        "line": "",
        "latitude": "33.062127",
        "distribution_area": "",
        "city_code": "325",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מצובה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כל בו מצובה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'ב'ד'ה'7-19 ג'ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "35.156094"
    },
    {
        "n_code": "4832",
        "city": "מצפה אבי\\\"ב",
        "line": "",
        "latitude": "32.838134",
        "distribution_area": "",
        "city_code": "1222",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מצפה אבי\\\"ב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ח'טיב מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-19:00 שישי 6:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.201433"
    },
    {
        "n_code": "2089",
        "city": "מצפה רמון",
        "line": "",
        "latitude": "30.613112",
        "distribution_area": "",
        "city_code": "99",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": ".mnf",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מרכז מסחרי מצפה ר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "תבליני השרון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-13 15-19  ו' 8-14 מרכז מסחרי",
        "mesirot_yn": "y",
        "longitude": "34.802166"
    },
    {
        "n_code": "3089",
        "city": "מצפה שלם",
        "line": "",
        "latitude": "31.568977",
        "distribution_area": "",
        "city_code": "3610",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מצפה שלם",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "כלבו מצפה שלם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-14 17-19 ו' 8:30-13:30",
        "mesirot_yn": "y",
        "longitude": "35.400326"
    },
    {
        "n_code": "4619",
        "city": "מרום גולן",
        "line": "",
        "latitude": "33.132119",
        "distribution_area": "",
        "city_code": "4101",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מרום גולן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "צרכניית מרום גולן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ד 7:00-18:00 ד 7:00-19:00 שישי 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.777293"
    },
    {
        "n_code": "3736",
        "city": "מרחב עם",
        "line": "",
        "latitude": "30.88759",
        "distribution_area": "",
        "city_code": "1340",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מרחב עם",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "המכולת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-20:00 ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.82811"
    },
    {
        "n_code": "2428",
        "city": "משגב תרדיון",
        "line": "",
        "latitude": "32.867695",
        "distribution_area": "",
        "city_code": "9897",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "2",
        "house": "999",
        "distribution_area_desc": "",
        "street": "כחול",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "א.ש.ד תרדיון בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-18 ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "35.273133"
    },
    {
        "n_code": "4166",
        "city": "משהד",
        "line": "",
        "latitude": "32.744919",
        "distribution_area": "",
        "city_code": "520",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "משהד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאפיית אלנור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 8:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.320181"
    },
    {
        "n_code": "3461",
        "city": "משהד",
        "line": "",
        "latitude": "32.738720",
        "distribution_area": "",
        "city_code": "520",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "245",
        "house": "31",
        "distribution_area_desc": "",
        "street": "סלאח אל דין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט חמודי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-23:00 ו' 8:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.325654"
    },
    {
        "n_code": "1972",
        "city": "משואות יצחק",
        "line": "",
        "latitude": "31.703964",
        "distribution_area": "",
        "city_code": "620",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "משואות יצחק",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מ.ת.מ (הגפן 2- בוויז)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-16:00 (בוויז- הגפן2 משואות יצחק)",
        "mesirot_yn": "y",
        "longitude": "34.688778"
    },
    {
        "n_code": "4840",
        "city": "משמר הנגב",
        "line": "",
        "latitude": "31.36398636454092",
        "distribution_area": "",
        "city_code": "395",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "משמר הנגב",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "דאר משמר הנגב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-19:00 שישי 7:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.71896843"
    },
    {
        "n_code": "3905",
        "city": "משמר השרון",
        "line": "",
        "latitude": "32.355948",
        "distribution_area": "",
        "city_code": "194",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "9998",
        "distribution_area_desc": "",
        "street": "משמר השרון",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "ארטוי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-18:00 ו'9-14",
        "mesirot_yn": "y",
        "longitude": "34.906231"
    },
    {
        "n_code": "1033",
        "city": "מתן",
        "line": "",
        "latitude": "32.15311091",
        "distribution_area": "",
        "city_code": "1315",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "84",
        "distribution_area_desc": "",
        "street": "הדר",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אריזות מעוצבות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 10-14 16-18 ו' 10-14:00. החנות תהיה סגורה ב01.05 ו02.05.",
        "mesirot_yn": "y",
        "longitude": "34.97158187"
    },
    {
        "n_code": "4641",
        "city": "מתתיהו",
        "line": "",
        "latitude": "31.9312465",
        "distribution_area": "",
        "city_code": "3648",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מתתיהו",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "גלידות יאמי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ליד תחנת דלק יעד א-ה 8:30-19:30 שישי 9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.0304453"
    },
    {
        "n_code": "3326",
        "city": "נאות גולן",
        "line": "",
        "latitude": "32.786107",
        "distribution_area": "",
        "city_code": "4551",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "5",
        "distribution_area_desc": "",
        "street": "נאות גולן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "לאב בגולן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 16-20",
        "mesirot_yn": "y",
        "longitude": "35.692828"
    },
    {
        "n_code": "4425",
        "city": "נאות גולן",
        "line": "",
        "latitude": "32.786297",
        "distribution_area": "",
        "city_code": "4551",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נאות גולן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר של הגולן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-20:00 ו 7:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.691380"
    },
    {
        "n_code": "1914",
        "city": "נגוהות",
        "line": "",
        "latitude": "31.493136",
        "distribution_area": "",
        "city_code": "3724",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נגוהות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "הר פונס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-19  ו' 8-13",
        "mesirot_yn": "y",
        "longitude": "34.983088"
    },
    {
        "n_code": "2151",
        "city": "נהורה",
        "line": "",
        "latitude": "31.621827",
        "distribution_area": "",
        "city_code": "309",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נהורה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכניית נהורה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:30-14 16:30-20 ו' 7-15",
        "mesirot_yn": "y",
        "longitude": "34.704552"
    },
    {
        "n_code": "3810",
        "city": "נהריה",
        "line": "",
        "latitude": "33.00298",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "325",
        "house": "100",
        "distribution_area_desc": "",
        "street": "הזמיר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אצל סימה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-18:00 ו'9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.10614"
    },
    {
        "n_code": "4483",
        "city": "נהריה",
        "line": "",
        "latitude": "33.007232",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "211",
        "house": "5",
        "distribution_area_desc": "",
        "street": "דרך יחיעם",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בא לי פיצה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה מ13:00 עד 04:00 בבוקר, שישי 10:00-שעה לפני שבת, ז שעה אחרי יציאת שבת",
        "mesirot_yn": "y",
        "longitude": "35.103397"
    },
    {
        "n_code": "4265",
        "city": "נהריה",
        "line": "",
        "latitude": "33.001830",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "409",
        "house": "35",
        "distribution_area_desc": "",
        "street": "לוחמי הגטאות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בורג נהריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-19 ו 7-14",
        "mesirot_yn": "y",
        "longitude": "35.097712"
    },
    {
        "n_code": "3650",
        "city": "נהריה",
        "line": "",
        "latitude": "32.99085",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "467",
        "house": "38",
        "distribution_area_desc": "",
        "street": "שקד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בלאק קופי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-23:00 ו'7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.08436"
    },
    {
        "n_code": "1088",
        "city": "נהריה",
        "line": "",
        "latitude": "33.0074011",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "211",
        "house": "7",
        "distribution_area_desc": "",
        "street": "דרך יחיעם",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "וינר טופי קופי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 8:30-23 ו'-8:30-15",
        "mesirot_yn": "y",
        "longitude": "35.10448093"
    },
    {
        "n_code": "1540",
        "city": "נהריה",
        "line": "",
        "latitude": "33.01261285",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "411",
        "house": "20",
        "distribution_area_desc": "",
        "street": "ויצמן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ויצמן מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 5-19:30 ו' 5-15",
        "mesirot_yn": "y",
        "longitude": "35.09401922"
    },
    {
        "n_code": "1966",
        "city": "נהריה",
        "line": "",
        "latitude": "33.020993",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "201",
        "house": "41",
        "distribution_area_desc": "",
        "street": "החלוץ",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מיני סל אלפסי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-20 ו' 6-שעה לפני כניסת שבת",
        "mesirot_yn": "y",
        "longitude": "35.108418"
    },
    {
        "n_code": "2762",
        "city": "נהריה",
        "line": "",
        "latitude": "32.999117",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "301",
        "house": "46",
        "distribution_area_desc": "",
        "street": "יפה נוף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת אברהם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-21 ו' 6-15",
        "mesirot_yn": "y",
        "longitude": "35.100901"
    },
    {
        "n_code": "4260",
        "city": "נהריה",
        "line": "",
        "latitude": "33.009292",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "404",
        "house": "46",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת התאומים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00 19:00 יום ו' - 9-14:00 הרצל 46 על פינת המייסדים",
        "mesirot_yn": "y",
        "longitude": "35.096797"
    },
    {
        "n_code": "4173",
        "city": "נהריה",
        "line": "",
        "latitude": "33.004586",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "409",
        "house": "12",
        "distribution_area_desc": "",
        "street": "לוחמי הגטאות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מעדני הכפר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-19 ו 8-15",
        "mesirot_yn": "y",
        "longitude": "35.097809"
    },
    {
        "n_code": "2392",
        "city": "נהריה",
        "line": "",
        "latitude": "32.994832",
        "distribution_area": "",
        "city_code": "9100",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "302",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שלמה המלך",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פלאקום נהריה בע''מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-19:45  ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.101404"
    },
    {
        "n_code": "4870",
        "city": "נוב",
        "line": "",
        "latitude": "32.828103",
        "distribution_area": "",
        "city_code": "4304",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נוב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בולדר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.784336"
    },
    {
        "n_code": "3197",
        "city": "נווה אור",
        "line": "",
        "latitude": "32.588662",
        "distribution_area": "",
        "city_code": "590",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נווה אור",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "צרכנייה מרקו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-19:00 ו' 6:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.554575"
    },
    {
        "n_code": "3839",
        "city": "נווה אילן",
        "line": "",
        "latitude": "31.804382",
        "distribution_area": "",
        "city_code": "405",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "9990",
        "distribution_area_desc": "",
        "street": "נווה אילן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "לוטו אלביס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה10:00-20:00 ו'9:00-13:30",
        "mesirot_yn": "y",
        "longitude": "35.092679"
    },
    {
        "n_code": "3444",
        "city": "נווה אילן",
        "line": "",
        "latitude": "31.808522",
        "distribution_area": "",
        "city_code": "405",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נווה אילן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "צרכנית נווה אילן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:00-20:00 ו' 6:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.080206"
    },
    {
        "n_code": "3292",
        "city": "נווה דניאל",
        "line": "",
        "latitude": "31.676283",
        "distribution_area": "",
        "city_code": "3725",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נווה דניאל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אורלי פרינט בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00",
        "mesirot_yn": "y",
        "longitude": "35.139598"
    },
    {
        "n_code": "1289",
        "city": "נוף איילון",
        "line": "",
        "latitude": "31.869351",
        "distribution_area": "",
        "city_code": "1333",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "13",
        "distribution_area_desc": "",
        "street": "דביר",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "נחלת הספר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-12 16-18 יום שלישי- סגור!! ו' 10-13",
        "mesirot_yn": "y",
        "longitude": "34.983575"
    },
    {
        "n_code": "4933",
        "city": "נוף הגליל",
        "line": "",
        "latitude": "32.698739513153875",
        "distribution_area": "",
        "city_code": "1061",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "161",
        "house": "1",
        "distribution_area_desc": "",
        "street": "דרך החטיבות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "GASTORE",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-18:00 שישי 10:00-13:00  קניון וואן ליד החנות של גלי",
        "mesirot_yn": "y",
        "longitude": "35.3118794"
    },
    {
        "n_code": "4293",
        "city": "נוף הגליל",
        "line": "",
        "latitude": "32.708033",
        "distribution_area": "",
        "city_code": "1061",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "20",
        "distribution_area_desc": "",
        "street": "עצמון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "גרעיני עפולה החמניה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 9:00-19:00",
        "mesirot_yn": "y",
        "longitude": "35.324245"
    },
    {
        "n_code": "4051",
        "city": "נוף הגליל",
        "line": "",
        "latitude": "32.707028",
        "distribution_area": "",
        "city_code": "1061",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "16",
        "distribution_area_desc": "",
        "street": "עצמון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פוטו פרו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 9:00-13:00 15:00-18:00 שישי 9:00-14:00 עצמן 16 כניסה ז'  הכוונה  למקום  https://youtu.be/4To1WwQnTSw",
        "mesirot_yn": "y",
        "longitude": "35.321701"
    },
    {
        "n_code": "4670",
        "city": "נוף הגליל",
        "line": "",
        "latitude": "32.72152479966729",
        "distribution_area": "",
        "city_code": "1061",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "258",
        "house": "3",
        "distribution_area_desc": "",
        "street": "יקינטון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פרחי שאדי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-14:00  16:00-19:00 שישי 9:00-17:00 רחוב יקינטון פינת רחוב עטרה טל: 7117",
        "mesirot_yn": "y",
        "longitude": "35.33586821"
    },
    {
        "n_code": "4587",
        "city": "נופים",
        "line": "",
        "latitude": "32.154146",
        "distribution_area": "",
        "city_code": "3790",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הדקל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סופר זול נופים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00 שישי 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.101935"
    },
    {
        "n_code": "3860",
        "city": "נופית",
        "line": "",
        "latitude": "32.760255",
        "distribution_area": "",
        "city_code": "1284",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "101",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הגליל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכול נופית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 17:00-19:00 שישי 12:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.148063"
    },
    {
        "n_code": "4699",
        "city": "נחף",
        "line": "",
        "latitude": "32.933371",
        "distribution_area": "",
        "city_code": "522",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נחף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מסרי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 9:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.314090"
    },
    {
        "n_code": "4854",
        "city": "נטור",
        "line": "",
        "latitude": "32.853484",
        "distribution_area": "",
        "city_code": "4014",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "נטור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר דידי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-19:00 שישי 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.753615"
    },
    {
        "n_code": "2896",
        "city": "נטע מרשם",
        "line": "",
        "latitude": "31.478477",
        "distribution_area": "",
        "city_code": "9903",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נטע מרשם",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אביטל סנדר  עיצוב פנים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-19:00 ו'8:30-13:00",
        "mesirot_yn": "y",
        "longitude": "34.928117"
    },
    {
        "n_code": "3265",
        "city": "ניין",
        "line": "",
        "latitude": "32.635212",
        "distribution_area": "",
        "city_code": "523",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "ניין",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מסעדת CEASAR 049064348",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א,ב,ג,ד,ה,ו,ש 11:00-24:00",
        "mesirot_yn": "y",
        "longitude": "35.346776"
    },
    {
        "n_code": "4842",
        "city": "ניצן ב'",
        "line": "",
        "latitude": "31.739964",
        "distribution_area": "",
        "city_code": "1419",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "101",
        "house": "7",
        "distribution_area_desc": "",
        "street": "משעול הזמיר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "פרצל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 16:30-21:30",
        "mesirot_yn": "y",
        "longitude": "34.641842"
    },
    {
        "n_code": "1842",
        "city": "ניצני עוז",
        "line": "",
        "latitude": "32.305890",
        "distribution_area": "",
        "city_code": "851",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ניצני עוז",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "בזוקה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א ג 13:30-19:00 ב ד ה 10:00-19:00  ו'10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.003678"
    },
    {
        "n_code": "2681",
        "city": "ניר בנים",
        "line": "",
        "latitude": "31.671228",
        "distribution_area": "",
        "city_code": "553",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ניר בנים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכניה דניאל ונעמה בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:30-14-16-19 ו7-14 לכתוב בוויז קואופ שופ בכפר ניר בנים",
        "mesirot_yn": "y",
        "longitude": "34.755805"
    },
    {
        "n_code": "1543",
        "city": "ניר עציון",
        "line": "",
        "latitude": "32.70016908",
        "distribution_area": "",
        "city_code": "769",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ניר עציון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "צרכניה ניר עציון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 7-16 ו' 7-11",
        "mesirot_yn": "y",
        "longitude": "34.98971522"
    },
    {
        "n_code": "4008",
        "city": "נס ציונה",
        "line": "",
        "latitude": "31.928460",
        "distribution_area": "",
        "city_code": "7200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "201",
        "house": "4",
        "distribution_area_desc": "",
        "street": "רוטשילד",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "בן חי תקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10-19 ו' 9-12",
        "mesirot_yn": "y",
        "longitude": "34.797786"
    },
    {
        "n_code": "2950",
        "city": "נס ציונה",
        "line": "",
        "latitude": "31.930421",
        "distribution_area": "",
        "city_code": "7200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "395",
        "house": "1",
        "distribution_area_desc": "",
        "street": "לרר משה",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "מטרו מובייל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:30-19:00 ו'  10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.787619"
    },
    {
        "n_code": "4794",
        "city": "נס ציונה",
        "line": "",
        "latitude": "31.928376",
        "distribution_area": "",
        "city_code": "7200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "122",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ביאליק",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "סטוק סנטר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-21:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.798748"
    },
    {
        "n_code": "4063",
        "city": "נס ציונה",
        "line": "",
        "latitude": "31.921091",
        "distribution_area": "",
        "city_code": "7200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "317",
        "house": "6",
        "distribution_area_desc": "",
        "street": "הפטיש",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "סלקשן מרכז ישפרו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.791237"
    },
    {
        "n_code": "4902",
        "city": "נס ציונה",
        "line": "",
        "latitude": "31.92254991249427",
        "distribution_area": "",
        "city_code": "7200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "309",
        "house": "12",
        "distribution_area_desc": "",
        "street": "החרש",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "רוני אור פרננדס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א,ב,ד,ה 8:00-18:00 ג' 8:00-16:00 שישי 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.80148528"
    },
    {
        "n_code": "4462",
        "city": "נעמ\\\"ה",
        "line": "",
        "latitude": "31.907932",
        "distribution_area": "",
        "city_code": "3713",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נעמ\\\"ה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "FIX PC",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-15:30",
        "mesirot_yn": "y",
        "longitude": "35.467946"
    },
    {
        "n_code": "4678",
        "city": "נצרת",
        "line": "",
        "latitude": "32.6951729957634",
        "distribution_area": "",
        "city_code": "7300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "509",
        "house": "69",
        "distribution_area_desc": "",
        "street": "איכסאל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חשמל ואיל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים ב-ש 10:00-20:00, א סגור",
        "mesirot_yn": "y",
        "longitude": "35.305080161"
    },
    {
        "n_code": "4295",
        "city": "נצרת",
        "line": "",
        "latitude": "32.700531",
        "distribution_area": "",
        "city_code": "7300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "391",
        "house": "999",
        "distribution_area_desc": "",
        "street": "אל ואדי אל ג'וואני",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת אבו סלים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "עי ביה\\\"ח האנגלי ב-ז 14:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.293626"
    },
    {
        "n_code": "4406",
        "city": "נצרת",
        "line": "",
        "latitude": "32.717686",
        "distribution_area": "",
        "city_code": "7300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "962",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בילאל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת בילאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ז 9:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.299433"
    },
    {
        "n_code": "2448",
        "city": "נצרת",
        "line": "",
        "latitude": "32.700698",
        "distribution_area": "",
        "city_code": "7300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "513",
        "house": "999",
        "distribution_area_desc": "",
        "street": "פאולוס השישי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספורט המעיין-בכיכר המעיין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ש 9-19:30",
        "mesirot_yn": "y",
        "longitude": "35.297194"
    },
    {
        "n_code": "2196",
        "city": "נריה",
        "line": "",
        "latitude": "31.956357",
        "distribution_area": "",
        "city_code": "9964",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נריה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "טלמונים אספקה טכנית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:30-19 ו' 7:30-12",
        "mesirot_yn": "y",
        "longitude": "35.117445"
    },
    {
        "n_code": "2018",
        "city": "נשר",
        "line": "",
        "latitude": "32.773456",
        "distribution_area": "",
        "city_code": "2500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "189",
        "house": "18",
        "distribution_area_desc": "",
        "street": "אופקים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אלפא פט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:30-20:00   ו' 10-16",
        "mesirot_yn": "y",
        "longitude": "35.033325"
    },
    {
        "n_code": "3970",
        "city": "נשר",
        "line": "",
        "latitude": "32.778315",
        "distribution_area": "",
        "city_code": "2500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "173",
        "house": "147",
        "distribution_area_desc": "",
        "street": "דרך בר יהודה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "זול סטוק נשר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12-19 ו'9-12",
        "mesirot_yn": "y",
        "longitude": "35.038969"
    },
    {
        "n_code": "2128",
        "city": "נשר",
        "line": "",
        "latitude": "32.782863",
        "distribution_area": "",
        "city_code": "2500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "251",
        "house": "22",
        "distribution_area_desc": "",
        "street": "המסילה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ממלכת האופים-צמוד לשופרסל דיל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-19  ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.035629"
    },
    {
        "n_code": "2106",
        "city": "נתיב השיירה",
        "line": "",
        "latitude": "32.994226",
        "distribution_area": "",
        "city_code": "792",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "נתיב השיירה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט נתיב השיירה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א ג ד ה  6-13 16-19  ב' ו'6-13",
        "mesirot_yn": "y",
        "longitude": "35.135257"
    },
    {
        "n_code": "3191",
        "city": "נתיבות",
        "line": "",
        "latitude": "31.416895",
        "distribution_area": "",
        "city_code": "246",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "317",
        "house": "2",
        "distribution_area_desc": "",
        "street": "גני טל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אותנטי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-21:00 ו' 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.571907"
    },
    {
        "n_code": "3761",
        "city": "נתיבות",
        "line": "",
        "latitude": "31.424320",
        "distribution_area": "",
        "city_code": "246",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "116",
        "house": "24",
        "distribution_area_desc": "",
        "street": "רבי עקיבא",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "חי אור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-20:00 ו' 9:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.600355"
    },
    {
        "n_code": "4057",
        "city": "נתיבות",
        "line": "",
        "latitude": "31.417546",
        "distribution_area": "",
        "city_code": "246",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "176",
        "house": "5",
        "distribution_area_desc": "",
        "street": "בעלי מלאכה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן צים סנטר נתיבות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 11:00-15:25 16:00-19:00 יום שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.594357"
    },
    {
        "n_code": "3890",
        "city": "נתניה",
        "line": "",
        "latitude": "32.297698",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "906",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הנביאים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אולטרה פון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'ב'ד'ה'  9-19 ג' ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "34.854007"
    },
    {
        "n_code": "3676",
        "city": "נתניה",
        "line": "",
        "latitude": "32.32308",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "842",
        "house": "12",
        "distribution_area_desc": "",
        "street": "הקדר",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אם.ג'י.אם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-18:00 ו' 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.87577"
    },
    {
        "n_code": "3918",
        "city": "נתניה",
        "line": "",
        "latitude": "32.317592",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "840",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אורט",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אקספרס נוחות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "פינת האורזים א-ה 7-23 ו' 7-16",
        "mesirot_yn": "y",
        "longitude": "34.871956"
    },
    {
        "n_code": "4905",
        "city": "נתניה",
        "line": "",
        "latitude": "32.325368",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1df2",
        "house": "25",
        "distribution_area_desc": "",
        "street": "אחד העם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "ביץ' האוס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00-14:00  ביץ האוס / billabong",
        "mesirot_yn": "y",
        "longitude": "34.859021"
    },
    {
        "n_code": "4838",
        "city": "נתניה",
        "line": "",
        "latitude": "32.313680",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "802",
        "house": "22",
        "distribution_area_desc": "",
        "street": "ארלוזורוב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "המהפכה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-12:00  15:00-20:00 שישי 9:00-16:00",
        "mesirot_yn": "y",
        "longitude": "34.880394"
    },
    {
        "n_code": "4826",
        "city": "נתניה",
        "line": "",
        "latitude": "32.319745",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "702",
        "house": "18",
        "distribution_area_desc": "",
        "street": "פנקס דוד",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "הנסיכים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-18:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.874088"
    },
    {
        "n_code": "4526",
        "city": "נתניה",
        "line": "",
        "latitude": "32.294817",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "958",
        "house": "10",
        "distribution_area_desc": "",
        "street": "גרינבוים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "הפינה של בני",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-13:00 16:00-19:00 יום שישי 10:00- שעה לפני כניסת השבת",
        "mesirot_yn": "y",
        "longitude": "34.851228"
    },
    {
        "n_code": "3575",
        "city": "נתניה",
        "line": "",
        "latitude": "32.33450614199973",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "327",
        "house": "43",
        "distribution_area_desc": "",
        "street": "שמואל הנציב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "הצרכנייה של ברוך",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-14:00 17:30-19:30",
        "mesirot_yn": "y",
        "longitude": "34.85644471"
    },
    {
        "n_code": "4022",
        "city": "נתניה",
        "line": "",
        "latitude": "32.301466",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "931",
        "house": "15",
        "distribution_area_desc": "",
        "street": "יחזקאל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "טנגיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 09:00-19:30 שישי 09:00-11:00",
        "mesirot_yn": "y",
        "longitude": "34.856839"
    },
    {
        "n_code": "3980",
        "city": "נתניה",
        "line": "",
        "latitude": "32.284770",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "943",
        "house": "24",
        "distribution_area_desc": "",
        "street": "קצנלסון יצחק",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "טריפל מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10-20",
        "mesirot_yn": "y",
        "longitude": "34.852274"
    },
    {
        "n_code": "1291",
        "city": "נתניה",
        "line": "",
        "latitude": "32.31505224",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "831",
        "house": "22",
        "distribution_area_desc": "",
        "street": "שפרינצק",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מיני מרקט נאות גנים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-22 ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "34.88561993"
    },
    {
        "n_code": "4730",
        "city": "נתניה",
        "line": "",
        "latitude": "32.319551",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "505",
        "house": "48",
        "distribution_area_desc": "",
        "street": "בן יהודה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט אורנה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:30 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.850910"
    },
    {
        "n_code": "4734",
        "city": "נתניה",
        "line": "",
        "latitude": "32.290426",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "gy2",
        "house": "6",
        "distribution_area_desc": "",
        "street": "כנרת",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מיסטר פון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-19:30 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.851344"
    },
    {
        "n_code": "3848",
        "city": "נתניה",
        "line": "",
        "latitude": "32.329432",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "134",
        "house": "8",
        "distribution_area_desc": "",
        "street": "פינס",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מכולת ממוקה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-13-16-19 ו' 9-12",
        "mesirot_yn": "y",
        "longitude": "34.862924"
    },
    {
        "n_code": "3067",
        "city": "נתניה",
        "line": "",
        "latitude": "32.278464",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "1016",
        "house": "2",
        "distribution_area_desc": "",
        "street": "האומנות",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סופר מרקט לחיות מחמד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 ו' 8:00-15:30",
        "mesirot_yn": "y",
        "longitude": "34.858844"
    },
    {
        "n_code": "1295",
        "city": "נתניה",
        "line": "",
        "latitude": "32.30283156",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "609",
        "house": "44",
        "distribution_area_desc": "",
        "street": "שד בן צבי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פיצוציית פצצות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-22 ו' 6-15",
        "mesirot_yn": "y",
        "longitude": "34.8543621"
    },
    {
        "n_code": "4196",
        "city": "נתניה",
        "line": "",
        "latitude": "32.321230",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "844",
        "house": "14",
        "distribution_area_desc": "",
        "street": "המסגר",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קומסס עולם של ניקיון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:30-19 ו 8:30-13",
        "mesirot_yn": "y",
        "longitude": "34.874765"
    },
    {
        "n_code": "4837",
        "city": "נתניה",
        "line": "",
        "latitude": "32.279362",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0008",
        "house": "11",
        "distribution_area_desc": "",
        "street": "גיבורי ישראל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קליית חממה נתניה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-20:00 שישי 8:0-15:00",
        "mesirot_yn": "y",
        "longitude": "34.861160"
    },
    {
        "n_code": "4860",
        "city": "נתניה",
        "line": "",
        "latitude": "32.320914644842674",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "843",
        "house": "6",
        "distribution_area_desc": "",
        "street": "האורזים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "שבלול מוצרי אפיה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-18:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.8713150"
    },
    {
        "n_code": "3742",
        "city": "נתניה",
        "line": "",
        "latitude": "32.319332",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0001",
        "house": "17",
        "distribution_area_desc": "",
        "street": "אבו שדיד אברהם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "שוק פירות וירקות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 11:00-20:00 שישי 9:00-16:00",
        "mesirot_yn": "y",
        "longitude": "34.853427"
    },
    {
        "n_code": "4775",
        "city": "נתניה",
        "line": "",
        "latitude": "32.32748326572386",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "433",
        "house": "14",
        "distribution_area_desc": "",
        "street": "שער הגיא",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "שטרסר ספרים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-18:00 שישי 10:00-13:30",
        "mesirot_yn": "y",
        "longitude": "34.85734368"
    },
    {
        "n_code": "4944",
        "city": "נתניה",
        "line": "",
        "latitude": "32.32666601374139",
        "distribution_area": "",
        "city_code": "7400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "402",
        "house": "11",
        "distribution_area_desc": "",
        "street": "אחד העם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "שיגעון של חנות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-18:00 שישי 10:00-14:",
        "mesirot_yn": "y",
        "longitude": "34.85939936"
    },
    {
        "n_code": "3852",
        "city": "סאג'ור",
        "line": "",
        "latitude": "32.941340",
        "distribution_area": "",
        "city_code": "525",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "סאג'ור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סאגור אשם מזון בעלי חיים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו' 10-20 0528399965",
        "mesirot_yn": "y",
        "longitude": "35.350450"
    },
    {
        "n_code": "4168",
        "city": "סולם",
        "line": "",
        "latitude": "32.607011",
        "distribution_area": "",
        "city_code": "526",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "3",
        "distribution_area_desc": "",
        "street": "סולם",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סופר מרקט סולם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 8:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.332513"
    },
    {
        "n_code": "4939",
        "city": "סוסיה",
        "line": "",
        "latitude": "31.392952",
        "distribution_area": "",
        "city_code": "3756",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "סוסיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "ישיבת סוסיא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.112373"
    },
    {
        "n_code": "4492",
        "city": "סופה",
        "line": "",
        "latitude": "31.237485",
        "distribution_area": "",
        "city_code": "1238",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "סופה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כלבו לב סופה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-12:00 + 18:00- 14:00 שישי 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.341768"
    },
    {
        "n_code": "1929",
        "city": "סח'נין",
        "line": "",
        "latitude": "32.867552",
        "distribution_area": "",
        "city_code": "7500",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "סח'נין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חי-אדרי שיווק ומסחר בע\\\"מ ממוקם בשכונת הנוצרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-13:30 15-19:30",
        "mesirot_yn": "y",
        "longitude": "35.295548"
    },
    {
        "n_code": "1736",
        "city": "סח'נין",
        "line": "",
        "latitude": "32.863505",
        "distribution_area": "",
        "city_code": "7500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "99",
        "distribution_area_desc": "",
        "street": "סח'נין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת הכיכר \\\"אבו יודה\\\"",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 10-24 ליד כיכר השוהאדה",
        "mesirot_yn": "y",
        "longitude": "35.302114"
    },
    {
        "n_code": "2045",
        "city": "עבדון",
        "line": "",
        "latitude": "33.048004",
        "distribution_area": "",
        "city_code": "892",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עבדון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר בכפר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-19  ו' 6-13",
        "mesirot_yn": "y",
        "longitude": "35.178393"
    },
    {
        "n_code": "2110",
        "city": "עדיעד",
        "line": "",
        "latitude": "32.041226",
        "distribution_area": "",
        "city_code": "9931",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עדיעד",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "כלבים ונהנים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-18  ו' 8-14",
        "mesirot_yn": "y",
        "longitude": "35.334392"
    },
    {
        "n_code": "3525",
        "city": "עוזייר",
        "line": "",
        "latitude": "32.79121526774848",
        "distribution_area": "",
        "city_code": "528",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1111",
        "distribution_area_desc": "",
        "street": "עוזייר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "MEMENTO",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ו 15:00-21:00 א' סגור.",
        "mesirot_yn": "y",
        "longitude": "35.32709304"
    },
    {
        "n_code": "3214",
        "city": "עופר",
        "line": "",
        "latitude": "32.623427",
        "distribution_area": "",
        "city_code": "810",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עופר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "צרכניית עופר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-16:00 ו' 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.981454"
    },
    {
        "n_code": "3872",
        "city": "עופרה",
        "line": "",
        "latitude": "31.959592",
        "distribution_area": "",
        "city_code": "3617",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "123",
        "house": "12",
        "distribution_area_desc": "",
        "street": "פנינת חן",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עוזרי פנינת חן 12",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א--ה 16:30-21:30 ו'8-14",
        "mesirot_yn": "y",
        "longitude": "35.261874"
    },
    {
        "n_code": "4464",
        "city": "עופרים",
        "line": "",
        "latitude": "32.018785",
        "distribution_area": "",
        "city_code": "3792",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "xoo5",
        "house": "7",
        "distribution_area_desc": "",
        "street": "השיטה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "רעותוש הסרת שיער",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א 15:30-17:00 ג-ה 17:00-19:00 שישי בתיאום מראש",
        "mesirot_yn": "y",
        "longitude": "35.045069"
    },
    {
        "n_code": "4438",
        "city": "עטרות א\\\"ת",
        "line": "",
        "latitude": "31.855189",
        "distribution_area": "",
        "city_code": "9998",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עטרות א\\\"ת",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "הסטוק עטרות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "קניון עטרות א-ה 16:00-21:00 ו 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.218690"
    },
    {
        "n_code": "2611",
        "city": "עטרת",
        "line": "",
        "latitude": "32.001341",
        "distribution_area": "",
        "city_code": "3658",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עטרת",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט עטרת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-12  16-19  ו' 7-13:30",
        "mesirot_yn": "y",
        "longitude": "35.176949"
    },
    {
        "n_code": "4174",
        "city": "עיילבון",
        "line": "",
        "latitude": "32.841376",
        "distribution_area": "",
        "city_code": "530",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עיילבון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אייט-טק 2008",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ליד בנק מרכנתיל ב,ג,ד,ה,ז 12-19:30",
        "mesirot_yn": "y",
        "longitude": "35.405493"
    },
    {
        "n_code": "3491",
        "city": "עילוט",
        "line": "",
        "latitude": "32.725520",
        "distribution_area": "",
        "city_code": "511",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "5",
        "distribution_area_desc": "",
        "street": "עילוט",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "דלק גז אנרגיה דור אלון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "24/7 ב-WAZE דור אלון עוקף עילוט)",
        "mesirot_yn": "y",
        "longitude": "35.265225"
    },
    {
        "n_code": "2393",
        "city": "עילוט",
        "line": "",
        "latitude": "32.717198",
        "distribution_area": "",
        "city_code": "511",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עילוט",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ריג'נט לסחר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א' 8-16  ב-ה 8-19 ו' 8-14 ש' 8-16",
        "mesirot_yn": "y",
        "longitude": "35.261384"
    },
    {
        "n_code": "1638",
        "city": "עין אל-אסד",
        "line": "",
        "latitude": "32.940589",
        "distribution_area": "",
        "city_code": "546",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין אל-אסד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכז קניות גאנם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 10-20",
        "mesirot_yn": "y",
        "longitude": "35.400129"
    },
    {
        "n_code": "4504",
        "city": "עין בוקק",
        "line": "",
        "latitude": "31.200634",
        "distribution_area": "",
        "city_code": "9993",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין בוקק",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "טאג' מאהל-מלון ליאונרדו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מלון ליאונרדו, ראשון-שבת 10:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.360956"
    },
    {
        "n_code": "4712",
        "city": "עין גדי",
        "line": "",
        "latitude": "31.451694",
        "distribution_area": "",
        "city_code": "2042",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עין גדי",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כלבו עין גדי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-19:00 שישי 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.381806"
    },
    {
        "n_code": "4508",
        "city": "עין דור",
        "line": "",
        "latitude": "32.656798",
        "distribution_area": "",
        "city_code": "436",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין דור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "נעלי ניצן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.418003"
    },
    {
        "n_code": "2887",
        "city": "עין הבשור",
        "line": "",
        "latitude": "31.281710",
        "distribution_area": "",
        "city_code": "1240",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עין הבשור",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכנית קם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-18:00 ו'7:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.442774"
    },
    {
        "n_code": "2672",
        "city": "עין הוד",
        "line": "",
        "latitude": "32.701552",
        "distribution_area": "",
        "city_code": "74",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין הוד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת בכפר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 6-19 כפר האומנים",
        "mesirot_yn": "y",
        "longitude": "34.981134"
    },
    {
        "n_code": "2953",
        "city": "עין החורש",
        "line": "",
        "latitude": "32.384142",
        "distribution_area": "",
        "city_code": "167",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עין החורש",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "רייט קליק תמיכת מחשבים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "אחרי שער הקיבוץ לפנות ימינה  046301141. א-ה 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.938777"
    },
    {
        "n_code": "2757",
        "city": "עין זיוון",
        "line": "",
        "latitude": "33.097302",
        "distribution_area": "",
        "city_code": "4503",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין זיוון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות  עין זיון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-20  ו' 7-17",
        "mesirot_yn": "y",
        "longitude": "35.797087"
    },
    {
        "n_code": "4726",
        "city": "עין חרוד (מאוחד)",
        "line": "",
        "latitude": "32.558800",
        "distribution_area": "",
        "city_code": "82",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עין חרוד (מאוחד)",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "כלבו עין חרוד מאוחד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-19:00 ה 7:00-21:00 שישי 7:00-13:30 שבת 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.392287"
    },
    {
        "n_code": "1668",
        "city": "עין יהב",
        "line": "",
        "latitude": "30.660672",
        "distribution_area": "",
        "city_code": "806",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין יהב",
        "line_desc": "",
        "sorting_area_code": "  6",
        "name": "מחסן ROI",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-13  14-16",
        "mesirot_yn": "y",
        "longitude": "35.239014"
    },
    {
        "n_code": "4942",
        "city": "עין מאהל",
        "line": "",
        "latitude": "32.720490",
        "distribution_area": "",
        "city_code": "532",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עין מאהל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאפיית בני אלחסן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ראשון עד שבת 8:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.353836"
    },
    {
        "n_code": "3523",
        "city": "עין מאהל",
        "line": "",
        "latitude": "32.723217",
        "distribution_area": "",
        "city_code": "532",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "111",
        "distribution_area_desc": "",
        "street": "עין מאהל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "נדין תקשורת-עין מאהל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ש 12:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.351239"
    },
    {
        "n_code": "3404",
        "city": "עין קנייא",
        "line": "",
        "latitude": "33.236803",
        "distribution_area": "",
        "city_code": "4502",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עין קנייא",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סבאח ומסא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "כל השבוע 07:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.731585"
    },
    {
        "n_code": "4615",
        "city": "עין תמר",
        "line": "",
        "latitude": "30.943054",
        "distribution_area": "",
        "city_code": "1251",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עין תמר",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אלוניר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-14:00  17:00-20:00 שישי 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.367323"
    },
    {
        "n_code": "4922",
        "city": "עכו",
        "line": "",
        "latitude": "32.943652",
        "distribution_area": "",
        "city_code": "7600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1113",
        "house": "38",
        "distribution_area_desc": "",
        "street": "עפרוני",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אדם ופרח",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "לרשום בוויז אדם ופרח א-ה 10:00-18:00",
        "mesirot_yn": "y",
        "longitude": "35.081549"
    },
    {
        "n_code": "3682",
        "city": "עכו",
        "line": "",
        "latitude": "32.929944",
        "distribution_area": "",
        "city_code": "7600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "306",
        "house": "10",
        "distribution_area_desc": "",
        "street": "אלקלעי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "המקום של מאיה - פאזל האוטלט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א',ב',ד',ה' 9:00-19:00 , ג' 9:00-14:00,  ו' 9:00-12:00 טלפון בחנות 048160722",
        "mesirot_yn": "y",
        "longitude": "35.092123"
    },
    {
        "n_code": "4928",
        "city": "עכו",
        "line": "",
        "latitude": "32.930352",
        "distribution_area": "",
        "city_code": "7600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "515",
        "house": "28",
        "distribution_area_desc": "",
        "street": "גיבורי סיני",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט אלואלאא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 10:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.076739"
    },
    {
        "n_code": "4844",
        "city": "עכו",
        "line": "",
        "latitude": "32.926069",
        "distribution_area": "",
        "city_code": "7600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "503",
        "house": "28",
        "distribution_area_desc": "",
        "street": "יהושפט",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת העיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ראשון עד שבת 7:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.077053"
    },
    {
        "n_code": "2523",
        "city": "עלי",
        "line": "",
        "latitude": "32.063896",
        "distribution_area": "",
        "city_code": "3765",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עלי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "SO GOOD מפגש עלי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-23  ו' 7- עד שעתיים פני כניסת שבת, מוצ''ש משעה אחרי צאת שבת עד 23:30 נמצא במוצש עלי כביש 60",
        "mesirot_yn": "y",
        "longitude": "35.264317"
    },
    {
        "n_code": "1557",
        "city": "עמינדב",
        "line": "",
        "latitude": "31.75213822",
        "distribution_area": "",
        "city_code": "779",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הברוש",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מינימרקט עמינדב",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 6-19 ו' 6-14",
        "mesirot_yn": "y",
        "longitude": "35.14246658"
    },
    {
        "n_code": "3302",
        "city": "עמיעד",
        "line": "",
        "latitude": "32.927858",
        "distribution_area": "",
        "city_code": "385",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עמיעד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות-עמיעד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-21:00 ו' 6:00- חצי שעה לפני כניסת השבת.",
        "mesirot_yn": "y",
        "longitude": "35.541014"
    },
    {
        "n_code": "4417",
        "city": "ענב",
        "line": "",
        "latitude": "32.283697",
        "distribution_area": "",
        "city_code": "3712",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ענב",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "כול בו עינב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מרכז מסחרי א-ה 9:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.125189"
    },
    {
        "n_code": "3241",
        "city": "עספיא",
        "line": "",
        "latitude": "32.719685",
        "distribution_area": "",
        "city_code": "534",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6733",
        "house": "1",
        "distribution_area_desc": "",
        "street": "חיילים משוחררים א'",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אלמנאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ב-ש 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.062726"
    },
    {
        "n_code": "4375",
        "city": "עספיא",
        "line": "",
        "latitude": "32.711531",
        "distribution_area": "",
        "city_code": "534",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6740",
        "house": "999",
        "distribution_area_desc": "",
        "street": "זוחלוק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "לרשותך על הדרך",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:30-21:00 ו 12:00-21:00 ז 17:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.067560"
    },
    {
        "n_code": "3607",
        "city": "עספיא",
        "line": "",
        "latitude": "32.71467",
        "distribution_area": "",
        "city_code": "534",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "xoo2",
        "house": "1",
        "distribution_area_desc": "",
        "street": "אבא חושי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מאפיית הברכה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ו 6:00-20:00 שכונת למסיל",
        "mesirot_yn": "y",
        "longitude": "35.06889"
    },
    {
        "n_code": "4189",
        "city": "עפולה",
        "line": "",
        "latitude": "32.622745",
        "distribution_area": "",
        "city_code": "7700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "117",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הבריגדה העברית",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט אלכסיי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-20 ו 8-15",
        "mesirot_yn": "y",
        "longitude": "35.325573"
    },
    {
        "n_code": "4188",
        "city": "עפולה",
        "line": "",
        "latitude": "32.606673",
        "distribution_area": "",
        "city_code": "7700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "386",
        "house": "54",
        "distribution_area_desc": "",
        "street": "שרת",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט אריאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-20 ו 8-12",
        "mesirot_yn": "y",
        "longitude": "35.283368"
    },
    {
        "n_code": "3389",
        "city": "עפולה",
        "line": "",
        "latitude": "32.606419",
        "distribution_area": "",
        "city_code": "7700",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "323",
        "house": "9",
        "distribution_area_desc": "",
        "street": "ירושלים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט עפולה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 08:00-21:00 ו' 08:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.287372"
    },
    {
        "n_code": "4068",
        "city": "עפולה",
        "line": "",
        "latitude": "32.605749",
        "distribution_area": "",
        "city_code": "7700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "320",
        "house": "26",
        "distribution_area_desc": "",
        "street": "קהלת ציון",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סלקשן עפולה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.297341"
    },
    {
        "n_code": "2619",
        "city": "עפולה",
        "line": "",
        "latitude": "32.600139",
        "distribution_area": "",
        "city_code": "7700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "431",
        "house": "1",
        "distribution_area_desc": "",
        "street": "החשמל",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "רותם עוגות חלום",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ד 9-19  ה' 9-20  ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "35.295287"
    },
    {
        "n_code": "4481",
        "city": "עצמון שגב",
        "line": "",
        "latitude": "32.865232",
        "distribution_area": "",
        "city_code": "917",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עצמון שגב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מיני מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-18:00 שישי 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.252564"
    },
    {
        "n_code": "4764",
        "city": "עראבה",
        "line": "",
        "latitude": "32.848912287927845",
        "distribution_area": "",
        "city_code": "531",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "עראבה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת עבד קזחא",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "רחוב עלאא נסאר, ימים א-ז 8:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.3363940"
    },
    {
        "n_code": "4513",
        "city": "ערד",
        "line": "",
        "latitude": "31.247414",
        "distribution_area": "",
        "city_code": "2560",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "248",
        "house": "55",
        "distribution_area_desc": "",
        "street": "תעשיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אותנטי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ליד יינות ביתן 9:00-21:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.201870"
    },
    {
        "n_code": "4041",
        "city": "ערד",
        "line": "",
        "latitude": "31.259425",
        "distribution_area": "",
        "city_code": "2560",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "122",
        "house": "27",
        "distribution_area_desc": "",
        "street": "הקנאים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן - קניון ערד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "35.209188"
    },
    {
        "n_code": "3307",
        "city": "ערערה",
        "line": "",
        "latitude": "32.498526",
        "distribution_area": "",
        "city_code": "637",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6731",
        "house": "56",
        "distribution_area_desc": "",
        "street": "אל דהראת",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אוטיקה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 10:0019:00 ו'-סגור",
        "mesirot_yn": "y",
        "longitude": "35.085887"
    },
    {
        "n_code": "2480",
        "city": "ערערה-בנגב",
        "line": "",
        "latitude": "31.145878",
        "distribution_area": "",
        "city_code": "1192",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ערערה-בנגב",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סופרמרקט האחים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 24/7",
        "mesirot_yn": "y",
        "longitude": "34.998578"
    },
    {
        "n_code": "4410",
        "city": "עשהאל",
        "line": "",
        "latitude": "31.373346",
        "distribution_area": "",
        "city_code": "8708",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עשהאל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות עשהאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-16:00 ו  9:00-11:00 0584695504 - זמין רק בוואטספ",
        "mesirot_yn": "y",
        "longitude": "35.043714"
    },
    {
        "n_code": "4137",
        "city": "עתלית",
        "line": "",
        "latitude": "32.686059",
        "distribution_area": "",
        "city_code": "53",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "122",
        "house": "19",
        "distribution_area_desc": "",
        "street": "דרך הים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כלבו דוריס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-18 ו 9-15",
        "mesirot_yn": "y",
        "longitude": "34.943445"
    },
    {
        "n_code": "4532",
        "city": "עתלית",
        "line": "",
        "latitude": "32.711714",
        "distribution_area": "",
        "city_code": "53",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "122",
        "house": "1",
        "distribution_area_desc": "",
        "street": "דרך הים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכבסת נוי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-17:00 שישי8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.948356"
    },
    {
        "n_code": "3519",
        "city": "עתניאל",
        "line": "",
        "latitude": "31.44075744237439",
        "distribution_area": "",
        "city_code": "3748",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "עתניאל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "יאיר ראובן פיתרונות מחשוב ע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-20:00  ו'9:00-14:00 מצפה קדם 75 ליצירת קשר 0526136130",
        "mesirot_yn": "y",
        "longitude": "35.03220025"
    },
    {
        "n_code": "4301",
        "city": "פוריה עילית",
        "line": "",
        "latitude": "32.732228",
        "distribution_area": "",
        "city_code": "1313",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "106",
        "house": "10",
        "distribution_area_desc": "",
        "street": "דרך השדה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר נוחות פוריה עילית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6-21 ו שעה לפני כניסת שבת",
        "mesirot_yn": "y",
        "longitude": "35.546260"
    },
    {
        "n_code": "3417",
        "city": "פוריידיס",
        "line": "",
        "latitude": "32.602245",
        "distribution_area": "",
        "city_code": "537",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "6710",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שכונה צפונית",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פנצריית חוף הכרמל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "כל השבוע 7-23",
        "mesirot_yn": "y",
        "longitude": "34.952230"
    },
    {
        "n_code": "4286",
        "city": "פורת",
        "line": "",
        "latitude": "32.272043",
        "distribution_area": "",
        "city_code": "767",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "101",
        "house": "59",
        "distribution_area_desc": "",
        "street": "הותיקים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אול מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:30-21 ו 6:30-14",
        "mesirot_yn": "y",
        "longitude": "34.949758"
    },
    {
        "n_code": "4639",
        "city": "פטיש",
        "line": "",
        "latitude": "31.326946",
        "distribution_area": "",
        "city_code": "749",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "פטיש",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכניית פטיש",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-14:00  17:00-20:00 שישי 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.558865"
    },
    {
        "n_code": "3104",
        "city": "פסוטה",
        "line": "",
        "latitude": "33.047917",
        "distribution_area": "",
        "city_code": "535",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "פסוטה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סטודיו וינוס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א' 15:00-20:00 ב,ג,ד,ה,ו,ש 8:00-20:00 (כיכר ראשונה שמאלה ליד המאפיה)",
        "mesirot_yn": "y",
        "longitude": "35.308854"
    },
    {
        "n_code": "1564",
        "city": "פצאל",
        "line": "",
        "latitude": "32.04399768",
        "distribution_area": "",
        "city_code": "3615",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "פצאל",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "צרכניית נצרים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:30-14:30 17-19 ו' 7-15",
        "mesirot_yn": "y",
        "longitude": "35.44175189"
    },
    {
        "n_code": "2652",
        "city": "פקיעין (בוקייעה)",
        "line": "",
        "latitude": "32.981453",
        "distribution_area": "",
        "city_code": "536",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "פקיעין (בוקייעה)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "בי אם אס מרקט בע''מ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 7:30-22 מדאח סנטר",
        "mesirot_yn": "y",
        "longitude": "35.331413"
    },
    {
        "n_code": "2017",
        "city": "פקיעין (בוקייעה)",
        "line": "",
        "latitude": "32.977064",
        "distribution_area": "",
        "city_code": "536",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "פקיעין (בוקייעה)",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "עאדל חיר אספקה טכנית בע\\\"מ-כביש ראשי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-17  ו' 8-15",
        "mesirot_yn": "y",
        "longitude": "35.336203"
    },
    {
        "n_code": "4102",
        "city": "פרדס חנה-כרכור",
        "line": "",
        "latitude": "32.467772",
        "distribution_area": "",
        "city_code": "7800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "263",
        "house": "14",
        "distribution_area_desc": "",
        "street": "תחיה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "הגורים של אייל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 09:00-19:00 ו' 09:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.952908"
    },
    {
        "n_code": "4713",
        "city": "פרדס חנה-כרכור",
        "line": "",
        "latitude": "32.46536608611152",
        "distribution_area": "",
        "city_code": "7800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "055b",
        "house": "79",
        "distribution_area_desc": "",
        "street": "חסידה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מינימרקט נעים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-14:00  16:00-19:00",
        "mesirot_yn": "y",
        "longitude": "34.95583198"
    },
    {
        "n_code": "2967",
        "city": "פרדסיה",
        "line": "",
        "latitude": "32.304592",
        "distribution_area": "",
        "city_code": "171",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "124",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שד הנשיא",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "אביבה קוסמטי 0528821845",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א' 16:30-18:30, ב'-ה' 10:30-13:00  16:30-18:30, ו' 10:00-12:00 מול קופת חולים כללית, קומה מינוס 1",
        "mesirot_yn": "y",
        "longitude": "34.902113"
    },
    {
        "n_code": "4046",
        "city": "פרוד",
        "line": "",
        "latitude": "32.93316",
        "distribution_area": "",
        "city_code": "599",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "53",
        "distribution_area_desc": "",
        "street": "פרוד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "משתלת גן גני",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-18 ו' 7-13 ניתן ליצור קשר : 0535397406",
        "mesirot_yn": "y",
        "longitude": "35.43420"
    },
    {
        "n_code": "4675",
        "city": "פרזון",
        "line": "",
        "latitude": "32.54547033779598",
        "distribution_area": "",
        "city_code": "2053",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "31",
        "distribution_area_desc": "",
        "street": "פרזון",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פיסיפוניקס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00 - 13:00",
        "mesirot_yn": "y",
        "longitude": "35.31111218"
    },
    {
        "n_code": "1021",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.09111745",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "1160",
        "house": "4",
        "distribution_area_desc": "",
        "street": "השילוח",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "אור מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א'-ה' 06:30-14:30",
        "mesirot_yn": "y",
        "longitude": "34.85594684"
    },
    {
        "n_code": "4889",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.10913378364333",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "198",
        "house": "7",
        "distribution_area_desc": "",
        "street": "סמ התבור",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "הידרו סטאר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:30-18:00  ליד ג.ק ציוד",
        "mesirot_yn": "y",
        "longitude": "34.88658185"
    },
    {
        "n_code": "4358",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.102441",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "144",
        "house": "9",
        "distribution_area_desc": "",
        "street": "גליס",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "זו ארץ זו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-19:30 ו 10:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.895856"
    },
    {
        "n_code": "4814",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.08394007583144",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "917",
        "house": "10",
        "distribution_area_desc": "",
        "street": "הוז דב",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "יובל מיני מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 07:30-19:45, שישי 07:30-14:30",
        "mesirot_yn": "y",
        "longitude": "34.87595782"
    },
    {
        "n_code": "4718",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.088608",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "929",
        "house": "18",
        "distribution_area_desc": "",
        "street": "ח\\\"ן",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "יופי של מכולת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "שעות חלוקה א'-ה' 6:30-10:00  14:30-18:15, שישי 6:30-13:00",
        "mesirot_yn": "y",
        "longitude": "34.872514"
    },
    {
        "n_code": "4947",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.098847",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "485",
        "house": "9",
        "distribution_area_desc": "",
        "street": "גרין יונה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מינימרקט רמת אלחנן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-18:00",
        "mesirot_yn": "y",
        "longitude": "34.898220"
    },
    {
        "n_code": "9007",
        "city": "פתח תקווה",
        "line": "",
        "latitude": "32.08948",
        "distribution_area": "",
        "city_code": "7900",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0018",
        "house": "23",
        "distribution_area_desc": "",
        "street": "*ההגנה*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס פ\\\"ת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 08:30-13:00 14:00-19:00 שישי 08:30-12:00",
        "mesirot_yn": "y",
        "longitude": "34.88544"
    },
    {
        "n_code": "4090",
        "city": "צובה",
        "line": "",
        "latitude": "31.783440",
        "distribution_area": "",
        "city_code": "465",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "צובה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מרכולית צובה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-16 ו 8-12",
        "mesirot_yn": "y",
        "longitude": "35.118688"
    },
    {
        "n_code": "4368",
        "city": "צומת ראם",
        "line": "",
        "latitude": "31.757326",
        "distribution_area": "",
        "city_code": "9985",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "צומת ראם",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "ד.כ אדירם לבניין וכליו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "מאחורי מקדונלד  א-ה 8:00-17:00 ו 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.783688"
    },
    {
        "n_code": "3078",
        "city": "צור הדסה",
        "line": "",
        "latitude": "31.720502",
        "distribution_area": "",
        "city_code": "1113",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "161",
        "house": "1",
        "distribution_area_desc": "",
        "street": "סנונית",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עוצמה לבית ולבניין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-17:30 ו' 8:00-13:30",
        "mesirot_yn": "y",
        "longitude": "35.093211"
    },
    {
        "n_code": "4088",
        "city": "צור יגאל",
        "line": "",
        "latitude": "32.217534",
        "distribution_area": "",
        "city_code": "1306",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "x002",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מצפה ספיר",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סוכרית המזל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-23 ו 8:30-15 ז 13-23 מרכז מסחרי מצפה ספיר מתחת לשופרסל דיל",
        "mesirot_yn": "y",
        "longitude": "34.984311"
    },
    {
        "n_code": "4108",
        "city": "צור יצחק",
        "line": "",
        "latitude": "32.239341",
        "distribution_area": "",
        "city_code": "1345",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "101",
        "house": "19",
        "distribution_area_desc": "",
        "street": "נחל אלכסנדר",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "Home 360",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מרכז מסחרי א-ה 9-19 ו 8-14",
        "mesirot_yn": "y",
        "longitude": "35.000100"
    },
    {
        "n_code": "4111",
        "city": "צור יצחק",
        "line": "",
        "latitude": "32.239341",
        "distribution_area": "",
        "city_code": "1345",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "101",
        "house": "19",
        "distribution_area_desc": "",
        "street": "נחל אלכסנדר",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סיאל משקאות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11-21 ו 10-14",
        "mesirot_yn": "y",
        "longitude": "35.000100"
    },
    {
        "n_code": "4412",
        "city": "צור משה",
        "line": "",
        "latitude": "32.298420",
        "distribution_area": "",
        "city_code": "276",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "104",
        "house": "5",
        "distribution_area_desc": "",
        "street": "השיבולים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "כלבו טכני לב השרון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-18:00 ו 7:00-14:00 מול תחנת דלק אלונית",
        "mesirot_yn": "y",
        "longitude": "34.913131"
    },
    {
        "n_code": "3264",
        "city": "צוריאל",
        "line": "",
        "latitude": "33.006889",
        "distribution_area": "",
        "city_code": "774",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "20",
        "distribution_area_desc": "",
        "street": "צוריאל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרקט צוריאל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-19:00 ו' 6:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.31337"
    },
    {
        "n_code": "3749",
        "city": "צפת",
        "line": "",
        "latitude": "32.96575",
        "distribution_area": "",
        "city_code": "8000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "215",
        "house": "12",
        "distribution_area_desc": "",
        "street": "עליה ב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "אקסטרה קופי (ליד שם הורד)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-21:00 ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "35.49611"
    },
    {
        "n_code": "1866",
        "city": "צפת",
        "line": "",
        "latitude": "32.968585",
        "distribution_area": "",
        "city_code": "8000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1955",
        "house": "39",
        "distribution_area_desc": "",
        "street": "ירושלים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ויצמן מחשבים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א ב ד ה 9-13:30 16-19 ג+ו' 9-13:30",
        "mesirot_yn": "y",
        "longitude": "35.493519"
    },
    {
        "n_code": "1833",
        "city": "צפת",
        "line": "",
        "latitude": "32.964015",
        "distribution_area": "",
        "city_code": "8000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1955",
        "house": "118",
        "distribution_area_desc": "",
        "street": "ירושלים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "כוכב התקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א ב ד ה 9-19, ג 9-14 ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.494376"
    },
    {
        "n_code": "4891",
        "city": "צפת",
        "line": "",
        "latitude": "32.967311",
        "distribution_area": "",
        "city_code": "8000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "405",
        "house": "328",
        "distribution_area_desc": "",
        "street": "ההסתדרות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת השכונה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-20:00 שישי 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.504264"
    },
    {
        "n_code": "4491",
        "city": "צפת",
        "line": "",
        "latitude": "32.962366",
        "distribution_area": "",
        "city_code": "8000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "603",
        "house": "11",
        "distribution_area_desc": "",
        "street": "החרמון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סבן מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-17:00 שישי 9:0012:00",
        "mesirot_yn": "y",
        "longitude": "35.513167"
    },
    {
        "n_code": "4583",
        "city": "צפת",
        "line": "",
        "latitude": "32.957983",
        "distribution_area": "",
        "city_code": "8000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "191c",
        "house": "20",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קישורית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-17:00 שישי 10:00-12:30",
        "mesirot_yn": "y",
        "longitude": "35.496106"
    },
    {
        "n_code": "3343",
        "city": "קדומים",
        "line": "",
        "latitude": "32.208719",
        "distribution_area": "",
        "city_code": "3557",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "139",
        "house": "3",
        "distribution_area_desc": "",
        "street": "היצירה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "נטו שירות- טמבוריה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה  7:30-18:00 ו' 7:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.156911"
    },
    {
        "n_code": "4048",
        "city": "קדימה-צורן",
        "line": "",
        "latitude": "32.287798",
        "distribution_area": "",
        "city_code": "195",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3120",
        "house": "41",
        "distribution_area_desc": "",
        "street": "דרך לב השרון צורן",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פיצוציית הזהב",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-20 ו' 8:30-13",
        "mesirot_yn": "y",
        "longitude": "34.945240"
    },
    {
        "n_code": "4479",
        "city": "קדימה-צורן",
        "line": "",
        "latitude": "32.269508",
        "distribution_area": "",
        "city_code": "195",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "207",
        "house": "2",
        "distribution_area_desc": "",
        "street": "החסידה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "קנדי לי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-21:00 שישי 8:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.917178"
    },
    {
        "n_code": "4766",
        "city": "קדש ברנע",
        "line": "",
        "latitude": "30.90379756401429",
        "distribution_area": "",
        "city_code": "9920",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "קדש ברנע",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "צרכניית קדש ברנע",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 ועד 7 בערב יום העצמאות 4.5.22 החנות פתוחה עד השעה 16 .שישי וערבי חג אין פעילות",
        "mesirot_yn": "y",
        "longitude": "34.39636220"
    },
    {
        "n_code": "3235",
        "city": "קלנסווה",
        "line": "",
        "latitude": "32.283663",
        "distribution_area": "",
        "city_code": "638",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "קלנסווה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "ורדה פון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 15:00-21:00 ימי שישי סגור (ורדה פון ב WAZE)",
        "mesirot_yn": "y",
        "longitude": "34.978933"
    },
    {
        "n_code": "4783",
        "city": "קלנסווה",
        "line": "",
        "latitude": "32.286837",
        "distribution_area": "",
        "city_code": "638",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "קלנסווה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "חמודי תקשורת",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "אל חסן בן עלי א-ה 8:30-23:30 שישי 13:00-19:00 שבת 8:30-23:30",
        "mesirot_yn": "y",
        "longitude": "34.982787"
    },
    {
        "n_code": "1878",
        "city": "קצרין",
        "line": "",
        "latitude": "32.995514",
        "distribution_area": "",
        "city_code": "4100",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "139",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שיאון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סמארטפיקס smartfix",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-17:00 ו'  9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.683498"
    },
    {
        "n_code": "4482",
        "city": "קצרין",
        "line": "",
        "latitude": "32.98706156454951",
        "distribution_area": "",
        "city_code": "4100",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "158",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ברנע",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "על גלגלים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 9:0014:00 ליד תחנת דלק פז - גוגל מפות - https://goo.gl/maps/ecgG9XzZskXayqjXA",
        "mesirot_yn": "y",
        "longitude": "35.71075584"
    },
    {
        "n_code": "4561",
        "city": "קרית אונו",
        "line": "",
        "latitude": "32.062579",
        "distribution_area": "",
        "city_code": "2620",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "167",
        "house": "20",
        "distribution_area_desc": "",
        "street": "מונטיפיורי",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "מרטלו אספקה טכנית",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-18:00 שישי 9:00-12:00. עקב חג הפסח מקום סגור עד 24.04",
        "mesirot_yn": "y",
        "longitude": "34.860707"
    },
    {
        "n_code": "4646",
        "city": "קרית אונו",
        "line": "",
        "latitude": "32.053824",
        "distribution_area": "",
        "city_code": "2620",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "168",
        "house": "6",
        "distribution_area_desc": "",
        "street": "שלמה המלך",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "פוסט שופ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.858782"
    },
    {
        "n_code": "2071",
        "city": "קרית אונו",
        "line": "",
        "latitude": "32.049634",
        "distribution_area": "",
        "city_code": "2620",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "211",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הבטחון",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "שפע משה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-20:30  ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "34.861451"
    },
    {
        "n_code": "1709",
        "city": "קרית ארבע",
        "line": "",
        "latitude": "31.530018",
        "distribution_area": "",
        "city_code": "3611",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "110",
        "house": "7",
        "distribution_area_desc": "",
        "street": "בן יפונה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "המרכז לספר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:0019:00 ו' 9:30-12:30",
        "mesirot_yn": "y",
        "longitude": "35.117995"
    },
    {
        "n_code": "3418",
        "city": "קרית ביאליק",
        "line": "",
        "latitude": "32.833197",
        "distribution_area": "",
        "city_code": "9500",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "184",
        "house": "1",
        "distribution_area_desc": "",
        "street": "ניצנים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מתוק בניצנים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-22 ו' 7-18",
        "mesirot_yn": "y",
        "longitude": "35.085740"
    },
    {
        "n_code": "4749",
        "city": "קרית ביאליק",
        "line": "",
        "latitude": "32.84535555875432",
        "distribution_area": "",
        "city_code": "9500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "119",
        "house": "192",
        "distribution_area_desc": "",
        "street": "דרך עכו",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "שופ אנד דרופ הקריון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:00 בתוך הקריון קומת קרקע ליד מקדונלדס0522697456",
        "mesirot_yn": "y",
        "longitude": "35.09193038"
    },
    {
        "n_code": "2928",
        "city": "קרית גת",
        "line": "",
        "latitude": "31.601437",
        "distribution_area": "",
        "city_code": "2630",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "125",
        "house": "179",
        "distribution_area_desc": "",
        "street": "שד העצמאות",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מינימרקט דניאל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-13 16-20 ג'-6-14 ו' 6-14",
        "mesirot_yn": "y",
        "longitude": "34.763888"
    },
    {
        "n_code": "4557",
        "city": "קרית חיים",
        "line": "",
        "latitude": "32.818529",
        "distribution_area": "",
        "city_code": "4991",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "3206",
        "house": "20",
        "distribution_area_desc": "",
        "street": "בן צבי יצחק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "החמניה קרית חיים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-22:00 שישי 12:00-16:00",
        "mesirot_yn": "y",
        "longitude": "35.068072"
    },
    {
        "n_code": "2039",
        "city": "קרית טבעון",
        "line": "",
        "latitude": "32.723997",
        "distribution_area": "",
        "city_code": "2300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0631",
        "house": "2",
        "distribution_area_desc": "",
        "street": "השקדים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ספיר יצחק- ציוד משרדי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א ב ד ה 7-13   16-19  ג' 7-13  ו'6-14",
        "mesirot_yn": "y",
        "longitude": "35.133373"
    },
    {
        "n_code": "4314",
        "city": "קרית יערים",
        "line": "",
        "latitude": "31.803254",
        "distribution_area": "",
        "city_code": "1137",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "105",
        "house": "2",
        "distribution_area_desc": "",
        "street": "הגר\\\"א",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "ברכת יוסף",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00  עד  22:00",
        "mesirot_yn": "y",
        "longitude": "35.100417"
    },
    {
        "n_code": "4558",
        "city": "קרית מוצקין",
        "line": "",
        "latitude": "32.829902",
        "distribution_area": "",
        "city_code": "8200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "25",
        "distribution_area_desc": "",
        "street": "שד קרן קימת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "החמניה קרית מוצקין",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-20:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.075359"
    },
    {
        "n_code": "3957",
        "city": "קרית מוצקין",
        "line": "",
        "latitude": "32.826237",
        "distribution_area": "",
        "city_code": "8200",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "102",
        "house": "40",
        "distribution_area_desc": "",
        "street": "הרב קוק",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "לאקי דוג",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-14 16-19 ו' 9-15",
        "mesirot_yn": "y",
        "longitude": "35.074663"
    },
    {
        "n_code": "1587",
        "city": "קרית מוצקין",
        "line": "",
        "latitude": "32.833479",
        "distribution_area": "",
        "city_code": "8200",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "133",
        "house": "10",
        "distribution_area_desc": "",
        "street": "שד ויצמן",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט רפי ורבקה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "‏א ב ד ה 7:30-20 ג' 7:30-14:00 ו' 7:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.075764"
    },
    {
        "n_code": "4773",
        "city": "קרית מלאכי",
        "line": "",
        "latitude": "31.733695",
        "distribution_area": "",
        "city_code": "1034",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "186",
        "house": "8",
        "distribution_area_desc": "",
        "street": "חלוצי התעשיה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "האסקי מתנות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-18:00 ו 8:00-14:30",
        "mesirot_yn": "y",
        "longitude": "34.755406"
    },
    {
        "n_code": "4634",
        "city": "קרית מלאכי",
        "line": "",
        "latitude": "31.736252487101034",
        "distribution_area": "",
        "city_code": "1034",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "0248",
        "house": "56",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "המפגש של סימו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-22:00 שישי 10:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.7403617"
    },
    {
        "n_code": "4069",
        "city": "קרית מלאכי",
        "line": "",
        "latitude": "31.730857",
        "distribution_area": "",
        "city_code": "1034",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "110",
        "house": "1",
        "distribution_area_desc": "",
        "street": "בגין מנחם",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן קרית מלאכי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.753186"
    },
    {
        "n_code": "3568",
        "city": "קרית נטפים",
        "line": "",
        "latitude": "32.117315004003636",
        "distribution_area": "",
        "city_code": "3746",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "קרית נטפים",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "א.י חומרי בניין קרית נטפים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ג 7:30-19:00 ד-ה 7:30-19:30 שישי 7:30-13:30 טל 035379669",
        "mesirot_yn": "y",
        "longitude": "35.1129077"
    },
    {
        "n_code": "4920",
        "city": "קרית עקרון",
        "line": "",
        "latitude": "31.860052",
        "distribution_area": "",
        "city_code": "469",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "135",
        "house": "34",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "בסט פיקס",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-18:30 שישי 10:30-13:00",
        "mesirot_yn": "y",
        "longitude": "34.822959"
    },
    {
        "n_code": "1014",
        "city": "קרית שמונה",
        "line": "",
        "latitude": "33.22110772",
        "distribution_area": "",
        "city_code": "2800",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "117",
        "house": "6",
        "distribution_area_desc": "",
        "street": "מלול יעקב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "YNS מחשבים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 09-17 ו' 09-14",
        "mesirot_yn": "y",
        "longitude": "35.57138583"
    },
    {
        "n_code": "4441",
        "city": "קרית שמונה",
        "line": "",
        "latitude": "33.207598",
        "distribution_area": "",
        "city_code": "2800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "410",
        "house": "100",
        "distribution_area_desc": "",
        "street": "שד תל חי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הסטוק קריית שמונה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 16:00-21:00 ו 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.569784"
    },
    {
        "n_code": "4340",
        "city": "קרית שמונה",
        "line": "",
        "latitude": "33.215952",
        "distribution_area": "",
        "city_code": "2800",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "202",
        "house": "12",
        "distribution_area_desc": "",
        "street": "יהודה הלוי",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט בן סימון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6-20 ו 6-15",
        "mesirot_yn": "y",
        "longitude": "35.568612"
    },
    {
        "n_code": "2234",
        "city": "קרית שמונה",
        "line": "",
        "latitude": "33.208800",
        "distribution_area": "",
        "city_code": "2800",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "201",
        "house": "5",
        "distribution_area_desc": "",
        "street": "ארלוזורוב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "רהיטי הצפון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ב,ג,ה  9:00  12:30 - 16:30 19  ד, ו- 9 12:30",
        "mesirot_yn": "y",
        "longitude": "35.568610"
    },
    {
        "n_code": "4855",
        "city": "קרני שומרון",
        "line": "",
        "latitude": "32.173044862518296",
        "distribution_area": "",
        "city_code": "3640",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "129",
        "house": "1",
        "distribution_area_desc": "",
        "street": "החרוב",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "בי מובייל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.0905424"
    },
    {
        "n_code": "4418",
        "city": "קרני שומרון",
        "line": "",
        "latitude": "32.175114",
        "distribution_area": "",
        "city_code": "3640",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "128",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שדרות רחבעם",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "לצאת מהכלים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "קניון קרני שומרון א-ה 8:30-20:00 ו 8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.091204"
    },
    {
        "n_code": "3582",
        "city": "קשת",
        "line": "",
        "latitude": "32.98047",
        "distribution_area": "",
        "city_code": "4006",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "קשת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "קשת דיגיטל -גולן (בתאום מראש)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה8:00-17:00 ו'8|:00-12:00 0524251625 בתיאום.",
        "mesirot_yn": "y",
        "longitude": "35.80948"
    },
    {
        "n_code": "3729",
        "city": "ראמה",
        "line": "",
        "latitude": "32.93703",
        "distribution_area": "",
        "city_code": "543",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ראמה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "סופר זול עידן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-16:00-23:00 ב-ו 8:00-23:00",
        "mesirot_yn": "y",
        "longitude": "35.36783"
    },
    {
        "n_code": "9014",
        "city": "ראש העין",
        "line": "",
        "latitude": "32.093435",
        "distribution_area": "",
        "city_code": "2640",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0025",
        "house": "1",
        "distribution_area_desc": "",
        "street": "*שלמה מעודה*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס ראש העין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה החל מ 10 בבוקר ועד 7 בערב, שישי וערבי חג 10-14 חגים - אין פעילות",
        "mesirot_yn": "y",
        "longitude": "34.945914"
    },
    {
        "n_code": "4437",
        "city": "ראש פינה",
        "line": "",
        "latitude": "32.969734",
        "distribution_area": "",
        "city_code": "26",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "168",
        "house": "999",
        "distribution_area_desc": "",
        "street": "האגס",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הסטוק ראש פינה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "מרכז שופינה א-ה 16:00-21:00 ו 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "35.550875"
    },
    {
        "n_code": "3958",
        "city": "ראש פינה",
        "line": "",
        "latitude": "32.968019",
        "distribution_area": "",
        "city_code": "26",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "103",
        "house": "999",
        "distribution_area_desc": "",
        "street": "דרך הגליל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "עגולה מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "פתוח 24/7",
        "mesirot_yn": "y",
        "longitude": "35.548339"
    },
    {
        "n_code": "4720",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.963201",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "201",
        "house": "18",
        "distribution_area_desc": "",
        "street": "ז'בוטינסקי",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "DIgital Store",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.800262"
    },
    {
        "n_code": "4740",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.967383",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "768",
        "house": "12",
        "distribution_area_desc": "",
        "street": "ההתיישבות",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "אותנטי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א'-ה' 9:30-19:00, ו' 7:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.824508"
    },
    {
        "n_code": "4710",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.996001",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "134",
        "house": "27",
        "distribution_area_desc": "",
        "street": "לישנסקי יוסף",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "הסיטונאים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 9:00-13:30 בהגעה לכתובת להכנס לחניון הגדול בצד ימין של הבניין ולהכנס יותר פנימה,צמודים לסוכנות רכבים",
        "mesirot_yn": "y",
        "longitude": "34.765845"
    },
    {
        "n_code": "2334",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.957827",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "412",
        "house": "46",
        "distribution_area_desc": "",
        "street": "ויניק",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "ווינר בר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-19 ו' 9-14 שבת 18-21",
        "mesirot_yn": "y",
        "longitude": "34.793436"
    },
    {
        "n_code": "4765",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.992893449190664",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "134",
        "house": "20",
        "distribution_area_desc": "",
        "street": "לישנסקי יוסף",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "מאפיית שיפון",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.7679452"
    },
    {
        "n_code": "4895",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.977663832395248",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1273",
        "house": "10",
        "distribution_area_desc": "",
        "street": "השירה העברית",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "מרכז מסירה קארדו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 14:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.7695775"
    },
    {
        "n_code": "3021",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.974207",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "174",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הרותם",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "מרקט אור",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7:00-18:00 ו' 7:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.784688"
    },
    {
        "n_code": "3331",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.986848",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "131",
        "house": "6",
        "distribution_area_desc": "",
        "street": "החלוץ",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "סופר אלין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-17 ו' 9-12",
        "mesirot_yn": "y",
        "longitude": "34.788884"
    },
    {
        "n_code": "3669",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.99270",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "134",
        "house": "18",
        "distribution_area_desc": "",
        "street": "לישנסקי יוסף",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "פוסט שופ ראשל\\\"צ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 ו' 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.76729"
    },
    {
        "n_code": "3802",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.96427",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1841",
        "house": "68",
        "distribution_area_desc": "",
        "street": "רוטשילד",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "פלאש פון",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה9:00-20:00 ו'9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.79772"
    },
    {
        "n_code": "9009",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.969586",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0007",
        "house": "37",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צי'טה שופס ראשל\\\"צ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 בבוקר ועד 7 בערב, שישי וערבי חג 10-14 חגים - אין פעילות",
        "mesirot_yn": "y",
        "longitude": "34.804993"
    },
    {
        "n_code": "4621",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.969485",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1829",
        "house": "13",
        "distribution_area_desc": "",
        "street": "אוסישקין",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "שפע מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.803398"
    },
    {
        "n_code": "4923",
        "city": "ראשון לציון",
        "line": "",
        "latitude": "31.958811",
        "distribution_area": "",
        "city_code": "8300",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "281",
        "house": "30",
        "distribution_area_desc": "",
        "street": "הירשפלד",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "תאתי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-22:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.796681"
    },
    {
        "n_code": "4493",
        "city": "רבבה",
        "line": "",
        "latitude": "32.120821",
        "distribution_area": "",
        "city_code": "3795",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רבבה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "עגיב שילוח",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א,ג,ה 7:30-19:00 ב,ד 7:30-17:00 שישי 08:30-12:30",
        "mesirot_yn": "y",
        "longitude": "35.129443"
    },
    {
        "n_code": "3451",
        "city": "רביבים",
        "line": "",
        "latitude": "31.051975",
        "distribution_area": "",
        "city_code": "354",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "רביבים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "כולבולית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה8:00-18:00 ו'8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.737366"
    },
    {
        "n_code": "2788",
        "city": "רגבה",
        "line": "",
        "latitude": "32.975499",
        "distribution_area": "",
        "city_code": "390",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רגבה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ניצת הדובדבן ביג רגבה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9-21  ו' 9-15  מוצ''ש: שעה אחרי צאת שבת עד 22",
        "mesirot_yn": "y",
        "longitude": "35.094409"
    },
    {
        "n_code": "3762",
        "city": "רהט",
        "line": "",
        "latitude": "31.39348",
        "distribution_area": "",
        "city_code": "1161",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "289",
        "house": "73",
        "distribution_area_desc": "",
        "street": "אלסלאם",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "אומגה פארם",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו' 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.75160"
    },
    {
        "n_code": "2585",
        "city": "רוויה",
        "line": "",
        "latitude": "32.449668",
        "distribution_area": "",
        "city_code": "2016",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רוויה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מכולת קארין",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-13:30 16-20 ו' 6-15",
        "mesirot_yn": "y",
        "longitude": "35.472718"
    },
    {
        "n_code": "2593",
        "city": "רוחמה",
        "line": "",
        "latitude": "31.497671",
        "distribution_area": "",
        "city_code": "362",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רוחמה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מרכול מנשה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א ב ד ה 7-14  16-19  ג' 7-14   ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "34.705144"
    },
    {
        "n_code": "3535",
        "city": "רחובות",
        "line": "",
        "latitude": "31.902420239027492",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1656",
        "house": "226",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "אמיר בעיר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-20:0 ו'8:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.8088316"
    },
    {
        "n_code": "3853",
        "city": "רחובות",
        "line": "",
        "latitude": "31.885953",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "626",
        "house": "32",
        "distribution_area_desc": "",
        "street": "זק\\\"ש אפרים",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "היפר שופ",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11-19 ו' 9-13",
        "mesirot_yn": "y",
        "longitude": "34.812145"
    },
    {
        "n_code": "1077",
        "city": "רחובות",
        "line": "",
        "latitude": "31.88128217",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "375",
        "house": "110",
        "distribution_area_desc": "",
        "street": "ששת הימים",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "המקום של שירקן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:00-20:00 ו' 8:00 - 12",
        "mesirot_yn": "y",
        "longitude": "34.78887773"
    },
    {
        "n_code": "4903",
        "city": "רחובות",
        "line": "",
        "latitude": "31.8901856469583",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1656",
        "house": "122",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "הרצל חד פעמי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 12:00-19:00,  שישי 09:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.813106941"
    },
    {
        "n_code": "3079",
        "city": "רחובות",
        "line": "",
        "latitude": "31.880092",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "683",
        "house": "1",
        "distribution_area_desc": "",
        "street": "בן דוד פנחס",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "לצאת מהכלים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-20:00 ו' 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.812654"
    },
    {
        "n_code": "4028",
        "city": "רחובות",
        "line": "",
        "latitude": "31.908363",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "285",
        "house": "43",
        "distribution_area_desc": "",
        "street": "בשיסט מרדכי",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "סופר נווה אמיתי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7-19 ו' 8-14:30",
        "mesirot_yn": "y",
        "longitude": "34.798199"
    },
    {
        "n_code": "4123",
        "city": "רחובות",
        "line": "",
        "latitude": "31.893800",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "335",
        "house": "23",
        "distribution_area_desc": "",
        "street": "דניאלי עמוס",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "שי לי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:00-19:00 שישי 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.800318"
    },
    {
        "n_code": "4714",
        "city": "רחובות",
        "line": "",
        "latitude": "31.894509",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "447",
        "house": "13",
        "distribution_area_desc": "",
        "street": "נורדאו",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "שפע ברכה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.815106"
    },
    {
        "n_code": "2596",
        "city": "רחובות",
        "line": "",
        "latitude": "31.884291",
        "distribution_area": "",
        "city_code": "8400",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1656",
        "house": "31",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "תן חיוך  (יש חניה צמודה)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11:00-18:00",
        "mesirot_yn": "y",
        "longitude": "34.815919"
    },
    {
        "n_code": "2270",
        "city": "רחלים",
        "line": "",
        "latitude": "32.103405",
        "distribution_area": "",
        "city_code": "3822",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רחלים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר רחלים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 11:00-13:00 17:00-19:00  שישי 9:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.257777"
    },
    {
        "n_code": "2793",
        "city": "ריחאניה",
        "line": "",
        "latitude": "33.049405",
        "distribution_area": "",
        "city_code": "540",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ריחאניה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "טי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ו 8:00-22:00",
        "mesirot_yn": "y",
        "longitude": "35.487255"
    },
    {
        "n_code": "3081",
        "city": "ריינה",
        "line": "",
        "latitude": "32.720156",
        "distribution_area": "",
        "city_code": "542",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "5",
        "distribution_area_desc": "",
        "street": "ריינה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חומרי בניין  אבו חאלד 0502025541",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ש 8:00-17:00 שים לב!!!! לא ניתן לאסוף חבילה מעבר לשעות הפעילות (ליד בנק מרכנתיל) לינק לוויז - https://waze.com/ul?a=sh",
        "mesirot_yn": "y",
        "longitude": "35.311624"
    },
    {
        "n_code": "4498",
        "city": "ריינה",
        "line": "",
        "latitude": "32.723328",
        "distribution_area": "",
        "city_code": "542",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ריינה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מגה קפה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "כביש ראשי, ב-ז 10:00-21:00, א סגור",
        "mesirot_yn": "y",
        "longitude": "35.317427"
    },
    {
        "n_code": "3818",
        "city": "ריינה",
        "line": "",
        "latitude": "32.712913",
        "distribution_area": "",
        "city_code": "542",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "ריינה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מספרת עבד טחבש S&N",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ש' 9:00-19:00 כתובת גוגל מפות https://goo.gl/maps/6EGangW6E8eJzT8G8 נייד: 0527812080",
        "mesirot_yn": "y",
        "longitude": "35.307114"
    },
    {
        "n_code": "2352",
        "city": "רכסים",
        "line": "",
        "latitude": "32.758552",
        "distribution_area": "",
        "city_code": "922",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "d5d1",
        "house": "999",
        "distribution_area_desc": "",
        "street": "א.ת. רכסים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "הכל למסיבה-רכסים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-20  ו' 8:30-14 איזור תעשייה רכסים בכביש לאיבטין.",
        "mesirot_yn": "y",
        "longitude": "35.097361"
    },
    {
        "n_code": "4444",
        "city": "רמות",
        "line": "",
        "latitude": "32.852228",
        "distribution_area": "",
        "city_code": "4702",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "dfgd",
        "house": "35",
        "distribution_area_desc": "",
        "street": "דליות",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "צרכנית רמות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-20:00 ו 6:0016:00 ע.חג 6:30-14:00  0534803373",
        "mesirot_yn": "y",
        "longitude": "35.664369"
    },
    {
        "n_code": "1435",
        "city": "רמות מאיר",
        "line": "",
        "latitude": "31.86894466",
        "distribution_area": "",
        "city_code": "735",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רמות מאיר",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "מינימרקט רמות מאיר",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 7-17 ו' 7-12",
        "mesirot_yn": "y",
        "longitude": "34.86103553"
    },
    {
        "n_code": "4578",
        "city": "רמלה",
        "line": "",
        "latitude": "31.934321",
        "distribution_area": "",
        "city_code": "8500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "415",
        "house": "2",
        "distribution_area_desc": "",
        "street": "צה\\\"ל",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "סטוק 4 יו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-19:00 שיש 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.858609"
    },
    {
        "n_code": "4064",
        "city": "רמלה",
        "line": "",
        "latitude": "31.932038",
        "distribution_area": "",
        "city_code": "8500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "112",
        "house": "10",
        "distribution_area_desc": "",
        "street": "היצירה",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "סלקשן רמלה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.881616"
    },
    {
        "n_code": "3921",
        "city": "רמלה",
        "line": "",
        "latitude": "31.934265",
        "distribution_area": "",
        "city_code": "8500",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "364",
        "house": "31",
        "distribution_area_desc": "",
        "street": "יוספטל גיורא",
        "line_desc": "",
        "sorting_area_code": "  2",
        "name": "קיוסק בשדרה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-23  ו'10-14",
        "mesirot_yn": "y",
        "longitude": "34.861553"
    },
    {
        "n_code": "1163",
        "city": "רמת אפעל",
        "line": "",
        "latitude": "32.045894",
        "distribution_area": "",
        "city_code": "1049",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "xo43",
        "house": "2",
        "distribution_area_desc": "",
        "street": "החוגה",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "סנופי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 9-20  ו' 7-15",
        "mesirot_yn": "y",
        "longitude": "34.834806"
    },
    {
        "n_code": "4500",
        "city": "רמת גן",
        "line": "",
        "latitude": "32.085732",
        "distribution_area": "",
        "city_code": "8600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "802",
        "house": "67",
        "distribution_area_desc": "",
        "street": "הרצל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "גלידות אייסי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00-20:00 שישי 10:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.815131"
    },
    {
        "n_code": "9008",
        "city": "רמת גן",
        "line": "",
        "latitude": "32.076438",
        "distribution_area": "",
        "city_code": "8600",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0007",
        "house": "22",
        "distribution_area_desc": "",
        "street": "*לוחמי סיני*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס רמת גן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.824866"
    },
    {
        "n_code": "9006",
        "city": "רמת השרון",
        "line": "",
        "latitude": "32.145070",
        "distribution_area": "",
        "city_code": "2650",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0001",
        "house": "63",
        "distribution_area_desc": "",
        "street": "*סוקולוב*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס רמה\\\"ש",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.838027"
    },
    {
        "n_code": "4885",
        "city": "רמת ישי",
        "line": "",
        "latitude": "32.70556834460713",
        "distribution_area": "",
        "city_code": "122",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "127",
        "house": "36",
        "distribution_area_desc": "",
        "street": "מעלה הרדוף",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "החמניה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-20:00",
        "mesirot_yn": "y",
        "longitude": "35.15807794"
    },
    {
        "n_code": "4390",
        "city": "רמת ישי",
        "line": "",
        "latitude": "32.705782",
        "distribution_area": "",
        "city_code": "122",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "118",
        "house": "1",
        "distribution_area_desc": "",
        "street": "הדקל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חברים על 4",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-19:00 שישי 10:00-15:00",
        "mesirot_yn": "y",
        "longitude": "35.172575"
    },
    {
        "n_code": "4768",
        "city": "רמת ישי",
        "line": "",
        "latitude": "32.706202",
        "distribution_area": "",
        "city_code": "122",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "158",
        "house": "22",
        "distribution_area_desc": "",
        "street": "הצפצפה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "פלסטלינה אומנות היצירה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:30-19:30 שישי 8:30-14:30",
        "mesirot_yn": "y",
        "longitude": "35.174342"
    },
    {
        "n_code": "2614",
        "city": "רמת צבי",
        "line": "",
        "latitude": "32.591844",
        "distribution_area": "",
        "city_code": "339",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רמת צבי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "צרכניית רמת צבי",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6:30-14  15:30-19:30  ו' 6:30-15  ערבי חג 6:30-14",
        "mesirot_yn": "y",
        "longitude": "35.415033"
    },
    {
        "n_code": "3915",
        "city": "רעננה",
        "line": "",
        "latitude": "32.197629",
        "distribution_area": "",
        "city_code": "8700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6000",
        "house": "9999",
        "distribution_area_desc": "",
        "street": "קניון רננים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "הסטוק -רעננה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 16:00-21:00 שישי 10:00-13:00 קניון רננים קומה 0",
        "mesirot_yn": "y",
        "longitude": "34.878215"
    },
    {
        "n_code": "3039",
        "city": "רעננה",
        "line": "",
        "latitude": "32.178355",
        "distribution_area": "",
        "city_code": "8700",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "229",
        "house": "36",
        "distribution_area_desc": "",
        "street": "אוסטרובסקי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "זו ארץ זו רעננה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א,ב,ד,ה 10:00-13:30  16:00-19:30  ג- 10:00-13:30  ו- 10:00-14:30",
        "mesirot_yn": "y",
        "longitude": "34.870964"
    },
    {
        "n_code": "3672",
        "city": "רעננה",
        "line": "",
        "latitude": "32.19850",
        "distribution_area": "",
        "city_code": "8700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "360",
        "house": "2",
        "distribution_area_desc": "",
        "street": "המלאכה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "פופסי רננים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה 10:00 - 21:30 ו' 9:30-15:00  ש' 20-23. קומה 1, כיכר מרכזית ליד ארוקה",
        "mesirot_yn": "y",
        "longitude": "34.88050"
    },
    {
        "n_code": "4793",
        "city": "רעננה",
        "line": "",
        "latitude": "32.179890",
        "distribution_area": "",
        "city_code": "8700",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "227",
        "house": "5",
        "distribution_area_desc": "",
        "street": "ז'בוטינסקי",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "רגעים מתוקים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 ו 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.874196"
    },
    {
        "n_code": "1509",
        "city": "רקפת",
        "line": "",
        "latitude": "32.85603654",
        "distribution_area": "",
        "city_code": "1228",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "רקפת",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מינימרקט רקפת",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א - ה 7:00 עד 21:00 ו 6:00 עד 16:00",
        "mesirot_yn": "y",
        "longitude": "35.26404406"
    },
    {
        "n_code": "4120",
        "city": "רשפ עלי אקספרס",
        "line": "",
        "latitude": "",
        "distribution_area": "",
        "city_code": "30025",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0002",
        "house": "6",
        "distribution_area_desc": "",
        "street": "אל אימאם מוסלים",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "Package נקודת דואר רש\\\"פ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:30-18",
        "mesirot_yn": "y",
        "longitude": ""
    },
    {
        "n_code": "3286",
        "city": "רתמים",
        "line": "",
        "latitude": "31.053808",
        "distribution_area": "",
        "city_code": "1260",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "רתמים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מזכירות רתמים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים ראשון שלישי וחמישי 15:30 - 18:30",
        "mesirot_yn": "y",
        "longitude": "34.690145"
    },
    {
        "n_code": "2056",
        "city": "שבי ציון",
        "line": "",
        "latitude": "32.981992",
        "distribution_area": "",
        "city_code": "282",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "101",
        "house": "3",
        "distribution_area_desc": "",
        "street": "דרך הים",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "רן אופן",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ה 10-18  ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.084459"
    },
    {
        "n_code": "4875",
        "city": "שבי שומרון",
        "line": "",
        "latitude": "32.26197055252307",
        "distribution_area": "",
        "city_code": "3571",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שבי שומרון",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "תיקון נמרץ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-20:00 שישי 8:00-14:00 בתאום מראש 0528112128",
        "mesirot_yn": "y",
        "longitude": "35.18433037"
    },
    {
        "n_code": "4772",
        "city": "שדה אליהו",
        "line": "",
        "latitude": "32.439528",
        "distribution_area": "",
        "city_code": "304",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שדה אליהו",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "כלבו שדה אליהו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:00-18:00 ה 7:00-19:00 שישי 7:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.514308"
    },
    {
        "n_code": "2246",
        "city": "שדה יעקב",
        "line": "",
        "latitude": "32.697473",
        "distribution_area": "",
        "city_code": "142",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "2",
        "distribution_area_desc": "",
        "street": "שדה יעקב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מסורת בעמק",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10-13 16:30-19 ו' 9-13 דרך האלון 2",
        "mesirot_yn": "y",
        "longitude": "35.141257"
    },
    {
        "n_code": "4050",
        "city": "שדמות מחולה",
        "line": "",
        "latitude": "32.348129",
        "distribution_area": "",
        "city_code": "3578",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שדמות מחולה",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "שי לכל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 15:00-21:00 שישי 10-14 עד השעה 15:00 איסוף בתאום מראש בלבד 0546277638",
        "mesirot_yn": "y",
        "longitude": "35.530477"
    },
    {
        "n_code": "3885",
        "city": "שדרות",
        "line": "",
        "latitude": "31.526707",
        "distribution_area": "",
        "city_code": "1031",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "191",
        "house": "2",
        "distribution_area_desc": "",
        "street": "דיין משה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "גורדוס",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.604980"
    },
    {
        "n_code": "1436",
        "city": "שדרות",
        "line": "",
        "latitude": "31.53132293",
        "distribution_area": "",
        "city_code": "1031",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "263",
        "house": "4",
        "distribution_area_desc": "",
        "street": "גולומב",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "מינימרקט גבריאל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-22 ו' 6-16",
        "mesirot_yn": "y",
        "longitude": "34.58796078"
    },
    {
        "n_code": "4040",
        "city": "שדרות",
        "line": "",
        "latitude": "31.544573",
        "distribution_area": "",
        "city_code": "1031",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "tqwr",
        "house": "999",
        "distribution_area_desc": "",
        "street": "מתחם מול 7",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סלקשן - שדרות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 11:00 19:00 הפסקה 15:25 16:00 יום ו' 9:00 13:00",
        "mesirot_yn": "y",
        "longitude": "34.590257"
    },
    {
        "n_code": "1438",
        "city": "שואבה",
        "line": "",
        "latitude": "31.79853487",
        "distribution_area": "",
        "city_code": "741",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שואבה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר מיני",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-22 ו' 6-14",
        "mesirot_yn": "y",
        "longitude": "35.07792655"
    },
    {
        "n_code": "3473",
        "city": "שוהם",
        "line": "",
        "latitude": "32.005580",
        "distribution_area": "",
        "city_code": "1304",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "195",
        "house": "161",
        "distribution_area_desc": "",
        "street": "שד עמק איילון",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "גלידות פלדמן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:30-18:30 שישי 9:00-13:30 איסוף החבילה 3 שעות לאחר קבלת הSMS",
        "mesirot_yn": "y",
        "longitude": "34.946625"
    },
    {
        "n_code": "3132",
        "city": "שוהם",
        "line": "",
        "latitude": "32.006564",
        "distribution_area": "",
        "city_code": "1304",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "202",
        "house": "30",
        "distribution_area_desc": "",
        "street": "דקל",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "פוסט שופ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 ו 9:00-13:00",
        "mesirot_yn": "y",
        "longitude": "34.951200"
    },
    {
        "n_code": "3421",
        "city": "שומרה",
        "line": "",
        "latitude": "33.084121",
        "distribution_area": "",
        "city_code": "614",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שומרה",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ביסטרו שומרה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-24",
        "mesirot_yn": "y",
        "longitude": "35.285502"
    },
    {
        "n_code": "4741",
        "city": "שומריה",
        "line": "",
        "latitude": "31.432790",
        "distribution_area": "",
        "city_code": "1265",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "1",
        "distribution_area_desc": "",
        "street": "שומריה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "משרד שומריה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א,ב,ד,ה 8:00-15:00 שישי 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.884116"
    },
    {
        "n_code": "4222",
        "city": "שזור",
        "line": "",
        "latitude": "32.934359",
        "distribution_area": "",
        "city_code": "527",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שזור",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מפגש אביחי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8-21 ו 8-16",
        "mesirot_yn": "y",
        "longitude": "35.350700"
    },
    {
        "n_code": "2093",
        "city": "שייח' דנון",
        "line": "",
        "latitude": "32.993422",
        "distribution_area": "",
        "city_code": "658",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שייח' דנון",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מכולת הכפר דנון050-8345888",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ש 9-23",
        "mesirot_yn": "y",
        "longitude": "35.148709"
    },
    {
        "n_code": "1986",
        "city": "שילה",
        "line": "",
        "latitude": "32.052484",
        "distribution_area": "",
        "city_code": "3641",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שילה",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "סופר מנחם ירקניית הגוש א.ת. שילה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ד 8-21 ה' 8-23 ו' 7-14",
        "mesirot_yn": "y",
        "longitude": "35.292973"
    },
    {
        "n_code": "4225",
        "city": "שילת",
        "line": "",
        "latitude": "31.915990",
        "distribution_area": "",
        "city_code": "1165",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "29",
        "distribution_area_desc": "",
        "street": "שילת",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "עולם הממתקים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-17:30 ו 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "35.025605"
    },
    {
        "n_code": "1602",
        "city": "שלוחות",
        "line": "",
        "latitude": "32.47183466",
        "distribution_area": "",
        "city_code": "439",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שלוחות",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "מרכולית שלוחות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה 6:30-19 ו' 6:30-12",
        "mesirot_yn": "y",
        "longitude": "35.48087153"
    },
    {
        "n_code": "3973",
        "city": "שלומי",
        "line": "",
        "latitude": "33.071733",
        "distribution_area": "",
        "city_code": "812",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "107",
        "house": "282",
        "distribution_area_desc": "",
        "street": "הרב חזן מסעוד",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "זול סטוק שלומי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10-19 ו' 9-14",
        "mesirot_yn": "y",
        "longitude": "35.146881"
    },
    {
        "n_code": "3004",
        "city": "שלומי",
        "line": "",
        "latitude": "33.073100",
        "distribution_area": "",
        "city_code": "812",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "104",
        "house": "43",
        "distribution_area_desc": "",
        "street": "הרב עוזיאל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "חיות וחלומות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-20:00 ו' 9:00-14:30",
        "mesirot_yn": "y",
        "longitude": "35.142513"
    },
    {
        "n_code": "4705",
        "city": "שמעה",
        "line": "",
        "latitude": "31.387353063971105",
        "distribution_area": "",
        "city_code": "3784",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "24",
        "distribution_area_desc": "",
        "street": "שמעה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "גפן חשמל",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 08:00-20:00 שישי 08:00-12:00  0529288902",
        "mesirot_yn": "y",
        "longitude": "35.0126962"
    },
    {
        "n_code": "4391",
        "city": "שעב",
        "line": "",
        "latitude": "32.891540",
        "distribution_area": "",
        "city_code": "538",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שעב",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "תחנת דלק ב.ס.ן",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "",
        "mesirot_yn": "y",
        "longitude": "35.234584"
    },
    {
        "n_code": "1811",
        "city": "שעל",
        "line": "",
        "latitude": "33.117238",
        "distribution_area": "",
        "city_code": "4009",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "שעל",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מים בשעל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 17:0-22:00  ו' 10-13:00",
        "mesirot_yn": "y",
        "longitude": "35.717655"
    },
    {
        "n_code": "1672",
        "city": "שער בנימין",
        "line": "",
        "latitude": "31.865693",
        "distribution_area": "",
        "city_code": "7002",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "99",
        "distribution_area_desc": "",
        "street": "שער בנימין",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "מאפיית שיפון-אזת\\\"ע שער בנימין",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 6:30-21:00 שישי 6:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.262312"
    },
    {
        "n_code": "2972",
        "city": "שפיים",
        "line": "",
        "latitude": "32.219712",
        "distribution_area": "",
        "city_code": "232",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "x112",
        "house": "1",
        "distribution_area_desc": "",
        "street": "חוצות שפיים",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "זו ארץ זו (פאוור סנטר)",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 9:30-20:00 ו' 9:00-15:00",
        "mesirot_yn": "y",
        "longitude": "34.828662"
    },
    {
        "n_code": "1852",
        "city": "שפרעם",
        "line": "",
        "latitude": "32.813632",
        "distribution_area": "",
        "city_code": "8800",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "fj78",
        "house": "999",
        "distribution_area_desc": "",
        "street": "בנק מסד כ.מערבית",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "ביג סייד - BIG SIDE ליד בנק מסד כניסה מערבית",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ב-ש 10-20",
        "mesirot_yn": "y",
        "longitude": "35.157344"
    },
    {
        "n_code": "1597",
        "city": "שריגים (לי-און)",
        "line": "",
        "latitude": "31.67732137",
        "distribution_area": "",
        "city_code": "1114",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "15",
        "distribution_area_desc": "",
        "street": "שריגים (לי-און)",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "פלוס מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "‏א-ה6:30-21 ו' 6:30-14",
        "mesirot_yn": "y",
        "longitude": "34.93535408"
    },
    {
        "n_code": "4597",
        "city": "שריד",
        "line": "",
        "latitude": "32.66329008869502",
        "distribution_area": "",
        "city_code": "126",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "117",
        "house": "1",
        "distribution_area_desc": "",
        "street": "מעיין",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מוסך שריד",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 7:30-17:00 שישי 7:30-13:00",
        "mesirot_yn": "y",
        "longitude": "35.22495774"
    },
    {
        "n_code": "4277",
        "city": "תימורים",
        "line": "",
        "latitude": "31.719068",
        "distribution_area": "",
        "city_code": "163",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "תימורים",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "Car point",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "סדש תימורים א-ה 8-17:30 ו 8-13",
        "mesirot_yn": "y",
        "longitude": "34.755382"
    },
    {
        "n_code": "3406",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.107501",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "360",
        "house": "11",
        "distribution_area_desc": "",
        "street": "הברזל",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "D.N.A",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-19:00 ו' 10:00-14:00 בעקבות חג הפסח התכנו שינויים בשעות הפעילות",
        "mesirot_yn": "y",
        "longitude": "34.836564"
    },
    {
        "n_code": "4938",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.067091",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1265",
        "house": "6",
        "distribution_area_desc": "",
        "street": "מזא\\\"ה",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "MAZEH KIOSK",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:00-19:00 שישי 10:00-16:00",
        "mesirot_yn": "y",
        "longitude": "34.772055"
    },
    {
        "n_code": "4110",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.071125",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "2579",
        "house": "10",
        "distribution_area_desc": "",
        "street": "האומנים",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "To bake",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9-18 ו 9-12",
        "mesirot_yn": "y",
        "longitude": "34.795119"
    },
    {
        "n_code": "3626",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.06077",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1303",
        "house": "2",
        "distribution_area_desc": "",
        "street": "סמ בית הבד",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "אופנת ג'ין ווסט",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 11-17.",
        "mesirot_yn": "y",
        "longitude": "34.77007"
    },
    {
        "n_code": "3356",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.052880",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "2373",
        "house": "8",
        "distribution_area_desc": "",
        "street": "בר יוחאי",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "ברכת רפאל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8-18 ו' 8-12",
        "mesirot_yn": "y",
        "longitude": "34.765832"
    },
    {
        "n_code": "4665",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.124606",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "310",
        "house": "35",
        "distribution_area_desc": "",
        "street": "עיר שמש",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "חיים בצהלה",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 10:00-20:00",
        "mesirot_yn": "y",
        "longitude": "34.825964"
    },
    {
        "n_code": "4393",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.039745",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "1935",
        "house": "29",
        "distribution_area_desc": "",
        "street": "שד' חב\\\"ד",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "מינימרקט אבו עומר",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה 9:00-12:00 ומ 17:00-23:00 שישי 8:00-12:30 שבת 10:00-23:00  מקום יהיה סגור מ07.07 עד 14.07",
        "mesirot_yn": "y",
        "longitude": "34.753357"
    },
    {
        "n_code": "1172",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.04925355",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "2306",
        "house": "15",
        "distribution_area_desc": "",
        "street": "ישראל מסלנט",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "מכולת ניסים",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 06-20  ו' 6:30-16",
        "mesirot_yn": "y",
        "longitude": "34.78114144"
    },
    {
        "n_code": "4805",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.043577",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "2412",
        "house": "74",
        "distribution_area_desc": "",
        "street": "דרך בן צבי",
        "line_desc": "",
        "sorting_area_code": "  1",
        "name": "סיטי מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 8:00-20:00 שישי 8:00-12:00",
        "mesirot_yn": "y",
        "longitude": "34.769771"
    },
    {
        "n_code": "9000",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.071654",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0043",
        "house": "5",
        "distribution_area_desc": "",
        "street": "*אבן גבירול*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס אבן גבירול",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.781725"
    },
    {
        "n_code": "9001",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.116582",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0042",
        "house": "12",
        "distribution_area_desc": "",
        "street": "*אמיר גלבוע*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס אמיר גלבוע",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.787679"
    },
    {
        "n_code": "9003",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.095091",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0041",
        "house": "41",
        "distribution_area_desc": "",
        "street": "*ירמיהו*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צ'יטה שופס ירמיהו",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "ימים א-ה החל מ 12 בצהריים ועד 7 בערב (שישי, ערבי חג וחגים אין פעילות)",
        "mesirot_yn": "y",
        "longitude": "34.777441"
    },
    {
        "n_code": "9012",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.05402402867952",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "#0046",
        "house": "36",
        "distribution_area_desc": "",
        "street": "*נגבה*",
        "line_desc": "",
        "sorting_area_code": " 11",
        "name": "צי'טה שופס יד אליהו",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "ימים א-ה החל מ 10 בבוקר ועד 7 בערב, שישי וערבי חג 10-14 חגים - אין פעילות",
        "mesirot_yn": "y",
        "longitude": "34.79921710"
    },
    {
        "n_code": "4926",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.109269",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "236",
        "house": "18",
        "distribution_area_desc": "",
        "street": "הדר יוסף",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "קונפטי כלים חד פעמי",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-18 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.818691"
    },
    {
        "n_code": "4929",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.110892076556695",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "364",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הארד",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "שופ מרקט",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 6:00-17:00",
        "mesirot_yn": "y",
        "longitude": "34.8414022"
    },
    {
        "n_code": "3061",
        "city": "תל אביב - יפו",
        "line": "",
        "latitude": "32.110055",
        "distribution_area": "",
        "city_code": "5000",
        "type": "חנות",
        "ahzarot_yn": "y",
        "street_code": "365",
        "house": "3",
        "distribution_area_desc": "",
        "street": "הנחושת",
        "line_desc": "",
        "sorting_area_code": "  7",
        "name": "שוק החיים הטובים",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:30-18:00 ו' 8:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.838489"
    },
    {
        "n_code": "4275",
        "city": "תל מונד",
        "line": "",
        "latitude": "32.258221",
        "distribution_area": "",
        "city_code": "154",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "117",
        "house": "2",
        "distribution_area_desc": "",
        "street": "השקד",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "בית הבובות",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 9:30-13:30  16:00-19:00  שישי  9:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.918494"
    },
    {
        "n_code": "3180",
        "city": "תל מונד",
        "line": "",
        "latitude": "32.262137",
        "distribution_area": "",
        "city_code": "154",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "125",
        "house": "8",
        "distribution_area_desc": "",
        "street": "דרך בני דרור",
        "line_desc": "",
        "sorting_area_code": "  8",
        "name": "סאג' אלפי כלים בע\\\"מ",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה8:00-17:00  ו' 8:30-14:00",
        "mesirot_yn": "y",
        "longitude": "34.917904"
    },
    {
        "n_code": "4327",
        "city": "תל קציר",
        "line": "",
        "latitude": "32.706735",
        "distribution_area": "",
        "city_code": "719",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "תל קציר",
        "line_desc": "",
        "sorting_area_code": "  3",
        "name": "מרכול התל",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 6-20 ו 6-14",
        "mesirot_yn": "y",
        "longitude": "35.616348"
    },
    {
        "n_code": "4834",
        "city": "תל שבע",
        "line": "",
        "latitude": "31.240026",
        "distribution_area": "",
        "city_code": "1054",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "6712",
        "house": "172",
        "distribution_area_desc": "",
        "street": "שכ 4",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "גלורי פארם",
        "negishut_lebaaley_mugbaluyot_yn": "y",
        "remarks": "א-ה 10:00-14:00  16:00-19:00 שישי 9:00-14:00",
        "mesirot_yn": "y",
        "longitude": "34.864344"
    },
    {
        "n_code": "2955",
        "city": "תקומה",
        "line": "",
        "latitude": "31.450694",
        "distribution_area": "",
        "city_code": "665",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "9000",
        "house": "999",
        "distribution_area_desc": "",
        "street": "תקומה",
        "line_desc": "",
        "sorting_area_code": "  5",
        "name": "סופר תקומה",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 8:14 15-20 ו' 8-13",
        "mesirot_yn": "y",
        "longitude": "34.579000"
    },
    {
        "n_code": "3828",
        "city": "תקוע",
        "line": "",
        "latitude": "31.641943",
        "distribution_area": "",
        "city_code": "3563",
        "type": "חנות",
        "ahzarot_yn": "n",
        "street_code": "xxx1",
        "house": "534",
        "distribution_area_desc": "",
        "street": "תכלת מרדכי",
        "line_desc": "",
        "sorting_area_code": " 10",
        "name": "אוצרות",
        "negishut_lebaaley_mugbaluyot_yn": "n",
        "remarks": "א-ה 10:00-14:00 19:00-21:00",
        "mesirot_yn": "y",
        "longitude": "35.231012"
    }
]