/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Box } from '@mui/material';
import React, { useState } from 'react';
import './ContactForm.scss';
import { TextField, MultilineField,SelectField } from '../../style-guide';
import ActionButton from '../Common/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { addContactUs, validateEmail, validatePhone } from '../../reducers/requestHandler';
import Loader from '../../style-guide/Loader';
const dummyData = {
    fullName: '',
    emailAddress: '',
    phoneNumber: '',
    requestSubject: 1,
    referralContent: ''
};

const ContactForm = ({ setIsDialog }) => {
    const [subjects, setSubjects] = useState([{ name: "כללי", id: 1 }, { name: "משלוח", id:2 }, { name: "תשלום", id: 3}]);
    const [loadingScreen, setloadingScreen] = useState(false);
    const [data, setData] = useState(dummyData);
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const { userInfo, innerContent } = useSelector(state => state.user);
    const addContactData = () => {
        if (data.fullName == undefined || data.fullName.trim().length == 0
            || data.emailAddress == undefined || data.emailAddress.trim().length == 0
            || data.phoneNumber == undefined || data.phoneNumber.trim().length == 0
            || data.requestSubject == undefined || subjects.find(u => u.id === data.requestSubject)?.name?.trim().length == 0
            || data.referralContent == undefined || data.referralContent.trim().length == 0

        ) {
            dispatch(snackbarToggle({ type: 'error', message: `Please enter all required fields` }));
            return
        }

        const postData = {
            "sessionId": userInfo.session,
            "userId": userInfo.user.id,
            "fullName": data.fullName,
            "email": data.emailAddress,
            "phone": data.phoneNumber,
            "subject": subjects.find(u => u.id === data.requestSubject)?.name,
            "content": data.referralContent
        }
        setloadingScreen(true)
        addContactUs(postData, function (response) {
            setIsDialog(false);
            setloadingScreen(false)
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: `פנייתך התקבלה ונציגנו יחזור אליך בהקדם` }));
            } if (response.misc.CODE === 2) {
                dispatch(snackbarToggle({ type: 'error', message: `שליחה נכשלה , אפשר להעביר את ההודעה דרך מייל,WhatsApp` }));
            }
        });
    }

    return (<Box container className="reg_popup_data">
        <Box item lg={12} className="popup_tittle">
            {`השאירו את פנייתכם
ונחזור אליכם בהקדם`}
        </Box>
        {loadingScreen && <Loader />}
        <Box>
            <TextField
                className='text-field'
                required
                value={data.fullName}
                onChange={event => setData(prev => ({ ...prev, fullName: event.target.value }))}
                label='שם מלא'
                dir={'rtl'}
            />
            <TextField
                className='text-field'
                required
                value={data.emailAddress}
                onChange={event => setData(prev => ({ ...prev, emailAddress: event.target.value }))}
                onBlur={event => {
                    if (!validateEmail(data.emailAddress)) {
                        dispatch(snackbarToggle({ type: 'error', message: `כתובת דואר לא תקינה` }));
                    }
                }}
                label='כתובת אימייל'
                dir={'rtl'}
               
            />
            <TextField
                className='text-field'
                required
                value={data.phoneNumber}
                onChange={event => setData(prev => ({ ...prev, phoneNumber: event.target.value }))}
                label="מספר טלפון נייד"
                dir={'rtl'}
                type="phone"
                onBlur={event => {
                    if (!validatePhone(data.phoneNumber)) {
                       // dispatch(snackbarToggle({ type: 'error', message: ` מספר טלפון לא תקין` }));
                    }
                }}
            />
            {!validatePhone(data.phoneNumber) && data.phoneNumber && <Box className='col-md-12' style={{ direction: "rtl", color: "red" }}>
                מספר טלפון לא תקין
            </Box>
            }
            {/*<TextField
                className='text-field'
                required
                value={data.requestSubject}
                onChange={event => setData(prev => ({ ...prev, requestSubject: event.target.value }))}
                label='נושא הפניה'
                dir={'rtl'}
            />*/}
            <SelectField
                className='text-field'
                required
                value={data.requestSubject}
                lg={3}
                isNumeric={true}
                onChange={event => setData(prev => ({ ...prev, requestSubject: event.target.value }))}
                options={subjects}
                islabelAnimate={false}
            />
            <MultilineField
                className='text-field'
                required
                rows={4}
                value={data.referralContent}
                onChange={event => setData(prev => ({ ...prev, referralContent: event.target.value }))}
                label='תוכן הפניה ( 500 תווים)'
                dir={'rtl'}
            />
            <Box className='form-action'>
                <ActionButton disabled={data?.referralContent?.length>500|| !validateEmail(data.emailAddress) || !validatePhone(data.phoneNumber)} type={'submit'} className={'submit-action'} textLabel={'חזרו אלי'} onClick={() => { addContactData() }} />
            </Box>
        </Box>
    </Box>)
}

export default ContactForm;