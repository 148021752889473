import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import './UsRetailers.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage, onRefreshTrick } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import retailersHero from '../../images/retailers/retailers-hero.jpg'
import amazon from '../../images/retailers/amazon.jpg'
import macys from '../../images/retailers/macys.jpg'
import nike from '../../images/retailers/nike.jpg'
import etsy from '../../images/retailers/etsy.jpg'
import oshkosh from '../../images/retailers/oshkosh.jpg'
import rockauto from '../../images/retailers/rockauto.jpg'
import jcrew from '../../images/retailers/jcrew.jpg'
import tjmaxx from '../../images/retailers/tjmaxx.jpg'
import pm6 from '../../images/retailers/6pm.jpg'
import zulily from '../../images/retailers/zulily.jpg'
import batworks from '../../images/retailers/batworks.jpg'
import sephora from '../../images/retailers/sephora.jpg'
import walmart from '../../images/retailers/walmart.jpg'
import levis from '../../images/retailers/levis.jpg'
import funko from '../../images/retailers/funko.jpg'
import ebay from '../../images/retailers/ebay.jpg'
import victoria from '../../images/retailers/victoria.jpg'
import gap from '../../images/retailers/gap.jpg'
import zappos from '../../images/retailers/zappos.jpg'
import carters from '../../images/retailers/carters.jpg'
import newegg from '../../images/retailers/newegg.jpg'
import nordstrom from '../../images/retailers/nordstrom.jpg'
import ipsy from '../../images/retailers/ipsy.jpg'
import colourpop from '../../images/retailers/colourpop.jpg'


export const UsRetailers = (props) => {
    const { user, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));


    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const goTo = (e) => {
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {

        }
    }
    const [bLoaded, setBloaded] = new useState(false);
    useEffect(() => {
        if (!bLoaded) {
            onRefreshTrick();
            setBloaded(true)
        }
    }, [user]);
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div className="page-title" style={{ backgroundImage: `url(${retailersHero})` }}>
                <div className="title-section">
                    <div>

                        <div className="container">
                            <h1 className="entry-title ">
                                Under process...  <div>
                                    {false &&<a className="btn show-register" onClick={function () { goTo('How It Works', 'How it Works', '/register.html') }}>
                                        Join for Free </a>
                                    }
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            {false && <main id="main-content">
                <div className="region region-content">
                    <div id="block-system-main" className="block block-system">
                        <div className="content">
                            <div about="/en/us-retailers" typeof="foaf:Document"
                                className="ds-2col-stacked node node-page node-full view-mode-full clearfix">
                                <div className="group-header">
                                    <div style={{ backgroundColor: "#e4eaea", padding: "50px 0" }}>
                                        <div className="container">
                                            <h2 style={{ textAlign: "center", marginBottom: "30px", fontWeight: "bold" }}>Shop from popular USA retailers
                                            </h2>
                                            <p className="rtecenter">redbox ships to more than 190 countries. redbox's customers are all over the world,
                                                from Saudi Arabia, the United Kingdom, Oman, and Kuwait to the UAE, Australia, Qatar, Japan, and more!
                                            </p>
                                            <p className="rtecenter">Here are the best online shopping websites in the U.S. Popular brands that you will
                                                love!</p>
                                            <ul className="retailers">
                                                <li><a target="_blank" href="https://www.amazon.com/">
                                                    <img alt="Shop from popular USA retailers like Amazon" src={amazon} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Amazon</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.macys.com/">
                                                    <img alt="Shop from popular USA retailers like Macy's" src={macys} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Macy's</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.nike.com/">
                                                    <img alt="Shop from popular USA retailers like Nike" src={nike} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Nike</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.etsy.com/">
                                                    <img alt="Shop from popular USA retailers like Etsy" src={etsy} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Etsy</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.oshkosh.com/">
                                                    <img alt="Shop from popular USA retailers like Osh Kosh" src={oshkosh} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Osh Kosh</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.rockauto.com/">
                                                    <img alt="Shop from popular USA retailers like RockAuto.com" src={rockauto} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">RockAuto.com</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.jcrew.com/">
                                                    <img
                                                        alt="Shop from popular USA retailers like Crew" src={jcrew} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">J.Crew</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://tjmaxx.tjx.com/">
                                                    <img alt="Shop from popular USA retailers like T.J.Maxx" src={tjmaxx} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">T.J.Maxx</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.6pm.com/">
                                                    <img alt="Shop from popular USA retailers like 6pm" src={pm6} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">6pm</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.zulily.com/">
                                                    <img alt="Shop from popular USA retailers like Zulily" src={zulily} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Zulily</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.bathandbodyworks.com/">
                                                    <img alt="Shop from popular USA retailers like Bath &amp; Body Works" src={batworks} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Bath &amp; Body Works</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.bathandbodyworks.com/">
                                                    <img alt="Shop from popular USA retailers like Sephora" src={sephora} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Sephora</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://colourpop.com/"><img
                                                    alt="Shop from popular USA retailers like ColourPop" src={colourpop} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">ColourPop</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.walmart.com/">
                                                    <img alt="Shop from popular USA retailers like Walmart" src={walmart} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Walmart</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.levi.com/US/en_US/">
                                                    <img alt="Shop from popular USA retailers like Levi's" src={levis} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Levi's</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.funko.com/">
                                                    <img alt="Shop from popular USA retailers like Funko" src={funko} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Funko</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.ebay.com/">
                                                    <img alt="Shop from popular USA retailers like eBay" src={ebay} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">eBay</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.victoriassecret.com/">
                                                    <img alt="Shop from popular USA retailers like Victoria's Secret" src={victoria} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Victoria's Secret</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.gap.com/">
                                                    <img alt="Shop from popular USA retailers like Gap" src={gap} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Gap</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.zappos.com/">
                                                    <img alt="Shop from popular USA retailers like Zappos" src={zappos} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Zappos</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.carters.com/">
                                                    <img alt="Shop from popular USA retailers like Carter's" src={carters} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Carter's</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.newegg.com/">
                                                    <img alt="Shop from popular USA retailers like Newegg" src={newegg} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Newegg</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://shop.nordstrom.com/">
                                                    <img alt="Shop from popular USA retailers like Nordstrom" src={nordstrom} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">Nordstrom</div>
                                                    </div>
                                                </a></li>
                                                <li><a target="_blank" href="https://www.ipsy.com/">
                                                    <img alt="Shop from popular USA retailers like Ipsy" src={ipsy} />
                                                    <div className="retailer-text">Shop at
                                                        <div className="retailer-name">IPSY</div>
                                                    </div>
                                                </a></li>
                                            </ul>
                                            <h2 style={{ textAlign: "center", marginBottom: "30px", fontWeight: "bold" }}>ِAnd many more!</h2>
                                            <div style={{ textAlign: "center" }}><a className="btn show-register" data-target="#register_modal_holder"
                                                data-toggle="modal" onClick={function () { goTo('How It Works', 'How it Works', '/register.html') }}>Join redbox</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-left">
                                </div>
                                <div className="group-right">
                                </div>
                                <div className="group-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>}
        </Paper>
    );
}

export default UsRetailers;