import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import './HowItWorks.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import logo from '../../images/homepage/logo.png'
import account from '../../images/homepage/account.png'
import account_white from '../../images/homepage/account_white.png'
import background from '../../images/title/how-it-works.jpg'
import step1_img from '../../images/step1_img.jpg'
import step2_img from '../../images/step2_img.jpg'


export const HowItWorks = (props) => {
    const { user, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));


    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const goTo = (e) => {
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {

        }
    }
    useEffect(() => {
    }, [user]);
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div className="page-title" style={{ backgroundImage: `url(${background})` }}>
                <div className="title-section">
                    <div>
                        
                        <div className="container">
                            <h1 className="entry-title ">
                               Under process... <div>
                                    {false && <a className="btn show-register" onClick={function () { goTo('How It Works', 'How it Works', '/register.html') }}>
                                        Join for Free </a>
                                    }
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            {false && <main id="main-content">
                <div className="region region-content">
                    <div id="block-system-main" className="block block-system">
                        <div className="content">
                            <div about="/en/how-it-works" typeof="foaf:Document"
                                className="ds-2col-stacked node node-page node-full view-mode-full clearfix">
                                <div className="group-header">

                                    <div className="content-section alternate">
                                        <div className="container narrow">
                                            <div className="row">
                                                <div className="col-xs-12" style={{ textAlign: "center" }}>
                                                    <h2>Shipping Simplified</h2>
                                                </div>
                                            </div>
                                            <div className="row" style={{ fontSize: "17px" }}>
                                                <p>Red Box Parcel's unique shipping services open up the possibility for you to buy products abroad and enjoy all the worlds (literally).</p>
                                                <p>All you have to do is register on the site for free (here), get a local address in the US or Europe, and enter it in the product order. The products you purchased will arrive in our warehouses abroad, be picked up by skilled staff and shipped to Israel according to Payment arrangement).</p>
                                            </div>


                                            <div className="col-xs-12 new_redbox_info">
                                                <p><strong><b>Your benefits</b></strong></p>
                                                <p><b>Easily buy from a long list of attractive and quality websites</b> in Europe and the USA, both at low prices from the country and brands that do not come to Israel at all.</p>
                                                <p><b>Receive final price in full transparency.</b>No surprises and no small print.</p>
                                                <p><b>Shipping costs are calculated </b>with gentle weighing in jumps of only 100 g.</p>
                                                <p><b>Choose the delivery that suits you</b> - collection point or delivery to your home.</p>
                                                <p><b>Customer service</b> - availability by e-mail and email with a quick response.</p>
                                                <p><b>Easily track</b> your package at any given moment.</p>
                                            </div>

                                            <div className="col-xs-12 new_redbox_info">
                                                <p><strong><b>Free registration and receiving a local address abroad</b></strong></p>
                                                <p>Sign up for the Red Box Parcel website for free - click here. Get a personal local address in the US or Europe (as needed). The ID number is private and secure for your use only.</p>
                                            </div>


                                            <div className="col-xs-12 new_redbox_info">
                                                <p><strong><b>Enter the local address on the relevant website</b></strong></p>
                                                <p>Buy on sites abroad and place an order while entering the local address you received from Red Box Parcel at the time of purchase. Feel free to enter the list of recommended sites in the link below.</p>
                                                <p>After ordering, enter the purchase invoice you received in the personal area on the Red Box Parcel website and you will be informed that your order has been received in the system.</p>
                                            </div>


                                            <div className="col-xs-12 new_redbox_info">
                                                <p><strong><b>The arrival of the products in our warehouses, taxation, and payment arrangements</b></strong></p>
                                                <p>The products arrive at the local address abroad in one of our warehouses in Europe and the USA. Payment request for package release will be sent after measurement and weighing by our skilled and professional team. Includes calculation of tax costs (click on our price and taxation calculator).</p>
                                            </div>


                                            <div className="col-xs-12 new_redbox_info">
                                                <p><strong><b>Receiving the package at your home</b></strong></p>
                                                <p>Upon payment, the package leaves for Israel and we take care of customs clearance and distribution with a courier (to the collection point or to the doorstep).</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-section alternate text-xs-center"><a className="btn show-register"
                                    data-target="#register_modal_holder" data-toggle="modal" href="#">Sign Up Now</a></div>
                            </div>

                            <div className="group-left">
                            </div>

                            <div className="group-right">
                            </div>

                            <div className="group-footer">
                            </div>

                        </div>
                    </div>
                </div>
            </main>}
        </Paper>
    );
}

export default HowItWorks;