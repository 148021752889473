/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useState, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { TextField } from '../../style-guide';
import './Registration.scss';
import { setCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession } from '../../reducers/user-reducers/UserSlicer';
import { snackbarToggle, snackbarAutoClose } from '../../reducers/snackbarSlicer';
import { removeCardToken, saveProfile, validateMyId, validatePhone, validateEmail, validateEngText, copyToClipboard1, activateRegister, listWarehouse } from '../../reducers/requestHandler';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import { autoCloseSnackbar } from '../../constants/global-constants';
import ActionButton from '../Common/ActionButton';
import OTPInput from 'otp-input-react';
import CloseIcon from '@mui/icons-material/Close';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import copy from '../../images/copy.png'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from '../../style-guide/Loader';
import { Dialog } from '../../style-guide';


const screens = ['Registeration', 'Password', 'OTPVerify', 'PersonalData'];
const countries = [
    { id: 1, val: "ארה”ב", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
    { id: 2, val: "מערב אירופה", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
    { id: 3, val: "פולין", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
    { id: 4, val: "טורקיה", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
];

const data = {
    otp: '',
    nickName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    hpAuth: '',
    isCoupon: false,
    isTnc: false,
    countryCode: '',
    addressLine_1: '',
    addressLine_2: '',
    state: '',
    city: '',
    postal: '',
    profileId: '',
}

export const Profile = ({ isProfile, setIsProfile, doPing, setIsAddAddress }) => {
    const [value, setValue] = useState(data);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(1)
    const [openRemoveCCDialog, setOpenRemoveCCDialog] = useState(false)
    const { userInfo, innerContent } = useSelector(state => state.user);
    const closeRemoveCreditCardToken = () => {
        doPing();
        setOpenRemoveCCDialog(false);
    }
    const removeCreditCardToken = () => {
        setOpenRemoveCCDialog(true);
    }
    const removeCreditCardToken1 = () => {
        let data = {
            sessionId: userInfo.session,
            opcode:"removeCardToken"
        }
        removeCardToken(data, function (data) {
            closeRemoveCreditCardToken();
        });
    }
    const saveProfileData = () => {
        let data = {
            sessionId: userInfo.session,
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phone: value.phone,
            setting: {
                ...userInfo.user.setting,
                profileId: value.profileId,
                isCouponAndMail: value.isCoupon
            }
,
        }
        saveProfile(data, function (response) {
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: "Profile updated" }));
                setIsProfile(false)
            } else {
                dispatch(snackbarToggle({ type: 'error', message: response.message }));
            }
            doPing();

        })
    }

    
    useEffect(() => {
        setValue(prev => ({ ...prev, firstName: userInfo.user.firstName }))
        setValue(prev => ({ ...prev, lastName: userInfo.user.lastName }))
        setValue(prev => ({ ...prev, email: userInfo.user.email }))
        setValue(prev => ({ ...prev, phone: userInfo.user.phone }))
        setValue(prev => ({ ...prev, profileId: userInfo.user.setting?.profileId }))
        setValue(prev => ({ ...prev, isCoupon: userInfo.user.setting?.isCouponAndMail }))
    }, [dispatch]);
    
    const [isLoading, setIsLoading] = new useState(false);
    

    console.log('data', data);

   
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [isEye, setIsEye] = new useState(true);
    const [isConfirmEye, setIsConfirmEye] = new useState(true);
    return (
        
        <DialogWithSideBar
            open={isProfile}
            isOutClose={true}
            onClose={() => { setIsProfile(false);}}
        >
            <Fragment>
                {isLoading && <Loader isFullDisplay />}
                {openRemoveCCDialog && (
                    <Dialog maxWidth='sm' open={openRemoveCCDialog} title={``} onHandleCancel={closeRemoveCreditCardToken}>

                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            נתונים אלו ימחקו <br />
                            ? האם להמשיך
                        </Grid>
                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                                removeCreditCardToken1()
                            }}>מחק</Button>
                            <Button sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} variant='contained' onClick={closeRemoveCreditCardToken}> בטל</Button>
                        </Grid>
                    </Dialog>
                )}
                <Box className="reg_popup_data">
                    <Box className="popup_tittle">
                        פרטים אישיים
                    </Box>
                    <Box className='row' >
                        <Box className='col-md-12'>
                            <TextField
                                className='text-field-disabled'
                                required
                                tabIndex={"3"}
                                dir={'rtl'}
                                value={value.email}
                                onChange={event => setValue(prev => ({ ...prev, email: event.target.value }))}
                                label='כתובת אימייל'
                                onBlur={event => {
                                    if (!validateEmail(value.email)) {
                                        dispatch(snackbarToggle({ type: 'error', message: `כתובת דואר לא תקינה` }));
                                    }
                                }}
                                readOnly={true}
                            />
                        </Box>
                        {!isMobile() && <Box className='col-md-12' style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <Box className='col-md-6' >
                                <TextField
                                    className='text-field'
                                    required
                                    dir={'rtl'}
                                    tabIndex={"1"}
                                    value={value.firstName}
                                    onChange={event => setValue(prev => ({ ...prev, firstName: event.target.value }))}
                                    label='שם פרטי באנגלית'
                                    onBlur={validateEngText(value.firstName)}
                                />
                                {!validateEngText(value.firstName) && value.firstName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                    Enter English text only
                                </Box>
                                }
                            </Box>
                            <Box className='col-md-6' >
                                <TextField
                                    className='text-field'
                                    required
                                    dir={'rtl'}
                                    tabIndex={"2"}
                                    value={value.lastName}
                                    onChange={event => setValue(prev => ({ ...prev, lastName: event.target.value }))}
                                    label='שם משפחה באנגלית'
                                    onBlur={validateEngText(value.lastName)}
                                />
                                {!validateEngText(value.lastName) && value.lastName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                    Enter English text only
                                </Box>
                                }
                            </Box>

                        </Box>
                        }
                        {isMobile() && <Box className='col-md-6' >
                            <TextField
                                className='text-field'
                                required
                                dir={'rtl'}
                                tabIndex={"1"}
                                value={value.firstName}
                                onChange={event => setValue(prev => ({ ...prev, firstName: event.target.value }))}
                                label='שם פרטי באנגלית'
                                onBlur={validateEngText(value.firstName)}
                            />
                            {!validateEngText(value.firstName) && value.firstName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                Enter English text only
                            </Box>
                            }
                        </Box>}
                        {isMobile() && <Box className='col-md-6' tabIndex={2}>
                            <TextField
                                className='text-field'
                                required
                                dir={'rtl'}
                                value={value.lastName}
                                onChange={event => setValue(prev => ({ ...prev, lastName: event.target.value }))}
                                label='שם משפחה באנגלית'
                                onBlur={validateEngText(value.lastName)}
                            />
                            {!validateEngText(value.lastName) && value.lastName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                Enter English text only
                            </Box>
                            }
                        </Box>}
                        
                        <Box className='col-md-12'>
                            <TextField
                                className='text-field'
                                required
                                dir={'rtl'}
                                tabIndex={"4"}
                                value={value.phone}
                                onChange={event => setValue(prev => ({ ...prev, phone: event.target.value }))}
                                label='מספר טלפון נייד'
                                onBlur={event => {
                                    if (!validatePhone(value.phone)) {
                                        // dispatch(snackbarToggle({ type: 'error', message: `מספר טלפון לא תקין` }));
                                    }
                                }}
                            />
                            {!validatePhone(value.phone) && value.phone && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                מספר טלפון לא תקין
                            </Box>
                            }
                        </Box>
                        <Box className='col-md-12'>
                            <TextField
                                className='text-field'
                                required
                                dir={'rtl'}
                                tabIndex={"4"}
                                value={value.profileId}
                                onChange={event => setValue(prev => ({ ...prev, profileId: event.target.value }))}
                                label='תעודת זהות של בעל החשבון'
                                
                            />
                            {!validateMyId(value.profileId) && value.phone && <Box className='col-md-12' style={{ direction: "rtl", color: "red",top:"-20px" }}>
                                תעודת זהות לא תקינה
</Box>
                            }
                        </Box>
                    </Box>
                    {<Box className='row flex-rtl align-items-center mb-1rem'>
                        <Box className='col-sm-8 col-xs-12'> <FormControlLabel dir='rtl' tabIndex={"7"} key={uuidv4()} control={< Checkbox checked={value?.isCoupon} onChange={event => setValue(prev => ({ ...prev, isCoupon: event.target.checked }))} />} label={"אני רוצה לקבל קופונים והטבות במייל"} />
                        </Box>
                    </Box>}
                    {/*<Box className='row flex-rtl align-items-center mb-1rem'>
                            <Box className='col-sm-4 col-xs-12 radio-title'>
                                סוג החשבון
                            </Box>
                            <Box className='col-sm-8 col-xs-12'>
                                <Button className={`btn-radio ${!isBusiness ? 'active' : ''}`} onClick={() => setIsBusiness(false)}>
                                    פרטי
                                </Button>
                                */}{/*<Button className={`btn-radio  ${isBusiness ? 'active' : ''}`} onClick={() => setIsBusiness(true)}>
                                    סוג החשבון
                                </Button>*/}{/*
                            </Box>
                        </Box>*/}
                    {<Box className='row flex-rtl align-items-center mb-1rem'>
                        <Box className='col-sm-8 col-xs-12'> <span style={{ marginRight:"28px", textDecoration: "underline", fontSize: "1rem", cursor: "pointer" }} onClick={() => { removeCreditCardToken(); }}>{`מחק אמצעי תשלום`}</span>
                        </Box>
                    </Box>}
                    <Box className='form-action' tabIndex={"9"}>
                        <ActionButton sx={{ width: "45% !important", background: "#0a3b52 !important" }} className={'submit-action'} textLabel={"הכתובות שלי"} onClick={() => { setIsProfile(false); setIsAddAddress(true); }} />
                        <ActionButton sx={{ width: "45% !important", ml: 2 }} disabled={!validatePhone(value.phone) || !validateMyId(value.profileId) || !validateEngText(value.lastName) || !validateEngText(value.firstName)} className={'submit-action'} textLabel={"שמירה"} onClick={() => { saveProfileData(); }} />
                        
                    </Box>

                </Box>
            </Fragment>
        </DialogWithSideBar>
    );
}

export default Profile;