/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Box, Grid } from '@mui/material';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import chatSvg from "../../images/homepage/chatIcon.png"
import logo from "../../images/homepage/logo.png"
import RedboxLogo from './../../images/newHomePage/redbox-logo.png';
import facebookWhite from "../../images/homepage/facebook_white.png"
import twitterWhite from "../../images/homepage/twitter_white.png"
import TZUR from "../../images/homepage/TZUR.png"
import '../LandingPage/LandingPage.scss';
import mainBanner from '../../images/redbox_about.jpg';
import certBanner from '../../images/cert.png';

export const About = ({ setIsRegister }) => {
    const { userInfo } = useSelector(state => state.user);
    const navigate = useNavigate();
    const { snackbar } = useSelector(state => state.snackbar);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }
    const goTo = (e) => {
    }
    useEffect(() => {

    }, [userInfo]);

    const onSnackbarHandleClose = () => { }

    return (
        <Grid lg={12} container justifyContent="center" style={{ direction: "rtl", fontSize: "20px", fontFamily:"Rubik" }}>
            <Grid lg={12} container style={{ maxWidth: "1080px", margin:"20px" }}>
                <Grid lg={12} container item flexDirection="column" alignItems="center" style={{ color:"#d70007", marginBottom:"70px"}} >
                    <h1>אודות</h1>
                    <h1><b>redbox</b> p a r c e l</h1>
                </Grid>
                <Grid lg={12} container item style={{  }}>
                    <p>Red Box Parcel מספקת שירות משלוחים הכולל קבלת כתובות בחו"ל ושילוח עד הבית – עם התחייבות למחיר הזול בישראל. החברה שייכת לקבוצת חברות שילוח <a href="http://tclog.co.il/index.php/he/">Total Care Logistics</a> אשר פועלת ברחבי העולם למעלה מ-15 שנה. לקבוצה סניפים בארה"ב, הולנד וישראל.</p>
                </Grid>
                <Grid lg={12} container item style={{}}>
                    <img src={mainBanner} style={{ width: "100%" }} />
                </Grid>
                <Grid lg={12} container item style={{ }}>
                    <p>לחברת רד בוקס פרסל רישיון עמילות מכס שהוענק לה מטעם משרד האוצר, לשחרור חבילות ומעטפות ביבוא אישי ומסחרי ורישיון בלדרות לשחרור חבילות מהמכס שהוענק לה מטעם מכס ישראל.</p><p>Red Box Parcel מפעילה מחסנים ומרכזים לוגיסטיים בפריסה בינלאומית ומספקת פלטפורמה לייבוא מוצרים בקלות, ביעילות ובזול, בשילוח ממגוון יעדים בארה"ב, אירופה, אנגליה וטורקיה. הצוות המיומן והמקצועי של רד בוקס פרסל מלווה את הלקוח לכל אורך הדרך.</p>
                    <p>החברה מציעה שירות שירות לקוחות מקצועי, מהיר, אמין ושקוף בעברית לכל שאלה.</p>
                </Grid>
                { /*certificate image*/}
                <Grid lg={12} container item flexDirection="column" alignItems="center" style={{ height:"506px"}}>
                    <img src={certBanner} width="577" height="790" sizes="(max-width: 577px) 100vw, 577px" style={{ height: "100%", width:"auto" }} />
                </Grid>
                <Grid lg={12} container item flexDirection="column" alignItems="center" >
                    <h2 style={{ color: "#d70007", margin: "20px" }}>סיפור החברה</h2>
                </Grid>
                <Grid lg={12} container item >
                    <p>בשנת 2009 הקים נמרוד מגידוב את Total Care Logistics, קבוצת שילוח בינלאומית המתמקדת בשילוח B2B. קבוצת טוטאל קר לוג'יסטיקס עובדת עם יבואנים ויצואנים ומתמחה באספקת סל שירותים ופתרונות לניהול תהליכי שרשרת אספקה, כולל:</p>
                    <ul>
                        <li>הובלה ימית ואווירית</li>
                        <li>קונסולידציות אוויריות וימיות מכל היעדים</li>
                        <li>שירותי עמילות מכס</li>
                        <li>ביטוח</li>
                        <li>שירותי drop shipping</li>
                        <li>ניהול מחסנים ממוחשבים בכל היעדים</li>
                        <li>שירותי ערך מוסף הכוללים אחסנה ואריזה</li>
                        <li>הובלה יבשתית ושירותי הפצה</li>
                        <li>מערכת שירותי מידע אונליין</li>
                    </ul>
                </Grid>

                <Grid lg={12} container item >
                    <p>Total Care Logistics מעסיקה כ-40 עובדים בשלושה סניפים: משרדי החברה בהוד השרון, מרכז לוגיסטי באמסטרדם, הולנד ומרכז לוגיסטי בניו ג'רזי, ארה"ב.</p>
                    <p>בשנת 2020 נכנסה טוטאל קר לוגיסטיקס לפיתוח כלים טכנולוגיים מתקדמים לניהול ומעקב אחר מטענים, הזמנות ועוד.</p>
                    <p>בשנת 2022 החברה נכנסה לראשונה לתחום ה-B2C עם חברת הבת Red Box Parcel, חברת קורייר המתמחה במשלוחים ויבוא אישי של חבילות במשקלים של 1-30 קילו.</p>
                </Grid>

            </Grid>
        </Grid>
    );
}

export default About;