/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { memo } from 'react';

import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';

const SelectFieldWrapper = ({sx,sx1,isNumeric=false, parentClassName, className, dir = 'rtl', label, value, onChange, options, islabelAnimate = true, isPrefix = false, disabled, required, xs, sm, md, lg, msx }) => {

    
    return (
        <Box className={`form-field-wrapper ${!islabelAnimate && 'no-animate'} ${parentClassName}`} sx={sx}>
            {/*{label && <InputLabel id={label} >
                {label}
            </InputLabel>
            }*/}
            {isNumeric ? <Select
                className={className}
                value={value || ''}
                label={label}
                onChange={onChange}
                size='small'
                dir={dir}
                disabled={disabled}
                sx={sx1}
                fullWidth>
                {options?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select> :
                <Autocomplete
                    freeSolo
                    selectOnFocus
                    autoComplete={true}
                    autoHighlight={true}
                    autoSelect={true}
                    clearOnBlur
                    fullWidth
                    dir={dir}
                    size='small'
                    sx={sx1}
                    options={options}
                    getOptionLabel={option => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        return option.name ? `${option.name}` : '';
                    }}
                    renderInput={params => <TextField {...params} label={label} required={required} error={required && (value == null || value == '')} />}
                    value={options.find(option => option.id === value)?.name || ''}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            var c = {
                                target: {
                                    value: newValue.id
                                }
                            }
                            onChange(c);
                        } else {

                            //onChange(field, '');
                        }
                    }}
                />}
        </Box>
    );
};

SelectFieldWrapper.defaultProps = {
    label: '',
    value: '',
    required: null,
    options: [],
    xs: 12,
    sm: 6,
    md: 4,
    lg: 2,
    msx: { maxHeight: '400px', fontSize: '0.8rem' }
};

export default memo(SelectFieldWrapper);
