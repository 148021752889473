/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
// JavaScript source code
import { useState, useEffect, useCallback } from 'react';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { URL, LANGUAGES, mailroom_id } from '../constants/global-constants';

import mc from '../images/mc.png'
import visa from '../images/visa.png'
import ae from '../images/ae.png'
import diners from '../images/diners.png'
import global_card from '../images/global_card.png'
import isracard from '../images/isracard.png'
var bDownLoad = false; 
var downLoadId = 0;


export const login = (userName, password, callback) => {
    let loginObj = { "userName": userName, "password": password, opcode: "login" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const activateUserByUserName = (userName,email, callback) => {
    let loginObj = { "userName": userName, "email": email, opcode: "activateUserByUserName" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const forgot_password_request = (userName, email, callback) => {
    let loginObj = { "userName": userName, "email": email, opcode: "forgotPassword" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const changePassword = (token, password, callback) => {
    let loginObj = { "token": token, "password": password, opcode: "changePassword" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const registerUser = (data, callback) => {
    data.opcode = "registerUser"
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}

export const addContactUs = (data,callback)=>{
    data.opcode = "addContactUs";
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const saveProfile = (data, callback) => {
    data.opcode = "saveProfile";
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const setUserData = (data,callback)=>{
    data.opcode = "setUserData";
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const setUserDataToBuyMe = (data,callback)=>{
    data.opcode = "setUserDataToBuyMe";
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const activateRegister = (data,callback)=>{
    data.opcode = "activateRegister";
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const ping = (session, callback) => {
    let loginObj = { "session": session, opcode: "ping" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const logout = (session, callback) => {
    let loginObj = { "sessionId": session, opcode: "logout" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getShippingRequestCount = (session, statuses,callback) => {
    let loginObj = { "sessionId": session, status: statuses, opcode: "getShippingRequestCount" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const consolidateParcels = (session, parcels, freeText,isFree,callback) => {
    let loginObj = { "sessionId": session, parcels: parcels, freeText: freeText, opcode: "consolidateParcels", isFree: isFree }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}

export const rescanParcel = (session, parcelId, freeText,callback) => {
    let loginObj = { "sessionId": session, parcelId: parcelId, freeText: freeText, opcode: "rescanParcel" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const repackParcel = (session, parcelId, freeRepack, freeText,callback) => {
    let loginObj = { "sessionId": session, parcelId: parcelId, freeText: freeText, opcode: "repackParcel", freeRepack: freeRepack }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getRepackRequest = (session, parcelId, callback) => {
    let loginObj = { "sessionId": session, rrid: parcelId, opcode: "getRepackRequest" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const repackRequestAcceptCost = (session, rrid, callback) => {
    let loginObj = { "sessionId": session, rrid: rrid, opcode: "repackRequestAcceptCost" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const cancelRepackRequestCustomer = (session, rrid, callback) => {
    let loginObj = { "sessionId": session, rrid: rrid, opcode: "cancelRepackRequestCustomer" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const addBuyMeRequest = (session, loginObj, callback) => {
    loginObj.opcode = "addBuyMeRequest"
    loginObj.sessionId = session
   sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getBuyMeRequestsByStatuses = (session, loginObj, callback) => {
    loginObj.opcode = "getBuyMeRequestsByStatuses"
    loginObj.sessionId = session
   sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getShippingRequestByHawb = (session, hawb,callback) => {
    let loginObj = { "sessionId": session, hawb: hawb, opcode: "getShippingRequestByHawb" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}

export const downloadByUrl = (url, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
}
export const downloadParcelImage = (hawb, callback) => {
    var url = "/shipping/downloadParcelImage/" + hawb;
    var fileName = "Parcel";
    fetch(url)
        .then(response =>
            response.blob()
        )
        .then(response => {
            const blob = new Blob([response], { type: 'image/png' });
            const downloadUrl = window.URL.createObjectURL(blob);
            //const a = document.createElement("a");
            //a.href = downloadUrl;
            //a.download = fileName;
            //a.target = "_blank";
            //document.body.appendChild(a);
            //a.click();
            callback(downloadUrl);
            return downloadUrl;
        })
}
export const downloadScanImage = (hawb, callback) => {
    var url = "/shipping/downloadScanImage/" + hawb;
    var fileName = "Parcel";
    fetch(url)
        .then(response =>
            response.blob()
        )
        .then(response => {
            const blob = new Blob([response], { type: 'image/png' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            //callback(downloadUrl);
            //return downloadUrl;
        })
}

export const getShippingRequestList = (session, statuses, callback) => {
    if (!session)
        return null;
    let loginObj = { "sessionId": session, status: statuses, opcode: "getShippingRequestList1" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            console.log(response)
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getTopNHotStore = (session, limit,callback) => {
    let loginObj = { "sessionId": session, active: true, limit: limit,hot:true, opcode: "getTopNHotStore" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getStoresByCityAndCategory = (session,ctr,cat,callback) => {
    let loginObj = { "sessionId": session, active: true, country: ctr, category: cat, opcode: "getStoresByCityAndCategory" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getActiveStoreByCountry = (session,ctr,callback) => {
    let loginObj = { "sessionId": session, active: true, country: ctr, hot: true, opcode: "getActiveStoreByCountry" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getHotStoreByCountry = (session,ctr,callback) => {
    let loginObj = { "sessionId": session, active: true, country: ctr, hot: true, opcode: "getHotStoreByCountry" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getHotStoreCountByActive = (session,callback) => {
    let loginObj = { "sessionId": session, active: true, hot: true, opcode: "getHotStoreCountByActive" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getActiveCountries = (session,callback) => {
    let loginObj = { "sessionId": session, active: true, opcode: "getActiveCountries" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getActiveCategories = (session,callback) => {
    let loginObj = { "sessionId": session, active: true, opcode: "getActiveCategories" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getCouponList = (locker,callback) => {
    let loginObj = { opcode: "getActiveCouponList", locker: locker }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const isValidCoupon = (locker,parcelId, whId, coupon,callback) => {
    let loginObj = { opcode: "isValidCoupon", parcelId: parcelId, whId: whId, coupon: coupon, locker: locker }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getCouponValue1 = (locker, parcelId, whId, coupon,wt, callback) => {
    let loginObj = { opcode: "getCouponValue", parcelId: parcelId, whId: whId, wt: wt, coupon: coupon, locker: locker }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)

        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getExchangeRate = (callback) => {
   // callback();
    let loginObj = { opcode: "getExchangeRate" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });/**/
}
export const getCityStreetNcode = (callback) => {
   // callback();
    let loginObj = { opcode: "getCityStreetNcode" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });/**/
}
export const getZigZagAddresses = (callback) => {
   // callback();
    let loginObj = { opcode: "getZigZagAddresses" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });/**/
}
export const getShippingCoPickupPoints = (session, shippingCoId, callback) => {
   // callback();
    let loginObj = { "sessionId": session, shippingCoId: shippingCoId, opcode: "getShippingCoPickupPoints" }
  /*  sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });*/
}
export const buyMeRequestPay = (session, data,callback) => {
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const buyMeRequestPayWithToken = (session, data,callback) => {
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const shippingDelivaryRequestPay = (session, data,callback) => {
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const shippingDelivaryRequestPayWithToken = (session, data, callback) => {
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)

        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const removeCardToken = (data, callback) => {
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)

        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getETAStatus = (h, callback) => {
    var data = { opcode:"getETAStatus", manifestId:h}
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)

        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const getBase64RecieptList = (data, callback) => {
    data.opcode ="getBase64RecieptList"
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const deleteReciept = (data, callback) => {
    data.opcode ="deleteReciept"
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}


export const getShipmentCost = (callback) => {
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, { opcode: "getShipmentCost" }, {
        successCallback: (response) => {
            callback(response)
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}



export const listWarehouse = (callback) => {
    var data = { opcode:"listWarehouse"}
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
            
        },
        failedCallback: error => {
            callback(error)
        }
    });
}
export const shipping_upload = (data, callback) => {
    sendRequest("/shipping/uploadInvoice", REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            console.log(response)

            callback(response)
        },
        failedCallback: error => {
            callback(error)
        }
    }, {
        'Accept': 'application/json'
    });
}
export const validateEngText = function (m) {
   // let text = /^[a-zA-Z_ _(_)_-]+$/;
    let text = /^[\.a-zA-Z0-9,!? ]*$/;
    return text.test(m);
}
export const validateHebrewText = function (m) {
    //return true
   // let text = /^[a-zA-Z_ _(_)_-]+$/;
    let text = /^[0-9\-\u0590-\u05FF '"]*$/;
    return text.test(m);
}
export const validatePhone = function (m) {
    /*let phoneno1 = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    let phoneno2 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let phoneno3 = /^\d{10}$/;*/
    let phone = /^0(5[^7])[0-9]{7}$/;
    //let phone = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
    //let phone = /^[- +]*[0-9][- +0-9]*/;
   /* if (phone.test(m)) {
        return (true)
    }*/
    return phone.test(m);
}
export const validateEmail = function (m) {
    var mReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (mReg.test(m)) {
        return (true)
    }
    return (false)
}
export const onRefreshTrick = function (name, url = window.location.href) {
    window.setTimeout(function () {
        console.log(window.location.href)
        var ht = window.location.href;
        var htArr = ht.split("/")

        var action = false;
        if (htArr?.length) {
            console.log(htArr[htArr.length - 1])
            action = htArr[htArr.length - 1];
        }
        if (action) {
            switch (action) {
                case 'home':
                    window.location = "https://redboxparcel.com"
                    navigate("/home");
                    break;
                case 'FAQS':
                    window.location = "https://redboxparcel.com"
                    navigate("/FAQS");
                    break;
                case 'store':
                    window.location = "https://redboxparcel.com"
                    navigate("/store");
                    break;
                case 'redboxPricing':
                    window.location = "https://redboxparcel.com"
                    navigate("/redboxPricing");
                    break;
                case 'accounts':
                    window.location = "https://redboxparcel.com"
                    navigate("/accounts");
                    break;
                case 'contactUs':
                    window.location = "https://redboxparcel.com"
                    navigate("/contactUs");
                    break;
                default:
                    window.location = "https://redboxparcel.com"
                    navigate("/home");

            }
        }
    }, 1500);
}
export const get_params = function(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export const copyToClipboard1 =function (text,callback) {
    window.navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
        callback("הועתק")
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
        callback("Could not copy text " + err)
    });
}

export function validateMyId(digits) {
    if (isValidIsraeliID(digits)) {
        return true;
    }
    else {
        return false;
    }
}

 export function validateDigits(digits) {
    if (digits != '') {
        var filter = /^[0-9]+$/;
        if (filter.test(digits)) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
       return true;
    }
}
export function checkEmptyText(text) {
    if (text != null && text != "") {
         return true;
    } else {
         return false;
    }
}
function isValidIsraeliID(id1) {
    var id = String(id1).trim();
    if (id.length > 9 || id.length < 5 || isNaN(id)) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}
var defaultFormat = /(\d{1,4})/g;
var cards = [
    //{type:'amex',pattern:/^3[47]/,format:/(\d{1,4})(\d{1,6})?(\d{1,5})?/,length:[15],cvcLength:[4],luhn:true},
    { text: "ae",type: ae, pattern: /^3[47]/, format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/, length: [15], cvcLength: [4], luhn: true },
    { text: "diners",type: diners, pattern: /^(36|38|39|3095|30[0-5]|2014|2149)/, format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/, length: [14], cvcLength: [3], luhn: true },
    { text: "mc",type: mc, pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/, format: defaultFormat, length: [16], cvcLength: [3], luhn: true },
    { text: "visa", type: visa, pattern: /^4/, format: defaultFormat, length: [13, 16, 19], cvcLength: [3], luhn: true },
    { text: "isracard", type: isracard, pattern: /^\d{8,9}$/, format: defaultFormat, length: [8], cvcLength: [3], luhn: true }
]

export function cardFromType(card) {
    switch (card) {
        case "ae":
            return ae;
        case "diners":
            return diners;
        case "mc":
            return mc;
        case "visa":
            return visa;
        case "isracard":
            return isracard;
        default:
            return global_card;
    }
}
export function cardTextFromNumber(num) {
    var card, j, len;
    num = (num + '').replace(/\D/g, '');
    for (j = 0, len = cards.length; j < len; j++) {
        card = cards[j];
        if (card.pattern.test(num)) {
           // document.getElementById("global_card").src = "/images/" + card.type + ".png";
            return card.text;
        }
        
    }
    return "global_card";
}
export function cardFromNumber(num) {
    var card, j, len;
    num = (num + '').replace(/\D/g, '');
    for (j = 0, len = cards.length; j < len; j++) {
        card = cards[j];
        if (card.pattern.test(num)) {
           // document.getElementById("global_card").src = "/images/" + card.type + ".png";
            return card.type;
        }
        
    }
    return global_card;
}
export function checkCvvAndNumber(cvv,num) {
    var card, j, len;
    num = (num + '').replace(/\D/g, '');
    for (j = 0, len = cards.length; j < len; j++) {
        card = cards[j];
        if (card.pattern.test(num)) {
           // document.getElementById("global_card").src = "/images/" + card.type + ".png";
            if (validateDigits(cvv) && card.cvcLength.includes(cvv.length)) {
                return true;
            } else {
                return false;
            }
        }
    }
    if (cvv.length == 3 || cvv.length == 4) {
        return true;
    }
    return false
}
var luhnChk = (function (arr) {
    return function (ccNum) {
        var
            len = ccNum.length,
            bit = 1,
            sum = 0,
            val;

        while (len) {
            val = parseInt(ccNum.charAt(--len), 10);
            sum += (bit ^= 1) ? arr[val] : val;
        }

        return sum && sum % 10 === 0;
    };
}([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));
export function validateCardNumber(item,callback) {
    var card = item.replace(/ /g, "");
    var filter = /^[0-9\- ]{8,19}$/;
    var bSucc = false;
    var bIsra = false;
    if (filter.test(card)) {
        if ((card.length == 8) || (card.length == 9)) {
            bIsra = true;
        }
        bSucc = true;
    }
    else {
        bSucc = false;
    }
    if (bSucc) {
        bSucc = luhnChk(card);
    }
    callback(bSucc, bIsra)
}

export function validateYear(year) {
   // return true;
    var d = getDateObject(new Date());
    if (Number(d.year) > Number(year)) {
        return false;
    } else {
        return true;
    }
}
export function validateMonth1(month) {
   // return true;
    var d = getDateObject(new Date());
    if (Number(month) <= Number(d.month)) {
        return false;
    } else {
        return true;
    }
}
export function validateMonth(year, month) {
    //return true;
    var bFalse = false;
    var d = getDateObject(new Date());
    if (validateYear(year)) {
        bFalse = true;
    }
    if (Number(year) == Number(d.year)) {
        bFalse = false;
        if (Number(month) == Number(d.month)) {
            return true;
        }
        if (validateMonth1(month)) {
            bFalse = true;
        } else {
            bFalse = false;
        }
    }
    /*if (Number(year) > Number(d.year)) {
        if (Number(d.month) <= Number(month) && validateYear(year)) {
            return true
        } else if (Number(d.month) > Number(month) && validateYear(year)) {
            return true
        }
        return false;
    } */
    return bFalse
}
export function getDateObject(date) {
    let p = new Intl.DateTimeFormat('en', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    }).formatToParts(date).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    return { day: p.day, month: p.month, year: p.year, hr: p.hour, second: p.second, dayPeriod: p.dayPeriod, };
}