/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Box, Grid } from '@mui/material';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import chatSvg from "../../images/homepage/chatIcon.png"
import logo from "../../images/homepage/logo.png"
import RedboxLogo from './../../images/newHomePage/redbox-logo.png';
import facebookWhite from "../../images/homepage/facebook_white.png"
import twitterWhite from "../../images/homepage/twitter_white.png"
import TZUR from "../../images/homepage/TZUR.png"
import '../LandingPage/LandingPage.scss';
import mainBanner from '../../images/redbox_about.jpg';
import certBanner from '../../images/cert.png';

export const Blog = ({ setIsRegister }) => {
    const { userInfo } = useSelector(state => state.user);
    const navigate = useNavigate();
    const { snackbar } = useSelector(state => state.snackbar);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }
    const goTo = (e) => {
    }
    useEffect(() => {
        if (window && document) {
            const script = document.createElement('script')
            const body = document.getElementsByTagName('body')[0]
            script.src = 'https://io.dropinblog.com/embedjs/a8245eb8-1369-4518-a392-d9bcbf326274.js'
            body.appendChild(script)
            script.addEventListener('load', () => {
                window.hbspt.forms.create({
                    // this example embeds a Hubspot form into a React app but you can tweak it for your use case
                    // any code inside this 'load' listener will run after the script is appended to the page and loaded in the client
                })
            })
        }
    }, [userInfo]);

    const onSnackbarHandleClose = () => { }

    return (
        <Grid lg={12} container justifyContent="center" style={{ direction: "rtl", fontSize: "20px", fontFamily:"Rubik" }}>
            
            <div id="dib-posts"></div>
        </Grid>
    );
}

export default Blog;