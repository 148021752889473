import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    snackbar: false,
  },
  reducers: {
    snackbarToggle: (state, { payload }) => {
          state.snackbar = payload;
          
    },
      snackbarAutoClose: (state, { payload }) => {
          window.setTimeout(function () { state.snackbar = false; },4000);
    },
  },
});

// Action creators are generated for each case reducer function
export const { snackbarToggle, snackbarAutoClose } = snackbarSlice.actions;

export default snackbarSlice.reducer;
