/* eslint-disable react/prop-types */
import React from "react";
import { Box, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './DialogWithSideBar.scss'

const DialogWithSideBar = ({ 
        open = false, 
        onClose = () => { }, 
        onClickBack = () => { }, 
        isBackButton = false, 
        isOutClose = false, 
        children 
    }) => {

    if (!open) {
        return null;
    }

    return (
        
        <Box className="sidebar-dialog" onClick={(e) => {
            if (isOutClose) {
                onClose()
            }
            e.stopPropagation();
        }}>
            <Box className="sidebar-form-dialog" onClick={(e) => {
                e.stopPropagation();
            }}>
                <Button className='close-dialog-btn' onClick={() => onClose()}>
                    <CloseIcon className='close-dialog' />
                </Button>
                {isBackButton && <Button className='back-dialog-btn' onClick={() => onClickBack()}>
                    <span>חזור </span> <ChevronRightIcon className='close-dialog' />
                </Button>}
                {children}
            </Box>
        </Box>
    )
}

export default DialogWithSideBar;