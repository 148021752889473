/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Grid, Box } from '@mui/material';
import './ContactUs.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import contactTitle from '../../images/title/contact-title.jpg'
import headingStyle from '../../images/icons/heading-style.png'
import account_banner from '../../images/homepage/account_banner.png'
import email from '../../images/homepage/email.png'
import cursor from '../../images/homepage/cursor.png'
import help1 from '../../images/homepage/help1.png'
import telephoneCall from '../../images/homepage/telephone-call.png'
import whatsapp from '../../images/homepage/whatsapp.png'
import chat from '../../images/homepage/chat.png'
import MetaTags from 'react-meta-tags';

import loyalityBotLeftBg from "../../images/homepage/loyalty_bot_left_bg.png"
import loyalityTag from "../../images/homepage/loyalty_tag.png"
import loyalityTopRightGb from "../../images/homepage/loyalty_top_right_gb.png"
import loyalityWoman from "../../images/homepage/loyalty_woman.png"
import how_to_redbox_bot_bg from '../../images/homepage/how_to_redbox_bot_bg.png'
import address from '../../images/homepage/address.png'
import tracking from '../../images/homepage/tracking.png'
import calculator from '../../images/homepage/calculator.png'
import userPng from '../../images/homepage/user.png'
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import ContactForm from '../ContactForm/ContactForm';
import ActionButton from '../Common/ActionButton';

export const ContactUs = ({ setIsLogin, setIsRegister, setActiveScreenInreg }) => {
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));


    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isDialog, setIsDialog] = useState(false);

    const goTo = (e) => {
    }
    const inVw = (px) => {
        return px * 100 / 1080;
    }
    const inVh = (px) => {
        return px * 100 / 1080;
    }
    const keyPressed = (e) => {
        // eslint-disable-next-line no-empty
        if (e.keyCode === 13) {

        }
    }
    useEffect(() => {
        //
    }, [userInfo]);

    return (
        <>
            <Paper sx={{ width: '100%', overflowX: 'hidden' }}>
                <MetaTags id="metaContact">
                    <title>Redbox Contact Us</title>
                    <meta id="meta-description" name="ContactUs" content="כל הדרכים ליצירת קשר" />
                    <meta id="meta-description-title" property="og:title" content="Redbox parcel" />
                </MetaTags>
                <Box component={'section'} className="page-title dark_image_filter" >
                    <Box className="title-section">
                        <Box className="container">
                            <h1 className="entry-title center_text rtl_text bold_text">כל הדרכים ליצירת קשר</h1>
                        </Box>
                    </Box>
                </Box>
                {/*<Grid items container lg={12} justifyContent="center">
                    <Grid items container lg={5.5} md={11} sx={11} style={{ margin: "10px", marginBottom: "30px", padding: "20px", direction: "rtl", color: "#d70007", fontSize: "20px", fontWeight: "bold", border: "1px solid #d70007", borderRadius: "5xp" }}>
                        {`לקוחות יקרים,` }
                        <br/>{`בעקבות המלחמה מוקד שירות הלקוחות שלנו מתמודד עם עומסים חריגים.` }
                        <br />{`בנושאים הקשורים לשירות לקוחות, בירורים בנוגע לסטטוס משלוח או חבילה, ניתן לפנות אלינו בווטאספ בימים א-ה בין השעות 09:00-16:00 או במייל - בכל זמן שנוח לכם.` }
                        <br />{`אנו עושים כמיטב יכולתנו לחזור אליכם בזמן הקצר ביותר ומתחייבים כי נעשה כל מאמץ על מנת שכל פנייה לשירות הלקוחות תקבל מענה עד 2 ימי עסקים.` }
                        <br />{`כאן בשבילכם, צוות Red Box` }
                    </Grid>
                </Grid>*/}
                <Box component={'section'} id="main-content">
                    <Grid container justifyContent="center">
                        <Grid item container justifyContent="center" lg={8} sx={{ margin: {xs: '0'}}}>
                            
                            {/*<Box className='col-lg-4 col-md-6 col-xs-12 mb-30'>
                                <Grid className="card-box">
                                    <Box className='img-icon-box'>
                                        <img src={telephoneCall} className="img-responsive img-icon" alt="" />
                                    </Box>
                                    <Box className="card-content">
                                        <p className="card-title">שירות לקוחות בטלפון</p>
                                        <p className='card-detail' style={{ direction: "ltr", marginLeft: "8%", textAlign:"right" }}> +972 547074297</p>
                                        <p className='card-detail'>שעות פעילות: 10:00-13:00</p>
                                        <p className='card-detail'> ימים: א-ה </p>
                                    </Box>
                                </Grid>
                            </Box>*/}
                            {/*<Box className='col-lg-6 col-md-6 col-xs-12 mb-30'>
                                <Grid className="card-box" onClick={() => setIsDialog(true)}>
                                    <Box className='img-icon-box'>
                                        <img src={chat} className="img-responsive img-icon" alt="" />
                                    </Box>
                                    <Box className="card-content">
                                        <p className="card-title">באמצעות פנייה באתר</p>
                                        <p className='card-detail'> כתבו לנו באמצעות הפנייה </p>
                                    </Box>
                                </Grid>
                            </Box>*/}
                            <Box className='col-lg-6 col-md-6 col-xs-12 mb-30' onClick={(e) => {
                                //window.location.href = "tel:+1-555-555-1212";
                                window.location.href = "mailto:info@redboxparcel.com";
                                e.preventDefault();
                            }}>
                                <Grid className="card-box">
                                    <Box className='img-icon-box'>
                                        <img src={email} className="img-responsive img-icon" alt="" />
                                    </Box>
                                    <Box className="card-content">
                                        <p className="card-title">שלחו לנו מייל</p>
                                        <p className='card-detail'>
                                            info@redboxparcel.com
                                        </p>
                                    </Box>
                                </Grid>
                            </Box>
                            {/*<Box className='col-lg-4 col-md-6 col-xs-12 mb-30'>
                                <Grid className="card-box" onClick={() => { document.getElementById('how_to_redbox').scrollIntoView(); }}>
                                    <Box className='img-icon-box'>
                                        <img src={cursor} className="img-responsive img-icon" alt="" />
                                    </Box>
                                    <Box className="card-content">
                                        <p className="card-title"> פעולות בקליק</p>
                                        */}{/*<p className='card-detail'>הנגר 24, הוד השרון </p>
                                        <p className='card-detail'>מיקוד 4257713</p>*/}{/*
                                    </Box>
                                </Grid>
                            </Box>*/}
                            <Box className='col-lg-6 col-md-6 col-xs-12 mb-30'>
                                <Grid className="card-box" onClick={function () { navigate('/FAQS', { state: { id: 1, name: 'faqList' } }) }}>
                                    <Box className='img-icon-box'>
                                        <img src={help1} className="img-responsive img-icon" alt="" />
                                    </Box>
                                    <Box className="card-content">
                                        <p className="card-title">שאלות ותשובות</p>
                                        <p className='card-detail'>כל מה שאתם צריכים לדעת</p>
                                        <p className='card-detail'> נמצא פה.</p>
                                    </Box>
                                </Grid>
                            </Box>
                            {<Box id="wtId" className='col-lg-6 col-md-6 col-xs-12 mb-30' onClick={(e) => {
                                
                            }}>
                                <a aria-label="Chat on WhatsApp" href="https://wa.me/+972543202099" target="_blank">
                                    <Grid className="card-box">
                                        <Box className='img-icon-box'>
                                            <img src={whatsapp} className="img-responsive img-icon" alt="" />
                                        </Box>
                                        <Box className="card-content">
                                            <p className="card-title">שירות לקוחות ב-Whatsapp</p>
                                            {/*<p className='card-detail'>0547074297</p>*/}
                                            <p className='card-detail'>שעות פעילות: 9:00-16:00</p>
                                            <p className='card-detail'> ימים: א-ה</p>
                                        </Box>
                                    </Grid>
                                </a>
                            </Box>}
                        </Grid>
                    </Grid>
                    <Box className="home_page_section_bot_but_one bot_space_40" style={{ minHeight: '500px'}} id="how_to_redbox">
                        {/* <img className="home_page_section_bot_but_one_bot_img" src={how_to_redbox_bot_bg} /> */}
                        <Box className="container">
                            {/* Section Title */}
                            <Box className="home_page_section_bot_but_one_header bot_space_40" sx={{ fontSize: {xs: '30px'}}}>
                                {`פעולות בקליק`}
                            </Box>
                            <Grid container className="row flex-rtl" sx={{ margin: {xs: '0'}}}>
                                <Grid item sm={3} xs={6} onClick={function () {
                                    if (userInfo.session) {
                                        navigate("/accounts")
                                    } else {
                                        setIsRegister(true)
                                    }
                                }} className="cursor_pointer">
                                    <Box className="min_hieght_set set_mid">
                                        <img src={userPng} className="how_to_images4" />
                                    </Box>
                                    <Box className='how_to_title'>
                                        <Button className="with_arrow_icon">{`להרשמה מהירה`}</Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={3} xs={6} onClick={function () {
                                    if (userInfo.session) {
                                        navigate("/accounts")
                                    } else {
                                        setIsLogin(true)
                                    }
                                }} className="cursor_pointer">
                                    <Box className="min_hieght_set set_mid">
                                        <img src={tracking} className="how_to_images2" />
                                    </Box>
                                    <Box className='how_to_title'>
                                        <Button className="with_arrow_icon">{`מעקב משלוחים`}</Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={3} xs={6} onClick={() => { if (userInfo.session) { setActiveScreenInreg('PersonalData'); setIsRegister(true) } else { setIsLogin(true) } }} className="cursor_pointer">
                                    <Box className="min_hieght_set set_mid">
                                        <img src={address} className="how_to_images1" />
                                    </Box>
                                    <Box className='how_to_title'>
                                        <Button className="with_arrow_icon">{`הכתובות שלי`}</Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={3} xs={6} onClick={() => { navigate("/redboxPricing") }} className="cursor_pointer">
                                    <Box className="min_hieght_set set_mid">
                                        <img src={calculator} className="how_to_images3" />
                                    </Box>
                                    <Box className='how_to_title'>
                                        <Button className="with_arrow_icon">{`מחשבון משלוחים`}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid class="contact_page_section_action">
                                <ActionButton onClick={function () {
                                    if (userInfo.session) {
                                        navigate("/accounts")
                                    } else {
                                        setIsLogin(true)
                                    }
                                }} textLabel={`לכל הפעולות באיזור האישי`} />
                            </Grid>
                        </Box>
                    </Box>
                    {/* <Box className="container">
                        <Box className="home_page_section_bot">
                            <Box className="home_page_section_bottom_right">
                                <Box className="home_page_section_text_content_right">
                                    <Box className="home_page_section_text_content_header">הצטרפו לתוכנית הנאמנות של Red Box!</Box>
                                    <Box className="home_page_section_text_content_text">רק חברי V.I.P של Red Box נהנים ממחירים בלעדיים ומ-10% הנחה על כל משלוח!</Box>
                                    <Box style={{ margin: '20px', float: 'right', width: '100%' }}>
                                        <Box component={'span'} className="home_page_section_text_content_button">למידע נוסף</Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="home_page_section_bottom_left">
                                <img className="home_page_section_bottom_left_bot" src={loyalityBotLeftBg}></img>
                                <img className="home_page_section_bottom_left_center" src={loyalityTag}></img>
                            </Box>
                            <Box className="home_page_section_top_left">
                                <Box className="home_page_section_text_content_left">
                                    <Box className="home_page_section_text_content_header">רוצים להישאר מעודכנים? הצטרפו לניוזלטר שלנו</Box>
                                    <Box style={{ margin: '20px', float: 'right', width: '100%' }}>
                                        <input style={{ borderRadius: '1.5rem' }} id="home_page_section_text_content_email" placeholder="האימייל שלך"></input>
                                        <Box component={'span'} className="home_page_section_text_content_button">להרשמה</Box>
                                    </Box>
                                    <Box className="home_page_section_text_content_text">מאשר/ת להשתמש במידע שמסרתי לצרכי הודעות ופרסומות כמפורט בתקנון האתר</Box>
                                </Box>
                            </Box>
                            <Box className="home_page_section_top_right">
                                <img className="home_page_section_top_right_img_bot" src={loyalityTopRightGb}></img>
                                <img className="home_page_section_top_right_img_center" src={loyalityWoman}></img>
                            </Box>
                        </Box>
                    </Box>*/}
                </Box>
            </Paper>
            <DialogWithSideBar
                open={isDialog}
                isOutClose={true}
                onClose={() => setIsDialog(false)}
            >
                <ContactForm setIsDialog={setIsDialog} />
            </DialogWithSideBar>
        </>
    );
}

export default ContactUs;