/* eslint-disable react/prop-types */
import React from "react";
import LoadingImg from '../../images/Spin-loader.gif';
import './loader.scss';

const Loader = ({isFullDisplay = false, className = '', isDarkBg = false}) => {
  return (
      <div className={`loader-wrapper ${className} ${isFullDisplay ? 'fixed' : ''} ${isDarkBg ? 'dark-bg' : ''}`} style={{zIndex:"9999999"}}>
      <img src={LoadingImg} alt="Loading..." />
    </div>
  )
}

export default Loader;