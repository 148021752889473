/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Paper, Grid, Box, Typography } from '@mui/material';
import './Store.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import account_banner from '../../images/homepage/account_banner.png';
import how_to_redbox_bot_bg from '../../images/homepage/how_to_redbox_bot_bg.png';
import expressDelivery from '../../images/homepage/express-delivery.png';
import creditCard from '../../images/homepage/credit-card.png';
import bag from '../../images/homepage/bag.png';
import userPng from '../../images/homepage/user.png';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import ContactForm from '../ContactForm/ContactForm';
import ActionButton from '../Common/ActionButton';
import LocMarker from '../../images/homepage/pin.png';
import Sephora from '../../images/store/sephora.png';
import UsPoloassn from '../../images/store/uspoloassn.png'
import flag_tr_small from '../../images/homepage/flag_tr_small.png'
import flag_po_small from '../../images/homepage/flag_po_small.png'
import flag_eu_small from '../../images/homepage/flag_eu_small.png'
import flag_us_small from '../../images/homepage/flag_us_small.png'
import hol from '../../images/homepage/hol.png'
import ger from '../../images/homepage/ger.png'
import fran from '../../images/homepage/fran.png'
import bel from '../../images/homepage/bel.png'
import store_middle_button from '../../images/store/store_middle_buton_img.png'
import globe from '../../images/homepage/globe1.png'
import top_back from '../../images/top_back.png'
import { TextField, SelectField, ConfirmDialog } from '../../style-guide';
import { listWarehouse, getActiveStoreByCountry, getHotStoreByCountry, getStoresByCityAndCategory, getHotStoreCountByActive, getTopNHotStore, getActiveCategories, getActiveCountries } from '../../reducers/requestHandler';
import AlertDialog from '../../style-guide/Dialog/AlertDialog';
import Loader from '../../style-guide/Loader';
import MetaTags from 'react-meta-tags';
export const Store = (props) => {
    const location = useLocation();
    const { setIsPersonal, setActiveScreenInreg, setIsRegister, setIsLogin } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [categories, setCategories] = new useState([]);
    const [countries, setCountries] = new useState([]);
    const [storeList, setStoreList] = new useState([]);
    const [allWh, setAllWh] = new useState([]);
    const [tempAllWh, setTempAllWh] = new useState([]);
    const [westEuCont, setWestEuCont] = new useState([]);
    const [wareHouseIds, setWareHouseIds] = new useState([]);
    const [orgAllStoreList, setOrgAllStoreList] = new useState([]);
    const [allStoreList, setAllStoreList] = new useState([]);
    const [hotStoreList, setHotStoreList] = new useState([]);
    const [selectedTab, setSelectedTab] = new useState(-1);
    const [selectedCategory, setSelectedCategory] = new useState("");
    const [isLoading, setIsLoading] = new useState(false);
    const [bSideWindow, setBSideWindow] = new useState(false);
    const [selectedStore, setSelectedStore] = new useState({});
    const [selectedCountry, setSelectedCountry] = new useState(1);
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isDialog, setIsDialog] = useState(false);

    const getExt = (name) => {
        let ext = ".png";
        if (name) {
            var lastIndexOf = name.lastIndexOf(".");
            if (lastIndexOf == -1) {
                ext = ".png"; // empty extension
            }
            ext = name.substring(lastIndexOf);
        }
        return ext;
    }
    const inVw = (px) => {
        return px * 100 / 1080;
    }
    const inVh = (px) => {
        return px * 100 / 1080;
    }
    const setWIds = (arr) => {
        var ids = [];
        for (var i = 0; i < arr.length; i++) {
          var hardCodeWesUroId=  tempAllWh?.find(u => u.name === 'מערב אירופה')?.id
            var hardCodeTurId = tempAllWh?.find(u => u.name === 'טורקיה')?.id
            /*if (arr[i]?.params?.wId == hardCodeWesUroId || arr[i]?.params?.wId == hardCodeTurId) {*/
            if (arr[i]?.params?.wId == hardCodeWesUroId) {
                ids.push(arr[i]?.params?.wId)
            }
            
        }
        let t = ids.filter((item,
            index) => ids.indexOf(item) === index);
        /* t.push(-1)
         t.push(-2)
         t.push(-3)
         t.push(-4)
         const index = t.indexOf(tempAllWh?.find(u => u.name === 'מערב אירופה')?.id);
 
         const x = t.splice(index, 1);*/

        //setWareHouseIds(t);


        if (location?.state?.name == "store_list") {
            document.getElementById('store_list').scrollIntoView();
            window.setTimeout(function () {
                if (location?.state?.wId) {

                    document.getElementById('store_home').scrollIntoView();
                    var t = [];
                    t.push(location?.state?.wId)
                    setWareHouseIds(t)
                }
            }, 1200);
        }


    }
    const keyPressed = (e) => {
        // eslint-disable-next-line no-empty
        if (e.keyCode === 13) {

        }
    }
    useEffect(() => {
        //if (userInfo.session) {

        setIsLoading(true)
        listWarehouse(function (data1) {
            if (data1) {
                var data = []
                var dataWids = []
                setTempAllWh(data1.data);
                var westEu = []

                if (!isMobile()) {
                    var d = data1.data.find(u => u.name === 'מערב אירופה');
                    if (d) {
                        var l = data1.data.length;
                        var t = { ...d }
                        t.name = "הולנד";
                        t.id = -1;
                        data.push(t);
                        westEu.push(t);
                        t = { ...d }
                        t.name = "גרמניה";
                        t.id = -2;
                        data.push(t);
                        westEu.push(t);
                        t = { ...d }
                        t.name = "צרפת";
                        t.id = -3;
                        data.push(t)
                        westEu.push(t)
                        t = { ...d }
                        t.name = "בלגיה";
                        t.id = -4;
                        data.push(t)
                        westEu.push(t)
                    }
                    console.log("*************")
                    console.log(data)
                    setWestEuCont(westEu)

                    /*data1.data.map((dat, index) => {
                        if (dat.name != 'פולין' && dat.name != 'מערב אירופה' && dat.name != 'טורקיה' && dat.name != 'ארה”ב' && dat.name != 'אנגליה' && dat.name != 'מערב אירופה') {
                            data.push(dat)
                        }
                    })*/

                    data1.data.map((dat, index) => {
                        if (dat.name === 'מערב אירופה') {
                            data.push(dat)
                            dataWids.push(dat.id)
                        }
                    })/**/

                    /**/

                    data1.data.map((dat, index) => {
                        if (dat.name === 'ארה”ב') {
                            data.push(dat)
                            dataWids.push(dat.id)
                        }
                    })
                    /*data1.data.map((dat, index) => {
                        if (dat.name === 'טורקיה') {
                            data.push(dat)
                            dataWids.push(dat.id)
                        }
                    })*/
                    setWareHouseIds(dataWids);
                    setAllWh(data)
                } else {
                    /*data1.data.map((dat, index) => {
                        if (dat.name === 'טורקיה') {
                            data.push(dat)
                            dataWids.push(dat.id)
                        }
                    })*/

                    data1.data.map((dat, index) => {
                        if (dat.name === 'ארה”ב') {
                            data.push(dat)
                            dataWids.push(dat.id)
                        }
                    })

                    data1.data.map((dat, index) => {
                        if (dat.name === 'מערב אירופה') {
                            data.push(dat)
                            dataWids.push(dat.id)
                        }
                    })
                    var d = data1.data.find(u => u.name === 'מערב אירופה');
                    if (d) {
                        var l = data1.data.length;
                        var t = { ...d }
                        t.name = "הולנד";
                        t.id = -1;
                        data.push(t);
                        westEu.push(t);
                        t = { ...d }
                        t.name = "גרמניה";
                        t.id = -2;
                        data.push(t);
                        westEu.push(t);
                        t = { ...d }
                        t.name = "צרפת";
                        t.id = -3;
                        data.push(t)
                        westEu.push(t)
                        t = { ...d }
                        t.name = "בלגיה";
                        t.id = -4;
                        data.push(t)
                        westEu.push(t)
                    }
                    console.log("*************")
                    console.log(data)
                    setWestEuCont(westEu)
                    /*data1.data.map((dat, index) => {
                        if (dat.name != 'פולין' && dat.name != 'מערב אירופה' && dat.name != 'טורקיה' && dat.name != 'ארה”ב' && dat.name != 'אנגליה' && dat.name != 'מערב אירופה') {
                            data.push(dat)
                        }
                    })*/
                    /**/



                    setWareHouseIds(dataWids);
                    setAllWh(data)
                }
            }
        });

        getTopNHotStore(userInfo.session, 6, function (response) {
            if (response.misc.CODE === 1) {
                setIsLoading(false)
                setStoreList(response.data)
            }
        })

        getActiveCountries(userInfo.session, function (response) {
            if (response.misc.CODE === 1) {
                var t = [{
                    id: 1,
                    name: "All"
                }]
                response.data.map((c, index) => {
                    t.push({
                        name: c,
                        id: index + 2
                    })
                })
                setCountries(t)
                getActiveStoreByCountry(userInfo.session, "all", function (response1) {
                    if (response1.misc.CODE === 1) {
                        

                        setAllStoreList(response1.data)
                        setOrgAllStoreList(response1.data)
                        setWIds(response1.data)
                        
                    }
                })
            }
        })

        getActiveCategories(userInfo.session, function (response) {
            if (response.misc.CODE === 1) {
                setCategories(response.data)
            }
        })
        if (location?.state?.name == "store_list") {
            document.getElementById('store_list').scrollIntoView();

        }
        
        // }
    }, [userInfo]);

    const getFlagByW = (w) => {
        switch (w) {
            case 'מערב אירופה':
                return flag_eu_small
            case 'ארה”ב':
                return flag_us_small
            case 'פולין':
                return flag_po_small
            case 'טורקיה':
                return flag_tr_small
            case 'הולנד':
                return hol
            case 'גרמניה':
                return ger
            case 'צרפת':
                return fran
            case 'בלגיה':
                return bel
        }
    }
    return (
        <>
            {isLoading && <Loader isFullDisplay />}
            <Paper sx={{ width: '100%', overflowX: 'hidden' }}>
                <MetaTags id="metaStore">
                    <title>{ `חנויות ומותגים מומלצים`} </title>
                    <meta id="meta-description" name="Store" content='חנויות ומותגים מומלצים' />
                    <meta id="meta-description-title" property="og:title" content="Redbox parcel" />
                </MetaTags>
                <Box component={'section'} className="page-title dark_image_filter">
                    <Box className="title-section">
                        <Box className="container">
                            <Typography component={'h1'} className="page-main-title center_text rtl_text bold_text">{ `חנויות ומותגים מומלצים`}</Typography>
                            <Typography className='page_sub_title' style={{direction: "rtl"}}> { `יש אינספור חנויות ומותגים בעולם ששולחים לארה"ב ואירופה - הנה כמה מהם:`}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box component={'section'} className="popular-section">
                    <Box className="custom_container">
                        <Typography component={'h1'} className='section-title'>החנויות הפופולאריות</Typography>
                        <Grid container sx={{ justifyContent: 'space-between' }}>
                            {storeList.map((st, ind) => (<Grid container key={ind} item md={1.9} sm={3.8} xs={5.8} className="shadow_box">
                                {/* <Grid container className='head' sx={{ flexWrap: "nowrap" }}>
                                    <Box>{st.category}</Box>
                                    <Box><img src={LocMarker} className='marker-icon' alt="" /><span>{st.country}</span></Box>
                                </Grid> */}

                                <Grid container className='content'>
                                    <img onClick={() => {
                                        window.open(st.link, "_blank");
                                    }} style={{ maxHeight: "50px" }} src={`/images/shops/${st.id}_logo${getExt(st.params?.fileName_logo)}`} alt='' />
                                </Grid>
                                <Grid container className='action'>
                                    <Button className='action-btn underline-btn' onClick={() => {
                                        setSelectedStore(st);
                                        setIsDialog(true);
                                    }}>טיפים לקניה</Button>
                                </Grid>
                            </Grid>
                            ))}
                        </Grid>
                        <Grid container sx={{ justifyContent: 'center' }}>
                            <Button className='action-filled-btn' onClick={() => {
                                document.getElementById('store_list').scrollIntoView();
                            }}>לכל החנויות</Button>
                        </Grid>
                    </Box>
                </Box>

                {false&&<Box component={'section'} className="home_page_section_bot_but_one" sx={{ minHeight: '500px' }}>
                   {/* <img className="home_page_section_bot_but_one_bot_img" src={how_to_redbox_bot_bg} />*/}
                    <Box className="container">
                        <Box className="row">
                            <Box className="col-sm-12 home_page_section_bot_but_one_header bot_space_40">איך קונים בחו"ל עם Red Box?</Box>
                        </Box>
                        <Box className="row">
                            <Box className="col-sm-1"></Box>
                            <Box className="col-sm-10 how_to_container_wrapper">
                                <Box className="col-md-3 col-sm-6 cursor_pointer" sx={{ marginBottom: { xs: '30px' } }} onClick={function () {
                                    if (userInfo.session) {
                                        navigate("/accounts")
                                    } else {
                                        setIsLogin(true)
                                    }
                                }}>
                                    <Box className="how_to_image_container set_mid">
                                        <img src={expressDelivery} className="how_to_images1" />
                                    </Box>
                                    <Box className="how_to_title">4</Box>
                                    <Box className="how_to_title">המשלוח בדרך אליכם</Box>
                                    <Box className="how_to_text">כל השאר עלינו! אנחנו נדאג לקליטת</Box>
                                    <Box className="how_to_text">ומשלוח החבילה שלכם עד לבית!</Box>
                                </Box>
                                <Box className="col-md-3 col-sm-6 cursor_pointer" onClick={function () {
                                    if (userInfo.session) {
                                        navigate("/accounts")
                                    } else {
                                        setIsLogin(true)
                                    }
                                }} sx={{ marginBottom: { xs: '30px' } }}>
                                    <Box className="how_to_image_container set_mid"><img src={creditCard} className="how_to_images2" /></Box>
                                    <Box className="how_to_title">3</Box>
                                    <Box className="how_to_title">תשלום</Box>
                                    <Box className="how_to_text">החבילה מגיעה למרכז שלנו, ואתם</Box>
                                    <Box className="how_to_text">מקבלים הודעת תשלום למשלוח</Box>
                                </Box>
                                <Box className="col-md-3 col-sm-6 cursor_pointer" onClick={function () {
                                    navigate("/store")
                                }} sx={{ marginBottom: { xs: '30px' } }}>
                                    <Box className="how_to_image_container set_mid"><img src={bag} className="how_to_images3" /></Box>
                                    <Box className="how_to_title">2</Box>
                                    <Box className="how_to_title">קונים בחו"ל</Box>
                                    <Box className="how_to_text">מזמינים את המותגים שאתם הכי אוהבים</Box>
                                    <Box className="how_to_text">לכתובת החדשה שלכם בחו"ל</Box>
                                </Box>
                                <Box className="col-md-3 col-sm-6 cursor_pointer" onClick={function () {
                                    if (userInfo.session) {
                                        navigate("/accounts")
                                    } else {
                                        setIsRegister(true)
                                    }
                                }} sx={{ marginBottom: { xs: '30px' } }}>
                                    <Box className="how_to_image_container set_mid"><img src={userPng} className="how_to_images4" /></Box>
                                    <Box className="how_to_title">1</Box>
                                    <Box className="how_to_title">נרשמים בחינם</Box>
                                    <Box className="how_to_text">מצטרפים ומקבלים כתובת ווירטואלית בחו"ל</Box>
                                    <Box className="how_to_text">לרישום מהיר {'>'}</Box>
                                </Box>
                            </Box>
                            <Box className="col-sm-1" ></Box>
                        </Box>
                    </Box>
                </Box>}

                 <Box component={'section'} className="popular-section" style={{ paddingBottom: '30px' }}>
                    <Box className="container" id="store_list">
                        <Typography id="store_home" component={'h1'} className='section-title'>כל החנויות לשופינג הבא שלכם</Typography>
                        {allWh.length > 0 && !isMobile() &&
                            <Grid className="shadow_box" >
                                <Grid container item md={12} sm={12} xs={11.8} className="page_title_flags_content_flag_div_container">
                                    {allWh?.filter((w) => { if (w.params?.active && w.id != tempAllWh?.find(u => u.name === 'מערב אירופה')?.id) { return w } }).map((w, i) => (<Grid key={i} container item sm={1} xs={3} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                        /*
                                        setAllStoreList(t);*/
                                        var ti = w.id;
                                        if (ti < 0) {
                                            ti = tempAllWh?.find(u => u.name === 'מערב אירופה')?.id;
                                        }
                                        var t = [];
                                        t.push(ti)
                                        setWareHouseIds(t)
                                    }}>
                                        <span style={{ marginTop: '10px', position: 'relative' }}>
                                            <img src={getFlagByW(w.name)} className="flag_img" />
                                            {/*{getFlagByW(w.name) === flag_us_small  && <label className='flag_label'>{`בקרוב`}</label>}
                                        {getFlagByW(w.name) === flag_po_small  && <label className='flag_label'>{`בקרוב`}</label>}*/}
                                        </span>
                                        {/* {w.id }*/}
                                        {w.name == 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{'אנגליה'}</Typography>}
                                        {w.name != 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{w.name}</Typography>}
                                    </Grid>
                                    ))}
                                    {<Grid container item sm={1} xs={3} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                        var t = [];
                                        for (var i = 0; i < allWh.length; i++) {
                                            if (allWh[i]?.params?.active)
                                                t.push(allWh[i].id);
                                        }
                                        setWareHouseIds(t);
                                    }}>
                                        <span className='flag_img img_bg1'><img src={globe} className="flag_img_sqr" /></span>
                                        <Typography className="page_title_flags_content_flag_div_p">כל החנויות</Typography>
                                    </Grid>}
                                    {/*<Grid container item sm={3} xs={6} className="page_title_flags_content_flag_div">
                                    <Link to="#slide2" style={{ marginTop: '10px' }}><img src={flag_po_small} className="flag_img" /></Link>
                                    <Typography className="page_title_flags_content_flag_div_p">פולין</Typography>
                                </Grid>
                                
                                <Grid container item sm={3} xs={6} className="page_title_flags_content_flag_div">
                                    <Link to="#slide4" style={{ marginTop: '10px' }}><img src={flag_us_small} className="flag_img" /></Link>
                                    <Typography className="page_title_flags_content_flag_div_p">ארה"ב</Typography>
                                </Grid>*/}
                                </Grid>
                            </Grid>}

                        {allWh.length > 0 && isMobile() &&
                            <Grid className="shadow_box" >
                                <Grid container item md={12} sm={12} xs={11.8} className="page_title_flags_content_flag_div_container">
                                    {allWh?.filter((w) => { if (w.params?.active && w.id != tempAllWh?.find(u => u.name === 'מערב אירופה')?.id  && w.name == 'ארה”ב') { return w } }).map((w, i) => (<Grid key={i} container item sm={1} xs={4} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                        /*
                                        setAllStoreList(t);*/
                                        var ti = w.id;
                                        if (ti < 0) {
                                            ti = tempAllWh?.find(u => u.name === 'מערב אירופה')?.id;
                                        }
                                        var t = [];
                                        t.push(ti)
                                        setWareHouseIds(t)
                                    }}>
                                        <span style={{ marginTop: '10px', position: 'relative' }}>
                                            <img src={getFlagByW(w.name)} className="flag_img" />
                                            {/*{getFlagByW(w.name) === flag_us_small  && <label className='flag_label'>{`בקרוב`}</label>}
                                        {getFlagByW(w.name) === flag_po_small  && <label className='flag_label'>{`בקרוב`}</label>}*/}
                                        </span>
                                        {/* {w.id }*/}
                                        {w.name == 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{'אנגליה'}</Typography>}
                                        {w.name != 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{w.name}</Typography>}
                                    </Grid>
                                    ))}
                                    {allWh?.filter((w) => { if (w.params?.active && w.id != tempAllWh?.find(u => u.name === 'מערב אירופה')?.id && w.name == 'טורקיה' && w.name != 'ארה”ב') { return w } }).map((w, i) => (<Grid key={i} container item sm={1} xs={3} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                        /*
                                        setAllStoreList(t);*/
                                        var ti = w.id;
                                        if (ti < 0) {
                                            ti = tempAllWh?.find(u => u.name === 'מערב אירופה')?.id;
                                        }
                                        var t = [];
                                        t.push(ti)
                                        setWareHouseIds(t)
                                    }}>
                                        <span style={{ marginTop: '10px', position: 'relative' }}>
                                            <img src={getFlagByW(w.name)} className="flag_img" />
                                            {/*{getFlagByW(w.name) === flag_us_small  && <label className='flag_label'>{`בקרוב`}</label>}
                                        {getFlagByW(w.name) === flag_po_small  && <label className='flag_label'>{`בקרוב`}</label>}*/}
                                        </span>
                                        {/* {w.id }*/}
                                        {w.name == 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{'אנגליה'}</Typography>}
                                        {w.name != 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{w.name}</Typography>}
                                    </Grid>
                                    ))}
                                    {<Grid container item sm={1} xs={4} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                        var t = [];
                                        for (var i = 0; i < allWh.length; i++) {
                                            if (allWh[i]?.params?.active)
                                                t.push(allWh[i].id);
                                        }
                                        setWareHouseIds(t);
                                    }}>
                                        <span className='flag_img img_bg1'><img src={globe} className="flag_img_sqr" /></span>
                                        <Typography className="page_title_flags_content_flag_div_p">כל החנויות</Typography>
                                    </Grid>}
                                    {allWh?.filter((w) => { if (w.params?.active && w.id != tempAllWh?.find(u => u.name === 'מערב אירופה')?.id && w.name !='טורקיה'&& w.name !='ארה”ב') { return w } }).map((w, i) => (<Grid key={i} container item sm={1} xs={3} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                        /*
                                        setAllStoreList(t);*/
                                        var ti = w.id;
                                        if (ti < 0) {
                                            ti = tempAllWh?.find(u => u.name === 'מערב אירופה')?.id;
                                        }
                                        var t = [];
                                        t.push(ti)
                                        setWareHouseIds(t)
                                    }}>
                                        <span style={{ marginTop: '10px', position: 'relative' }}>
                                            <img src={getFlagByW(w.name)} className="flag_img" />
                                            {/*{getFlagByW(w.name) === flag_us_small  && <label className='flag_label'>{`בקרוב`}</label>}
                                        {getFlagByW(w.name) === flag_po_small  && <label className='flag_label'>{`בקרוב`}</label>}*/}
                                        </span>
                                        {/* {w.id }*/}
                                        {w.name == 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{'אנגליה'}</Typography>}
                                        {w.name != 'פולין' && <Typography className="page_title_flags_content_flag_div_p">{w.name}</Typography>}
                                    </Grid>
                                    ))}
                                    
                                    {/*<Grid container item sm={3} xs={6} className="page_title_flags_content_flag_div">
                                    <Link to="#slide2" style={{ marginTop: '10px' }}><img src={flag_po_small} className="flag_img" /></Link>
                                    <Typography className="page_title_flags_content_flag_div_p">פולין</Typography>
                                </Grid>
                                
                                <Grid container item sm={3} xs={6} className="page_title_flags_content_flag_div">
                                    <Link to="#slide4" style={{ marginTop: '10px' }}><img src={flag_us_small} className="flag_img" /></Link>
                                    <Typography className="page_title_flags_content_flag_div_p">ארה"ב</Typography>
                                </Grid>*/}
                                </Grid>
                            </Grid>}
                        <Grid container className='fillter-tabs' justifyContent="space-between" >
                            {false ? <SelectField
                                className='text-field store_select'
                                required
                                value={selectedCountry}
                                onChange={event => {
                                    setSelectedCountry(event.target.value)
                                    var ctr = countries.find(u => u.id === event.target.value)?.name;
                                    if (selectedTab != -1) {
                                        setIsLoading(true)
                                        getStoresByCityAndCategory(userInfo.session, ctr, selectedCategory, function (response1) {
                                            if (response1.misc.CODE === 1) {
                                                setIsLoading(false)
                                                setAllStoreList(response1.data)
                                                setOrgAllStoreList(response1.data)
                                                //setWIds(response1.data)
                                            }
                                        })
                                    } else {
                                        setIsLoading(true)
                                        getHotStoreByCountry(userInfo.session, ctr, function (response1) {
                                            if (response1.misc.CODE === 1) {
                                                setIsLoading(false)
                                                setAllStoreList(response1.data)
                                                setOrgAllStoreList(response1.data)
                                                //setWIds(response1.data)
                                            }
                                        })
                                    }
                                }
                                }
                                label=''
                                options={countries}
                                islabelAnimate={false}
                                isPrefix
                                parentClassName={'select-field xs-fullwidth'}
                            /> : <div>&nbsp;</div>}
                            <ul className='tabs-list'>
                                <li className={selectedTab == -1 ? 'active' : ''}><a onClick={() => {
                                    setSelectedTab(-1)
                                    var ctr = countries.find(u => u.id === selectedCountry)?.name;
                                    setIsLoading(true)
                                    getHotStoreByCountry(userInfo.session, ctr, function (response1) {
                                        if (response1.misc.CODE === 1) {
                                            setIsLoading(false)
                                            setAllStoreList(response1.data)
                                            setOrgAllStoreList(response1.data)
                                            //setWIds(response1.data)
                                        }
                                    })
                                }}>הכי פופולאריים</a></li>
                                {categories.map((c, index) => (<li className={selectedTab == index ? 'active' : ''} key={index + 1} onClick={() => {
                                    setSelectedTab(index)
                                    setSelectedCategory(c)
                                    var ctr = countries.find(u => u.id === selectedCountry)?.name;
                                    setIsLoading(true)
                                    getStoresByCityAndCategory(userInfo.session, ctr, c, function (response1) {
                                        if (response1.misc.CODE === 1) {
                                            setIsLoading(false)
                                            setAllStoreList(response1.data)
                                            setOrgAllStoreList(response1.data)
                                            //setWIds(response1.data)
                                        }
                                    })
                                }}>
                                    <a>
                                        {c}
                                    </a>
                                </li>))}

                                {/*<li><a href="#">אופנה</a></li>
                                <li><a href="#">פארם וטיפוח</a></li>
                                <li><a href="#">חשמל ואלקטרוניקה</a></li>
                                <li><a href="#">מחשבים וטאבלטים</a></li>
                                <li><a href="#">לרכב</a></li>
                                <li><a href="#">חיות מחמד</a></li>
                                <li><a href="#">קטגוריות נוספות</a></li>*/}

                            </ul>

                        </Grid>
                    </Box>
                    <Box className="custom_container">

                        {wareHouseIds.filter((w) => { if (w) { return w } }).map((wId) => (<>{allStoreList.filter((st) => {

                            /*var t = 0;
                            if (wId < 0) {
                                t = tempAllWh?.find(u => u.name === 'מערב אירופה')?.id
                            }
                            if (st?.params?.wId == wId || st?.params?.wId == t ) {
                                return st
                            }*/
                            if (st?.params?.wId == wId) {
                                return st
                            }
                        }).length > 0 && <Grid container key={wId} justifyContent="flex-end" sx={{ borderRadius: "10px", mb: 2 }}>
                                {allWh?.find(u => u.id === wId)?.name &&
                                    <>{tempAllWh?.find(u => u.name === 'מערב אירופה')?.id != wId ? <Box className='brand_card_title'>{allWh?.find(u => u.id === wId)?.name}(<span>חנויות מומלצות </span>) <img src={getFlagByW(allWh?.find(u => u.id === wId)?.name)} style={{ margin: "5px" }} className="flag_img" /></Box> : 
                                    <Box className='brand_card_title'>
                                        <Grid className="" style={{color:"white !important"}}>
                                            <Grid container item md={10} sm={10} xs={10} className="page_title_flags_content_flag_div_container">
                                                {westEuCont?.map((w1, i) => (<Grid key={i} container item sm={2} xs={2} className="page_title_flags_content_flag_div cursor_pointer" >
                                                    <span style={{ marginTop: '10px', position: 'relative' }}>
                                                        <img src={getFlagByW(w1.name)} className="flag_img" />
                                                        {/*{getFlagByW(w.name) === flag_us_small  && <label className='flag_label'>{`בקרוב`}</label>}
                                                        {getFlagByW(w.name) === flag_po_small  && <label className='flag_label'>{`בקרוב`}</label>}*/}
                                                    </span>
                                                    <Typography className="page_title_flags_content_flag_div_p" style={{ color:"white" }}>{w1.name}</Typography>
                                                </Grid>
                                                ))}

                                            </Grid>
                                        </Grid>
                                    </Box>
                                    }</>}

                                <Grid container sx={{ justifyContent: 'center' }}>
                                    {allStoreList.filter((st) => {
                                        /*var t = 0;
                            if (wId < 0) {
                                t = tempAllWh?.find(u => u.name === 'מערב אירופה')?.id
                            }*/
                                        if (st?.params?.wId == wId) {
                                            return st
                                        }
                                    }).map((st, ind) => (<Grid container key={ind} item md={1.9} sm={3.8} xs={5.8} className="shadow_box">
                                        {/* <Grid container className='head' sx={{ flexWrap: "nowrap" }}>
                                    <Box>{st.category}</Box>
                                    <Box><img src={LocMarker} className='marker-icon' alt="" /><span>{st.country}</span></Box>
                                </Grid> */}

                                        <Grid container className='content'>
                                            <img onClick={() => {
                                                window.open(st.link, "_blank");
                                            }} style={{ maxHeight: "50px" }} src={`/images/shops/${st.id}_logo${getExt(st.params?.fileName_logo)}`} alt={st.name} />
                                        </Grid>
                                        <Grid container className='action'>
                                            <Button className='action-btn underline-btn' onClick={() => {
                                                setSelectedStore(st);
                                                setIsDialog(true);
                                            }}>טיפים לקניה</Button>
                                        </Grid>
                                    </Grid>
                                    ))}
                                </Grid>

                            </Grid>}</>))}
                    </Box>

                </Box>
            </Paper>
            <DialogWithSideBar
                open={isDialog}
                isOutClose={true}
                onClose={() => { setIsDialog(false); setBSideWindow(false) }}
            >
                <Box container className="reg_popup_data">
                    <Box item lg={12} className="popup_tittle lg">
                        לפני שאתם עוברים לאתר
                    </Box>
                    <Box item lg={12} className="popup_tittle">
                        <img style={{ height: "30px" }} src={`/images/shops/${selectedStore.id}_logo${getExt(selectedStore.params?.fileName_logo)}`} alt='' />
                    </Box>
                    <Box item lg={12} className="popup_tittle sm">
                        טיפים להזמנה באתר
                    </Box>
                    <Grid container item lg={12} justifyContent="flex-end">
                        <ul className="store_tips">
                            {allWh.find(u => u.id === selectedStore.params?.wId)?.params?.rightTextList?.map((t, index) => (<>{t && <li key={index}>
                                {t}
                            </li>}</>))}
                        </ul>
                    </Grid>
                    {/* <Box item lg={12} className="popup_tittle" onClick={() => {
                        setBSideWindow(true);
                    }}>

                        <img className="cursor_pointer" style={{ maxWidth: "none", height: "280px" }} src={`data:${selectedStore.params?.right_type};base64,${selectedStore.rightInfo}`} alt='' />
                    </Box>*/}
                    {/* { <Box item lg={12} className="popup_tittle" >
                        <ActionButton type={'submit'} textLabel={`איך למלא את פרטי המשלוח`} onClick={() => {
                            setBSideWindow(true);
                        }} sx={{ height: "50px !important", fontSize:"16px !important" }} />
                    </Box>} */}

                    <Box item lg={12} className="popup_tittle sm anchor"
                        style={{ position: 'relative', cursor: 'pointer' }}
                        onClick={() => setBSideWindow(true)}>
                        <img src={store_middle_button} style={{ maxWidth: '110%', marginLeft: '-9%' }} alt={'טיפים להזמנה באתר'} />
                        <label className='btton_label'>{`טיפים להזמנה באתר`}</label>
                    </Box>


                    <Grid justifyContent="center" alignItems="center" style={{ background: "#f6f6f6", borderRadius: "20px", border: "1px solid #e9e9e9", padding: '10px' }}>
                        <div style={{ fontSize: "16px !important", lineHeight: 1.13, padding: "10px" }} className="top_space_20 bot_space_10 redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer bold_text" onClick={() => {

                            if (userInfo.session) {
                                setActiveScreenInreg('PersonalData'); setIsRegister(true);
                            } else {
                                setIsLogin(true)
                            }

                        }} variant={'outlined'}>
                            התחברו וקבלו את הכתובת שלכם
                        </div>
                        <Box className="bot_space_20 center_text cursor_pointer" onClick={() => {
                            if (userInfo.session) {
                                setActiveScreenInreg('PersonalData'); setIsRegister(true);
                            } else {
                                setActiveScreenInreg('Registeration'); setIsRegister(true);
                            }
                        }}>
                            <span className="">
                                עדיין לא נרשמתם? <span style={{ fontWeight: "bold" }}>הירשמו בחינם וקבלו כתובת לקניות בחו"ל &gt;</span>
                            </span>
                        </Box>
                    </Grid>
                    <Box className='form-action'>
                        <ActionButton type={'submit'} className={'submit-action'} textLabel={`${selectedStore.name} המשך לאתר`} onClick={() => {
                            window.open(selectedStore.link, "_blank")
                        }} />
                    </Box>
                </Box>
                {bSideWindow && <Box className="reg_popup_data left-side">

                    <Grid container item lg={1} justifyContent="flex-start" alignContent="flex-end" sx={{ mt: '2vh !important', fontSize: "24px", fontWeight: "bold", color: "#d70007", cursor: "pointer" }} onClick={() => { setBSideWindow(false) }}>
                        <img src={top_back} className="flag_img" style={{ width: "40px" }} />
                    </Grid>
                    <Box item lg={12} className="popup_tittle lg">
                        טיפים לרישום וקנייה באתר זה
                    </Box>
                    <Grid container item lg={12} justifyContent="flex-end" sx={{ padding: "3rem !important", paddingBottom: "0 !important" }}>
                        <ul className="store_tips">
                            {selectedStore.params?.textList?.map((t, index) => (<>{t && <li key={index}>
                                {t}
                            </li>}</>))}
                        </ul>
                    </Grid>
                    <Grid container item lg={11} justifyContent="center" alignContent="center" sx={{ color: '#d70007', fontSize: "14px", fontWeight: "bold" }}>
                        <img src={`/images/shops/${selectedStore.id}_left${getExt(selectedStore.params?.fileName_left)}`} alt='' />
                    </Grid>

                </Box>}
            </DialogWithSideBar>
            {/* <ConfirmDialog open = {true} message={'Are you sure want to close this dialog box?'} /> */}
            {/* <AlertDialog open={true} /> */}
        </>
    );
}

export default Store;