/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Draggable from 'react-draggable';
import { autoCloseSnackbar } from '../../constants/global-constants';
import { validateEngText } from '../../reducers/requestHandler';
const PaperComponent = props => {
    return (
        <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
};

const AlertDialog = ({ type = "", open = false, onHandleCancel = () => { }, children, maxWidth, message = '' }) => {
    const [bOpen, setBopen] = useState(open);
    const dispatch = useDispatch();
    useEffect(() => {
        if (type != 'error') {
            window.setTimeout(function () {
                onHandleCancel();
            }, autoCloseSnackbar);
        }
    }, [open]);
    return (
        <Dialog
            open={open}
            onClose={onHandleCancel}
            maxWidth={maxWidth}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            className='system-alert'>

            <IconButton onClick={onHandleCancel} className='close-icon'>
                <CloseIcon />
            </IconButton>
            <DialogContent className='alert-content'>
                {type == 'error' ? <WarningAmberIcon className='alert-icon' /> : <ThumbUpIcon className='alert-icon' sx={{ fill: "green !important" }} />}
                {/* <Typography component={'h4'}>{`ישנה תקלה במערכת`}</Typography>*/}
                <Typography component={'p'} style={{ direction: validateEngText(message)?'ltr': "rtl" }}>{message}</Typography>
                {children}
            </DialogContent>
        </Dialog>
    );
};


export default AlertDialog;
