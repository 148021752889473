/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';

import { Box, TextField } from '@mui/material';

const TextFieldWrapper = ({
    label,
    value,
    onChange,
    onPaste,
    onBlur,
    onKeyDown,
    readOnly,
    required,
    placeholder,
    type,
    xs,
    sm,
    md,
    sx,
    lg,
    endAdornment,
    className,
    dir = 'rtl',
    tabIndex,
    islabelAnimate = true,
    isPrefix = false,
    parentClassName = ''
}) => {
    let inputProps = { tabIndex: tabIndex, sx: { fontSize: '0.8rem', padding: '0px', background: readOnly ? '#e9e9e9' : '' } }

    if (type == 'number') {
        inputProps = { inputProps: { min: 0 } };
    }

    return (
        <Box className={`form-field-wrapper ${!islabelAnimate && 'no-animate'} ${parentClassName}`} sx={sx} autoComplete="off" component="form">
            <TextField
                InputProps={{
                    readOnly,
                    ...inputProps,
                    endAdornment,
                }}
                dir={dir}
                error={required && (value === null || value === '')}
                required={required}
                placeholder={label}
                fullWidth
                label={islabelAnimate ? label : null}
                type={type}
                value={value || ''}
                onChange={onChange}
                onPaste={onPaste}
                sx={sx}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className={className}
                autoComplete="new-password"

            />
        </Box>
    );
};

TextFieldWrapper.defaultProps = {
    label: '',
    value: '',
    placeholder: '',
    readOnly: false,
    required: null,
    endAdornment: null,
    xs: 12,
    sm: 6,
    md: 4,
    lg: 2,
};

export default memo(TextFieldWrapper);
