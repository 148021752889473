/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Box, Grid } from '@mui/material';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import chatSvg from "../../images/homepage/chatIcon.png"
import logo from "../../images/homepage/logo.png"
import RedboxLogo from './../../images/newHomePage/redbox-logo.png';
import facebookWhite from "../../images/homepage/facebook_white.png"
import twitterWhite from "../../images/homepage/twitter_white.png"
import instagram from "../../images/homepage/instagram.png"
import TZUR from "../../images/homepage/TZUR.png"
import { FaInstagram } from "react-icons/fa";
export const Content = ({ setIsRegister }) => {
    const { userInfo } = useSelector(state => state.user);
    const navigate = useNavigate();
    const { snackbar } = useSelector(state => state.snackbar);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }
    const goTo = (e) => {
    }
    useEffect(() => {

    }, [userInfo]);

    const onSnackbarHandleClose = () => { }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', background:"#404040" }}>
            <Grid container className="container" sx={{ marginBottom: '50px', flexDirection: { xs: 'column-reverse', sm: 'column' } }}>
               {/* <Grid className="row">
                    <img className="footer_chat" src={chatSvg}></img>

                </Grid>*/}
                
                <Grid container className="row" sx={{ margin: { xs: '0' }, justifyContent: 'space-between' }}>

                    <Grid item md={2.5} sm={5.8} xs={12} sx={{ marginBottom: { xs: '20px' } }}>
                        <ul id="menu-information" className="menu">
                            <li>
                              <a>  כתובתינו
                                    </a></li>
                            <li>
                                <a>  Red box parcel</a>
                            </li>
                            <li>
                                <a>  רחוב הנגר 24 הוד השרון 4527713
                                </a></li>
                            <li>
                                <a>   24 Hanagar St. Hod Hasharon 4527713</a>
                            </li>
                            {/*<li ><a onClick={() => {
                                if (userInfo.session) {
                                    navigate("/accounts")
                                } else {
                                    setIsRegister(true)
                                }
                            }} >לקבלת כתובת בארה"ב</a></li>
                            <li ><a onClick={() => {
                                if (userInfo.session) {
                                    navigate("/accounts")
                                } else {
                                    setIsRegister(true)
                                }
                            }} >לקבלת כתובת במערב אירופה</a></li>
                            <li ><a onClick={() => {
                                if (userInfo.session) {
                                    navigate("/accounts")
                                } else {
                                    setIsRegister(true)
                                }
                            }}>לקבלת כתובת בטורקיה</a></li>
                            <li ><a onClick={() => {
                                if (userInfo.session) {
                                    navigate("/accounts")
                                } else {
                                    setIsRegister(true)
                                }
                            }} >לקבלת כתובת באנגליה</a></li>*/}
                        </ul>
                    </Grid>
                    <Grid item md={2} sm={5.8} xs={12} sx={{ marginBottom: { xs: '20px' } }}>
                        <ul id="menu-information" className="menu">
                            <li className="menu-799"><a style={{ cursor: "pointer" }} onClick={() => { navigate('/store') }}>חנויות</a></li>
                            <li className="menu-799"><a style={{ cursor: "pointer" }} onClick={() => { navigate('/FAQS') }} >איך זה עובד?</a></li>
                            <li className="menu-799"><a style={{ cursor: "pointer" }} onClick={() => { navigate('/redboxPricing')}} >תעריפי משלוח</a></li>
                           {/* <li className="menu-799"><a onClick={() => { navigate('FAQS') }} >שאלות ותשובות</a></li>*/}
                            <li className="menu-799"><a style={{ cursor: "pointer" }} onClick={() => { navigate('/contactUs') }} >שירות לקוחות</a></li>
                            <li className="menu-799"><a style={{ cursor: "pointer" }} target="_blank" href="/מדיניות פרטיות1.pdf" style={{color:"white"}}>מדיניות הפרטיות</a></li>
                            <li className="menu-799"><a style={{ cursor: "pointer" }} target="_blank" href="/TnC.pdf" style={{ color: "white" }}>תנאי השימוש</a></li>
                        </ul>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}style={{ color: "white" }} sx={{ marginBottom: { xs: '20px' } }}>
                        <Box id="menu-information" className="menu menu_long_bg">
                            <p style={{ direction: "rtl" }}>
                                { `Redbox parcel שייכת לקבוצת חברות שילוח Total Care Logistics, אשר פועלת ברחבי העולם למעלה מ 15 שנה עם משרד ראשי בישראל וסניפים בארה"ב והולנד.`}<br/>
                                { `Redbox Parcel מפעילה מחסנים ומרכזים לוגיסטיים בפריסה בינלאומית ומספקת פלטפורמה המאפשרת לייבא מוצרים בקלות וביעילות מארה"ב ומערב אירופה. לחברה רישיונות עמילות מכס מטעם משרד האוצר לשחרור חבילות ומעטפות ביבוא אישי ומסחרי. הצוות המיומן והמקצועי שלנו מלווה את הלקוח לכל אורך הדרך, כולל שירות לקוחות בעברית. Red Box Parcel מציעה שירות מקצועי, מהיר, אמין ושקוף הכולל מענה בעברית לכל נושאי המיסוי הכרוכים ביבוא אישי.`}<br/>
                                <br />
                            </p>
                            {/*<p style={{direction:"rtl"}}>Red Box Parcel שייכת לקבוצת חברות שילוח אשר פועלת ברחבי העולם למעלה מ 15 שנה.<br/>
                                לקבוצה, סניפים בארה&quot;ב, הולנד ותורכיה.<br />
                                Red Box Parcel מספקת פלטפורמה המאפשרת לייבא מוצרים בקלות וביעילות ממגוון יעדים בארה&quot;ב, מערב
                                אירופה, אנגליה  ומפעילה מחסנים ומרכזים לוגיסטיים מעבר לים בפריסה בינלאומית.<br />
                                לחברה רשיונות עמילות מכס מטעם משרד האוצר לשחרור חבילות ומעטפות ביבוא אישי ומסחרי.<br />
                                הצוות המיומן והמקצועי שלנו מלווה את הלקוח לכל אורך הדרך, כולל שירות לקוחות בעברית.<br/>Red Box Parcel
                                מציעה שירות מקצועי, מהיר, אמין ושקוף כולל ודאות מוחלטת לגבי המחיר הסופי ומענה בעברית לכל נושאי
                                המיסוי הכרוכים ביבוא אישי.<br />
                            </p>*/}
                            <Box className="footer_menu_logo"><img alt="" src={logo} className="footer_menu_logo" /></Box>
                            <Box className="footer_menu_facebook_logo" onClick={() => {
                                window.open("https://www.facebook.com/redboxparcel/")
                                
                            }}><img alt="" src={facebookWhite} className="footer_menu_icons" /></Box>
                            <Box onClick={() => { window.open("https://www.instagram.com/redboxparcel/") }} className="footer_menu_twitter_logo">
                                {false && <a style={{ cursor: "pointer" }}>  <svg class="svg svg-insta" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <use xlinkHref="https://redboxparcel.com/instagram.svg#insta"></use>
                                </svg>
                                </a>}
                                <FaInstagram style={{ width: "35px", height: "35px" ,cursor:"pointer" }}></FaInstagram>
                                {false&&<img alt="" src={instagram} className="footer_menu_icons" />}</Box>
                            
                        </Box>
                    </Grid>
                </Grid>

                {/*<Box className="row"><img style={{width:'300px',padding:'2rem'}} src={TZUR}></img></Box>*/}
            </Grid>

            {!userInfo.session && <Box className="footer_last" id="conditional_footer">
                <a style={{cursor:"pointer"}} onClick={() => {
                    setIsRegister(true)
                }} >עדיין אין לך כתובת לקניות בחו"ל?</a>
            </Box>

            }
            {/*{snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}*/}
        </Paper>
    );
}

export default Content;